export const tipoPago = [
  { id: false, label: 'Porcentaje' },
  { id: true, label: 'Fijo' },
];

export const tipoPagoCobranza = [
  { id: 6, label: 'Pago de mensualidad' },
  { id: 8, label: 'Abono a capital' },
];

