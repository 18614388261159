import React, { useState } from 'react';
import { Grid, Hidden } from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useHistory } from 'react-router-dom';

import TextField from '../../componentes/TextField';
import Button from '../../componentes/Button';
import Footer from '../../componentes/Footer';
import SpinnerCirculo from '../../componentes/SpinnerCirculo';
import styles from './styles';
import endpoints from '../../configuraciones/endpoints';
import axios from '../../configuraciones/axios';
import {
  CAMPO_REQUERIDO, CORREO_NO_REGISTRADO,
  CONTRASENA_OLVIDADA_ETIQUETA
} from '../../constantes/mensajes';
import { emailRegex, regexMessages } from "../../utilidades/regex";

const schema = yup.object({
  correo: yup.string()
    .matches(
      emailRegex,
      {
        message: regexMessages.EMAIL,
        excludeEmptyString: true,
      }
    ).required(CAMPO_REQUERIDO),
});

const ContrasenaOlvidada = () => {
  const [mostrarSpinner, setMostrarSpinner] = useState(false);

  const history = useHistory();

  const classes = styles();

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    formState: { errors },
    setError,
  } = methods;


  const restablecerContrasena = (usuario) => {
    setMostrarSpinner(true);

    localStorage.setItem('correoUsuario', usuario.correo ?? '');

    axios.post(endpoints.envioCorreoContrasenaOlvidada(), usuario)
      .then(response => {
        setMostrarSpinner(false);
        if (response.status === 209) {
          setError('correo', {
            type: 'manual',
            message: CORREO_NO_REGISTRADO,
          });
        } else {
          history.push('/correo-confirmacion');
        }
      })
      .catch(error => {
        setMostrarSpinner(false);
      });
  }

  return (
    <>
      <Grid container className={classes.main}>
        <Grid item xs={12}>
          <Grid container className={classes.body}>
            <Hidden smDown>
              <Grid item md={7} className={classes.contenedorTitulo}>
                <Grid container alignItems='baseline'>
                  <img src='/assets/images/logoSINfondo.svg' alt='icono' width='100' height='100' />
                </Grid>
                <Grid container alignItems='baseline'>
                  <h1>Condor&nbsp;</h1>
                  <h2>ADMON</h2>
                </Grid>
              </Grid>
            </Hidden>
            <Grid item xs={12} md={4}>
              <FormProvider {...methods}>
                <form noValidate onSubmit={handleSubmit(restablecerContrasena)}>
                  <Grid container spacing={1} className={classes.card} id="card">
                    <Grid item xs={12}>
                      <h2>Olvidé mi contraseña</h2>
                      {!mostrarSpinner && <><p>{CONTRASENA_OLVIDADA_ETIQUETA}</p> </>}
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container justifyContent='center'>
                        <img src='/assets/images/logoSINfondo.svg' alt='icono' className={classes.logoDelCampo} />
                      </Grid>
                    </Grid>
                    {mostrarSpinner && <SpinnerCirculo label='Restableciendo contraseña' />}
                    {!mostrarSpinner && <>
                      <Grid item xs={12}>
                        <TextField
                          name='correo'
                          placeHolder="Correo electrónico"
                          required
                          error={errors.correo}
                          helperText={errors.correo?.message}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          name="restablecer"
                          label="Restablecer Contraseña"
                          classesCustom={{ boton: classes.botonInicio }}
                          isSubmit
                        />
                      </Grid>
                      <Grid item xs={12} className={classes.pieCardLabel}>
                        <Link to='/login' className={classes.enlace}>
                          <h2>Regresar</h2>
                        </Link>
                      </Grid>
                    </>}
                  </Grid>
                </form>
              </FormProvider>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Footer />
        </Grid>
      </Grid >
    </>
  );
};

export default ContrasenaOlvidada;
