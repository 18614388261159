import React, { useCallback, useEffect, useState } from 'react';
import {
  AppBar as MaterialBar, Toolbar,
  Box, Button,
  Menu, MenuItem,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { RiArrowDownSFill } from 'react-icons/ri';
import isEqual from 'lodash/isEqual';

import Typography from '../../componentes/Typography';
import { quitarPermisos, setPermisos } from '../../ducks/appbar';
import { removeToken } from '../../ducks/user';

import axios from '../../configuraciones/axios';
import endpoints from '../../configuraciones/endpoints';

import styles from './styles';

const AppBar = () => {
  const classes = styles();
  const { permisos, user } = useSelector(
    ({
      appbar: { permisos },
      user: { user }
    }) => ({ permisos, user }),
    (prev, next) => {
      return (
        isEqual(prev.permisos, next.permisos) &&
        prev.user.perfilId === next.user.perfilId &&
        prev.user.nombre === next.user.nombre
      );
    }
  );
  const dispatch = useDispatch();
  const [userAnchor, setUserAnchor] = useState(null);

  const cerrarSesion = useCallback(() => {
    dispatch(removeToken());
    dispatch(quitarPermisos());
    sessionStorage.clear();
    localStorage.clear();
  }, [dispatch]);

  useEffect(() => {
    if (permisos.length === 0) {
      axios.get(endpoints.permisosAsignados(user.perfilId)).then((data) => {
        dispatch(setPermisos(data));
      });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <MaterialBar position="static" className={classes.root}>
        <Toolbar>
          <Box flex={1} style={{ height: '64px', display: 'flex' }} flexDirection="row">
            <Box className={classes.logoContainer}>
              <img
                alt="logo"
                src="/assets/images/logoSINfondo.svg"
                className={classes.logo} />
            </Box>
            <Box flex={1} className={classes.userContainer}>
              <Menu
                keepMounted={false}
                open={Boolean(userAnchor)}
                anchorEl={userAnchor}
                onClose={() => setUserAnchor(null)}
                transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              >
                <MenuItem onClick={cerrarSesion}>Cerrar sesión</MenuItem>
              </Menu>
              <div className={classes.borderContainer}>
                <Button
                  variant="text"
                  className={classes.user}
                  onClick={({ currentTarget }) => setUserAnchor(currentTarget)}>
                  <Typography>{user.nombre}</Typography>
                  <RiArrowDownSFill />
                </Button>
              </div>
            </Box>
          </Box>
        </Toolbar>
      </MaterialBar>
    </div>
  );
};

export default React.memo(AppBar);
