import React from 'react';
import propTypes from 'prop-types';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Dialog,
  Divider,
  Grid,
} from '@material-ui/core';
import { IoMdClose } from 'react-icons/io';
import styles from './styles';
import Typography from '../Typography';

const Modal = ({
  handleClose,
  open,
  titulo,
  children,
  showTitle,
  showActions,
  actions,
  maxWidth,
}) => {
  const classes = styles();
  return (
    <div>
      <Dialog onClose={handleClose} open={open} maxWidth={maxWidth}>
        {showTitle && (
          <DialogTitle disableTypography>
            <Grid container alignItems="center">
              <Grid item xs={10}>
                <Typography variant='h6'>{titulo}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    {handleClose ? (
                      <IconButton className={classes.closeButton} onClick={handleClose}>
                        <IoMdClose />
                      </IconButton>
                    ) : null}
                  </Grid>
                </Grid>

              </Grid>
            </Grid>
          </DialogTitle>
        )}
        <Divider orientation='horizontal' />
        <DialogContent className={classes.contenido}>{children}</DialogContent>
        {showActions && (
          <DialogActions className={classes.acciones}>{actions}</DialogActions>
        )}
      </Dialog>
    </div>
  );
};

Modal.propTypes = {
  /** Componente hijo a renderizar */
  children: propTypes.node.isRequired,
  /** Titulo/Label del modal */
  titulo: propTypes.string,
};

Modal.defaultProps = {
  titulo: '',
  showActions: false,
  showTitle: true,
  actions: null
};

export default Modal;
