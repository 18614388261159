import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '80vh',
    [theme.breakpoints.down('sm')]: {
      height: '83vh',
    },
  },
  bottom: {
    backgroundColor: paleta.tabla.bordertd,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  top: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    position: 'relative',
  },
  title: {
    color: paleta.tabla.bordertd,
    fontSize: 42,
    fontWeight: '500',
    position: 'absolute',
    top: 62,
    fontFamily: 'Designer',
    textTransform: 'uppercase',
    [theme.breakpoints.only('xs')]: {
      top: 0,
      textAlign: 'center',
    },
  },
  title2: {
    color: paleta.sidebar.backgroundColor,
    fontSize: 28,
    maxWidth: 500,
    marginBottom: 20,
  },
  error: {
    color: paleta.tabla.bordertd,
    fontSize: 150,
    fontWeight: '600',
    position: 'absolute',
    bottom: -62,
  },
}));

export default styles;
