import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles(() => ({
  boxImagen: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '12px',
    backgroundColor: '#F6F9FC',
    cursor: 'pointer',
    position: 'relative',
    border: `1px dashed ${paleta.bar.primary}`,
    '& path': {
      color: '#717571',
    },
    '& svg': {
      color: '#f7f7f7',
    },
    '& span': {
      textAlign: 'center',
    },
    '&.error': {
      border: `1px dashed red`,
      '& .MuiTypography-root': {
        color: `red`,
      },
    },
    '& .overlay': {
      display: 'none',
    },
    '&.disabled': {
      '& .overlay': {
        display: 'block',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        margin: 0,
        backgroundColor: '#f6f9fcbf',
        borderRadius: '12px',
        cursor: 'no-drop'
      },
    },
  },
  link: {
    '&.MuiTypography-root': {
      color: '#227CDD!important',
      textDecoration: 'underline',
    },
  },
  imagen: {
    objectFit: 'cover',
    borderRadius: '12px',
    border: `1px solid ${paleta.bar.primary}`,
    transition: 'transform 0.2s ease-in-out',
  },
  contenedorImagen: {
    position: 'relative',
  },
  botonRemover: {
    backgroundColor: '#e1e1e6 !important',
    position: 'absolute !important',
    right: '5px',
    top: '15px',

    '&:hover': {
      backgroundColor: '#d1d1d6 !important',
    },
  },
}));

export default styles;
