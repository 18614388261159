import React from 'react';
import propTypes from 'prop-types';
import Sidebar from '../../componentes/Sidebar';
import CabeceroListado from '../../componentes/CabeceroListado';
import styles from './styles';

const Default = ({
  children,
  mostrarCabeceroListado,
  mostrarCabeceroFormulario,
  customCabecero,
  titulo,
  subtitulo,
  placeHolder,
  buscar,
  agregar,
  guardar,
  cancelar,
  style,
  mostrarLista,
  filtrosCabeceros,
  frmId,
  soloListado,
  importar,
  esReporte,
  apiKey,
  soloCancelar,
  labelGuardar,
  labelCancelar,
}) => {
  const classes = styles();

  return (
    <div>
      <div className={classes.root}>
        <Sidebar mostrarLista={mostrarLista} />
        <main className={classes.main}>
          <CabeceroListado
            titulo={titulo}
            subtitulo={subtitulo}
            placeHolder={placeHolder}
            buscar={buscar}
            agregar={agregar}
            customCabecero={customCabecero}
            esListado={mostrarCabeceroListado && !mostrarCabeceroFormulario}
            guardar={guardar}
            cancelar={cancelar}
            customStyles={style}
            filtrosCabeceros={filtrosCabeceros}
            frmId={frmId}
            soloListado={soloListado}
            importar={importar}
            esReporte={esReporte}
            apiKey={apiKey}
            soloCancelar={soloCancelar}
            labelGuardar={labelGuardar}
            labelCancelar={labelCancelar}
          />
          {children}
        </main>
      </div>
    </div>
  );
};

Default.propTypes = {
  children: propTypes.node.isRequired,
  mostrarCabeceroListado: propTypes.bool,
  mostrarCabeceroFormulario: propTypes.bool,
  titulo: propTypes.string,
  subtitulo: propTypes.string,
  placeHolder: propTypes.string,
  buscar: propTypes.func,
  agregar: propTypes.func,
  guardar: propTypes.func,
  cancelar: propTypes.func,
  datosLista: propTypes.object,
  customCabecero: propTypes.node,
  filtrosCabeceros: propTypes.shape({
    sitio: propTypes.bool,
    invernadero: propTypes.bool,
    nave: propTypes.bool,
  }),
  frmId: propTypes.string,
  accionesFormulario: propTypes.node,
  soloListado: propTypes.bool,
  importar: propTypes.func,
  soloReporte: propTypes.bool,
  apiKey: propTypes.string,
  soloCancelar: propTypes.bool,
  labelGuardar: propTypes.string,
  labelCancelar: propTypes.string,
};

Default.defaultProps = {
  mostrarCabeceroListado: false,
  mostrarCabeceroFormulario: false,
  titulo: "",
  subtitulo: "",
  placeHolder: "",
  buscar: null,
  agregar: null,
  guardar: null,
  cancelar: null,
  datosLista: {},
  mostrarLista: false,
  customCabecero: null,
  filtrosCabeceros: {
    sitio: false,
    invernadero: false,
    nave: false,
  },
  frmId: null,
  soloListado: false,
  importar: null,
  soloReporte: false,
  soloCancelar: false,
  apiKey: '',
  labelGuardar: 'Guardar',
  labelCancelar: 'Cancelar',
};

export default React.memo(Default);
