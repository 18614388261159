import React, { useRef, useState } from 'react';
import { MdOutlineReceiptLong } from 'react-icons/md';
import { GiReceiveMoney } from 'react-icons/gi';
import { SlWallet } from 'react-icons/sl';
import { Chip, IconButton } from '@material-ui/core';

import clsx from 'clsx';
import moment from 'moment';

import Listados from '../../contenedores/Listados';
import { COBRANZAS } from '../../configuraciones/endpoints';
import { formatoMoneda, leyendaFechaCobranza } from '../../utilidades/functions';
import EstadosCobranzaEnum from '../../constantes/estadosCobranzaEnum';

import FiltroCobranzas from './FiltroCobranzas';
import Mensualidades from './mensualidades';

import styles from './styles';
import Flujos from './flujos';

const Cobranzas = ({ history, location }) => {

  const clases = styles();
  const [abrirMensualidad, setAbrirMensualidad] = useState(false);
  const [abrirFlujo, setAbrirFlujo] = useState(false);
  const [esAbono, setEsAbono] = useState();
  const [id, setID] = useState();
  const refListado = useRef();
  const refFiltro = useRef();

  const cerrarAbonoMensualidad = () => {
    setID(undefined);
    setAbrirMensualidad(false);
    refListado.current.consultarListado();
    refFiltro.current.limpiarFiltro();
  };

  const cerrarFlujos = () => {
    setID(undefined);
    setAbrirFlujo(false);
    refFiltro.current.limpiarFiltro();
  };

  const cabeceros = [
    { label: 'Pago', transform: ({ numero }) => numero || 'E' },
    { label: 'Nombre', transform: ({ ventum }) => ventum.cliente.nombre },
    { label: 'Teléfono', transform: ({ ventum }) => ventum.cliente.telefono },
    { label: 'Proyecto', transform: ({ ventum }) => ventum.lote.manzana.proyecto.nombre },
    { label: 'Manzana', transform: ({ ventum }) => ventum.lote.manzana.nombre },
    { label: 'Lote', transform: ({ ventum }) => ventum.lote.numero },
    { label: 'Tipo', transform: ({ tipo }) => tipo === 'M' ? 'Mensualidad' : 'Enganche' },
    { label: 'Vence', transform: ({ fecha }) => moment.utc(fecha).format("DD-MM-YYYY") },
    { label: 'Mensualidad', transform: ({ importe }) => formatoMoneda(importe) },
    { label: 'Interés', transform: ({ intereses }) => formatoMoneda(intereses) },
    { label: 'Pagado', transform: ({ pagado }) => formatoMoneda(pagado) },
    { label: 'Total', transform: ({ importe, intereses }) => formatoMoneda(parseFloat(importe) + parseFloat(intereses)) },
    {
      label: 'Estatus', align: 'center', transform: ({ estado, activo }) => (
        <Chip
          label={activo ? estado.nombre : 'Eliminado'}
          size="small"
          className={clsx(clases.chip, activo ? estado.nombre : 'Eliminado')}
        />
      ),
    },
    {
      label: '', align: 'center', transform: ({ estado, fecha, id, flujosCobranzas }) => estado.id === EstadosCobranzaEnum.Cobrado ? <IconButton
        size="small"
        onClick={() => { setAbrirFlujo(true); setID(id); }}
        disabled={flujosCobranzas.length === 0}
      >
        <MdOutlineReceiptLong />
      </IconButton>
        :
        leyendaFechaCobranza(fecha)
    },
  ];

  return (
    <>
      <Listados
        ref={refListado}
        titulo="Cobranza"
        customCabecero={<FiltroCobranzas ref={refFiltro} />}
        history={history}
        location={location}
        showActions
        defaultActions={false}
        puedeReactivar={false}
        customActions={[
          {
            onClick: ({ id }) => {
              setID(id);
              setEsAbono(true);
              setAbrirMensualidad(true);
            },
            icon: <GiReceiveMoney />,
            disabled: ({ estado, fecha, estadoPrevio, activo }) => {
              const estadoAnteriorEsCobrado = (estadoPrevio !== EstadosCobranzaEnum.Cobrado);
              return !activo || ((estado.id === EstadosCobranzaEnum.Cobrado)) || ((moment.utc(fecha).isAfter(moment.utc().add(1, 'month'))) && estadoAnteriorEsCobrado)
            }
          },
          {
            onClick: ({ id }) => {
              setID(id);
              setEsAbono(false);
              setAbrirMensualidad(true);
            },
            icon: <SlWallet />,
            disabled: ({ estado, fecha, estadoPrevio, activo }) => {
              const estadoAnteriorEsCobrado = (estadoPrevio !== EstadosCobranzaEnum.Cobrado);
              return !activo || ((estado.id === EstadosCobranzaEnum.Cobrado)) || ((moment.utc(fecha).isAfter(moment.utc().add(1, 'month'))) && estadoAnteriorEsCobrado)
            }
          }
        ]}
        cabeceros={cabeceros}
        apiKey={COBRANZAS}
        soloListado
      />
      <Mensualidades
        abierto={abrirMensualidad}
        cerrar={cerrarAbonoMensualidad}
        abonoId={id}
        esAbono={esAbono}
      />
      <Flujos
        abierto={abrirFlujo}
        cerrar={cerrarFlujos}
        abonoId={id}
      />
    </>
  );
}


export default Cobranzas;
