/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, useRef, forwardRef, useImperativeHandle } from 'react';
import propTypes from 'prop-types';
import queryString from 'query-string';
import Default from '../Default';
import Tabla from '../../componentes/Table';
import endpoints from '../../configuraciones/endpoints';
import axios from '../../configuraciones/axios';
import general from '../../configuraciones/general';
import usePermisoEdicion from '../../hooks/usePermisoEdicion';

const FORMULARIO = 'formulario';

const Listados = forwardRef(({
  titulo, subtitulo, mostrarLista,
  datosLista, history, location,
  placeHolder, cabeceros, apiKey,
  parametros, customActions, mostrarCabeceroListado,
  mostrarCabeceroFormulario, guardar, cancelar, showActions,
  customClass, rowErrors, customCabecero, filtrosBusqueda, soloListado, esReporte,
  defaultActions, importar, hideDelete, puedeReactivar
}, ref) => {
  const { puedeEditar } = usePermisoEdicion();
  const [registros, setRegistros] = useState({ rows: [], count: 0 });
  const [filtros, setFiltros] = useState({
    busqueda: '',
  });

  const paginaRef = useRef(1);

  const consultarListado = () => {
    axios
      .get(endpoints.base.url(apiKey), {
        params: {
          ...filtros,
          ...parametros,
          registrosPorPagina: general.ELEMENTOS_POR_PAGINA,
          pagina: paginaRef.current,
        },
      })
      .then((e) => {
        if (e.rows.length === 0 && paginaRef.current > 1) {
          history.push({ search: `?pagina=${paginaRef.current - 1}` });
        }
        setRegistros(e);
      });
  };

  const buscar = useCallback((busqueda, criterios) => {
    setFiltros(({ busqueda, ...criterios }));
    history.replace({ search: `?pagina=${1}` });
  }, [history]);

  const obtenerPath = (id = "") => `${location.pathname}/${FORMULARIO}/${id}`;

  const irAFormulario = useCallback(({ id }) => {
    const query = queryString.parse(window.location.hash.split("?")[1] || "");
    history.push({
      pathname: obtenerPath(id || ""),
      state: { pagina: query.pagina || 1 },
    });
  }, []);

  const eliminar = useCallback(({ id }) => {
    axios.delete(endpoints.base.url(apiKey, id)).then(consultarListado).catch(() => {});
  }, []);

  const reactivar = useCallback(({ id }) => {
    axios.patch(endpoints.base.url(apiKey, id)).then(consultarListado).catch(() => {});
  }, []);

  useEffect(() => {
    const query = queryString.parse(location.search);
    paginaRef.current = query.pagina;
    consultarListado();
  }, [location]);

  useImperativeHandle(ref, () => {
    return {
      consultarListado() {
        consultarListado();
      },
    };
  }, []);

  return (
    <Default
      titulo={titulo}
      placeHolder={placeHolder}
      buscar={buscar}
      guardar={guardar}
      cancelar={cancelar}
      agregar={mostrarCabeceroListado && irAFormulario}
      subtitulo={subtitulo || `${registros?.count || 0} elemento(s)`}
      mostrarLista={mostrarLista}
      datosLista={datosLista}
      customCabecero={customCabecero}
      mostrarCabeceroListado={mostrarCabeceroListado}
      mostrarCabeceroFormulario={mostrarCabeceroFormulario}
      filtrosCabeceros={filtrosBusqueda}
      soloListado={soloListado}
      importar={importar}
      esReporte={esReporte}
      apiKey={apiKey}
    >
      <Tabla
        headers={cabeceros}
        rows={registros?.rows || []}
        count={registros?.count || 0}
        onDelete={!mostrarCabeceroFormulario && eliminar}
        onEdit={!mostrarCabeceroFormulario && irAFormulario}
        onRestore={puedeReactivar && !mostrarCabeceroFormulario && reactivar}
        moreActions={customActions}
        defaultActions={defaultActions}
        customClass={customClass}
        showActions={showActions && puedeEditar}
        rowErrors={rowErrors}
        hideDelete={hideDelete}
      />
    </Default>
  );
});

Listados.propTypes = {
  titulo: propTypes.string.isRequired,
  history: propTypes.object.isRequired,
  location: propTypes.object.isRequired,
  placeHolder: propTypes.string,
  cabeceros: propTypes.array.isRequired,
  apiKey: propTypes.string.isRequired,
  customStyles: propTypes.object,
  mostrarCabeceroFormulario: propTypes.bool,
  onDelete: propTypes.bool,
  onEdit: propTypes.bool,
  moreActions: propTypes.array,
  parametros: propTypes.object,
  customActions: propTypes.array,
  mostrarCabeceroListado: propTypes.bool,
  guardar: propTypes.func,
  cancelar: propTypes.func,
  showActions: propTypes.bool,
  filtrosBusqueda: propTypes.shape({
    sitio: propTypes.bool,
    invernadero: propTypes.bool,
    nave: propTypes.bool,
  }),
  soloListado: propTypes.bool,
  esReporte: propTypes.bool,
  defaultActions: propTypes.bool,
  importar: propTypes.func,
  hideDelete: propTypes.func,
};

Listados.defaultProps = {
  customActions: [],
  mostrarCabeceroListado: true,
  mostrarCabeceroFormulario: false,
  guardar: null,
  cancelar: null,
  showActions: false,
  placeHolder: 'Búsqueda por nombre',
  filtrosBusqueda: {
    sitio: false,
    invernadero: false,
    nave: false,
  },
  soloListado: false,
  esReporte: false,
  defaultActions: true,
  importar: null,
  hideDelete: null,
  puedeReactivar: true,
};

export default Listados;
