import React, { cloneElement, useMemo } from 'react';
import propTypes from 'prop-types';
import { Box, Grid } from '@material-ui/core';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { FormProvider, useForm } from 'react-hook-form';
import { CiImport } from 'react-icons/ci';
import Boton from '../Button';
import TextField from '../TextField';
import Typography from '../Typography';

import styles from './styles';
import AccionesCabecero from '../AccionesCabecero';
import usePermisoEdicion from '../../hooks/usePermisoEdicion';
import Exportar from '../Exportar';

const CabeceroListado = ({
  titulo,
  subtitulo,
  placeHolder,
  buscar,
  agregar,
  esListado,
  guardar,
  cancelar,
  customStyles,
  filtrosCabeceros,
  frmId,
  customCabecero,
  soloListado,
  importar,
  esReporte,
  apiKey,
  soloCancelar,
  labelGuardar,
  labelCancelar,
}) => {
  const classes = styles();
  const { puedeEditar } = usePermisoEdicion();
  const formMethods = useForm();
  const { register, handleSubmit } = formMethods;

  const handleSearch = (filters) => {
    const { txtBusqueda, ...otrosFiltros } = filters;
    if (buscar) {
      buscar(txtBusqueda, otrosFiltros);
    }
  };

  const Cabecero = useMemo(() => !!customCabecero && cloneElement(customCabecero),
    [customCabecero]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(handleSearch)}>
        <Grid container className={classes.root}>
          <Grid item xs={6} sm={6} md={8} lg={9} className={classes.titleContainer}>
            <Typography
              component="h2"
              className={classes.title}
              style={customStyles}
            >
              {titulo}
            </Typography>
            <Typography className={classes.subtitle}>{subtitulo}</Typography>
          </Grid>
          {!soloListado && (agregar && puedeEditar) && (
            <Grid item xs={6} md={4} lg={3} className={classes.btnContainer}>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Boton
                    fullWidth
                    icono={<IoMdAddCircleOutline size={18} />}
                    label="Agregar"
                    onClick={agregar}
                    classesCustom={{
                      boton: classes.btnAdd,
                    }}
                  />
                </Grid>
                {importar && (
                  <Grid item xs={12} sm={6}>
                    <Boton
                      fullWidth
                      onClick={importar}
                      icono={<CiImport size={18} />}
                      label="Importar"
                      classesCustom={{
                        boton: classes.btnAdd,
                      }}
                      border
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
          {esListado && (
            <Grid item xs={12} className={classes.actionContainer}>
              {(buscar && !customCabecero) && (
                <Grid container justifyContent="flex-end" alignItems="flex-end" spacing={1}
                  style={{ marginBottom: 20 }}
                >
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      name="txtBusqueda"
                      placeHolder={placeHolder}
                      inputClassName={classes.textFieldInput}
                      onClick={(event) => event.stopPropagation()}
                      onFocus={(event) => event.stopPropagation()}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <AccionesCabecero />
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
          {!esListado && (
            <Box flex={1} className={classes.actionContainer}>
              {buscar && (
                <TextField
                  name="txtBusqueda"
                  placeHolder={placeHolder}
                  className={classes.textField}
                  register={register}
                />
              )}
              {(soloCancelar) || ((guardar || frmId)) && (
                <Boton
                  onClick={guardar}
                  label={labelGuardar}
                  classesCustom={{ boton: classes.btn }}
                  isSubmit={Boolean(frmId)}
                  frmId={frmId}
                />
              )}
              {cancelar && (
                <Boton
                  onClick={cancelar}
                  label={labelCancelar}
                  classesCustom={{ boton: classes.btn }}
                  border
                />
              )}
            </Box>
          )}
          {esReporte && (
            <Grid item xs={12} className={classes.actionContainer}>
              <Exportar urlExportar={apiKey} />
            </Grid>

          )}
        </Grid>
        {Cabecero}
      </form >
    </FormProvider >
  );
};

CabeceroListado.propTypes = {
  titulo: propTypes.string.isRequired,
  subtitulo: propTypes.string,
  placeHolder: propTypes.string,
  buscar: propTypes.func,
  agregar: propTypes.func,
  esListado: propTypes.bool,
  guardar: propTypes.func,
  cancelar: propTypes.func,
  customStyles: propTypes.object,
  customCabecero: propTypes.node,
  filtrosCabeceros: propTypes.oneOfType([propTypes.bool, propTypes.shape({
    sitio: propTypes.bool,
    invernadero: propTypes.bool,
    nave: propTypes.bool,
  })]),
  frmId: propTypes.string,
  soloListado: propTypes.bool,
  importar: propTypes.func,
  esReporte: propTypes.bool,
  apiKey: propTypes.string,
  soloCancelar: propTypes.bool,
  labelGuardar: propTypes.string,
  labelCancelar: propTypes.string,
};

CabeceroListado.defaultProps = {
  subtitulo: '',
  placeHolder: '',
  buscar: null,
  agregar: null,
  esListado: true,
  guardar: null,
  cancelar: null,
  customCabecero: null,
  filtrosCabeceros: {
    sitio: false,
    invernadero: false,
    nave: false,
  },
  frmId: null,
  soloListado: false,
  importar: null,
  esReporte: false,
  apiKey: '',
  soloCancelar: false,
  labelGuardar: 'Guardar',
  labelCancelar: 'Cancelar',
};

export default React.memo(CabeceroListado);
