import React, { useCallback, useEffect, useState } from 'react'
import { Grid, IconButton } from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';
import clsx from 'clsx';
import { FaTimes } from 'react-icons/fa';
import { PiMicrosoftExcelLogoFill } from 'react-icons/pi';
import { read, utils } from 'xlsx';
import { toast } from 'react-toastify';

import Modal from '../../componentes/Modal';
import Typography from '../../componentes/Typography';
import SeleccionadorArchivo from '../../componentes/SeleccionadorArchivo';
import AutoComplete from '../../componentes/AutoComplete';
import endpoints, { EMPRESAS, IMPORTAR, LOTES } from '../../configuraciones/endpoints';
import axios from '../../configuraciones/axios';
import styles from './styles';
import Button from '../../componentes/Button';
import EstadosLoteEnum from '../../constantes/estadosLoteEnum';
import { ERROR_IMPORTAR_ARCHIVOS } from '../../constantes/mensajes';
import configuraciones from '../../configuraciones/general';


const propiedades = [
  'numero', 'descripcion', 'manzana', 'categoria', 'frente', 'fondo',
  'lateralIzquierdo', 'lateralDerecho', 'area', 'construccion', 'calleNorte', 'calleEste',
  'calleSur', 'calleOeste', 'precioM2', 'precioTotal',
];

const ModalImportar = ({ abierto, cerrarModal }) => {
  const clases = styles();
  const [empresas, setEmpresas] = useState();
  const [proyectos, setProyectos] = useState();
  const [puedeCerrar, setPuedeCerrar] = useState(true);
  const [mostrarSpinner, setMostrarSpinner] = useState();
  const metodos = useForm();
  const {
    formState: { errors },
    getValues,
    reset,
    watch,
  } = metodos;

  const empresaId = watch('empresaId');
  const proyectoId = watch('proyectoId');

  /** Método que consulta las empresas existentes para seleccionar */
  const consultarEmpresas = async () => {
    const bdEmpresas = await axios.get(endpoints.base.opciones(EMPRESAS));
    setEmpresas(bdEmpresas);
  };

  /** Método que consulta los proyectos realacionados a la empresa seleccionada. */
  const consultarProyectos = useCallback(async () => {
    if (empresaId) {
      const bdProyectos = await axios.get(endpoints.proyectosPorEmpresa(empresaId));
      setProyectos(bdProyectos);
    }
  }, [empresaId]);

  /** Método que guarda la información leída del archivo de lotes. */
  const guardarLotes = (data) => {
    setPuedeCerrar(false);
    setMostrarSpinner(true);
    axios.post(endpoints.base.url(LOTES, IMPORTAR), data)
      .then(() => {
        reset();
        cerrarModal();
      })
      .catch(() => { })
      .finally(() => {
        setPuedeCerrar(true);
        setMostrarSpinner(false);
      });
  }

  /** Método que sube el archivo a servidor para importar los lotes. */
  const leerArchivo = async (archivo) => {
    try {
      const reader = new FileReader();
      reader.onload = (evt) => {
        const bstr = evt.target.result;
        const wb = read(bstr, { type: 'binary' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = utils.sheet_to_json(ws, { header: 1 });
        const lotes = [];
        for (let i = 1; i < data.length; i++) {
          const lote = {};
          data[i].forEach((value, index) => {
            lote[propiedades[index]] = value;
          });
          lote.estadoId = EstadosLoteEnum.Disponible;
          lote.activo = true;
          lotes.push(lote);
        }
        const { empresaId, proyectoId } = getValues();
        guardarLotes({ empresaId, proyectoId, lotes });
      };
      reader.readAsBinaryString(archivo);
    } catch (error) {
      toast.error(ERROR_IMPORTAR_ARCHIVOS);
    }
  };

  /** Método que maneja el cerrado del modal. */
  const manejarCerrado = () => {
    if (puedeCerrar) {
      reset();
      cerrarModal();
    }
  };

  /** Método que descarga el archivo de ejemplo del servidor */
  const descargarArchivo = () => {
    const link = document.createElement('a');
    link.download = configuraciones.NOMBRE_ARCHIVO_EJEMPLO;
    link.href = `${configuraciones.URL_ARCHIVO_EJEMPLO}${configuraciones.NOMBRE_ARCHIVO_EJEMPLO}`;
    link.click();
  }

  /** Effect para consultar los proyectos cuando la función cambie. */
  useEffect(() => consultarProyectos(), [consultarProyectos]);

  /** Effect para consultar las empresas al inciar la pantalla. */
  useEffect(() => consultarEmpresas(), []);

  return (
    <Modal
      open={abierto}
      handleClose={manejarCerrado}
      maxWidth="lg"
      showTitle={false}
    >
      <Grid container style={{ width: '45vw', padding: 20 }}>
        <Grid item xs={12}>
          <FormProvider {...metodos}>
            <form id="frmImportar">
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs>
                  <Typography variant="h6">Importar Lotes</Typography>
                </Grid>
                <Grid item xs="auto">
                  <IconButton onClick={manejarCerrado}>
                    <FaTimes size={15} />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <AutoComplete
                    name="empresaId"
                    label="Empresa:"
                    options={empresas}
                    error={errors.empresaId}
                    helperText={errors.empresaId?.message}
                    required
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <AutoComplete
                    name="proyectoId"
                    label="Proyecto:"
                    options={proyectos}
                    error={errors.proyectoId}
                    helperText={errors.proyectoId?.message}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <SeleccionadorArchivo
                    disabled={!proyectoId || !empresaId}
                    onChange={leerArchivo}
                    mostrarSpinner={mostrarSpinner}
                  />
                </Grid>
              </Grid>
              {!mostrarSpinner && (
                <>
                  <Grid container justifyContent="space-between" style={{ marginBlock: 20 }}>
                    <Grid xs="auto">
                      <Typography variant="h6">Formatos soportados: Excel, CSV</Typography>
                    </Grid>
                    <Grid xs="auto">
                      <Typography variant="h6">Tamaño Máximo: 2MB</Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    className={clsx(clases.ejemplo)}
                    justifyContent="space-between"
                    alignItems="center">
                    <Grid item md={8} xs={12}>
                      <Typography variant="h6">
                        <PiMicrosoftExcelLogoFill />
                        Archivo de ejemplo
                      </Typography>
                      <Typography variant="span">
                        Este archivo cuenta con la distribución de columnas necesarias para una correcta
                        importación de lotes al sistema.
                      </Typography>
                    </Grid>
                    <Grid item xs="auto">
                      <Button
                        label="Descargar"
                        onClick={descargarArchivo}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </form>
          </FormProvider>
        </Grid>
      </Grid>
    </Modal >
  )
}

export default ModalImportar