import React, { useRef, useState } from 'react'
import { Chip, Grid } from '@material-ui/core';
import { MdCheckBox, MdCheckBoxOutlineBlank, MdQuestionMark } from 'react-icons/md';
import clsx from 'clsx';
import { BsPencil } from 'react-icons/bs';
import { FiTrash } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import moment from 'moment';

import Listados from '../../contenedores/Listados';
import endpoints, { VENTAS } from '../../configuraciones/endpoints';
import paleta from '../../configuraciones/paleta';
import { formatoMoneda } from '../../utilidades/functions';
import FiltrosVenta from './filtros';
import styles from './styles';
import CancelacionVenta from './cancelacion';
import { ConfiguracionXMLHttp, peticionXMLHttp } from '../../utilidades/peticionXMLHttp';
import ESTADO_VENTA from '../../constantes/estadosVenta';
import Modal from '../../componentes/Modal';
import Typography from '../../componentes/Typography';

const CheckBox = ({ checked }) => (
  <>
    {
      checked ? <MdCheckBox size={20} color={paleta.bar.primary} /> :
        <MdCheckBoxOutlineBlank size={20} color={paleta.bar.primary} />
    }
  </>
)

const Ventas = ({ history, location }) => {
  const token = useSelector(({ user }) => user?.token);
  const classes = styles();
  const refListado = useRef();
  const [venta, setVenta] = useState();
  const [claveLote, setClaveLote] = useState();
  const [cancelacionAbierto, setCancelacionAbierto] = useState();
  const [mostrarMotivoCancelacion, setMostrarMotivoCancelacion] = useState();
  const [motivo, setMotivo] = useState();
  const [usuarioCancelacion, setUsuarioCancelacion] = useState();
  const [fechaCancelacion, setFechaCancelacion] = useState();
  const { superAdmin } = useSelector(
    ({ user: { user: { superAdmin } } }) => ({ superAdmin })
  );

  /**
   * Método que abre el modal de confirmación para eliminar.
   * @param row Objeto con la información del movimiento a cancelar.
   * @param index Index del renglón donde se hizo click.
   * @param event Evento que ejecuta el método.
   */
  const abrirModal = (row) => {
    setVenta(row);
    setClaveLote(row.lote.clave);
    setCancelacionAbierto(true);
  };

  /**
   * Método que cierra al modal de confirmación.
   */
  const alCancelar = () => {
    setCancelacionAbierto(false);
    setVenta(null);
  };

  /**
   * Método que realiza la cancelación del movimiento al aceptar el modal.
   */
  const alAceptar = async (cancelacion, callback) => {
    const configuracion = new ConfiguracionXMLHttp({
      tipoPeticion: 'DELETE',
      body: { ...cancelacion, claveLote },
      token: token,
      urlEndpoint: endpoints.base.url(VENTAS, venta.id),
      formatoArchivo: 'pdf',
      visualizar: true,
    });

    peticionXMLHttp(configuracion).then(() => {
      alCancelar();
      refListado.current.consultarListado();
      callback();
    });
  };

  /**
   * Método para mostrar el motivo de cancelación en caso de tener.
   */
  const mostrarModalMotivoCancelacion = (motivo, usuario, fecha) => {
    if (motivo) {
      setMotivo(motivo);
      setUsuarioCancelacion(usuario);
      setFechaCancelacion(fecha);
      setMostrarMotivoCancelacion(true);
    }
  }

  const cabeceros = [
    { label: '#', key: 'id', className: classes.header },
    { label: 'Emp.', transform: ({ lote }) => lote?.manzana?.proyecto?.empresa?.nombre },
    { label: 'Proyecto', transform: ({ lote }) => lote?.manzana?.proyecto?.nombre },
    { label: 'Manzana', transform: ({ lote }) => lote?.manzana?.nombre },
    { label: 'Lote', transform: ({ lote }) => lote?.numero },
    { label: 'Área', transform: ({ lote }) => lote?.area },
    { label: 'Fecha', key: 'fecha', width: 100 },
    { label: 'Cliente', transform: ({ cliente }) => cliente?.nombre },
    { label: 'Vendedor', transform: ({ vendedor }) => vendedor?.nombre },
    { label: 'F.Canc.', transform: ({ fechaCanc }) => fechaCanc || 'N/A' },
    { label: 'Anti.', transform: ({ anticipoMinimo }) => formatoMoneda(anticipoMinimo) },
    { label: 'A.Pag.', transform: ({ anticipo }) => formatoMoneda(anticipo) },
    { label: 'Crédito', transform: ({ credito }) => <CheckBox checked={credito} /> },
    { label: 'Total', transform: ({ total }) => formatoMoneda(total) },
    { label: 'Pagado', transform: ({ pagado }) => pagado ? formatoMoneda(pagado) : '---' },
    {
      label: 'Cobrado', transform: ({ estado }) => {
        return (<CheckBox checked={estado.nombre === ESTADO_VENTA.VENDIDO} />)
      }
    },
    {
      label: 'Estatus', align: 'center', transform: ({ estado, activo, motivoCancelacion, usuarioModificacion, fechaCancelacion }) => (

        activo ?
          <Chip
            label={estado.nombre}
            size="small"
            className={clsx(classes.chip, estado.nombre)}
          />
          :
          <Chip
            label="Eliminado"
            size="small"
            className={clsx('Eliminado')}
            onDelete={() => mostrarModalMotivoCancelacion(motivoCancelacion, usuarioModificacion.nombre, fechaCancelacion)}
            deleteIcon={<MdQuestionMark />
            }
          />
      ),
    },
  ];

  return <>
    <Listados
      ref={refListado}
      titulo="Ventas"
      customCabecero={<FiltrosVenta />}
      history={history}
      location={location}
      cabeceros={cabeceros || []}
      apiKey={VENTAS}
      showActions
      defaultActions={false}
      customActions={[
        {
          icon: <BsPencil />,
          onClick: ({ id }) => history.push(`/ventas/ventas/formulario/${id}`),
          validate: ({ estado, activo }) => (estado.nombre !== ESTADO_VENTA.VENDIDO && activo) || (superAdmin && activo),
        },
        {
          icon: <FiTrash />,
          onClick: abrirModal,
          validate: ({ estado, activo }) => (estado.nombre !== ESTADO_VENTA.VENDIDO && activo) || (superAdmin && activo),
        },
      ]}
    />;
    <CancelacionVenta
      titulo={`Cancelar venta del lote: ${claveLote}`}
      abierto={cancelacionAbierto}
      alCerrar={alCancelar}
      alEnviar={alAceptar}
      venta={venta}
    />
    <Modal
      maxWidth="md"
      open={mostrarMotivoCancelacion}
      handleClose={() => setMostrarMotivoCancelacion(false)}
      showTitle={false}
    >
      <Grid container alignContent="center" className={classes.motivo}>
        <Typography variant="h6" bold>Motivo de cancelación:</Typography>
        <Grid item xs={12} className={classes.textMotivo}>
          <Typography>{motivo}</Typography>
        </Grid>
        <Grid item xs={12} className={classes.textMotivo}>
          <Grid container>
            <Grid item xs={6}>
              <Grid item xs={12}>
                <Typography><b>Usuario:</b></Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>{usuarioCancelacion}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid item xs={12}>
                <Typography><b>Fecha:</b> {moment(fechaCancelacion).format("LL")}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography><b>Hora:</b> {moment(fechaCancelacion).format("hh:mm A")}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </Modal >
  </>
}

export default Ventas;
