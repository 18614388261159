import React, { useEffect, useState } from 'react';
import {
  List,
} from '@material-ui/core';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import MenuItem from '../MenuItem';
import { MENU_OPCIONES } from '../../ducks/appbar';
import styles from './styles';

/**
 * Método recursivo encargado de filtrar las opciones disponibles para el menu del sistema
 * para que solo se muestren aquellas a las que tenga acceso el usuario.
 * Si la opcion no tiene las propiedades submenu y id, siempre se agrega a la lista filtrada.
 * @param {*} opciones - Listado de opciones disponibles a filtrar
 * @param {*} permisosAsignados - Listado de los permisos asignados al usuario
 * @returns Lista de opciones del menu filtrada por permisos del usuario
 */
const filtrarMenu = (opciones, permisosAsignados, superAdmin) => {
  const menuFiltrado = [];

  for (const opcion of opciones) {
    if (!opcion.submenu && !opcion.id) {
      menuFiltrado.push(opcion);
    } else if (!!opcion.submenu) {
      let subMenuFiltrado = filtrarMenu(opcion.submenu, permisosAsignados, superAdmin);
      if (subMenuFiltrado.length > 0) {
        menuFiltrado.push({ ...opcion, submenu: subMenuFiltrado });
      }
    } else {
      if (Boolean(permisosAsignados.find((permiso) => permiso.moduloId === opcion.id)) || superAdmin) {
        menuFiltrado.push(opcion);
      }
    }
  }

  return menuFiltrado;
};

const Sidebar = ({ mostrarLista }) => {
  const [menuFiltradoPorPermisos, setMenuFiltradoPorPermisos] = useState([]);
  const classes = styles();
  const { sidebarIsOpen, superAdmin } = useSelector(({
    appbar: { sidebarIsOpen, },
    user: { user: { superAdmin } }
  }) => ({ sidebarIsOpen, superAdmin }));
  const { permisos, permisosConsultados } = useSelector(
    ({
      appbar: { permisos, permisosConsultados }
    }) => ({ permisos, permisosConsultados }),
    (prev, next) => {
      return (
        prev.permisosConsultados === next.permisosConsultados &&
        isEqual(prev.permisos, next.permisos)
      )
    }
  );

  useEffect(() => {
    if (permisosConsultados) {
      setMenuFiltradoPorPermisos(filtrarMenu(MENU_OPCIONES, permisos, superAdmin));
    }
  }, [permisosConsultados, permisos, superAdmin]);

  return (
    <div className={clsx(classes.root, { sidebarIsOpen, mostrarLista })}>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}>
        <div style={{ flex: 4, textAlign: 'center' }}>
          <List>
            {menuFiltradoPorPermisos.map((item, index) => (
              <MenuItem key={index} menu={item} />
            ))}
          </List>
        </div>
      </div>
      {mostrarLista}
    </div>
  );
};

export default Sidebar;
