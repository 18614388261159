import React, { useRef, useState } from 'react';
import { TbMapSearch, TbMapUp, TbSelectAll, TbTopologyRing } from 'react-icons/tb';
import { MdGridOn } from 'react-icons/md';
import { Button } from '@material-ui/core';
import { FiTrash } from 'react-icons/fi';

import axiosInstance from '../../configuraciones/axios';
import endpoints, { PROYECTOS } from '../../configuraciones/endpoints';

import Listados from '../../contenedores/Listados';
import Confirmacion from '../../componentes/Confirmacion';
import FiltrosComponent from '../../componentes/FiltrosComponent';

const pasos = [
  { paso: 0, texto: 'Subir plano', icono: <TbMapUp fontSize="inherit" /> },
  { paso: 1, texto: 'Dibujar manzana', icono: <TbTopologyRing fontSize="inherit" /> },
  { paso: 2, texto: 'Configurar lotes', icono: <MdGridOn fontSize="inherit" /> },
  { paso: 3, texto: 'Asignar lotes', icono: <TbSelectAll fontSize="inherit" /> },
  { paso: 4, texto: 'Ver mapa', icono: <TbMapSearch fontSize="inherit" /> },
];

export const ESTADOS_MAPA = {
  SIN_PLANO: 14,
  SIN_MANZANAS: 15,
  MANZANAS_PENDIENTES: 16,
  LOTES_SIN_CONFIGURAR: 17,
  LOTES_PENDIENTES: 18,
  LOTES_SIN_ASIGNAR: 19,
  COMPLETADO: 20,
};

const estadoMapas = (estado) => {
  const estadosMapeo = {
    [ESTADOS_MAPA.SIN_PLANO]: 0,
    [ESTADOS_MAPA.SIN_MANZANAS]: 1,
    [ESTADOS_MAPA.MANZANAS_PENDIENTES]: 1,
    [ESTADOS_MAPA.LOTES_SIN_CONFIGURAR]: 2,
    [ESTADOS_MAPA.LOTES_PENDIENTES]: 2,
    [ESTADOS_MAPA.LOTES_SIN_ASIGNAR]: 3,
    [ESTADOS_MAPA.COMPLETADO]: 4
  };

  return estadosMapeo[estado];
};

const Mapas = ({ history, location }) => {

  const refListado = useRef();

  const [abrirConfirmacion, setAbrirConfirmacion] = useState(null);
  const [proyecto, setProyecto] = useState(null);

  const abrirModal = (row, index, event) => {
    setProyecto({ ...row, index });
    setAbrirConfirmacion(event.currentTarget);
  };

  const alAceptar = async () => {
    await axiosInstance.delete(endpoints.eliminarConfiguracionMapa(proyecto?.id));
    alCancelar();
    refListado.current.consultarListado();
  };

  /**
   * Método que cierra al modal de confirmación.
   */
  const alCancelar = () => {
    setAbrirConfirmacion(null);
    setProyecto(null);
  };

  const cabeceros = [
    { label: 'Empresa', transform: ({ empresa }) => empresa?.nombre },
    { label: 'Nombre', key: 'nombre' },
    {
      label: 'Mapa', transform: ({ estadoMapa, id }) => {
        const paso = estadoMapas(estadoMapa);
        const { texto, icono } = pasos.find(p => p.paso === paso);

        return (
          <Button
            variant="text"
            startIcon={icono}
            onClick={() => history.push(`mapas/formulario/${id}`)}
          >
            {texto}
          </Button>
        );
      }
    },
  ];

  return (
    <>
      <Listados
        ref={refListado}
        titulo="Mapas"
        customCabecero={<FiltrosComponent mostrarProyectos habilitarBusqueda={false}/>}
        history={history}
        location={location}
        cabeceros={cabeceros}
        apiKey={PROYECTOS}
        showActions
        soloListado
        defaultActions={false}
        puedeReactivar={false}
        customActions={[
          {
            icon: <FiTrash />,
            onClick: abrirModal,
            validate: ({ estadoMapa }) => (estadoMapa !== ESTADOS_MAPA.SIN_PLANO),
          },
        ]} />

      <Confirmacion
        anchor={abrirConfirmacion}
        onClose={alCancelar}
        onAccept={alAceptar} />
    </>
  );
};

export default Mapas;
