import React, { useState } from 'react';
import { Grid, Hidden, IconButton } from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams, useHistory } from 'react-router-dom';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import md5 from 'md5';
import { IoEyeSharp, IoEyeOffSharp } from 'react-icons/io5';

import TextField from '../../componentes/TextField';
import Button from '../../componentes/Button';
import Footer from '../../componentes/Footer';
import SpinnerCirculo from '../../componentes/SpinnerCirculo';

import styles from './styles';
import endpoints from '../../configuraciones/endpoints';
import axios, { setTokenHeader } from '../../configuraciones/axios';
import { CONTRASENAS_NO_COINCIDEN, CAMPO_REQUERIDO, CONTRASENA_LONGITUD } from '../../constantes/mensajes';

const schema = yup.object().shape({
  contrasena: yup.string().required(CAMPO_REQUERIDO).min(8, CONTRASENA_LONGITUD),
  confirmarContrasena: yup.string().oneOf([yup.ref('contrasena'), null], CONTRASENAS_NO_COINCIDEN).required(CAMPO_REQUERIDO).min(8, CONTRASENA_LONGITUD)
});

const ReiniciarContrasena = () => {
  const { token } = useParams();
  const history = useHistory();

  const classes = styles();

  const [mostrarSpinner, setMostrarSpinner] = useState(false);
  const [mostrarContrasena, setMostrarContrasena] = useState(false);
  const [mostrarConfirmarContrasena, setMostrarConfirmarContrasena] = useState(false);

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const cambiarContrasena = (usuario) => {
    setMostrarSpinner(true);

    const usuarioContrasenas = {
      token,
      contrasena: md5(usuario.contrasena)
    };

    axios.put(endpoints.reiniciarContrasena(), usuarioContrasenas, {
      headers: { Authorization: token }
    }).then((sesion) => {
      setTimeout(() => {
        setMostrarSpinner(false);
        setTokenHeader(sesion.token);
        history.push('/login');
      }, 2000);
    }).catch(error => {
      setMostrarSpinner(false);
    });
  }

  const cambiarMostrarContrasena = () => {
    setMostrarContrasena(!mostrarContrasena);
  };

  const cambiarMostrarConfirmarContrasena = () => {
    setMostrarConfirmarContrasena(!mostrarConfirmarContrasena);
  };

  return (
    <>
      <Grid container className={classes.main}>
        <Grid item xs={12}>
          <Grid container className={classes.body}>
            <Hidden smDown>
              <Grid item md={7} className={classes.contenedorTitulo}>
                <Grid container alignItems='baseline'>
                  <img src='/assets/images/logoSINfondo.svg' alt='icono' width='100' height='100' />
                </Grid>
                <Grid container alignItems='baseline'>
                  <h1>Condor&nbsp;</h1>
                  <h2>ADMON</h2>
                </Grid>
              </Grid>
            </Hidden>
            <Grid item xs={12} md={4}>
              <FormProvider {...methods}>
                <form noValidate onSubmit={handleSubmit(cambiarContrasena)}>
                  <Grid container spacing={1} className={classes.card} id="card">
                    <Grid item xs={12}>
                      <h2>Ingresa tu nueva contraseña</h2>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container justifyContent='center'>
                        <img src='/assets/images/logoSINfondo.svg' alt='icono' className={classes.logoDelCampo} />
                      </Grid>
                    </Grid>
                    {mostrarSpinner && <SpinnerCirculo label='Estableciendo nueva contraseña' />}
                    {!mostrarSpinner && <>
                      <p>Utiliza por lo menos 8 caracteres</p>
                      <Grid item xs={12}>
                        <TextField
                          name='contrasena'
                          placeHolder="Contraseña"
                          type={mostrarContrasena ? 'text' : 'password'}
                          required
                          error={errors.contrasena}
                          helperText={errors.contrasena?.message}
                          simbolo={
                            <IconButton size="small" onClick={cambiarMostrarContrasena}>
                              {mostrarContrasena ? <IoEyeOffSharp className={classes.icon} /> : <IoEyeSharp className={classes.icon} />}
                            </IconButton>
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name='confirmarContrasena'
                          placeHolder="Confirma Contraseña"
                          type={mostrarConfirmarContrasena ? 'text' : 'password'}
                          required
                          error={errors.confirmarContrasena}
                          helperText={errors.confirmarContrasena?.message}
                          simbolo={
                            <IconButton size="small" onClick={cambiarMostrarConfirmarContrasena}>
                              {mostrarConfirmarContrasena ? <IoEyeOffSharp className={classes.icon} /> : <IoEyeSharp className={classes.icon} />}
                            </IconButton>
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          name="guardarContrasena"
                          label="Establecer nueva contraseña"
                          classesCustom={{ boton: classes.botonInicio }}
                          isSubmit
                        />
                      </Grid>
                    </>}
                  </Grid>
                </form>
              </FormProvider>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Footer />
        </Grid>
      </Grid >

    </>
  );
};

export default ReiniciarContrasena;
