/* eslint-disable import/no-anonymous-default-export */
import React, { Suspense, useEffect, useState } from 'react';
import {
  HashRouter, Route, Switch, Redirect,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';

import AppBar from '../componentes/AppBar';

import Dashboard from '../paginas/Dashboard';
import Forbidden from '../paginas/401';
import Login from '../paginas/Login';
import ContrasenaOlvidada from '../paginas/ContrasenaOlvidada';
import ReiniciarContrasena from '../paginas/ReiniciarContrasena';
import CorreoConfirmacion from '../paginas/CorreoConfirmacion';
import NotFound from '../paginas/404';
import Perfil from '../paginas/Perfil';
import Perfiles from '../paginas/Perfiles';
import Proyectos from '../paginas/Proyectos';
import Proyecto from '../paginas/Proyecto';
import Usuario from '../paginas/Usuario';
import Empresas from '../paginas/Empresas';
import Empresa from '../paginas/Empresa';
import Usuarios from '../paginas/Usuarios';
import Clientes from '../paginas/Clientes';
import Cliente from '../paginas/Cliente';
import Manzanas from '../paginas/Manzanas';
import Manzana from '../paginas/Manzana';
import Lotes from '../paginas/Lotes';
import Lote from '../paginas/Lote';
import Cuentas from '../paginas/Cuentas';
import Cuenta from '../paginas/Cuenta';
import Ventas from '../paginas/Ventas';
import Venta from '../paginas/Venta';
import Flujos from '../paginas/Flujos';
import Flujo from '../paginas/Flujo';
import Cobranzas from '../paginas/Cobranzas';
import ReporteFlujos from '../paginas/ReporteFlujos';
import ReporteVentas from '../paginas/ReporteVentas';
import ReporteClientes from '../paginas/ReporteClientes';
import Expedientes from '../paginas/Expedientes';
import Expediente from '../paginas/Expediente';
import Mapa from '../paginas/Mapa';

import usePermisoEdicion from '../hooks/usePermisoEdicion';
import { SIN_PERMISOS_EDICION } from '../constantes/mensajes';
import Mapas from '../paginas/Mapas';


export default () => {
  const user = useSelector(store => store.user);

  const PrivateRoute = ({ component, isForm, path, ...rest }) => {
    const [tienePermiso, setTienePermiso] = useState(true);
    const { isAuth, permisos, permisosConsultados, superAdmin } = useSelector(
      ({
        user: { isAuth }, appbar: { permisos, permisosConsultados }, user: { user: superAdmin }
      }) => ({ isAuth, permisos, permisosConsultados, superAdmin }),
      (prev, next) => {
        return (
          prev.isAuth === next.isAuth &&
          prev.permisosConsultados === next.permisosConsultados &&
          isEqual(prev.permisos, next.permisos)
        )
      }
    );
    rest.location.state = { ...rest.state, ...rest.location.state };
    const { puedeEditar } = usePermisoEdicion();
    const rutaCatalogo = isForm ? path.substring(0, path.search('/formulario')) : '';

    useEffect(() => {
      if (permisosConsultados && rest.location.state.moduloId) {
        setTienePermiso(Boolean(
          permisos.find((permiso) => permiso.moduloId === rest.location.state.moduloId))
          || superAdmin
        );
      }
    }, [rest.location, permisos, permisosConsultados, superAdmin]);

    useEffect(() => {
      if (isForm && !puedeEditar) {
        toast.warning(SIN_PERMISOS_EDICION);
      }
    }, [isForm, puedeEditar]);

    return (
      <Route
        {...rest}
        render={(props) => {
          if (!isAuth) {
            return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
          }
          if (!tienePermiso) {
            return <Redirect to={{ pathname: '/forbidden', state: { from: props.location } }} />;
          }
          if (isForm && !puedeEditar) {
            return <Redirect to={{ pathname: rutaCatalogo, state: { from: props.location } }} />;
          }
          return React.createElement(component, props);
        }}
      />
    );
  };
  const PublicRoute = ({ component, ...rest }) => {
    rest.location.state = { ...rest.state, ...rest.location.state };
    const { isAuth } = useSelector(({ user: { isAuth } }) => ({ isAuth }));
    return (
      <Route
        {...rest}
        render={(props) => (isAuth ? (
          <Redirect to={{ pathname: '/dashboard', }} />
        ) : (
          React.createElement(component, props)
        ))}
      />
    );
  };

  return (
    <HashRouter>
      {(user.isAuth) && <AppBar />}
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <PublicRoute path={['/', '/login']} component={Login} exact />
          <PublicRoute path={['/', '/contrasena-olvidada']} component={ContrasenaOlvidada} exact />
          <PublicRoute path={['/', '/recuperar-contrasena/:token']} component={ReiniciarContrasena} exact />
          <PublicRoute path={['/', '/correo-confirmacion/']} component={CorreoConfirmacion} exact />

          <PrivateRoute path="/dashboard" component={Dashboard} exact />
          <PrivateRoute path="/catalogos/empresas" component={Empresas} state={{ moduloId: 5 }} exact />
          <PrivateRoute path="/catalogos/empresas/formulario/:id?" isForm component={Empresa} state={{ moduloId: 5 }} exact />
          <PrivateRoute path="/catalogos/usuarios" component={Usuarios} state={{ moduloId: 3 }} exact />
          <PrivateRoute path="/catalogos/usuarios/formulario/:id?" isForm state={{ moduloId: 3 }} component={Usuario} exact />
          <PrivateRoute path="/catalogos/perfiles" component={Perfiles} state={{ moduloId: 4 }} exact />
          <PrivateRoute path="/catalogos/perfiles/formulario/:id?" isForm component={Perfil} state={{ moduloId: 4 }} exact />
          <PrivateRoute path="/catalogos/clientes" component={Clientes} state={{ moduloId: 6 }} exact />
          <PrivateRoute path="/catalogos/clientes/formulario/:id?" isForm component={Cliente} state={{ moduloId: 6 }} exact />
          <PrivateRoute path="/catalogos/proyectos" component={Proyectos} state={{ moduloId: 7 }} exact />
          <PrivateRoute path="/catalogos/proyectos/formulario/:id?" isForm component={Proyecto} state={{ moduloId: 7 }} exact />
          <PrivateRoute path="/catalogos/manzanas" component={Manzanas} state={{ moduloId: 8 }} exact />
          <PrivateRoute path="/catalogos/manzanas/formulario/:id?" isForm component={Manzana} state={{ moduloId: 8 }} exact />
          <PrivateRoute path="/catalogos/lotes" component={Lotes} state={{ moduloId: 9 }} exact />
          <PrivateRoute path="/catalogos/lotes/formulario/:id?" isForm component={Lote} state={{ moduloId: 9 }} exact />
          <PrivateRoute path="/catalogos/cuentas" component={Cuentas} state={{ moduloId: 11 }} exact />
          <PrivateRoute path="/catalogos/cuentas/formulario/:id?" isForm component={Cuenta} state={{ moduloId: 11 }} exact />
          <PrivateRoute path="/catalogos/mapas" component={Mapas} state={{ moduloId: 19 }} exact />
          <PrivateRoute path="/catalogos/mapas/formulario/:id?" isForm component={Mapa} state={{ moduloId: 19 }} exact />
          <PrivateRoute path="/ventas/ventas" component={Ventas} state={{ moduloId: 12 }} exact />
          <PrivateRoute path="/ventas/ventas/formulario/:id?" isForm component={Venta} state={{ moduloId: 12 }} exact />
          <PrivateRoute path="/ventas/flujos" component={Flujos} state={{ moduloId: 13 }} exact />
          <PrivateRoute path="/ventas/flujos/formulario/:id?" isForm component={Flujo} state={{ moduloId: 13 }} exact />
          <PrivateRoute path="/ventas/cobranzas" component={Cobranzas} state={{ moduloId: 14 }} exact />
          <PrivateRoute path="/ventas/expedientes" component={Expedientes} state={{ moduloId: 20 }} exact />
          <PrivateRoute path="/ventas/expedientes/formulario/:id?" isForm component={Expediente} state={{ moduloId: 20 }} exact />
          <PrivateRoute path="/reportes/ventas" component={ReporteVentas} state={{ moduloId: 16 }} exact />
          <PrivateRoute path="/reportes/flujos" component={ReporteFlujos} state={{ moduloId: 17 }} exact />
          <PrivateRoute path="/reportes/clientes" component={ReporteClientes} state={{ moduloId: 18 }} exact />

          <Route path="/forbidden" component={Forbidden} />
          <Route path="*" component={NotFound} />
        </Switch>
      </Suspense>
    </HashRouter>
  );
};
