import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { PiMicrosoftExcelLogoFill } from 'react-icons/pi';
import { AiOutlinePrinter } from 'react-icons/ai';

import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';
import styles from './styles';
import { ERROR_REPORTE } from "../../constantes/mensajes";

import endpoints from '../../configuraciones/endpoints';
import { ConfiguracionXMLHttp, peticionXMLHttp } from '../../utilidades/peticionXMLHttp';

const Exportar = ({ urlExportar }) => {
  const token = useSelector(({ user }) => user?.token);
  const classes = styles();
  const { getValues } = useFormContext();

  const exportarArchivo = (formato) => {
    const filtros = getValues();
    const filtrosEnviar = {};

    for (const key in filtros) {
      if (filtros[key] || typeof filtros[key] === 'boolean' ) {
        filtrosEnviar[key] = filtros[key];
      }
    }
    filtrosEnviar.formato = formato;
    const filtrosRequest = new URLSearchParams(filtrosEnviar);

    const configuracion = new ConfiguracionXMLHttp({
      tipoPeticion: 'GET',
      token: token,
      urlEndpoint: `${endpoints.base.exportar(urlExportar)}?${(filtrosRequest)}`,
      formatoArchivo: formato,
      mensajeNoAceptable: ERROR_REPORTE,
    });

    peticionXMLHttp(configuracion);

  };


  return (
    <Grid container justifyContent="flex-end" spacing={1}>
      <Grid item xs="auto">
        <Button
          startIcon={<PiMicrosoftExcelLogoFill size={18} />}
          className={clsx(classes.btn, classes.exportar)}
          fullWidth
          onClick={() => exportarArchivo('excel')}
        >
          <Typography>Exportar</Typography>
        </Button>
      </Grid>
      <Grid item xs="auto">
        <Button
          startIcon={<AiOutlinePrinter size={18} className={classes.imprimirIcono} />}
          className={clsx(classes.btn, classes.imprimir)}
          fullWidth
          onClick={() => exportarArchivo('pdf')}
        >
          <Typography>Imprimir</Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export default Exportar;
