import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const usePermisoEdicion = () => {
  const { permisos, permisosConsultados, superAdmin } = useSelector(
    ({ appbar, user: { user: { superAdmin } } }) => ({ ...appbar, superAdmin })
  );
  const location = useLocation();

  const puedeEditar = (
    permisosConsultados ? ((permisos?.find(({ moduloId }) => (
      moduloId === location.state.moduloId
    )) || {}).permisoEdicion || superAdmin) : true
  );

  return {
    puedeEditar,
  }
};

export default usePermisoEdicion;
