import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';
import Button from '../../componentes/Button';
import Typography from '../../componentes/Typography';
import Footer from '../../componentes/Footer';
import styles from './styles';
import { ocultarOpciones } from '../../ducks/appbar';

const Info = ({ es404, history }) => {
  const classes = styles();
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(ocultarOpciones(true));
    return(() => {
      dispatch(ocultarOpciones(false));
    })
  })

  return (
    <div>
      <Box className={classes.root}>
        <Box flex={1} className={classes.top}>
          <Typography component="p" className={classes.title}>
            {es404 ? 'SITIO NO ENCONTRADO' : 'ACCESO DENEGADO'}
          </Typography>
          <Typography component="p" className={classes.error}>
            {es404 ? '404' : '401'}
          </Typography>
        </Box>
        <Box flex={1} className={classes.bottom}>
          <Typography component="p" className={classes.title2}>
            {!es404 ?
              'Los sentimos, su usuario no cuenta con permiso para acceder a este sitio'
              : 'Los sentimos, el sitio que intenta acceder no existe'}
          </Typography>
          <Button label="Ir al inicio"
            onClick={() => history.push('/dashboard')} />
        </Box>
      </Box>
      <Footer />
    </div>
  );
};


export default Info;
