
import React, { useState } from 'react';
import propTypes from 'prop-types';
import { toInteger } from 'lodash';
import {
  Dialog, Grid, DialogTitle, FormControlLabel,
  FormControl, FormLabel, RadioGroup,
  Radio, DialogContent, DialogActions
} from '@material-ui/core';

import Button from '../../componentes/Button';

const DialogHileras = ({ mostrar, alCerrar, manzanaSeleccionada, onChange, capturarHilera }) => {
  const [valor, setValor] = useState(0);

  /**
   * Metodo para asignar a que hilera pertenece el lote irregular
   */
  const aceptar = () => {
    alCerrar();
    onChange({ value: valor });
    setValor(0);
  };

  const actualizarValor = ({ target: { value } }) => setValor(toInteger(value));

  return (
    <Dialog open={mostrar}>
      <DialogTitle>Información de la manzana {manzanaSeleccionada.nombre}</DialogTitle>
      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {/* Form para ingresar a que hilera pertenece un lote */}
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">¿A que hilera pertenece el lote?</FormLabel>
                  <RadioGroup aria-label="hilera" name="hilera" onChange={actualizarValor} value={valor}>
                    <FormControlLabel value={1} control={<Radio />} label="Hilera 1" />
                    <FormControlLabel value={2} control={<Radio />} label="Hilera 2" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }} >
        
        <Button
          label="Cancelar"
          border
          onClick={alCerrar}
        />
        <Button
          label="Aceptar"
          onClick={aceptar}
        />

      </DialogActions>
    </Dialog>
  );
};

DialogHileras.propTypes = {
  /** Flag que indica si el dialogo se muestra o no */
  mostrar: propTypes.bool.isRequired,
  /** Función que se llama al cerrar el dialogo */
  alCerrar: propTypes.func.isRequired,
  /** Numero de manzana seleccionada */
  manzanaSeleccionada: propTypes.object.isRequired,
  /** Flag para mostrar el input y capturar la cantidad de hileras */
  capturarHilera: propTypes.bool,
};

DialogHileras.defaultProps = {
  capturarHilera: false,
};

export default DialogHileras;
