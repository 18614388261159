/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { toast } from 'react-toastify';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import AutoComplete from '../../componentes/AutoComplete';
import Default from '../../contenedores/Default';
import Select from '../../componentes/Select';
import TextField from '../../componentes/TextField';

import axios from '../../configuraciones/axios';
import endpoints, {
  MANZANAS, EMPRESAS,
} from '../../configuraciones/endpoints';
import estatus from '../../constantes/estatus';
import { CAMPO_REQUERIDO,ELEMENTO_NO_ENCONTRADO } from '../../constantes/mensajes';
import { trim } from '../../utilidades/functions';


const schema = yup.object({
  nombre: yup.string().required(CAMPO_REQUERIDO),
  empresaId: yup.number().required(CAMPO_REQUERIDO),
  proyectoId: yup.number().required(CAMPO_REQUERIDO),
  fecha: yup.string().required(CAMPO_REQUERIDO),
  descripcion: yup.string(),
  habilitado: yup.boolean().oneOf([true, false]).required(CAMPO_REQUERIDO),
});

const Manzana = ({ history, match }) => {
  const [empresas, setEmpresas] = useState([]);
  const [proyectos, setProyectos] = useState([]);
  const { params: { id } } = match;

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      habilitado: true,
      descripcion: '',
    }
  });

  const {
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = methods;

  const empresaId = watch('empresaId');

  /**
   * Regresa a la pantalla principal del catálogo de manzanas en la primera página.
   */
  const regresar = () => {
    const { location: { state: { pagina } } } = history;
    history.push({
      pathname: '/catalogos/manzanas',
      search: pagina && pagina > 1 ? `?pagina=${pagina}` : '',
    });
  };

  /**
   * Realiza una petición POST o PUT para afectar la manzana
   * en la base de datos.
   */
  const guardar = useCallback((manzana) => {
    const metodo = id ? 'put' : 'post';
    axios[metodo](endpoints.base.url(MANZANAS, id), trim(manzana)).then(regresar).catch(() => {});
  }, []);

  /**
   * Consulta los datos iniciales del formulario de manzanas.
   * @returns Promesa con los objetos consultados.
   */
  const consultarDatosIniciales = () => new Promise((resolve, reject) => {
    const promesas = [
      axios.get(endpoints.base.opciones(EMPRESAS)),
    ];
    if (id) promesas.push(axios.get(endpoints.base.url(MANZANAS, id)));

    Promise.all(promesas).then((resultadosPromesas) => {
      const [empresasBd, manzanaBd] = resultadosPromesas;
      setEmpresas(empresasBd);

      if (manzanaBd?.id) {
        reset({
          ...manzanaBd,
          proyectoId: manzanaBd.proyectoId.toString(),
          empresaId: manzanaBd.proyecto.empresaId.toString(),
        });
      } else if (id) {
        toast.warning(ELEMENTO_NO_ENCONTRADO);
        regresar();
      }

      resolve();
    }).catch(reject);
  });

  /**
   * Consulta los proyectos con base a la empresa seleccionada.
   */
  const consultarProyectos = async () => {
    const proyectosBd = await axios.get(endpoints.proyectosPorEmpresa(empresaId));
    setProyectos(proyectosBd);
  };

  useEffect(() => {
    consultarDatosIniciales();
  }, [id]);

  useEffect(() => {
    if (!!empresaId) consultarProyectos();
    else setProyectos([]);
  }, [empresaId]);

  return <Default
    titulo={Boolean(id) ? 'Editar Manzana' : 'Nueva Manzana'}
    placeHolder={''}
    mostrarCabeceroFormulario
    cancelar={regresar}
    frmId="frmManzana">
    <FormProvider {...methods}>
      <form noValidate onSubmit={handleSubmit(guardar)} id="frmManzana">
        <Grid container spacing={2}>
          <Grid item lg={3} md={4} sm={12} xs={12}>
            <TextField
              label="Nombre: *"
              placeHolder="Nombre"
              type="number"
              name="nombre"
              inputProps={{ maxLength: 50 }}
              error={errors.nombre}
              helperText={errors.nombre?.message}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={12} xs={12}>
            <AutoComplete
              label="Empresa: *"
              name="empresaId"
              placeHolder="Selecciona una opción"
              options={empresas}
              error={errors.empresaId}
              helperText={errors.empresaId?.message}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={12} xs={12}>
            <AutoComplete
              label="Proyecto: *"
              name="proyectoId"
              placeHolder="Selecciona una opción"
              options={proyectos}
              labelProp="nombre"
              error={errors.proyectoId}
              helperText={errors.proyectoId?.message}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={12} xs={12}>
            <TextField
              name="fecha"
              type="date"
              label="Fecha:"
              placeHolder="Fecha"
              error={errors.fecha}
              helperText={errors.fecha?.message}
            />
          </Grid>
          <Grid item lg={6} md={4} sm={12} xs={12}>
            <TextField
              name="descripcion"
              label="Descripción:"
              placeHolder="Descripción"
              inputProps={{ maxLength: 250 }}
              error={errors.descripcion}
              helperText={errors.descripcion?.message}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={12} xs={12}>
            <Select
              label="Estatus"
              required
              options={estatus}
              name="habilitado"
              placeHolder={'Selecciona una opción'}
              error={errors.habilitado}
              helperText={errors.habilitado?.message}
            />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  </Default>;
};

export default React.memo(Manzana);