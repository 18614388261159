const paleta = {
  textField: {
    text: '#657381',
    textBlack: '#33404E',
    backgroundColor: '#F5F8FA',
    backgroundColorHover: '#EEF3F7',
    backgroundColorDisabled: '#EAF0F6',
    error: '#e74c3c',
    border: '#E1E7ED',
  },
  bar: {
    primary: '#003153',
    secondary: '#4B5D6E',
    border: 'rgba(36, 36, 36, 0.1)',
    icono: '#33404E',
    iconoBorder: '#E1E7ED',
    user: '#409A8E',
    elements: '#8CA0B6',
    details: '#EBEFF2',
    point: '#BAB6B6',
    pronostico: '#E9EEFA',
    loading: '#003973',
    subItemActivo: '#EAF0F7',
    adorno: '#003366',
  },
  sidebar: {
    active: '#EAF0F6',
    backgroundColor: '#FFF',
  },
  activo: {
    primary: '#7C98B6',
    pronostico: '#2053d2'
  },
  tabla: {
    bordertd: '#CBD6E2',
    rowError: '#ffbaba',
  },
  zoomBackgroundColor: '#cad3db90',
  boton: {
    primary: '#113050',
    secondary: '#409A8E',
    info: '#113050',
    border: '#E1E7ED',
    white: '#FFF',
    secondaryDisabled: '#9FCDC7',
  },
  spinner:{
    main:'#003366',
    border:'#7A9ACB'
  },
  apartado:{
    main:'#8FA7C',
  },
  vendido:{
    main:'#409A8E',
  },
  tablas : {
    botonAdd: '#F4F9FD',
    colorTextoAdd: '#1F2129',
  },
  planos : {
    pasoLabelActivo: '#1031A9',
    pasoLabelCompletado: '#1E88E5',
    botonesHover: '#25B68B',
    manzanaActivoBackground: '#F7F7F7', 
  },
};

export default paleta;
