import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Grid, IconButton } from '@material-ui/core';
import clsx from 'clsx';
import { IoPricetagOutline } from 'react-icons/io5';
import { FaTimes } from 'react-icons/fa';

import axiosInstance from '../../configuraciones/axios';
import endpoints, { LOTES } from '../../configuraciones/endpoints';
import EstadosLoteEnum from '../../constantes/estadosLoteEnum';

import Button from '../Button';
import Modal from '../Modal';
import TextField from '../TextField';
import Typography from '../Typography';
import styles from './styles';


const ModalInfoLote = ({ abierto, cerrarModal, loteId }) => {
  const classes = styles();
  const methods = useForm();
  const history = useHistory();
  const { reset } = methods;
  const [lote, setLote] = useState();

  /**
   * Función que obtiene la información con base al loteId proporcionado,
   * una vez obtenida la información, la asigna a los campos del formulario.
   */
  const obtenerInformacionLote = useCallback(async () => {
    if (loteId && loteId !== 0) {
      const loteInfo = await axiosInstance.get(endpoints.base.url(LOTES, loteId));
      reset({
        ...loteInfo,
        proyecto: loteInfo?.manzana?.proyecto?.nombre,
        empresa: loteInfo?.manzana?.proyecto?.empresa?.nombre,
        manzana: loteInfo?.manzana?.nombre,
        estado: loteInfo?.estado?.nombre,
      });
      setLote(loteInfo);
    }
  }, [loteId])

  /** Función que abre la pantalla de venta, con el lote seleccionado como parámetro. */
  const venderLote = async () => {
    history.push(`/ventas/ventas/formulario/?loteId=${loteId}`);
  }

  /** Effect que ejecuta la función cada que esta cambie. */
  useEffect(() => { obtenerInformacionLote(); }, [obtenerInformacionLote]);

  return (
    <Modal
      open={abierto}
      handleClose={cerrarModal}
      maxWidth="sm"
      showTitle={false}
      padding={5}
    >
      <Grid container style={{ padding: '1rem' }}>
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end" style={{ marginBottom: '-1.5rem' }}>
            <IconButton onClick={cerrarModal} style={{ right: 0 }}>
              <FaTimes size={15} />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" aling="center">Lote {lote?.clave}</Typography>
        </Grid>
        <Grid item xs={12}>
          <FormProvider {...methods}>
            <form noValidate id="frmLote">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="Manzana:"
                    name="manzana"
                    placeHolder="Manzana"
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Lote:"
                    name="numero"
                    placeHolder="Lote"
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Proyecto:"
                    name="proyecto"
                    placeHolder="Proyecto"
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Empresa:"
                    name="empresa"
                    placeHolder="Empresa"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Clave:"
                    name="clave"
                    placeHolder="Clave"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Frente:"
                    name="frente"
                    placeHolder="Frente"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Fondo:"
                    name="fondo"
                    placeHolder="Fondo"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Lat. Izq.:"
                    name="lateralIzquierdo"
                    placeHolder="Lateral Izquierdo"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Lat. Der.:"
                    name="lateralDerecho"
                    placeHolder="Lateral Derecho"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Área:"
                    name="area"
                    placeHolder="Área"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Construcción:"
                    name="construccion"
                    placeHolder="Construcción"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="precioM2"
                    type="coin"
                    label="Precio:"
                    placeHolder="Precio"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="precioTotal"
                    type="coin"
                    label="Total:"
                    placeHolder="Total"
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    inputClassName={clsx(classes.txtEstado, lote?.estado?.nombre)}
                    label="Disponibilidad:"
                    name="estado"
                    placeHolder="Disponibilidad"
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ marginTop: '1rem' }} justifyContent="center">
                {
                  lote?.estadoId === EstadosLoteEnum.Disponible ? (
                    <>
                      <Grid item xs={6} sm={4}>
                        <Button
                          classesCustom={{ boton: classes.cancelar }}
                          onClick={cerrarModal}
                          label="Cerrar"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Button
                          icono={<IoPricetagOutline style={{ transform: 'rotate(-90deg)' }} size={18} />}
                          classesCustom={{ boton: classes.vender }}
                          onClick={venderLote}
                          label="Vender lote"
                          fullWidth
                        />
                      </Grid>
                    </>
                  ) : (
                    <Grid item xs={6} sm={4}>
                      <Button
                        onClick={cerrarModal}
                        label="Aceptar"
                        fullWidth
                      />
                    </Grid>
                  )
                }
              </Grid>
            </form>
          </FormProvider>
        </Grid>
      </Grid>
    </Modal>
  )
}

export default ModalInfoLote;
