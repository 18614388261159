import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles(() => ({
  container: {
    position: 'absolute',
    zIndex: 55,
    bottom: '2%',
    right: '2%',
    border: '1px solid #ccc',
    padding: '1vw',
    borderRadius: '5px',
    backgroundColor: 'white',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    boxSizing: 'border-box',
    transform: 'scale(0.7)',
    transformOrigin: 'bottom right',

    maxWidth: '250px',
    transform: 'scale(0.9)',

    '@media (max-width: 1300px)': {
      maxWidth: '300px',
      transform: 'scale(0.8)',
    },
    '@media (max-width: 1024px)': {
      maxWidth: '300px',
      transform: 'scale(0.8)',
    },
    '@media (max-width: 768px)': {
      maxWidth: '400px',
      transform: 'scale(0.7)',
    },
    '@media (max-width: 480px)': {
      maxWidth: '100px',
      transform: 'scale(0.6)',
    },
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: '5px',
    height: '30px'
  },
  fullscreenButton: {
    background: 'none',
    border: 'none',
    fontSize: '10px',
    cursor: 'pointer',
    alignItems: 'right',
    marginRight: '5px',
  },
  btn: {
    color: 'white',
    marginTop: '5px',
    border: `1px solid ${paleta.boton.border}`,
    '& .MuiTypography-body1': {
      fontSize: 14,
      fontWeight: 'bold',
      marginTop: 3,
      color: 'white',
    },
    '&:hover': {
      backgroundColor: `${paleta.boton.primary}BF !important`,
    },
  },
  filtrar: {
    padding: '5px',
    maxHeight: '30px',
    backgroundColor: paleta.boton.primary,
  }
}));

export default styles;