import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  generales: {
    marginTop: 10,
  },
  administracion: {
    marginTop: 20,
  },
  marginbottom: {
    marginBottom: 10,
  },
}));

export default styles;
