import React, { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { AiOutlineUserAdd } from 'react-icons/ai'
import { Grid } from '@material-ui/core';

import { CAMPO_REQUERIDO, MAX_LONGITUD } from "../../constantes/mensajes";
import axios from '../../configuraciones/axios';
import endpoints, { CLIENTES } from "../../configuraciones/endpoints";
import { trim } from '../../utilidades/functions';
import TextField from '../../componentes/TextField';
import { emailRegex, phoneRegex, regexMessages, rfcRegex } from '../../utilidades/regex';
import Modal from '../../componentes/Modal';
import Typography from '../../componentes/Typography';
import Boton from '../../componentes/Button';
import styles from './styles';
import CustomSelect from '../../componentes/Select';
import generos from '../../constantes/generos';

const schema = yup.object({
  nombre: yup.string().required(CAMPO_REQUERIDO),
  genero: yup.string().required(CAMPO_REQUERIDO),
  razonSocial: yup.string(),
  rfc: yup.string()
    .matches(
      rfcRegex,
      {
        message: regexMessages.RFC,
        excludeEmptyString: true,
      },
    ),
  domicilio: yup.string(),
  numero: yup.number()
    .transform((value) => (isNaN(value) ? undefined : value)),
  colonia: yup.string(),
  cp: yup.string()
    .test('len', MAX_LONGITUD(5), (val) => val.length <= 5),
  ciudad: yup.string(),
  estado: yup.string(),
  telefono: yup.string().required(CAMPO_REQUERIDO)
    .matches(
      phoneRegex,
      {
        message: regexMessages.PHONE,
        excludeEmptyString: true,
      }
    ),
  correo: yup.string()
    .matches(
      emailRegex,
      {
        message: regexMessages.EMAIL,
        excludeEmptyString: true,
      }
    ),
  referencia: yup.string(),
  telefonoReferencia: yup.string()
    .matches(
      phoneRegex,
      {
        message: regexMessages.PHONE,
        excludeEmptyString: true,
      }
    ),
});

const NuevoCliente = ({ abierto, cerrar, empresaId }) => {
  const classes = styles();
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      cp: '',
      domicilio: '',
      numero: 0,
      colonia: '',
      ciudad: '',
      estado: '',
    }
  });

  const {
    handleSubmit,
    reset,
    formState: {
      errors,
    },
  } = methods;

  const alCerrar = useCallback((nuevo) => {
    reset();
    cerrar(nuevo);
  }, [cerrar, reset]);

  /**
   * Función que guarda la información nueva del cliente.
   */
  const guardar = useCallback((cliente) => {
    const clienteGuardar = {
      ...cliente,
      empresaId,
    };
    axios.post(endpoints.base.url(CLIENTES), trim(clienteGuardar)).then(() => alCerrar(true));
  }, [alCerrar, empresaId]);


  return (
    <Modal
      open={abierto}
      handleClose={() => alCerrar(false)}
      maxWidth="md"
      showTitle={false}
    >
      <FormProvider {...methods}>
        <form id="frmCliente" noValidate onSubmit={handleSubmit(guardar)}>
          <Grid container spacing={1} style={{ padding: '20px 30px' }}>
            <Grid item md={8} sm={6} xs={5}>
              <Typography variant="h6">Agregar Cliente</Typography>
            </Grid>
            <Grid item md={4} sm={6} xs={7}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={6}>
                  <Grid container justifyContent="flex-end">
                    <Grid item xs={12}>
                      <Boton
                        isSubmit
                        fullWidth
                        icono={<AiOutlineUserAdd />}
                        variant="outlined"
                        label="Agregar"
                        frmId="frmCliente"
                        classesCustom={{
                          boton: classes.btnLote
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container justifyContent="flex-end">
                    <Grid item xs={12}>
                      <Boton
                        fullWidth
                        variant="outlined"
                        label="Cancelar"
                        onClick={() => alCerrar()}
                        classesCustom={{
                          boton: classes.btnCancelar
                        }}
                      />
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Nombre:"
                name="nombre"
                placeHolder="Nombre"
                inputProps={{ maxLength: 100 }}
                error={errors.nombre}
                helperText={errors.nombre?.message}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomSelect
                label="Genero:"
                name="genero"
                placeHolder="Selecciona una opción"
                options={generos}
                error={errors.genero}
                helperText={errors.genero?.message}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Teléfono:"
                name="telefono"
                placeHolder="Teléfono"
                inputProps={{ maxLength: 10 }}
                error={errors.telefono}
                helperText={errors.telefono?.message}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Correo:"
                name="correo"
                placeHolder="Correo"
                inputProps={{ maxLength: 50 }}
                error={errors.correo}
                helperText={errors.correo?.message}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Razón social:"
                name="razonSocial"
                placeHolder="Razón social"
                inputProps={{ maxLength: 250 }}
                error={errors.razonSocial}
                helperText={errors.razonSocial?.message}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="RFC:"
                name="rfc"
                placeHolder="R.F.C"
                inputProps={{ maxLength: 13 }}
                error={errors.rfc}
                helperText={errors.rfc?.message}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Calle:"
                name="domicilio"
                placeHolder="Calle"
                inputProps={{ maxLength: 250 }}
                error={errors.domicilio}
                helperText={errors.domicilio?.message}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <TextField
                label="Número:"
                name="numero"
                placeHolder="Número"
                inputProps={{ maxLength: 10, min: 0 }}
                error={errors.numero}
                helperText={errors.numero?.message}
                type="number"
              />
            </Grid>
            <Grid item xs={12} md={7} lg={4}>
              <TextField
                label="Colonia:"
                name="colonia"
                placeHolder="Colonia"
                inputProps={{ maxLength: 50 }}
                error={errors.colonia}
                helperText={errors.colonia?.message}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <TextField
                name="cp"
                type="number"
                label="CP:"
                placeHolder="C.P."
                inputProps={{ maxLength: 5 }}
                error={errors.cp}
                helperText={errors.cp?.message}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="Ciudad:"
                name="ciudad"
                placeHolder="Ciudad"
                inputProps={{ maxLength: 20 }}
                error={errors.ciudad}
                helperText={errors.ciudad?.message}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Estado:"
                name="estado"
                placeHolder="Estado"
                inputProps={{ maxLength: 20 }}
                error={errors.estado}
                helperText={errors.estado?.message}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Referencia"
                name="referencia"
                placeHolder="Referencia"
                inputProps={{ maxLength: 100 }}
                error={errors.referencia}
                helperText={errors.referencia?.message}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Teléfono Referencia:"
                name="telefonoReferencia"
                placeHolder="Teléfono"
                inputProps={{ maxLength: 10 }}
                error={errors.telefonoReferencia}
                helperText={errors.telefonoReferencia?.message}
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default React.memo(NuevoCliente);