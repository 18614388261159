import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const useStyles = makeStyles(() => ({
  accionesBase: {
    position: 'absolute',
    bottom: 10,
    minHeight: 45,
    minWidth: 200,
    zIndex: 55,
    left: '50%',
    transform: 'translate(-50%, 0)',
    borderRadius: 5,
  },
  acciones: {
    backgroundColor: 'rgba(90, 102, 115, 0.9)',
    '& button': {
      height: '25px !important',
      minHeight: '25px !important',
    },
  },
  texto: {
    color: 'white',
  },
  btnCancelar: {
    border: '1px solid #FFF',
    color: paleta.boton.white,
  },
  btnGuardar: {
    backgroundColor: paleta.boton.primary,
    color: '#FFFFFF !important',
  },
  btnEditar: {
    backgroundColor: 'white',
    color: paleta.boton.secondary,
    borderColor: paleta.boton.secondary,
    fontWeight: 500,
    '&:hover': {
      color: 'white',
      borderColor: 'white',
      backgroundColor: paleta.planos.botonesHover,
    }
  },
  iconoEditar:{ 
    marginRight: '10px',
  },
  btnConfigurar: {
    backgroundColor: paleta.planos.botonesHover,
    minWidth: 80,
    color: '#FFF',
    '&:hover': {
      backgroundColor: paleta.planos.botonesHover,
    }
  }
}));


export default useStyles;
