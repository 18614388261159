import React, { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Default from "../../contenedores/Default";
import { CAMPO_REQUERIDO, FECHA_NACIMIENTO_INCORRECTA, ELEMENTO_NO_ENCONTRADO } from "../../constantes/mensajes";
import axios from '../../configuraciones/axios';
import endpoints, { CLIENTES, EMPRESAS } from "../../configuraciones/endpoints";
import { trim } from '../../utilidades/functions';
import { Grid } from "@material-ui/core";
import CustomSelect from "../../componentes/Select";
import generos from "../../constantes/generos";
import TextField from "../../componentes/TextField";
import AutoComplete from "../../componentes/AutoComplete";
import validations, { curpRegex, phoneRegex, regexMessages, rfcRegex, emailRegex } from "../../utilidades/regex";
import estatus from "../../constantes/estatus";
import { toast } from "react-toastify";
import { ESTADOS_CIVILES } from "../../constantes/tipoEstado";

const schema = yup.object({

  genero: yup.string().required(CAMPO_REQUERIDO),
  nombre: yup.string().required(CAMPO_REQUERIDO),
  razonSocial: yup.string().nullable(),
  identificacion: yup.string().nullable(),
  fechaNacimiento:
    yup.date()
      .max(new Date(), FECHA_NACIMIENTO_INCORRECTA)
      .transform((value) => (isNaN(value) ? undefined : value)),
  lugarNacimiento: yup.string().nullable(),
  profesion: yup.string().nullable(),
  rfc:
    yup.string()
      .matches(
        rfcRegex,
        {
          message: regexMessages.RFC,
          excludeEmptyString: true,
        },
      ).nullable(),
  curp:
    yup.string()
      .matches(
        curpRegex,
        {
          message: regexMessages.CURP,
          excludeEmptyString: true,
        }
      ).nullable(),
  domicilio: yup.string(),
  numero:
    yup.number()
      .max(9999999999, validations.LONGITUD_CARACTERES(10))
      .transform((value) => (isNaN(value) ? undefined : value)),
  colonia: yup.string(),
  cp: yup.number()
    .max(99999, validations.LONGITUD_CARACTERES(5))
    .transform((value) => (isNaN(value) ? undefined : value)),
  ciudad: yup.string(),
  estado: yup.string(),
  telefono:
    yup.number()
      .max(9999999999, regexMessages.LONGITUD_TELEFONO)
      .transform((value) => (isNaN(value) ? undefined : value))
      .required(CAMPO_REQUERIDO),
  celular:
    yup.number()
      .max(9999999999, regexMessages.LONGITUD_TELEFONO)
      .transform((value) => (isNaN(value) ? undefined : value)),
  contacto: yup.string().nullable(),
  estadoCivilId: yup.number().nullable(),
  correo: yup.string()
    .matches(
      emailRegex,
      {
        message: regexMessages.EMAIL,
        excludeEmptyString: true,
      }
    ).nullable(),
  empresaId: yup.string().required(CAMPO_REQUERIDO),
  referencia: yup.string().nullable(),
  telefonoReferencia:
    yup.string()
      .matches(
        phoneRegex,
        {
          message: regexMessages.PHONE,
          excludeEmptyString: true,
        }
      ),
  referencia2: yup.string().nullable(),
  telefonoReferencia2:
    yup.string()
      .matches(
        phoneRegex,
        {
          message: regexMessages.PHONE,
          excludeEmptyString: true,
        }
      ),
  habilitado: yup.boolean(),
});

const Cliente = ({ history, match }) => {
  const { params: { id } } = match;
  const [empresas, setEmpresas] = useState([]);
  const [estadosCiviles, setEstadosCiviles] = useState([]);
  const hoy = new Date();

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      genero: 'H',
      estadoCivilId: 1,
      habilitado: true,
      cp: 0,
      domicilio: '',
      numero: 0,
      colonia: '',
      ciudad: '',
      estado: '',
    },
  });

  const {
    handleSubmit,
    reset,
    formState: {
      errors,
    },
  } = methods;

  /**
   * La función "regresar" navega a la página '/catalogos/clientes' con un parámetro de consulta
   * 'pagina' basado en el estado de la página anterior.
   */
  const regresar = useCallback(() => {
    const { location: { state: { pagina } } } = history;
    history.push({
      pathname: '/catalogos/clientes',
      search: pagina && pagina > 1 ? `?pagina=${pagina}` : '',
    });
  }, [history]);

  /**
   * La función `consultarDatosIniciales` recupera datos iniciales de un cliente de una base de datos
   * y establece valores específicos basados en los datos recuperados.
   */
  const consultarDatosIniciales = useCallback(() => new Promise((resolve, reject) => {
    const promesas = [
      axios.get(endpoints.base.opciones(EMPRESAS)),
      axios.get(endpoints.estados(ESTADOS_CIVILES)),
    ];
    if (id) promesas.push(axios.get(endpoints.base.url(CLIENTES, id)));
    Promise.all(promesas).then((resultadosPromesas) => {
      const [empresasDB, estadosCivilesDB, clienteDB] = resultadosPromesas;
      if (clienteDB?.id) {
        reset({
          ...clienteDB,
          fechaNacimiento: clienteDB.fechaNacimiento?.trim(),
          telefono: clienteDB.telefono.trim(),
          celular: clienteDB.celular?.trim(),
          telefonoReferencia: clienteDB.telefonoReferencia?.trim(),
          telefonoReferencia2: clienteDB.telefonoReferencia2?.trim(),
          empresaId: clienteDB.empresaId.toString(),
        });

      } else if (id) {
        toast.warning(ELEMENTO_NO_ENCONTRADO);
        regresar();
      }

      setEmpresas(empresasDB);
      setEstadosCiviles(estadosCivilesDB);

      resolve();
    }).catch(reject);
  }), [id, regresar, reset]);

  useEffect(() => {
    consultarDatosIniciales();
  }, [consultarDatosIniciales]);

  /* El código realiza una solicitud PUT usando Axios para actualizar el objeto 
  `cliente` en un punto final específico. Si `id` no está definido, realiza una
  solicitud POST para crear un nuevo objeto `cliente` en el mismo punto final.
  */
  const guardar = useCallback((cliente) => {
    const metodo = id ? 'put' : 'post';
    axios[metodo](endpoints.base.url(CLIENTES, id), trim(cliente)).then(regresar).catch(() => { });
  }, [id, regresar]);

  return (
    <Default
      titulo={Boolean(id) ? 'Editar cliente' : 'Nuevo cliente'}
      mostrarCabeceroFormulario
      cancelar={regresar}
      frmId="frmCliente"
    >
      <FormProvider {...methods}>
        <form noValidate id="frmCliente" onSubmit={handleSubmit(guardar)}>
          <Grid container spacing={1}>

            <Grid item xs={12} md={6} lg={2}>
              <CustomSelect
                label="Genero:"
                name="genero"
                options={generos}
                error={errors.genero}
                helperText={errors.genero?.message}
                required
              />
            </Grid>
            <Grid item xs={12} md={6} lg={5}>
              <TextField
                label="Nombre:"
                name="nombre"
                placeHolder="Nombre"
                inputProps={{ maxLength: 100 }}
                error={errors.nombre}
                helperText={errors.nombre?.message}
                required
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                label="Razón social:"
                name="razonSocial"
                placeHolder="Razón social"
                inputProps={{ maxLength: 250 }}
                error={errors.razonSocial}
                helperText={errors.razonSocial?.message}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
              <TextField
                label="Identificación:"
                name="identificacion"
                placeHolder="Identificación"
                inputProps={{ maxLength: 30 }}
                error={errors.identificacion}
                helperText={errors.identificacion?.message}
              />
            </Grid>

            <Grid item xs={12} md={2} lg={2}>
              <TextField
                label="Fecha de nacimiento:"
                name="fechaNacimiento"
                inputProps={{ maxLength: 30, min: hoy }}
                error={errors.fechaNacimiento}
                helperText={errors.fechaNacimiento?.message}
                type='date'
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                label="Lugar de nacimiento:"
                name="lugarNacimiento"
                placeHolder="Lugar de nacimiento"
                inputProps={{ maxLength: 50 }}
                error={errors.lugarNacimiento}
                helperText={errors.lugarNacimiento?.message}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <TextField
                label="Profesión:"
                name="profesion"
                placeHolder="Profesión"
                inputProps={{ maxLength: 50 }}
                error={errors.profesion}
                helperText={errors.profesion?.message}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <TextField
                label="RFC:"
                name="rfc"
                placeHolder="R.F.C"
                inputProps={{ maxLength: 13 }}
                error={errors.rfc}
                helperText={errors.rfc?.message}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <TextField
                label="CURP:"
                name="curp"
                placeHolder="CURP"
                inputProps={{ maxLength: 18 }}
                error={errors.curp}
                helperText={errors.curp?.message}
              />
            </Grid>

            <Grid item xs={12} md={7} lg={4}>
              <TextField
                label="Domicilio:"
                name="domicilio"
                placeHolder="Domicilio"
                inputProps={{ maxLength: 250 }}
                error={errors.domicilio}
                helperText={errors.domicilio?.message}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={1}>
              <TextField
                label="Número:"
                name="numero"
                placeHolder="Número"
                inputProps={{ min: 0 }}
                error={errors.numero}
                helperText={errors.numero?.message}
                type="number"
              />
            </Grid>
            <Grid item xs={12} md={7} lg={2}>
              <TextField
                label="Colonia:"
                name="colonia"
                placeHolder="Colonia"
                inputProps={{ maxLength: 50 }}
                error={errors.colonia}
                helperText={errors.colonia?.message}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={1}>
              <TextField
                label="CP:"
                name="cp"
                placeHolder="C.P."
                inputProps={{ min: 0 }}
                error={errors.cp}
                helperText={errors.cp?.message}
                type="number"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
              <TextField
                label="Ciudad:"
                name="ciudad"
                placeHolder="Ciudad"
                inputProps={{ maxLength: 20 }}
                error={errors.ciudad}
                helperText={errors.ciudad?.message}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
              <TextField
                label="Estado:"
                name="estado"
                placeHolder="Estado"
                inputProps={{ maxLength: 20 }}
                error={errors.estado}
                helperText={errors.estado?.message}
              />
            </Grid>

            <Grid item xs={12} md={3} lg={2}>
              <TextField
                label="Teléfono:"
                name="telefono"
                placeHolder="Teléfono"
                inputProps={{ min: 0 }}
                error={errors.telefono}
                helperText={errors.telefono?.message}
                type="number"
                required
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <TextField
                label="Celular:"
                name="celular"
                placeHolder="Celular"
                inputProps={{ min: 0 }}
                error={errors.celular}
                helperText={errors.celular?.message}
                type="number"
              />
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <TextField
                label="Contacto:"
                name="contacto"
                placeHolder="Contacto"
                inputProps={{ maxLength: 100 }}
                error={errors.contacto}
                helperText={errors.contacto?.message}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <CustomSelect
                label="Estado civil:"
                name="estadoCivilId"
                labelProp="nombre"
                options={estadosCiviles}
                error={errors.estadoCivilId}
                helperText={errors.estadoCivilId?.message}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <TextField
                label="Correo:"
                name="correo"
                placeHolder="Correo"
                inputProps={{ maxLength: 50 }}
                error={errors.correo}
                helperText={errors.correo?.message}
              />
            </Grid>

            <Grid item xs={12} md={8} lg={3}>
              <TextField
                label="Referencia #1:"
                name="referencia"
                placeHolder="Referencia"
                inputProps={{ maxLength: 100 }}
                error={errors.referencia}
                helperText={errors.referencia?.message}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <TextField
                label="Teléfono:"
                name="telefonoReferencia"
                placeHolder="Teléfono"
                inputProps={{ maxLength: 10 }}
                error={errors.telefonoReferencia}
                helperText={errors.telefonoReferencia?.message}
              />
            </Grid>
            <Grid item xs={12} md={8} lg={3}>
              <TextField
                label="Referencia #2:"
                name="referencia2"
                placeHolder="Referencia"
                inputProps={{ maxLength: 100 }}
                error={errors.referencia2}
                helperText={errors.referencia2?.message}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <TextField
                label="Teléfono:"
                name="telefonoReferencia2"
                placeHolder="Teléfono"
                inputProps={{ maxLength: 10 }}
                error={errors.telefonoReferencia2}
                helperText={errors.telefonoReferencia2?.message}
              />
            </Grid>

            <Grid item xs={12} md={3} lg={3}>
              <AutoComplete
                label="Empresa:"
                name="empresaId"
                options={empresas}
                error={errors.empresaId}
                helperText={errors.empresaId?.message}
                required
              />
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <CustomSelect
                label="Estado:"
                name="habilitado"
                options={estatus}
                error={errors.habilitado}
                helperText={errors.habilitado?.message}
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Default>
  );
};

export default React.memo(Cliente);