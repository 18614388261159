import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles(() => ({
  btn: {
    color: 'white',
    border: `1px solid ${paleta.boton.border}`,
    '& .MuiTypography-body1': {
      fontSize: 14,
      fontWeight: 'bold',
      marginTop: 3,
    },
  },
  exportar: {
    backgroundColor: paleta.bar.primary,
    '& .MuiTypography-body1': {
      color: 'white',
    },
    '&:hover': {
      backgroundColor: `${paleta.boton.info}BF !important`,
    },
  },
  imprimir: {
    backgroundColor: paleta.boton.white,
    '& .MuiTypography-root': {
      color: `${paleta.boton.primary} !important`,
    },
  '&:hover': {
      backgroundColor: `${paleta.boton.secondary}BF !important`,
    },
  },
  imprimirIcono:{
    color: paleta.bar.primary,
  }
}));

export default styles;
