import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box } from '@material-ui/core';
import Plano from '../../componentes/Plano';
import { ordenarLotes } from './Lotes';
import ListadoManzanas from '../../componentes/ListadoManzanas';
import { ColoresPlanos, ColoresSolidosPlanos } from '../../constantes/coloresPlanos';
import SimbologiaColor from '../../componentes/Simbologia/SimboloColor';
import Typography from '../../componentes/Typography';

const Asignacion = ({ img, manzanas, setManzanas, setManzanasOrdenadas, puedeContinuar }) => {
  const [manzanaSeleccionada, setManzanaSeleccionada] = useState({});
  const [asignacionLotes, setAsignacionLotes] = useState(false);

  const simbologia = [
    {
      simbolo: <SimbologiaColor colorFondo={ColoresPlanos.LoteInicio} colorBorde={ColoresPlanos.LoteInicio}/>,
      label: <Typography>Lote inicial</Typography>
    },
    {
      simbolo: <SimbologiaColor colorFondo={ColoresPlanos.loteFinal} colorBorde={ColoresPlanos.loteFinal}/>,
      label: <Typography>Lote final</Typography>
    },
    {
      simbolo: <SimbologiaColor colorFondo={ColoresPlanos.Hilera1} colorBorde={ColoresPlanos.Hilera1}/>,
      label: <Typography>Hilera 1</Typography>
    },
    {
      simbolo: <SimbologiaColor colorFondo={ColoresPlanos.Hilera2} colorBorde={ColoresSolidosPlanos.Hilera2}/>,
      label: <Typography>Hilera 2</Typography>
    },
  ]

  /**
   * Meotodo encargado para el ordenamiento de poligonos
   * @param {*} poligonos Arreglo de poligonos a ordenar
   * @param {*} inicio Poligono inicial
   * @param {*} fin Poligono final
   * @returns Un arreglo de poligonos ordenados a partir
   */
  const ordenar = (poligonos = [], inicio, fin) => {
    const poligonosInicio = poligonos.filter(({ hilera }) =>
      hilera !== undefined &&
      hilera === poligonos[inicio ? inicio : 0]?.hilera
    );

    const poligonosFin = poligonos.filter(({ hilera }) =>
      hilera !== undefined &&
      hilera === poligonos[fin ? fin : poligonos.length - 1].hilera &&
      hilera !== poligonos[inicio ? inicio : 0]?.hilera
    );

    let coordenadasOrdenadas = inicio === 0 ? poligonosInicio : poligonosInicio.reverse();

    if (poligonos.length !== 1) {
      if (fin === (poligonos.length - 1)) {
        coordenadasOrdenadas = coordenadasOrdenadas.concat([...poligonosFin]);
      } else {
        coordenadasOrdenadas = [...coordenadasOrdenadas, ...poligonosFin.reverse()];
      }
    }
    coordenadasOrdenadas = coordenadasOrdenadas.map((coordenada, i) => ({ ...coordenada, lote: i + 1 }));
    return { coordenadas: coordenadasOrdenadas };
  };

  /**
   * Metodo para actualizar las una manzana con base al indice, propiedad y el valor proporcionado
   */
  const actualizarManzanasOrdenadas = useCallback((indice, prop, value) => {

    const nuevasManzanas = [...manzanas];
    if (indice !== -1) {
      nuevasManzanas[indice][prop] = value;
    }

    setManzanasOrdenadas(nuevasManzanas);
  }, [manzanas, setManzanasOrdenadas]);

  /**
   * Metodo useCallback para actualizar los vertices de la manzana de forma ordenada
   */
  const actualizarVerticesLotesOrdenadas = useCallback(() => {

    const nuevasManzanas = [...manzanas];

    nuevasManzanas.forEach((manzana) => {
      if (manzana.lotes && manzana.lotes.length > 0) {
        manzana.lotesVertices?.forEach((lote, i) => {
          const loteVertices = manzana.lotes[i]?.loteVertices;
          manzana.lotes[i].loteVertices = lote.coordenadas?.map((coordenada, index) => ({
            loteId: loteVertices[index]?.loteId ? loteVertices[index].loteId : manzana.lotes[i].id,
            ...coordenada,
          }));
        });
      }
    });

    setManzanasOrdenadas(nuevasManzanas);
  }, [manzanas, setManzanasOrdenadas])

  /**
   * Metodo para asignar el valor loteInicial o loteFinal de la hilera de la manzana
   */
  const asignarOrdenLotes = async (propiedad, valor) => {
    const nuevasManzanas = [...manzanas];
    const index = manzanas.findIndex((m) => m.id === manzanaSeleccionada.id);
    nuevasManzanas[index][propiedad] = valor;
    setManzanas(nuevasManzanas);
  }

  const mapearPoligonosPorManzana = useCallback((manzanas) => {
    const poligonosLotesPorManzana = [];
    let indicePoligono = 0;

    for (const manzana of manzanas) {
      const manzanaPoligono = {
        id: manzana.id,
        loteInicial: manzana.loteInicial,
        loteFinal: manzana.loteFinal,
        lotesVertices: manzana.loteVertices || []
      };

      if (manzana.lotesVertices) {
        const lotesOrdenados = manzana.lotesVertices.sort(ordenarLotes);
        let indiceLote = 0;
        for (const lote of lotesOrdenados) {
          manzanaPoligono.lotesVertices.push({
            ...lote,
            indiceLote,
            indicePoligono,
          });
          indicePoligono++;
          indiceLote++;
        }
      }

      poligonosLotesPorManzana.push(manzanaPoligono);
    }

    return poligonosLotesPorManzana;
  }, []);

  const poligonos = useMemo(() => {

    let poligonosLotesOrdenados = [];
    const poligonos = mapearPoligonosPorManzana(manzanas, true);

    poligonos.forEach((poligono, i) => {

      if (poligono.lotesVertices.length > 0) {
        const lotesOrdenados = ordenar(
          poligono.lotesVertices,
          poligono.loteInicial ? poligono.loteInicial : 0,
          poligono.loteFinal ? poligono.loteFinal : poligono.lotesVertices.length - 1,
        );

        // Colores de los lotes inicial y final
        lotesOrdenados.coordenadas = lotesOrdenados.coordenadas.map(c => ({
          ...c,
          fill: ColoresPlanos['Hilera' + c.hilera],
          stroke: ColoresSolidosPlanos['Hilera' + c.hilera],
        }));
        
        lotesOrdenados.coordenadas[0].fill = ColoresPlanos.LoteInicio;
        lotesOrdenados.coordenadas[0].stroke = ColoresSolidosPlanos.LoteInicio;
        lotesOrdenados.coordenadas[lotesOrdenados.coordenadas.length - 1].fill = ColoresPlanos.loteFinal;
        lotesOrdenados.coordenadas[lotesOrdenados.coordenadas.length - 1].stroke = ColoresSolidosPlanos.loteFinal;

        poligonosLotesOrdenados.push(lotesOrdenados);
        actualizarManzanasOrdenadas(i, 'lotesVertices', lotesOrdenados.coordenadas);
        setAsignacionLotes(false);
      }
    });
    actualizarVerticesLotesOrdenadas();

    return poligonosLotesOrdenados;

  }, [manzanas, mapearPoligonosPorManzana, actualizarManzanasOrdenadas, actualizarVerticesLotesOrdenadas]);

  useEffect(() => {
    if (manzanas.every((m) => m.lotes.every((l) => l.loteVertices && l.loteVertices.length > 0))) {
      puedeContinuar(true);
    } else {
      puedeContinuar(false);
    }
  }, [manzanas, puedeContinuar]);

  /**
   * Metodo para actualizar la manzana seleccionada del listado
   */
  const cambiarManzanaSeleccionada = (nuevaManzana) => {
    if (nuevaManzana.id === manzanaSeleccionada.id) return;
    setAsignacionLotes(false);
    setManzanaSeleccionada({
      ...nuevaManzana,
      lotesVertices: nuevaManzana.lotesVertices || [],
    });
  }

  return (
    <Box flex={1} display="flex" padding={1} overflow="auto" flexDirection="row">

      <Box flex={1} paddingRight={2}>
        <ListadoManzanas
          manzanas={manzanas.filter((manzana) => manzana.lotes.length > 0 && manzana.manzanaVertices?.length > 0)}
          manzanaSeleccionada={manzanaSeleccionada}
          setManzanaSeleccionada={cambiarManzanaSeleccionada}
          enAsignacion
          asignacionLotes={setAsignacionLotes}
        />
      </Box>

      <Box flex={4} display="flex" overflow="auto" flexDirection="column">
        <Plano
          img={img}
          poligonos={poligonos}
          mostrarTooltip
          poligonoValidarPunto={manzanaSeleccionada.manzanaVertices}
          enAsignacion
          asignarOrdenLotes={asignarOrdenLotes}
          manzanaSeleccionada={manzanaSeleccionada}
          asignacionLotes={asignacionLotes}
          alCancelar={() => setAsignacionLotes(false)}
          mostrarSimbologia
          simbologia={simbologia}
        />
      </Box>
    </Box>
  );
};

export default Asignacion;
