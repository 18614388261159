import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Grid } from '@material-ui/core';
import moment from 'moment';

import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { GiReceiveMoney } from 'react-icons/gi';

import axios from '../../configuraciones/axios';
import axiosInstance from '../../configuraciones/axios';
import endpoints, { COBRANZAS } from "../../configuraciones/endpoints";

import TextField from '../../componentes/TextField';
import Modal from '../../componentes/Modal';
import Typography from '../../componentes/Typography';
import Boton from '../../componentes/Button';
import AutoComplete from '../../componentes/AutoComplete';
import CustomSelect from '../../componentes/Select';

import styles from './styles';

import { tipoPagoCobranza } from '../../constantes/tipoPago';
import tiposCuenta from '../../constantes/tiposCuenta';
import { CAMPO_REQUERIDO, IMPORTE_MENOR_A_CERO, PAGO_MENSUALIDAD_INCORRECTO } from '../../constantes/mensajes';
import { toast } from 'react-toastify';
import TipoPagoCobranzaEnum from '../../constantes/tipoPagoCobranzaEnum';
import { trim } from '../../utilidades/functions';

const schema = yup.object({
  numero: yup.number(),
  nombreProyecto: yup.string(),
  nombreManzana: yup.string(),
  numeroLote: yup.number(),
  cliente: yup.string(),
  pagadoMensualidad: yup.number().transform((value) => (isNaN(value) ? undefined : value)),
  intereses: yup.number(),
  importeMensualidad: yup.number(),

  esAbono: yup.boolean(),
  tipoMovimientoId: yup.number().when('esAbono', ([esAbono], field) => {
    return (!esAbono ? field.required(CAMPO_REQUERIDO) : field);
  }).transform((value) => (isNaN(value) ? undefined : value)),

  formasPago: yup.number().required(CAMPO_REQUERIDO).transform((value) => (isNaN(value) ? undefined : value)),
  cuentaId: yup.number().required(CAMPO_REQUERIDO),
  pagado: yup.string().required(CAMPO_REQUERIDO),
  referencia: yup.string().required(CAMPO_REQUERIDO),
  fechaMostrar: yup.string().required(CAMPO_REQUERIDO),
});

const Mensualidades = ({ abierto, cerrar, abonoId, esAbono }) => {
  const classes = styles();
  const hoy = moment().format('yyyy-MM-DDTHH:mm');
  const [cuentas, setCuentas] = useState();

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      esAbono: false,
      tipoMovimientoId: undefined,
      cuentaId: undefined,
      referencia: undefined,
      fechaMostrar: hoy,
    },
  });

  const {
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: {
      errors,
    },
  } = methods;

  useEffect(() => {
    setValue('esAbono', esAbono);
  }, [esAbono, setValue]);

  const pagadoMensualidad = watch('pagadoMensualidad');
  const totalMensualidad = watch('importeMensualidad');
  const intereses = watch('intereses');
  const pagado = watch('pagado');
  const tipoMovimientoId = watch('tipoMovimientoId');
  const formasPago = watch('formasPago');

  /**
   * Método que consulta los datos inciales de la pantalla
   */
  const consultarDatosIniciales = useCallback(() => new Promise((resolve, reject) => {
    const promesas = [];
    if (abonoId) promesas.push(axios.get(endpoints.base.url(COBRANZAS, abonoId)));

    Promise.all(promesas).then(async (resultadosPromesas) => {
      const [cobranzaDd] = resultadosPromesas;
      if (cobranzaDd?.id) {
        reset((actual) => ({
          ...actual,
          numero: cobranzaDd.numero,
          nombreCliente: cobranzaDd.ventum.cliente.nombre,
          nombreManzana: cobranzaDd.ventum.lote.manzana.nombre,
          nombreProyecto: cobranzaDd.ventum.lote.manzana.proyecto.nombre,
          numeroLote: cobranzaDd.ventum.lote.numero,
          pagadoMensualidad: cobranzaDd.pagado || undefined,
          importeMensualidad: cobranzaDd.importe,
          intereses: cobranzaDd.intereses,
          pagado: parseFloat(cobranzaDd.importe) + parseFloat(cobranzaDd.intereses) - (cobranzaDd.pagado || 0),
        }));
      }
      resolve();
    }).catch(reject);
  }), [abonoId, reset]);

  const alCerrar = useCallback(() => {
    reset();
    cerrar();
  }, [cerrar, reset]);

  const obtenerCuentas = useCallback(async () => {
    const cuentasBd = await axiosInstance.get(endpoints.cuentasPorTipoCuenta(formasPago));
    setCuentas(cuentasBd);
  }, [formasPago]);

  useEffect(() => {
    if (!!formasPago) obtenerCuentas();
  }, [formasPago, obtenerCuentas]);

  /** 
  * Método para validar el monto dependiendo del tipo de pago a la mensualidad
  */
  const validarMontoTipoPago = useCallback(() => {

    const importeFloat = +parseFloat(pagado).toFixed(2);
    const totalMensualidadFloat = (+parseFloat(totalMensualidad).toFixed(2)) + (+parseFloat(intereses).toFixed(2));
    const restanteMensualidadFloat = +parseFloat(totalMensualidadFloat - parseFloat(pagadoMensualidad)).toFixed(2)
    let valido = true;

    switch (true) {
      case (importeFloat <= 0):
        toast.warn(IMPORTE_MENOR_A_CERO);
        valido = false;
        break;
      
      case (
        !esAbono &&
        (tipoMovimientoId === TipoPagoCobranzaEnum.PagoMensualidad) &&
        (importeFloat !== restanteMensualidadFloat)
      ):
        toast.warn(PAGO_MENSUALIDAD_INCORRECTO);
        valido = false;
        break;

      default:
        break;
    }

    return valido;
  }, [esAbono, intereses, pagado, pagadoMensualidad, tipoMovimientoId, totalMensualidad]);

  /**
   * Función que realiza los movimientos del abono, pago de mensualidad o abono a capital.
   */
  const guardar = useCallback((mensualidad) => {
    if (validarMontoTipoPago()) {
      const objGuardar = {
        ...mensualidad,
        fecha: moment(mensualidad.fechaMostrar),
      };
      axios.put(endpoints.base.url(COBRANZAS, abonoId), trim(objGuardar)).then(alCerrar).catch(() => { });
    }
  }, [abonoId, alCerrar, validarMontoTipoPago]);

  /**
  * Evento tipo constructor al entrar al formulario.
  */
  useEffect(() => {
    consultarDatosIniciales();
  }, [abonoId, consultarDatosIniciales]);

  return (
    <Modal
      open={abierto}
      handleClose={() => alCerrar(false)}
      maxWidth="md"
      showTitle={false}
    >
      <FormProvider {...methods}>
        <form id="frmMensualidad" noValidate onSubmit={handleSubmit(guardar)}>
          <Grid container spacing={1} style={{ padding: '20px 30px' }}>
            <Grid item md={8} sm={6} xs={5}>
              <Typography variant="h6">
                {
                  esAbono ? 'Abono de mensualidad' : 'Pago de mensualidad'
                }
              </Typography>
            </Grid>

            {/* Botones */}
            <Grid item md={4} sm={6} xs={7}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={6}>
                  <Grid container justifyContent="flex-end">
                    <Grid item xs={12}>
                      <Boton
                        isSubmit
                        fullWidth
                        icono={<GiReceiveMoney />}
                        variant="outlined"
                        label={
                          esAbono ? 'Abonar' : 'Pagar'
                        }
                        frmId="frmMensualidad"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container justifyContent="flex-end">
                    <Grid item xs={12}>
                      <Boton
                        fullWidth
                        variant="outlined"
                        label="Cancelar"
                        onClick={alCerrar}
                        classesCustom={{
                          boton: classes.btnCancelar
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Formulario */}
            <Grid item xs={12} md={2}>
              <TextField
                label="No. pago:"
                name="numero"
                placeHolder="No. pago"
                error={errors.numero}
                helperText={errors.numero?.message}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Proyecto:"
                name="nombreProyecto"
                placeHolder="Proyecto"
                error={errors.nombreProyecto}
                helperText={errors.nombreProyecto?.message}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                label="Manzana:"
                name="nombreManzana"
                placeHolder="Manzana"
                error={errors.nombreManzana}
                helperText={errors.nombreManzana?.message}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                label="No. lote:"
                name="numeroLote"
                placeHolder="No. lote"
                error={errors.numeroLote}
                helperText={errors.numeroLote?.message}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={2} />

            <Grid item xs={12} md={6}>
              <TextField
                label="Cliente:"
                name="nombreCliente"
                placeHolder="Cliente"
                error={errors.nombreCliente}
                helperText={errors.nombreCliente?.message}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                label="Importe pagado:"
                name="pagadoMensualidad"
                placeHolder="Importe pagado"
                error={errors.pagadoMensualidad}
                helperText={errors.pagadoMensualidad?.message}
                type="coin"
                disabled
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                label="Importe intereses:"
                name="intereses"
                placeHolder="Importe intereses"
                error={errors.intereses}
                helperText={errors.intereses?.message}
                type="coin"
                disabled
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                label="Importe total:"
                name="importeMensualidad"
                placeHolder="Importe total"
                error={errors.importeMensualidad}
                helperText={errors.importeMensualidad?.message}
                type="coin"
                disabled
              />
            </Grid>

            {
              !esAbono &&
              <>
                <Grid item xs={12} md={3}>
                  <CustomSelect
                    name="tipoMovimientoId"
                    label="Tipo de pago:"
                    placeHolder="Selecciona una opción"
                    options={tipoPagoCobranza}
                    className={classes.control}
                    error={errors.tipoMovimientoId}
                    helperText={errors.tipoMovimientoId?.message}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={9} />
              </>
            }

            <Grid item xs={12} md={3}>
              <CustomSelect
                name="formasPago"
                label="Formas de pago:"
                placeHolder="Selecciona una opción"
                options={tiposCuenta}
                className={classes.control}
                error={errors.formasPago}
                helperText={errors.formasPago?.message}
                required
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <AutoComplete
                name="cuentaId"
                label="Cuenta:"
                placeHolder="Selecciona una opción"
                options={cuentas}
                className={classes.control}
                error={errors.cuentaId}
                helperText={errors.cuentaId?.message}
                required
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Importe:"
                name="pagado"
                placeHolder="Importe"
                error={errors.pagado}
                helperText={errors.pagado?.message}
                type="coin"
                required
              />
            </Grid>
            <Grid item xs={12} md={3} />

            <Grid item xs={12} md={6}>
              <TextField
                label="Referencia:"
                name="referencia"
                placeHolder="Referencia"
                inputProps={{ maxLength: 50 }}
                error={errors.referencia}
                helperText={errors.referencia?.message}
                required
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                name="fechaMostrar"
                type="datetime-local"
                label="Fecha:"
                inputProps={{ max: hoy }}
                className={classes.control}
                error={errors.fecha}
                helperText={errors.fecha?.message}
              />
            </Grid>

          </Grid>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default React.memo(Mensualidades);