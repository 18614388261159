import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles(() => ({
  btn: {
    color: 'white',
    border: `1px solid ${paleta.boton.border}`,
    '& .MuiTypography-body1': {
      fontSize: 14,
      fontWeight: 'bold',
      marginTop: 3,
    },
  },
  buscar: {
    backgroundColor: paleta.boton.secondary,
    '& .MuiTypography-body1': {
      color: 'white',
    },
    '&:hover': {
      backgroundColor: `${paleta.boton.secondary}BF !important`,
    },
  },
  limpiar: {
    color: paleta.bar.primary,
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: `${paleta.boton.secondary}BF !important`,
    },
  },
}));

export default styles;
