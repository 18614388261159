import React from 'react';
import { Backdrop } from '@material-ui/core';
import { BeatLoader } from 'react-spinners';
import { useSelector } from 'react-redux';
import paleta from '../../configuraciones/paleta';
import styles from './styles';
import clsx from 'clsx';

const Spinner = () => {
  const classes = styles();
  const ajax = useSelector(({ ajax }) => ajax);
  return (
    <Backdrop
      className={clsx(classes.root)}
      open={ajax > 0}
      transitionDuration={400}
    >
      <BeatLoader
        loading={ajax > 0}
        color={paleta.bar.loading}
        size={32}
        speedMultiplier={1.1}
      />
    </Backdrop>
  );
};

export default Spinner;
