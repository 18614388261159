import moment from 'moment';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { BsPencil } from 'react-icons/bs';
import { FiTrash } from 'react-icons/fi';
import { IconButton } from '@material-ui/core';
import { MdOutlineReceiptLong } from 'react-icons/md';

import FiltrosFlujos from './filtroFlujos';

import axiosInstance from '../../configuraciones/axios';
import endpoints, { FLUJOS } from '../../configuraciones/endpoints';

import Confirmacion from '../../componentes/Confirmacion';
import Listados from '../../contenedores/Listados';

import { formatoMoneda } from '../../utilidades/functions';
import { ConfiguracionXMLHttp, peticionXMLHttp } from '../../utilidades/peticionXMLHttp';


const Flujos = ({ history, location }) => {
  const [anchorConfirmacion, setAnchorConfirmacion] = useState(null);
  const [flujo, setFlujo] = useState(null);
  const refListado = useRef();
  const token = useSelector(({ user }) => user?.token);


  /**
   * Método que abre el modal de confirmación para eliminar.
   * @param row Objeto con la información del movimiento a cancelar.
   * @param index Index del renglón donde se hizo click.
   * @param event Evento que ejecuta el método.
   */
  const abrirModal = (row, index, event) => {
    setFlujo({ ...row, index });
    setAnchorConfirmacion(event.currentTarget);
  };

  /**
   * Método que cierra al modal de confirmación.
   */
  const alCancelar = () => {
    setAnchorConfirmacion(null);
    setFlujo(null);
  };

  /**
   * Método que realiza la cancelación del movimiento al aceptar el modal.
   */
  const alAceptar = async () => {
    await axiosInstance.delete(endpoints.base.url(FLUJOS, flujo?.id));
    alCancelar();
    refListado.current.consultarListado();
  };

  const obtenerComprobante = (id) => {
    const configuracion = new ConfiguracionXMLHttp({
      tipoPeticion: 'GET',
      token: token,
      urlEndpoint: endpoints.obtenerComprobanteFlujo(id),
      visualizar: true,
    });
    peticionXMLHttp(configuracion).then().catch(() => { });
  }

  const cabeceros = [
    { label: 'Folio', key: 'folio' },
    { label: 'Flujo', transform: ({ ingreso }) => ingreso ? 'Ingreso' : 'Egreso' },
    { label: 'Fecha', width: 100, transform: ({ fecha }) => moment(fecha).format('DD-MM-YYYY') },
    {
      label: 'Hora', transform: ({ fecha }) => (
        moment(fecha).format('hh:mm A')
      ),
    },
    { label: 'Concepto', key: 'concepto' },
    { label: 'Empresa', transform: ({ venta }) => venta?.lote?.manzana?.proyecto?.empresa?.nombre },
    { label: 'Proyecto', transform: ({ venta }) => venta?.lote?.manzana?.proyecto?.nombre },
    { label: 'Manzana', transform: ({ venta }) => venta?.lote?.manzana?.nombre },
    { label: 'Lote', transform: ({ venta }) => venta?.lote?.numero },
    { label: 'Cliente', transform: ({ venta }) => venta?.cliente?.nombre },
    { label: 'Movimiento', transform: ({ tipoMovimiento }) => tipoMovimiento?.nombre },
    { label: 'Cuenta', transform: ({ cuenta }) => cuenta?.nombre },
    { label: 'Referencia', key: 'referencia' },
    { label: 'Forma de pago', transform: ({ cuenta }) => cuenta?.tipoCuenta?.nombre },
    { label: 'Total', transform: ({ importe }) => formatoMoneda(importe) },
    { label: 'Saldo anterior', transform: ({ saldoAnterior }) => formatoMoneda(saldoAnterior) },
    { label: 'Saldo posterior', transform: ({ saldoPosterior }) => formatoMoneda(saldoPosterior) },
    {
      label: 'Comprobante', align: 'center', transform: ({ id }) => <IconButton
        size="small"
        onClick={() => {
          obtenerComprobante(id);
        }}>
        <MdOutlineReceiptLong />
      </IconButton>
    },
  ];

  return (
    <>
      <Listados
        ref={refListado}
        titulo="Flujos"
        customCabecero={<FiltrosFlujos />}
        history={history}
        location={location}
        showActions
        defaultActions={false}
        customActions={[
          {
            onClick: ({ id }) => history.push(`/ventas/flujos/formulario/${id}`),
            icon: <BsPencil />,
            validate: ({ usuarioModificacionId, ingreso, venta: { activo: ventaActiva }, concepto }) => (
              !(!!usuarioModificacionId || !ingreso || !ventaActiva || concepto.includes('REACTIVACION'))
            ),
          },
          {
            onClick: abrirModal,
            icon: <FiTrash />,
            validate: ({ usuarioModificacionId, ingreso, venta: { activo: ventaActiva }, concepto }) => (
              !(!!usuarioModificacionId || !ingreso || !ventaActiva || concepto.includes('REACTIVACION'))
            ),
          },
        ]}
        cabeceros={cabeceros}
        apiKey={FLUJOS}
      />
      <Confirmacion
        anchor={anchorConfirmacion}
        onClose={alCancelar}
        onAccept={alAceptar}
      />
    </>
  );
}

export default Flujos;
