import { makeStyles } from '@material-ui/core';

const estilos = makeStyles(() => ({
  contenedorCargadorImagenes: {
    marginTop: 5,
  },
  previsualizacionImagen: {
    position: 'relative',
    textAlign: 'center',
    padding: '10px',
    border: '2px solid #ccc',
    borderRadius: '10px',
    backgroundColor: 'white',
  },
  imagenSeleccionada: {
    maxWidth: '100%',
    maxHeight: '300px',
  },
  botonCerrar: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    color: '#000',
  },
  dropzone: {
    cursor: 'pointer',
    textAlign: 'center',
    padding: '20px',
    border: '2px solid #ccc',
    borderRadius: '10px',
    backgroundColor: '#f9f9f9',
  },
  iconoSubir: {
    fontSize: '40px',
    color: '#666',
  },
  textoSubir: {
    marginTop: '10px',
    fontWeight: 'bold',
    color: '#666',
  },
  subtextoSubir: {
    color: '#999',
  },
  contenedorBotonSubir: {
    marginTop: '20px',
    textAlign: 'center',
  },
  label: {
    marginTop: '5px',
    marginBottom: '10px',
    fontSize: 12,
    fontWeight: 'bold',
  },
}));

export default estilos;
