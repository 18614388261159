import React from 'react';
import { BiArchive } from 'react-icons/bi';
import { FaFileSignature } from 'react-icons/fa6';
import { useSelector } from 'react-redux';
import { Chip, IconButton } from '@material-ui/core';
import clsx from 'clsx';

import FiltrosExpedientes from './filtros';
import styles from '../Ventas/styles';
import Listados from '../../contenedores/Listados';
import { formatoMoneda } from '../../utilidades/functions';
import { ConfiguracionXMLHttp, peticionXMLHttp } from '../../utilidades/peticionXMLHttp';
import endpoints, { VENTAS } from '../../configuraciones/endpoints';
import BarraProgreso from '../../componentes/BarraProgreso';
import { ESTADOS_VENTA } from '../../constantes/tipoEstado';
import ESTADO_VENTA from '../../constantes/estadosVenta';

const Expedientes = ({ history, location }) => {

  const classes = styles();
  const token = useSelector(({ user }) => user?.token);

  /**
   * Metodo para generar el contrato de la venta
   * @param {*} id identificador de la venta
   */
  const obtenerContratoVenta = (id, credito) => {
    const configuracion = new ConfiguracionXMLHttp({
      tipoPeticion: 'GET',
      token: token,
      formatoArchivo: 'word',
      urlEndpoint: endpoints.obtenerContratoVenta(id),
      nombreArchivo: `Contrato de venta a ${credito ? 'credito' : 'contado'}`,

    });
    peticionXMLHttp(configuracion).then().catch(() => { });
  }

  const cabeceros = [
    { label: '#', key: 'id', className: classes.header },
    { label: 'Emp.', transform: ({ lote }) => lote?.manzana?.proyecto?.empresa?.nombre },
    { label: 'Proyecto', transform: ({ lote }) => lote?.manzana?.proyecto?.nombre },
    { label: 'Manzana', transform: ({ lote }) => lote?.manzana?.nombre },
    { label: 'Lote', transform: ({ lote }) => lote?.numero },
    { label: 'Fecha', key: 'fecha', width: 100 },
    { label: 'Cliente', transform: ({ cliente }) => cliente?.nombre },
    { label: 'Vendedor', transform: ({ vendedor }) => vendedor?.nombre },
    {
      label: 'Plazo',
      align: 'center',
      width: 100,
      transform: ({ mesesFinanciamiento, cobranzasPagadas }) => <BarraProgreso total={mesesFinanciamiento ? mesesFinanciamiento : 1} avance={mesesFinanciamiento ? cobranzasPagadas : 1} esPorcentual={false} />,
    },
    { label: 'Total', transform: ({ total }) => formatoMoneda(total) },
    { label: 'Pagado', transform: ({ pagado }) => pagado ? formatoMoneda(pagado) : '---' },

    {
      label: 'Contrato', align: 'center', transform: ({ id, credito, activo }) => <IconButton
        size="small"
        disabled={!activo}
        onClick={() => {
          obtenerContratoVenta(id, credito);
        }}>
        <FaFileSignature />
      </IconButton>
    }, {
      label: 'Archivo', align: 'center', transform: ({ id }) => <IconButton
        size="small"
        onClick={() => history.push(`expedientes/formulario/${id}`)}
        >
        <BiArchive />
      </IconButton>
    },
    {
      label: 'Estatus', align: 'center', transform: ({ estado, activo }) => (
          <Chip
            label={activo ? estado.nombre === ESTADO_VENTA.APARTADO ? 'No pagado' : 'Pagado' : 'Eliminado'}
            size="small"
            className={clsx(classes.chip, activo ? estado.nombre : 'Eliminado')}
          />
      ),
    },
  ];

  return (
    <>
      <Listados
        titulo="Expedientes"
        placeHolder="Búsqueda por nombre"
        customCabecero={<FiltrosExpedientes />}
        soloListado
        history={history}
        location={location}
        cabeceros={cabeceros}
        apiKey={VENTAS}
      />
    </>
  )
};

export default Expedientes;
