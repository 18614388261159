import * as yup from 'yup';
import React, { useEffect, useState, useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { toast } from 'react-toastify';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';

import Default from '../../contenedores/Default';
import TextField from '../../componentes/TextField';
import AutoComplete from '../../componentes/AutoComplete';
import { formatoMoneda, trim } from '../../utilidades/functions';

import axios from '../../configuraciones/axios';
import endpoints, { FLUJOS, EMPRESAS } from '../../configuraciones/endpoints';
import { CAMPO_REQUERIDO, MIN_CAMPO, ELEMENTO_NO_ENCONTRADO } from '../../constantes/mensajes';
import tipoFormaPago from '../../constantes/tipoFormaPago';
import moment from 'moment';
import TiposMovimientoEnum from '../../constantes/tiposMovimientoEnum';
import { ConfiguracionXMLHttp, peticionXMLHttp } from '../../utilidades/peticionXMLHttp';

const schema = yup.object({
  ingreso: yup.bool(),
  tipoMovimientoId: yup.number(),
  fecha: yup.string().required(CAMPO_REQUERIDO),
  empresaId: yup.number().required(CAMPO_REQUERIDO),
  proyectoId: yup.number().required(CAMPO_REQUERIDO),
  clienteId: yup.number().required(CAMPO_REQUERIDO),
  tipoCuentaId: yup.number().required(CAMPO_REQUERIDO),
  cuentaId: yup.number().required(CAMPO_REQUERIDO),
  importe: yup.number()
    .min(1, MIN_CAMPO(1))
    .transform((value) => (isNaN(value) ? undefined : value))
    .required(CAMPO_REQUERIDO),
  ventaId: yup.string().required(CAMPO_REQUERIDO),
  concepto: yup.string(),
  referencia: yup.string(),
});

const Flujo = ({ history, match }) => {
  const hoy = moment().format('yyyy-MM-DDTHH:mm');
  const [empresas, setEmpresas] = useState([]);
  const [proyectos, setProyectos] = useState([]);
  const [lotes, setLotes] = useState([]);
  const [cuentas, setCuentas] = useState([]);
  const [clientes, setClientes] = useState([]);
  const token = useSelector(({ user }) => user?.token);


  const { params: { id } } = match;

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ingreso: true,
      fecha: hoy,
      referencia: '',
      concepto: '',
      tipoMovimientoId: TiposMovimientoEnum.Ingresos,
    },
  });

  const {
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = methods;

  const empresaId = watch('empresaId');
  const proyectoId = watch('proyectoId');
  const clienteId = watch('clienteId');
  const tipoCuentaId = watch('tipoCuentaId');


  const regresar = useCallback(() => {
    const { location: { state: { pagina } } } = history;
    history.push({
      pathname: '/ventas/flujos',
      search: pagina && pagina > 1 ? `?pagina=${pagina}` : '',
    });
  }, [history]);

  const guardar = useCallback((flujo) => {
    const metodo = id ? 'put' : 'post';
    const flujoGuardar = trim(flujo);

    const configuracion = new ConfiguracionXMLHttp({
      tipoPeticion: metodo,
      body: {
        ...flujoGuardar,
        fecha: moment(flujoGuardar.fecha),
      },
      token: token,
      urlEndpoint: endpoints.crearActualizar(FLUJOS, id),
      visualizar: true,
    });

    peticionXMLHttp(configuracion).then(regresar).catch(() => { });

  }, [id, regresar, token]);

  const consultarDatosIniciales = useCallback(() => new Promise((resolve, reject) => {
    const promesas = [
      axios.get(endpoints.base.opciones(EMPRESAS)),
    ];
    if (id) promesas.push(axios.get(endpoints.base.url(FLUJOS, id)));

    Promise.all(promesas).then((resultadosPromesas) => {
      const [empresasBd, flujoBd] = resultadosPromesas;
      setEmpresas(empresasBd);

      if (flujoBd?.id) {
        const fechaUTC = moment.utc(flujoBd.fecha);
        const fecha = fechaUTC.format('YYYY-MM-DDTHH:mm');
        reset({
          ...flujoBd,
          ventaId: flujoBd?.ventaId.toString(),
          proyectoId: flujoBd?.venta?.lote?.manzana?.proyectoId.toString(),
          empresaId: flujoBd?.venta?.lote?.manzana?.proyecto?.empresaId,
          clienteId: flujoBd?.venta?.clienteId,
          cuentaId: flujoBd?.cuentaId.toString(),
          tipoCuentaId: flujoBd?.cuenta?.tipoCuentaId.toString(),
          fecha,
        })
      } else if (id) {
        toast.warning(ELEMENTO_NO_ENCONTRADO);
        regresar();
      }
      resolve();
    }).catch(reject);
  }), [id, regresar, reset]);

  /**
   * Consulta los proyectos con base a la empresa seleccionada.
   */
  const consultarProyectos = useCallback(async () => {
    if (empresaId) {
      const proyectosBd = await axios.get(endpoints.proyectosPorEmpresa(empresaId));
      setProyectos(proyectosBd);
    } else {
      setProyectos([]);
    }
  }, [empresaId]);

  /**
  * Consulta los clientes con base al proyecto seleccionado.
  */
  const consultarClientes = useCallback(async () => {
    if (proyectoId) {
      const clientesBd = await axios.get(endpoints.clientesPorProyecto(proyectoId));
      setClientes(clientesBd);
    } else {
      setClientes([]);
    }
  }, [proyectoId]);

  /**
  * Consulta los lotes con base al cliente seleccionado.
  */
  const consultarLotes = useCallback(async () => {
    if (clienteId) {
      const ventasBd = await axios.get(endpoints.ventasPorCliente(clienteId));
      const lotesListado = []
      ventasBd.forEach((venta) => {
        lotesListado.push({
          id: venta.id,
          nombre: `${venta.lote.manzana.proyecto.nombre} - ${venta.lote.clave}`,
        });
      });
      setLotes(lotesListado);
    } else {
      setLotes([]);
    }
  }, [clienteId]);

  /**
  * Consulta las cuentas con base al proyecto y forma de Pago seleccionado.
  */
  const consultarCuentas = useCallback(async () => {
    if (tipoCuentaId) {
      const bdCuentas = await axios.get(endpoints.cuentasPorTipoCuenta(tipoCuentaId));
      const cuentasListado = [];
      bdCuentas.forEach((cuenta) => {
        cuentasListado.push({
          id: cuenta.id,
          nombre: `${cuenta.nombre} - ${formatoMoneda(cuenta.saldo)}`
        })
      });
      setCuentas(cuentasListado);
    } else {
      setCuentas([]);
    }
  }, [tipoCuentaId]);

  /** Método que limpia el valor del cliente seleccionado */
  const limpiarClientes = () => {
    setValue('cuentaId', undefined);
    setCuentas([]);
    setValue('ventaId', undefined);
    setLotes([]);
  };

  /** Método que limpia el valor del proyecto seleccionado */
  const limpiarProyectos = () => {
    setValue('clienteId', undefined);
    setClientes([]);
    limpiarClientes();
  };

  /** Método que limpia los valores relacionados a la empresa */
  const limpiarEmpresas = () => {
    setValue('proyectoId', undefined);
    setProyectos([]);
    limpiarProyectos();  
  };

  /* useEffect utilizado para consultar los proyectos cuando la empresa seleccionada cambie. */
  useEffect(() => consultarProyectos(), [consultarProyectos]);

  /* useEffect utilizado para consultar las cuentas cuando la función cambie. */
  useEffect(() => consultarCuentas(), [consultarCuentas]);

  /* useEffect utilizado para consultar los clientes cuando la función cambie. */
  useEffect(() => consultarClientes(), [consultarClientes]);

  /* useEffect utilizado para consultar los lotes cuando la función cambie. */
  useEffect(() => consultarLotes(), [consultarLotes]);

  useEffect(() => {
    consultarDatosIniciales();
  }, [consultarDatosIniciales]);

  return <Default
    titulo={Boolean(id) ? 'Editar Ingreso' : 'Nuevo Ingreso'}
    placeHolder={''}
    mostrarCabeceroFormulario
    cancelar={regresar}
    frmId="frmFlujo"
  >
    <FormProvider {...methods}>
      <form noValidate onSubmit={handleSubmit(guardar)} id="frmFlujo">
        <Grid container spacing={2} id="frmFlujo">
          <Grid item lg={3} md={4} sm={12} xs={12}>
            <TextField
              name="fecha"
              type="datetime-local"
              label="Fecha:"
              placeHolder="Fecha"
              inputProps={{ max: hoy }}
              error={errors.fecha}
              helperText={errors.fecha?.message}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={12} xs={12}>
            <AutoComplete
              label="Empresa: *"
              name="empresaId"
              placeHolder="Selecciona una opción"
              options={empresas}
              labelProp="empresaId"
              onClear={limpiarEmpresas}
              error={errors.empresaId}
              helperText={errors.empresaId?.message}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={12} xs={12}>
            <AutoComplete
              label="Proyecto: *"
              name="proyectoId"
              placeHolder="Selecciona una opción"
              options={proyectos}
              onClear={limpiarProyectos}
              error={errors.proyectoId}
              helperText={errors.proyectoId?.message}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={12} xs={12}>
            <AutoComplete
              label="Cliente: *"
              name="clienteId"
              placeHolder="Selecciona una opción"
              options={clientes}
              labelProp="cliente"
              onClear={limpiarClientes}
              error={errors.clienteId}
              helperText={errors.clienteId?.message}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={12} xs={12}>
            <AutoComplete
              label="Lotes del Cliente: *"
              name="ventaId"
              placeHolder="Selecciona una opción"
              options={lotes}
              labelProp="lotesCliente"
              error={errors.ventaId}
              helperText={errors.ventaId?.message}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={12} xs={12}>
            <AutoComplete
              label="Forma de pago: *"
              name="tipoCuentaId"
              placeHolder="Selecciona una opción"
              options={tipoFormaPago}
              error={errors.tipoCuentaId}
              helperText={errors.tipoCuentaId?.message}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={12} xs={12}>
            <AutoComplete
              label="Cuenta: *"
              name="cuentaId"
              placeHolder="Selecciona una opción"
              options={cuentas}
              labelProp="cuenta"
              error={errors.cuentaId}
              helperText={errors.cuentaId?.message}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={12} xs={12}>
            <TextField
              name="importe"
              type="coin"
              label="Cantidad: "
              placeHolder="$0.00"
              inputProps={{ min: 0 }}
              error={errors.importe}
              helperText={errors.importe?.message}
              required
            />
          </Grid>
          <Grid item lg={3} md={4} sm={12} xs={12}>
            <TextField
              label="Concepto: "
              name="concepto"
              placeHolder="Concepto"
              inputProps={{ maxLength: 50 }}
              error={errors.concepto}
              helperText={errors.concepto?.message}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={12} xs={12}>
            <TextField
              label="Referencia: "
              name="referencia"
              placeHolder="Referencia"
              inputProps={{ maxLength: 50 }}
              error={errors.referencia}
              helperText={errors.referencia?.message}
            />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  </Default>;
};

export default React.memo(Flujo);