/* eslint-disable import/no-anonymous-default-export */
const START_AJAX = 'START_AJAX';
const END_AJAX = 'END_AJAX';

export default (state = 0, { type }) => {
  switch (type) {
    case START_AJAX:
      return state + 1;
    case END_AJAX:
      return state <= 0 ? 0 : state - 1;
    default:
      return state;
  }
};

export const startAjax = () => ({ type: START_AJAX });

export const endAjax = () => ({ type: END_AJAX });