import React from 'react';
import Estatus from '../../componentes/Estatus';
import Listados from '../../contenedores/Listados';
import { PERFILES } from '../../configuraciones/endpoints';

const cabeceros = [
  { label: 'Nombre', key: 'nombre' },
  { label: 'Descripción', key: 'descripcion' },
  {
    label: 'Estatus', transform: ({ habilitado, activo }) => (
      <Estatus habilitado={habilitado} activo={activo} />
    ),
  },];

const Perfiles = ({ history, location }) => {
  return <Listados
    titulo="Perfiles"
    placeHolder="Búsqueda por nombre"
    history={history}
    location={location}
    showActions
    cabeceros={cabeceros}
    apiKey={PERFILES}
    defaultActions
  />;
};

export default Perfiles;