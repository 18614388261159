import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import propTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import axios from '../../configuraciones/axios';
import endpoints, { EMPRESAS } from '../../configuraciones/endpoints';

import AccionesCabecero from '../AccionesCabecero';
import AutoComplete from '../AutoComplete';
import TextField from '../TextField';

const FiltrosComponent = ({ mostrarProyectos, habilitarBusqueda }) => {
  const [empresas, setEmpresas] = useState([]);
  const [proyectos, setProyectos] = useState([]);
  const { watch } = useFormContext();
  const empresaId = watch('empresaId');

  const consultarEmpresas = async () => {
    const bdEmpresas = await axios.get(endpoints.base.opciones(EMPRESAS));
    setEmpresas(bdEmpresas);
  };

  const consultarProyectos = useCallback(async () => {
    const bdProyectos = await axios.get(endpoints.proyectosPorEmpresa(empresaId));
    setProyectos(bdProyectos);
  }, [empresaId]);

  useEffect(() => consultarEmpresas(), []);
  useEffect(() => {
    if (!!empresaId && mostrarProyectos) {
      consultarProyectos();
    } else {
      setProyectos([]);
    }
  }, [empresaId, mostrarProyectos, consultarProyectos]);

  return (
    <Grid
      container
      justifyContent="flex-end"
      alignItems="flex-end"
      spacing={1}
      style={{ marginBottom: 20 }}
    >
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <AutoComplete
          name="empresaId"
          label="Empresa:"
          options={empresas}
        />
      </Grid>
      {
        mostrarProyectos && (
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <AutoComplete
              name="proyectoId"
              label="Proyecto:"
              options={proyectos}
            />
          </Grid>
        )
      }
      {
        habilitarBusqueda &&
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <TextField
            fullWidth
            name="txtBusqueda"
            placeHolder="Búsqueda por nombre"
          />
        </Grid>
      }
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <AccionesCabecero />
      </Grid>
    </Grid>
  );
};

FiltrosComponent.propTypes = {
  /** Bandera par validar si muestra el select de proyectos */
  mostrarProyectos: propTypes.bool,
  habilitarBusqueda: propTypes.bool,
};

FiltrosComponent.defaultProps = {
  mostrarProyectos: false,
  habilitarBusqueda: true,
};

export default FiltrosComponent;
