import React from 'react';
import { Grid } from '@material-ui/core';
import styles from './styles';

const SimbologiaColor = ({ colorFondo, colorBorde }) => {
  const classes = styles();

  return (
    <Grid
      className={classes.simbologiaColor}
      style={{
        backgroundColor: colorFondo,
        borderColor: colorBorde,
      }}
    />
  );
};

export default SimbologiaColor;
