/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { toast } from 'react-toastify';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import AutoComplete from '../../componentes/AutoComplete';
import Default from '../../contenedores/Default';
import Select from '../../componentes/Select';
import TextField from '../../componentes/TextField';

import axios from '../../configuraciones/axios';
import endpoints, {
  EMPRESAS, CUENTAS,
} from '../../configuraciones/endpoints';
import estatus from '../../constantes/estatus';
import { CAMPO_REQUERIDO, MAX_LONGITUD, MIN_CAMPO ,ELEMENTO_NO_ENCONTRADO} from '../../constantes/mensajes';
import { trim } from '../../utilidades/functions';
import tiposCuenta from '../../constantes/tiposCuenta';


const schema = yup.object({
  tipoCuentaId: yup.number().oneOf([1, 2, 3, 4]).required(CAMPO_REQUERIDO),
  nombre: yup.string().required(CAMPO_REQUERIDO),
  empresaId: yup.number().required(CAMPO_REQUERIDO),
  proyectoId: yup.number().required(CAMPO_REQUERIDO),
  banco: yup.string().required(CAMPO_REQUERIDO),
  tarjeta: yup.number()
    .min(0, MIN_CAMPO(0))
    .max(10 ** 16, MAX_LONGITUD(16))
    .transform((value) => (isNaN(value) ? undefined : value)),
  clabe: yup.number()
    .min(0, MIN_CAMPO(0))
    .transform((value) => (isNaN(value) ? undefined : value))
    .max(10 ** 18, MAX_LONGITUD(18)),
  saldo: yup.number()
    .min(0, MIN_CAMPO(0))
    .transform((value) => (isNaN(value) ? undefined : value)),
  color: yup.string(),
  habilitado: yup.boolean().oneOf([true, false]).required(CAMPO_REQUERIDO),
});

const Cuenta = ({ history, match }) => {
  const [empresas, setEmpresas] = useState([]);
  const [proyectos, setProyectos] = useState([]);
  const { params: { id } } = match;

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      habilitado: true,
      tipoCuentaId: '1',
      color: '#000000',
    }
  });

  const {
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = methods;

  const empresaId = watch('empresaId');

  /**
   * Regresa a la pantalla principal del catálogo de cuentas en la primera página.
   */
  const regresar = () => {
    const { location: { state: { pagina } } } = history;
    history.push({
      pathname: '/catalogos/cuentas',
      search: pagina && pagina > 1 ? `?pagina=${pagina}` : '',
    });
  };

  /**
   * Realiza una petición POST o PUT para afectar la cuenta
   * en la base de datos.
   */
  const guardar = useCallback((info) => {
    const metodo = id ? 'put' : 'post';
    axios[metodo](endpoints.base.url(CUENTAS, id), trim(info)).then(regresar);
  }, []);

  /**
   * Consulta los datos iniciales del formulario de cuentas.
   * @returns Promesa con los objetos consultados.
   */
  const consultarDatosIniciales = () => new Promise((resolve, reject) => {
    const promesas = [
      axios.get(endpoints.base.opciones(EMPRESAS)),
    ];
    if (id) promesas.push(axios.get(endpoints.base.url(CUENTAS, id)));

    Promise.all(promesas).then((resultadosPromesas) => {
      const [empresasBd, cuentaBd] = resultadosPromesas;
      setEmpresas(empresasBd);

      if (cuentaBd?.id) {
        reset({
          ...cuentaBd,
          tipoCuentaId: cuentaBd.tipoCuentaId.toString(),
          proyectoId: cuentaBd.proyectoId.toString(),
          empresaId: cuentaBd.proyecto.empresaId.toString(),
          tarjeta: parseInt(cuentaBd.tarjeta?.trim(), 10),
          clabe: parseInt(cuentaBd.clabe?.trim(), 10),
        });
      } else if (id) {
        toast.warning(ELEMENTO_NO_ENCONTRADO);
        regresar();
      }

      resolve();
    }).catch(reject);
  });

  /**
   * Consulta los proyectos con base a la empresa seleccionada.
   */
  const consultarProyectos = async () => {
    const proyectosBd = await axios.get(endpoints.proyectosPorEmpresa(empresaId));
    setProyectos(proyectosBd);
  };

  useEffect(() => {
    consultarDatosIniciales();
  }, [id]);

  useEffect(() => {
    if (!!empresaId) consultarProyectos();
    else setProyectos([]);
  }, [empresaId]);

  return <Default
    titulo={Boolean(id) ? 'Editar Cuenta' : 'Nueva Cuenta'}
    placeHolder={''}
    mostrarCabeceroFormulario
    cancelar={regresar}
    frmId="frmCuenta">
    <FormProvider {...methods}>
      <form noValidate onSubmit={handleSubmit(guardar)} id="frmCuenta">
        <Grid container spacing={2}>
          <Grid item lg={3} md={4} sm={12} xs={12}>
            <Select
              label="Tipo: *"
              options={tiposCuenta}
              name="tipoCuentaId"
              error={errors.tipoCuentaId}
              helperText={errors.tipoCuentaId?.message}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={12} xs={12}>
            <TextField
              label="Nombre: *"
              placeHolder="Nombre"
              name="nombre"
              inputProps={{ maxLength: 50 }}
              error={errors.nombre}
              helperText={errors.nombre?.message}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={12} xs={12}>
            <AutoComplete
              label="Empresa: *"
              name="empresaId"
              placeholder="Selecciona una opción"
              options={empresas}
              error={errors.empresaId}
              helperText={errors.empresaId?.message}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={12} xs={12}>
            <AutoComplete
              label="Proyecto: *"
              name="proyectoId"
              placeholder="Selecciona una opción"
              options={proyectos}
              error={errors.proyectoId}
              helperText={errors.proyectoId?.message}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={12} xs={12}>
            <TextField
              name="banco"
              label="Banco: *"
              placeHolder="Banco"
              error={errors.banco}
              helperText={errors.banco?.message}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={12} xs={12}>
            <TextField
              name="tarjeta"
              type="number"
              label="Tarjeta:"
              placeHolder="Tarjeta"
              inputProps={{ maxLength: 16, min: 0 }}
              error={errors.tarjeta}
              helperText={errors.tarjeta?.message}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={12} xs={12}>
            <TextField
              name="clabe"
              type="number"
              label="Clabe"
              placeHolder="Clabe"
              inputProps={{ maxLength: 18, min: 0 }}
              error={errors.clabe}
              helperText={errors.clabe?.message}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={12} xs={12}>
            <TextField
              name="saldo"
              type="number"
              label="Saldo:"
              placeHolder="Saldo"
              inputProps={{ maxLength: 13, min: 0 }}
              error={errors.saldo}
              helperText={errors.saldo?.message}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={12} xs={12}>
            <TextField
              name="color"
              type="color"
              label="Color:"
              placeHolder="Color"
              error={errors.color}
              helperText={errors.color?.message}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={12} xs={12}>
            <Select
              label="Estatus"
              required
              options={estatus}
              name="habilitado"
              error={errors.habilitado}
              helperText={errors.habilitado?.message}
            />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  </Default>;
};

export default React.memo(Cuenta);