import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import Plano from '../../componentes/Plano';
import { mapearPoligonosLotes } from './Lotes';
import ModalInfoLote from '../../componentes/ModalInfoLote';
import axiosInstance from '../../configuraciones/axios';
import endpoints from '../../configuraciones/endpoints';
import { FaDownLeftAndUpRightToCenter } from 'react-icons/fa6';
import Typography from '../../componentes/Typography';
import SimbologiaColor from '../../componentes/Simbologia/SimboloColor';
import { ColoresEstadoLote, ColoresSolidoEstadoLote } from '../../constantes/coloresPlanos';

const MapaCompletado = ({ id, img }) => {
  const [mostrarDetalle, setMostrarDetalle] = useState(false);
  const [idLote, setIdLote] = useState(0);
  const [filtros, setFiltros] = useState({ manzana: 1 });
  const [poligonosLotes, setPoligonosLotes] = useState([]);
  const [poligonosLotesFiltrado, setPoligonosLotesFiltrado] = useState([]);

  const simbologia = [
    {
      simbolo: <SimbologiaColor colorFondo={ColoresEstadoLote.Disponible} colorBorde={ColoresSolidoEstadoLote.Disponible}/>,
      label: <Typography>Disponible</Typography>
    },
    {
      simbolo: <SimbologiaColor colorFondo={ColoresEstadoLote.Vendido} colorBorde={ColoresSolidoEstadoLote.Vendido}/>,
      label: <Typography>Vendido</Typography>
    },
    {
      simbolo: <SimbologiaColor colorFondo={ColoresEstadoLote.Apartado} colorBorde={ColoresSolidoEstadoLote.Apartado}/>,
      label: <Typography>Apartado</Typography>
    },
    {
      simbolo: <SimbologiaColor colorFondo={ColoresEstadoLote.Suspendido} colorBorde={ColoresSolidoEstadoLote.Suspendido}/>,
      label: <Typography>Suspendido</Typography>
    }
  ]

  useEffect(async () => {
    const manzanasDb = await axiosInstance.get(endpoints.manzanasPlanos(id));
    if (manzanasDb) setPoligonosLotes(mapearPoligonosLotes(manzanasDb, filtros));
  }, []);

  useEffect(() => {
    setPoligonosLotesFiltrado(
      poligonosLotes.map(manzana => {
        const poligonosFiltrados = manzana.coordenadas.filter((lote) => {
          return (
            (filtros.manzana === "" || lote.manzanaId === parseInt(filtros.manzana)) &&
            (filtros.tipoLote === "" || lote.tipoLoteId === filtros.tipoLote) &&
            (filtros.categoria === "" || lote.categoriaId === filtros.categoria) &&
            (filtros.disponibilidad === "" || lote.estadoId === filtros.disponibilidad)
          );
        });
        return {
          ...manzana,
          coordenadas: poligonosFiltrados
        };
      })
    );
  }, [filtros]);


  return (
    <Box flex={1} display="flex" padding={1} overflow="auto" flexDirection="row">
      <ModalInfoLote abierto={mostrarDetalle} loteId={idLote} cerrarModal={() => setMostrarDetalle(false)} />
      <Box flex={4} display="flex" overflow="auto" flexDirection="column">
        <Plano
          img={img}
          poligonos={poligonosLotesFiltrado.length > 0 ? poligonosLotesFiltrado : poligonosLotes}
          mostrarTooltip
          loteId={setIdLote}
          mostrarDetalleLote={() => setMostrarDetalle(true)}
          filtros={setFiltros}
          mostrarFiltro
          proyectoId={id}
          mostrarSimbologia
          simbologia={simbologia}
        />
      </Box>
    </Box>
  );
};

export default MapaCompletado;
