import React, { useCallback, useEffect, useState } from 'react'
import { Grid, IconButton } from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { PiHandCoinsBold } from 'react-icons/pi';
import { BsTrash } from 'react-icons/bs';

import AutoComplete from '../../componentes/AutoComplete';
import CustomSelect from '../../componentes/Select';
import Table from '../../componentes/Table';
import TextField from '../../componentes/TextField';
import Typography from '../../componentes/Typography';
import tiposCuenta from '../../constantes/tiposCuenta';
import { formatoMoneda } from '../../utilidades/functions';
import { CAMPO_REQUERIDO } from '../../constantes/mensajes';
import Boton from '../../componentes/Button';
import styles from './styles';
import axiosInstance from '../../configuraciones/axios';
import endpoints, { CUENTAS, TIPO_CUENTA_PROYECTO } from '../../configuraciones/endpoints';
import { MAX_LONGITUD } from '../../constantes/mensajes';

const esquemaAnticipo = yup.object({
  tipoCuentaId: yup.string().required(CAMPO_REQUERIDO),
  cuentaId: yup.string().required(CAMPO_REQUERIDO),
  concepto: yup.string().required(CAMPO_REQUERIDO),
  importe: yup.string().required(CAMPO_REQUERIDO)
    .test('length', MAX_LONGITUD(11), (val) => val?.length <= 11),
  fechaMostrar: yup.string().required(CAMPO_REQUERIDO),
});

const VentaAnticipos = ({ datos, onChange, deshabilitado, proyectoId }) => {
  const classes = styles();
  const hoy = moment().format('yyyy-MM-DDTHH:mm');
  const [anticipos, setAnticipos] = useState([]);
  const [cuentas, setCuentas] = useState();
  const metodos = useForm({
    resolver: yupResolver(esquemaAnticipo),
    defaultValues: {
      fechaMostrar: hoy,
    }
  });

  const {
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = metodos;

  const tipoCuentaId = watch('tipoCuentaId');

  /**
   * Método que obtiene las cuentas registradas, relacionadas al proyecto
   * y tipo de cuenta seleccionada.
   */
  const obtenerCuentas = useCallback(async () => {
    if (proyectoId && tipoCuentaId) {
      const cuentasBd = await axiosInstance.get(endpoints.base.url(CUENTAS, TIPO_CUENTA_PROYECTO),
        {
          params: {
            proyectoId,
            tipoCuentaId,
          },
        },
      );
      setCuentas(cuentasBd);
    }
  }, [tipoCuentaId, proyectoId]);

  const agregarAnticipo = (anticipo) => {
    const { id, label, fechaMostrar } = tiposCuenta.find(({ id }) => id === anticipo.tipoCuentaId);
    const nuevo = {
      ...anticipo,
      cuenta: {
        ...cuentas.find(({ id }) => id === anticipo.cuentaId),
        tipoCuenta: {
          id,
          nombre: label,
        },
      },
      fecha: moment(fechaMostrar),
      activo: true,
    };
    const nuevos = [...anticipos, nuevo];
    setAnticipos(nuevos);
    onChange(nuevos);
    reset();
  };

  const eliminarAnticipo = (index) => {
    const nuevos = [...anticipos];
    nuevos.splice(index, 1);
    setAnticipos(nuevos);
    onChange(nuevos);
  };

  const cabeceros = [
    { label: 'Forma de pago', transform: ({ cuenta }) => cuenta?.tipoCuenta?.nombre },
    { label: 'Cuenta', transform: ({ cuenta }) => cuenta.nombre },
    { label: 'Concepto', key: 'concepto' },
    { label: 'Importe', transform: ({ importe }) => formatoMoneda(importe) },
    { label: 'Fecha', transform: ({ fechaMostrar }) => moment(fechaMostrar).format('DD/MM/YYYY HH:mm') },
    {
      label: 'Opciones', transform: (_, index) => (
        <IconButton
          size="small"
          style={{ color: '#1F2129' }}
          onClick={() => eliminarAnticipo(index)}
          disabled={deshabilitado}
        >
          <BsTrash />
        </IconButton>)
    },
  ];

  /** Effect que obtiene las cuentas cuando la función cambie. */
  useEffect(() => obtenerCuentas(), [obtenerCuentas]);

  useEffect(() => {
    if (!!datos) setAnticipos(datos);
  }, [datos]);


  return (
    <FormProvider {...metodos}>
      <form onSubmit={handleSubmit(agregarAnticipo)} id="frmAnticipo" className={classes.frmAnticipo}>
        <Grid container spacing={2} alignItems="flex-end" style={{ marginTop: 20 }}>
          <Grid item xs={12}>
            <Typography
              component="h2"
            >
              Anticipos:
            </Typography>
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <CustomSelect
              name="tipoCuentaId"
              options={tiposCuenta}
              label="Forma de Pago:"
              placeHolder="Selecciona una opción"
              error={errors.tipoCuentaId}
              helperText={errors.tipoCuentaId?.message}
              className={classes.control}
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <AutoComplete
              name="cuentaId"
              label="Cuenta:"
              options={cuentas}
              error={errors.cuentaId}
              helperText={errors.cuentaId?.message}
              className={classes.control}
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <TextField
              name="concepto"
              label="Concepto:"
              placeHolder="Concepto"
              inputProps={{ maxLength: 150 }}
              error={errors.concepto}
              helperText={errors.concepto?.message}
              className={classes.control}
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <TextField
              name="importe"
              type="coin"
              label="Importe:"
              placeHolder="$0.00"
              error={errors.importe}
              helperText={errors.importe?.message}
              className={classes.control}
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <TextField
              name="fechaMostrar"
              type="datetime-local"
              label="Fecha:"
              error={errors.fecha}
              helperText={errors.fecha?.message}
              className={classes.control}
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <Boton
              fullWidth
              icono={<PiHandCoinsBold />}
              label="Agregar"
              classesCustom={{ boton: classes.btnLote }}
              frmId="frmAnticipo"
              isSubmit
              disabled={deshabilitado}
            />
          </Grid>
        </Grid>
      </form>
      <Grid container style={{ marginTop: 15 }}>
        <Grid item xs={12}>
          <Table
            headers={cabeceros}
            rows={anticipos.filter(({ activo }) => activo)}
            showActions={false}
          />
        </Grid>
      </Grid>
    </FormProvider>
  )
}

export default VentaAnticipos;
