import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import moment from 'moment';
import { Grid } from '@material-ui/core';
import { MdOutlineReceiptLong } from 'react-icons/md';
import styles from './styles';

import Modal from '../../componentes/Modal';
import Typography from '../../componentes/Typography';
import Boton from '../../componentes/Button';
import Tabla from '../../componentes/Table'

import axiosInstance from '../../configuraciones/axios';
import endpoints from '../../configuraciones/endpoints';
import { ConfiguracionXMLHttp, peticionXMLHttp } from '../../utilidades/peticionXMLHttp';

const cabeceros = [
  { label: 'Fecha', transform: ({ fecha }) => moment(fecha).format("DD-MM-YYYY") },
  { label: 'Hora', transform: ({ fecha }) => moment(fecha).format("hh:mm A") },
  { label: 'Forma de pago', transform: ({ cuenta }) => cuenta.tipoCuenta.nombre },
  { label: 'Cuenta', transform: ({ cuenta }) => cuenta.nombre },
  { label: 'Referencia', key: 'referencia' },
  { label: 'Importe', transform: ({ importe }) => `$ ${parseFloat(importe).toFixed(2)}` },
];

const Flujos = ({ abierto, cerrar, abonoId }) => {
  const classes = styles();
  const [flujos, setFlujos] = useState();
  const token = useSelector(({ user }) => user?.token);

  const obtenerFlujos = useCallback(async () => {
    const flujosBd = await axiosInstance.get(endpoints.obtenerFlujosCobranza(abonoId));
    setFlujos(flujosBd);
  }, [abonoId]);

  /**
   * Función que realiza la petición para generar el comprobante
   */
  const generarComprobante = useCallback((id) => {
    const configuracion = new ConfiguracionXMLHttp({
      tipoPeticion: 'GET',
      token: token,
      urlEndpoint: endpoints.obtenerComprobanteFlujo(id),
      visualizar: true,
    });
    
    peticionXMLHttp(configuracion);

  }, [token]);

  /**
  * Evento tipo constructor al entrar a la pantalla de venta.
  */
  useEffect(() => {
    if (abierto) obtenerFlujos();
  }, [abierto, abonoId, obtenerFlujos]);

  return (
    <Modal
      open={abierto}
      handleClose={cerrar}
      maxWidth="md"
      showTitle={false}
    >
      <Grid container spacing={1} style={{ padding: '20px 30px' }}>
        <Grid item md={8} sm={6} xs={5}>
          <Typography variant="h6">
            Flujos
          </Typography>
        </Grid>

        {/* Botones */}
        <Grid item md={4} sm={6} xs={2}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <Grid container justifyContent="flex-end">
                <Boton
                  fullWidth
                  variant="outlined"
                  label="Cancelar"
                  onClick={cerrar}
                  classesCustom={{
                    boton: classes.btnCancelar
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Tabla */}
        <Grid item xs={12}>
          <Tabla
            headers={cabeceros}
            rows={flujos?.rows || []}
            count={flujos?.count || 0}
            moreActions={[
              {
                onClick: ({id}) => generarComprobante(id),
                icon: <MdOutlineReceiptLong />,
              }
            ]}
            actionsTitle='Comprobante'
            customClass={classes.tablaFlujos}
            hidePaginator
          />
        </Grid>

      </Grid>
    </Modal>
  );
};

export default React.memo(Flujos);