import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import propTypes from 'prop-types';
import { Tooltip, IconButton } from '@material-ui/core';
import clsx from 'clsx';
import { GiInfo } from 'react-icons/gi';
import Typography from '../Typography';
import styles from './styles';
import { formatoMoneda } from '../../utilidades/functions';

const TextField = ({
  label, name, type, onChange, isHandleChange,
  inputProps, required, error, tooltip, rows, helperText,
  placeHolder, className, onKeyDown, disabled,
  inputClassName, inputStyles, simbolo, onClick, onFocus,
  fullWidth, onBlur, ...rest
}) => {
  const classes = styles();
  const inputRef = useRef(null);
  const [value, setValue] = useState('');
  const formMethods = useFormContext();
  const customRegister = useMemo(() => (formMethods && formMethods.register(name)) || {}, [name, formMethods]);
  const customValue = formMethods.watch(name);

  const handleChange = useCallback((e) => {
    let valor = e.target.value;
    if (type === 'coin') {
      const valorLimpio = e.target.value.replace(/\D/g, '');
      valor = (Number(valorLimpio) / 100).toString();
    }

    formMethods.setValue(name, valor);
    formMethods.trigger(name);
  }, [name, type, formMethods]);

  useEffect(() => {
    if (type === 'coin') {
      if (typeof customValue === 'undefined') {
        setValue('');
      } else {
        setValue(formatoMoneda(customValue.toString()));
      }
    }
  }, [customValue, type]);

  useEffect(() => {
    if (type === 'number' && inputRef.current) {
      const handleWheel = (e) => { e.preventDefault(); };
      const input = inputRef.current;
      input.addEventListener("wheel", handleWheel, { passive: false });

      return () => {
        input.removeEventListener("wheel", handleWheel);
      };
    }
  }, [type]);

  return (
    <div className={clsx(classes.root, className)}>
      {label && (
        <Typography className={classes.label}>
          {label}
          {required && ' * '}
          {tooltip !== '' && (
            <Tooltip title={<Typography style={{ fontSize: 14 }}>{tooltip}</Typography>}>
              <IconButton size="small">
                <GiInfo />
              </IconButton>
            </Tooltip>
          )}
        </Typography>
      )}
      {type !== 'textarea' ?
        <div className={classes.containerInputs}>
          <input
            ref={inputRef}
            className={
              clsx(
                classes.input,
                inputClassName,
                {
                  [classes.error]: error, disabled,
                  [classes.fullWidth]: fullWidth,
                  [classes.simbolo]: simbolo,
                }
              )
            }
            style={inputStyles}
            name={name}
            type={type}
            required={required}
            placeholder={placeHolder}
            onKeyDown={onKeyDown}
            onClick={onClick}
            onFocus={onFocus}
            disabled={disabled}
            {...inputProps}
            {...rest}
            // {...customRegister}
            value={value || customValue || ''}
            onChange={handleChange}
            onBlur={onBlur}
          />
          <div className={classes.iconContainer}>
            {simbolo}
          </div>
        </div>
        : <textarea
          className={
            clsx(
              classes.input,
              classes.textarea,
              inputClassName,
              {
                [classes.error]: error, disabled,
                [classes.fullWidth]: fullWidth,
              }
            )
          }
          name={name}
          type={type}
          rows={rows}
          placeholder={placeHolder}
          onKeyDown={onKeyDown}
          onClick={onClick}
          onFocus={onFocus}
          disabled={disabled}
          style={{
            ...inputStyles,
            resize: 'none',
            padding: 5,
          }}
          {...inputProps}
          {...rest}
          {...customRegister}
        />}
      {helperText && helperText !== '' && <Typography
        className={clsx(classes.helperText, { error })}
        component="p">
        {helperText}
      </Typography>}
    </div>
  );
};

TextField.propTypes = {
  label: propTypes.string,
  name: propTypes.string.isRequired,
  type: propTypes.string,
  onChange: propTypes.func,
  isHandleChange: propTypes.bool,
  inputProps: propTypes.object,
  required: propTypes.bool,
  error: propTypes.oneOfType([propTypes.string, propTypes.bool, propTypes.object]),
  tooltip: propTypes.string,
  rows: propTypes.number,
  helperText: propTypes.string,
  placeHolder: propTypes.string,
  className: propTypes.string,
  onKeyDown: propTypes.func,
  disabled: propTypes.bool,
  inputClassName: propTypes.string,
  inputStyles: propTypes.object,
  fullWidth: propTypes.bool,
  onBlur: propTypes.func,
};

TextField.defaultProps = {
  label: '',
  type: 'text',
  isHandleChange: false,
  inputProps: {},
  required: false,
  error: false,
  tooltip: '',
  rows: 1,
  helperText: '',
  placeHolder: '',
  className: '',
  onKeyDown: null,
  disabled: false,
  style: {},
  fullWidth: true,
  onBlur: () => { },
};

export default React.memo(TextField, (prev, next) => {
  return prev.value === next.value &&
    prev.error === next.error &&
    prev.helperText === next.helperText &&
    prev.disabled === next.disabled &&
    prev.tooltip === next.tooltip &&
    prev.type === next.type &&
    prev.onBlur === next.onBlur &&
    prev.onChange === next.onChange &&
    prev.inputClassName === next.inputClassName;
});
