import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  main: {
    backgroundImage: 'url(/assets/images/fondo-login.svg)',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '100vh',
    minHeight: 700,
  },
  body: {
    [theme.breakpoints.up("md")]: {
      padding: 40,
    },
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
  contenedorTitulo: {
    fontFamily: 'Designer',
    '& h1': {
      fontSize: 44,
      fontWeight: '500',
    },
    '& h2': {
      fontSize: 38,
      fontWeight: '500',
    },
  },
  card: {
    [theme.breakpoints.up("md")]: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'lightgray',
      borderRadius: 10,
      padding: 30,
      backgroundColor: 'rgba(255,255,255,0.90)',
    },
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
    '& p': {
      fontSize: '12px'
    },
  },
  botonInicio: {
    width: '100%',
    [theme.breakpoints.up("md")]: {
      marginTop: '60px',
      padding: '10px',
      fontSize: '18px',
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: '40px',
      padding: '5px',
      fontSize: '16px',
    },
  },
  logoDelCampo: {
    [theme.breakpoints.up("md")]: {
      width: 80,
      heigth: 80,
    },
    [theme.breakpoints.down("sm")]: {
      width: 60,
      heigth: 60,
    },
  },
  pieCardLabel:{
    textAlign:'center',
    textDecorationStyle:'none'
  },
  enlace: {
    textDecoration: 'none',
    color: '#003366',
  },
}));

export default styles;
