import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { Paper, IconButton, Checkbox, Container } from '@material-ui/core';
import clsx from 'clsx';
import { FiTrash } from 'react-icons/fi';
import { BsPencil } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { LiaTrashRestoreAltSolid } from 'react-icons/lia';

import Confirmacion from '../Confirmacion';
import Typography from '../Typography';
import Paginador from '../Paginador';

import general from '../../configuraciones/general';

import styles from './styles';
import { MENSAJE_ELIMINAR_ELEMENTO, MENSAJE_RESTABLECER_ELEMENTO } from '../../constantes/mensajes';

const Table = ({
  headers, rows, showActions, onEdit,
  onDelete, moreActions, showEmptyLabel, hideDelete,
  count, hidePaginator, showCheckbox, onSelectAll,
  onSelect, customStyles, customClass, cellStyles,
  rowErrors, emptyMessage, defaultActions, actionsTitle, onRestore
}) => {
  const classes = styles();
  const [anchorConfirmacion, setAnchorConfirmacion] = useState(null);
  const [itemSelected, setItemSelected] = useState(null);
  const [mensajeConfirmacion, setMensajeConfirmacion] = useState(null);
  const [esRestablecer, setEsRestablecer] = useState(null);
  const { ajax, superAdmin } = useSelector(
    ({ ajax, user: { user: { superAdmin } } }) => ({ ajax, superAdmin })
  );
  const [isPaginatorVisible, setIsPaginatorVisible] = useState(false);

  useEffect(() => {
    if (rows.length === 0 && showEmptyLabel && ajax === 0) {
      setIsPaginatorVisible(false);
    } else {
      setIsPaginatorVisible(true);
    }
  }, [rows.length, showEmptyLabel, ajax])

  const defaultsCustoms = (event, row, index) => {
    setItemSelected({ ...row, index });
    setAnchorConfirmacion(event.currentTarget);
  }
  const customDelete = (event, row, index) => {
    setEsRestablecer(false);
    defaultsCustoms(event, row, index);
    setMensajeConfirmacion(MENSAJE_ELIMINAR_ELEMENTO);
  };

  const customRestore = (event, row, index) => {
    setEsRestablecer(true);
    defaultsCustoms(event, row, index);
    setMensajeConfirmacion(MENSAJE_RESTABLECER_ELEMENTO);
  };

  const cancelConfirmation = () => {
    setAnchorConfirmacion(null);
    setItemSelected(null);
  };

  const acceptConfirmation = () => {
    esRestablecer ? onRestore({ ...itemSelected }) : onDelete({ ...itemSelected });
    cancelConfirmation();
  };
  
  return (
    <div>
      <Paper className={clsx(classes.root, customClass, hidePaginator && classes.noPaginator)} style={customStyles}>
        <Confirmacion
          anchor={anchorConfirmacion}
          onClose={cancelConfirmation}
          onAccept={acceptConfirmation}
          mensaje={mensajeConfirmacion}
        />
        <table className={clsx(classes.table)}>
          <thead>
            <tr>
              {showCheckbox && <th style={{ textAlign: 'center' }}>
                <Checkbox
                  checked={rows.every(({ isSelected }) => isSelected)}
                  value={rows.every(({ isSelected }) => isSelected)}
                  onClick={() => onSelectAll(rows.every(({ isSelected }) => isSelected))}
                />
              </th>}
              {headers.map((head, index) => (
                <th key={index} className={head.className || ''} style={{ textAlign: head.align || 'left' }}>
                  <Typography className={classes.label}>
                    {head.label}
                  </Typography>
                </th>
              ))}
              {showActions && <th>
                <Typography className={classes.label}>
                  {actionsTitle || 'Opciones'}
                </Typography>
              </th>}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index, arr) => (
              <tr key={index} className={clsx(classes.rowHover, {
                [classes.rowError]: rowErrors.includes(index),
              })}>
                {showCheckbox && <td style={{ textAlign: 'center' }}>
                  <Checkbox
                    checked={row.isSelected}
                    value={row.isSelected}
                    onClick={() => onSelect(row, index)}
                  />
                </td>}
                {headers.map((head, hIndex) => (
                  <td key={hIndex}
                    style={{
                      textAlign: head.align || 'left',
                      border: (arr.length === index + 1) && (arr.length === general.ELEMENTOS_POR_PAGINA) && 'none',
                      minWidth: head.width,
                      ...cellStyles
                    }}
                  >
                    {!head.transform ?
                      <Typography>{row[head.key] && row[head.key] !== '' ? row[head.key] : '- - -'}</Typography>
                      :
                      <Typography>
                        {head.transform(row, index)}
                      </Typography>
                    }
                  </td>
                ))}
                {showActions && (
                  <td className={classes.actions} style={{ border: (arr.length === index + 1) && (arr.length === general.ELEMENTOS_POR_PAGINA) && 'none' }}>
                    {moreActions.map(({ onClick, icon, validate, transform, disabled = false }, mIndex) => (
                      (!validate || validate(row)) && (
                        icon ? <IconButton
                          key={mIndex}
                          size="small"
                          disabled={typeof disabled === 'function' ? disabled(row, index, rows) : disabled}
                          className={clsx(classes.btnAdd, classes.btnAction)}
                          onClick={(e) => onClick(row, index, e)}>
                          {icon}
                        </IconButton> : <>
                          {transform(row, index)}
                        </>
                      )
                    ))}
                    {defaultActions && onEdit && row.id !== '0' && <IconButton
                      size="small"
                      className={clsx(classes.btnAdd, classes.btnAction)}
                      onClick={() => onEdit(row, index)}
                      title='Editar registro'>
                      <BsPencil />
                    </IconButton>}
                    {defaultActions && onDelete && (!hideDelete || !hideDelete(row)) && row.id !== '0' &&
                      !(typeof row.activo === 'boolean' && !row.activo ) && <IconButton
                        size="small"
                        className={clsx(classes.btnAdd, classes.btnAction)}
                        onClick={(e) => customDelete(e, row, index)}
                        title='Eliminar registro'>
                        <FiTrash />
                      </IconButton>}
                    {
                      superAdmin && onRestore && (typeof row.activo === 'boolean' && !row.activo) && (
                        <IconButton
                          size="small"
                          className={clsx(classes.btnAdd, classes.btnActionReactive)}
                          onClick={(e) => customRestore(e, row, index)}
                          title='Restaurar registro'>
                          <LiaTrashRestoreAltSolid size={19}/>
                          
                        </IconButton>
                      )
                    }
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        {
          !isPaginatorVisible && rows.length <= 0 && (
            <div style={{
              position: 'absolute',
              top: '50%',
              width: '100%',
            }}>
              <Typography
                component="p"
                className={classes.label}
                style={{ textAlign: 'center' }}>
                {emptyMessage}
              </Typography>
            </div>
          )
        }
      </Paper>
      {
        isPaginatorVisible &&
        <Container style={{ padding: 0, marginTop: 10 }} className={classes.table}>
          {!hidePaginator && <Paginador count={count} />}
        </Container>
      }
    </div>
  );
};

Table.propTypes = {
  headers: propTypes.arrayOf(propTypes.shape({
    label: propTypes.string.isRequired,
    key: propTypes.string,
    transform: propTypes.func,
    align: propTypes.string,
  })),
  rows: propTypes.array,
  showActions: propTypes.bool,
  onEdit: propTypes.oneOfType([propTypes.func, propTypes.bool]),
  onDelete: propTypes.oneOfType([propTypes.func, propTypes.bool]),
  moreActions: propTypes.array,
  showEmptyLabel: propTypes.bool,
  hideDelete: propTypes.func,
  count: propTypes.number,
  hidePaginator: propTypes.bool,
  showCheckbox: propTypes.bool,
  onSelectAll: propTypes.func,
  onSelect: propTypes.func,
  customStyles: propTypes.object,
  cellStyles: propTypes.object,
  rowErrors: propTypes.array,
  emptyMessage: propTypes.string,
  defaultActions: propTypes.bool,
  actionsTitle: propTypes.string,
};

Table.defaultProps = {
  headers: [],
  rows: [],
  showActions: true,
  onEdit: null,
  onDelete: null,
  moreActions: [],
  showEmptyLabel: true,
  hideDelete: null,
  count: 0,
  hidePaginator: false,
  showCheckbox: false,
  onSelectAll: null,
  onSelect: null,
  rowErrors: [],
  emptyMessage: 'NO HAY REGISTROS PARA MOSTRAR',
  defaultActions: true,
  actionsTitle: null,
};

export default React.memo(Table);
