import React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

const Color = ({ color }) => {
  return (
    <Box style={{
      position: 'relative',
      height: 18,
      width: 18,
      borderRadius: 14,
      backgroundColor: color,
    }} />
  )
}

Color.propTypes = {
  /** Color a mostrar */
  color: PropTypes.string.isRequired,
};

export default Color;
