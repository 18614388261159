import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  IconButton,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { Autocomplete } from '@material-ui/lab';
import { GiInfo } from 'react-icons/gi';
import Typography from '../Typography';
import styles from './styles';
import clsx from 'clsx';

const AutoComplete = ({
  name,
  label,
  options,
  control,
  register,
  error,
  propValor,
  propEtiqueta,
  required,
  tooltip,
  onClear,
  className,
  disabled,
  ...rest
}) => {
  const classes = styles();
  const formMethods = useFormContext();
  const customValue = useMemo(() => (formMethods && formMethods.watch(name)) || '', [name, formMethods]);
  const value = useMemo(() => (
    (customValue && String(customValue).trim() !== '')
    && options.find((option) => option[propValor] === customValue)
  ) || null, [options, propValor, customValue]);

  return (
    <FormControl
      fullWidth
      className={clsx(classes.formControl, className)}
      error={Boolean(error)}
    >
      {label && (
        <Typography className={classes.label}>
          {label}
          {required && ' * '}
          {tooltip !== '' && (
            <Tooltip title={<Typography style={{ fontSize: 14 }}>{tooltip}</Typography>}>
              <IconButton size="small">
                <GiInfo />
              </IconButton>
            </Tooltip>
          )}
        </Typography>
      )}
      <Autocomplete
        disabled={disabled}
        options={options}
        value={value}
        onChange={(_, option, reason) => {
          const newValue = option ? option[propValor] : null;
          formMethods.setValue(name, newValue);
          formMethods.trigger(name);
          if (reason === 'clear') {
            onClear();
          }
        }}
        noOptionsText="Sin elementos"
        getOptionLabel={(option) => option[propEtiqueta]}
        className={clsx({ [classes.error]: error })}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            error={Boolean(error)}
            helperText={error?.message}
            placeholder="Selecciona una opción"
            variant="filled"
            {...rest}
          />
        )}
      />
    </FormControl>
  );
};

AutoComplete.propTypes = {
  /** Nombre del Select */
  name: PropTypes.string.isRequired,
  /** Label del Select */
  label: PropTypes.string.isRequired,
  /** Arreglo con las opciones que apareceran en el Select */
  options: PropTypes.array,
  /** Bandera que indica si el campo es requerido */
  required: PropTypes.bool,
  /** Tooltip del Select */
  tooltip: PropTypes.string,
  /** Propiedad donde se almacena el valor */
  propValor: PropTypes.string,
  /** Propiedad donde se almacena la etiqueta. */
  propEtiqueta: PropTypes.string,
  /** Propiedad para ejecutar metodos de limpiar filtros */
  onClear: PropTypes.func,
  /** Propiedad para clases personalizadas. */
  className: PropTypes.string,
  /** Propiedad deshabilitar el input. */
  disabled: PropTypes.bool,
};

AutoComplete.defaultProps = {
  options: [],
  required: false,
  tooltip: '',
  propValor: 'id',
  propEtiqueta: 'nombre',
  onClear: () => { },
  className: '',
  disabled: false,
};

export default AutoComplete;
