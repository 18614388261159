import React from 'react';
import { Grid } from '@material-ui/core';
import styles from './styles';

const Simbologia = ({ simbologia }) => {
  const classes = styles();

  return (
    <Grid container spacing={1} className={classes.container}>
      {simbologia.map((item, index) => (
        <Grid item xs={12} key={index}>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              {item.simbolo}
            </Grid>
            <Grid item>
              {item.label}
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default Simbologia;
