import React, { useCallback } from 'react';
import propTypes from 'prop-types';

import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';

import styles from './styles';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { useState } from 'react';
import SpinnerCirculo from '../SpinnerCirculo';
import { TAMANIO_EXCEDIDO, TIPO_NO_PERMITIDO } from '../../constantes/mensajes';

const VistaSimple = ({
  archivo, onClick, error, disabled,
  mostrarSpinner,
}) => {
  const classes = styles();

  return (
    <>
      <Box
        className={clsx(classes.boxImagen, { error, disabled })}
        onClick={onClick}
        height={220}
      >
        <div className="overlay" />
        {
          (!mostrarSpinner) ? (
            <>
              <AiOutlineCloudUpload size={50} />
              {!error && (
                <Typography variant="h6">
                  <Typography variant="p">Arrastrar y soltar archivo o </Typography>
                  <Typography variant="p" className={classes.link}>Cargar</Typography>
                </Typography>
              )}
              {error &&
                <Typography variant="h6" className={clsx({ error })}>
                  <Typography variant="p">{archivo.name} </Typography>
                  <Typography variant="p" className={classes.link}>Cambiar</Typography>
                </Typography>
              }
            </>
          ) : (
            <Box style={{ marginTop: '15%' }}>
              <SpinnerCirculo />
            </Box>
          )
        }
      </Box>
      {error &&
        <Typography variant="h6" style={{ color: 'red' }}>
          {error}
        </Typography>
      }
    </>
  );
};

VistaSimple.propTypes = {
  /** Ruta de la imagen */
  src: propTypes.string,
  /** Funcion que se ejecuta al dar clic en la imagen */
  onClick: propTypes.func,
  /** Indica si existe un error para ajustar los estilos del componente */
  error: propTypes.bool,
  /** Funcion para eliminar la imagen */
  onEliminar: propTypes.func,
  /** Establece si se puede editar la imagen */
  disabled: propTypes.bool,
  mostrarSpinner: propTypes.bool,
};

VistaSimple.defaultProps = {
  src: null,
  onClick: () => { },
  error: false,
  onEliminar: () => { },
  disabled: false,
  mostrarSpinner: false,
};

const SeleccionadorArchivo = ({
  src,
  onChange,
  onRemoveImage,
  tamanioMaximo,
  multiple,
  limiteImagenes,
  mostrarSpinner,
  disabled,
}) => {
  const inputRef = React.useRef();
  const [archivo, setArchivo] = useState();
  const [error, setError] = useState();

  const openSelector = useCallback(() => {
    if (inputRef.current) inputRef.current.click();
  }, []);

  const extensionValidation = useCallback((e) => {
    const formatosValidos = ['csv', 'xlsx', 'xls'];

    const nombreArchivo = e.dataTransfer.files[0].name;
    const arrayExtension = nombreArchivo.split('.');
    const extension = arrayExtension[arrayExtension.length - 1];

    if (formatosValidos.includes(extension)) return true;
    return false;
  }, []);

  // eslint-disable-next-line consistent-return
  const fileEvent = useCallback((e, isDropEvent) => {
    e.preventDefault();
    const tamanioMaximoBytes = tamanioMaximo * 1024 * 1024;
    const file = (isDropEvent) ? e.dataTransfer.files[0] : e.target.files[0];
    setArchivo(file);
    if (isDropEvent && extensionValidation(e) === false) {
      setError(TIPO_NO_PERMITIDO);
      return;
    }
    if (file.size > tamanioMaximoBytes) {
      setError(TAMANIO_EXCEDIDO(tamanioMaximo));
      return;
    }

    if (file) {
      setError(null);
      onChange(file);
    }
    e.target.value = null;
  }, [extensionValidation, onChange, tamanioMaximo]);

  const handleChange = useCallback((e) => fileEvent(e, false), [fileEvent]);
  const handleDrop = useCallback((e) => fileEvent(e, true), [fileEvent]);
  const handleDropOver = (e) => e.preventDefault();

  return (
    <Box
      style={{ width: '100%' }}
      onDrop={(disabled) ? () => { } : handleDrop}
      onDragOver={(disabled) ? () => { } : handleDropOver}
    >
      <VistaSimple
        src={src}
        onClick={(disabled) ? () => { } : openSelector}
        error={error}
        onEliminar={onRemoveImage}
        disabled={disabled}
        archivo={archivo}
        mostrarSpinner={mostrarSpinner}
      />
      <input
        ref={inputRef}
        multiple={multiple}
        type="file"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        onChange={handleChange}
        style={{ display: 'none' }}
      />
    </Box>
  );
};

SeleccionadorArchivo.propTypes = {
  /** Encabezado del componente */
  label: propTypes.string,
  /** Objeto o cadena con la ruta de la imagen */
  src: propTypes.string || propTypes.object,
  /** Funcion para cambiar la imagen */
  onChange: propTypes.func.isRequired,
  /** Funcion para eliminar la imagen */
  onRemoveImage: propTypes.func,
  /** Establece el tamaño maximo de la imagen */
  tamanioMaximo: propTypes.number,
  /** Establece si se podran agregar multiples imagenes al mismo tiempo */
  multiple: propTypes.bool,
  /** Establece el limite de imagenes permitido */
  limiteImagenes: propTypes.number,
  /** Establece en caso de error los ajustes de estilos correspondientes */
  error: propTypes.bool,
  /** Establece si el componente estará desactivado */
  disabled: propTypes.bool,
};

SeleccionadorArchivo.defaultProps = {
  label: 'Imagen',
  src: null,
  onRemoveImage: () => { },
  tamanioMaximo: 2,
  multiple: false,
  limiteImagenes: 5,
  error: false,
  disabled: false,
};

export default SeleccionadorArchivo;
