import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles(() => ({
  txtEstado: {
    fontWeight: 'bold',
    '&.Disponible': { color: '#0AC8AF' },
    '&.Suspendido': { color: '#A3ABB3' },
    '&.Apartado': { color: '#DEB972' },
    '&.Vendido': { color: '#409A8E' },
  },
  vender: {
    backgroundColor: paleta.boton.secondary,
    borderColor: `${paleta.boton.secondary} !important`,
    '& .MuiTypography-body1': {
      color: 'white',
    },
    '&:hover': {
      backgroundColor: `${paleta.boton.secondary}BF !important`,
    },
  },
  cancelar: {
    backgroundColor: 'white',
    '& .MuiTypography-root': {
      color: `${paleta.bar.primary} !important`,
    },
    '&:hover': {
      backgroundColor: `${paleta.boton.secondary}BF !important`,
    },
  },
}));

export default styles;
