import React from 'react';
import Estatus from '../../componentes/Estatus';
import FiltrosComponent from '../../componentes/FiltrosComponent';
import Listados from '../../contenedores/Listados';
import { CLIENTES } from '../../configuraciones/endpoints';

const cabeceros = [
  { label: 'Nombre', key: 'nombre' },
  { label: 'CURP', key: 'curp' },
  { label: 'Domicilio', key: 'domicilio' },
  { label: 'Teléfono', transform: ({ telefono }) => telefono?.trim() || '---' },
  { label: 'Celular', transform: ({ celular }) => celular?.trim() || '---' },
  { label: 'Correo', key: 'correo' },
  { label: 'Empresa', transform: ({ empresa }) => empresa?.nombre || '---' },
  {
    label: 'Estatus', transform: ({ habilitado, activo }) => (
      <Estatus habilitado={habilitado} activo={activo} />
    ),
  },
];

const Clientes = ({ history, location }) => (
  <Listados
    titulo="Clientes"
    customCabecero={<FiltrosComponent />}
    history={history}
    location={location}
    showActions
    cabeceros={cabeceros}
    apiKey={CLIENTES}
    hideDelete={({ activo }) => !activo}
  />
);

export default Clientes;