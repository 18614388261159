import { FaMoneyCheckAlt } from 'react-icons/fa';
import { FaFileArrowDown, FaFileArrowUp, FaFileSignature } from 'react-icons/fa6';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import moment from 'moment';
import { Button, Grid, IconButton } from '@material-ui/core';
import { BsTrash } from 'react-icons/bs';

import styles from '../Venta/styles';
import Table from '../../componentes/Table';
import Boton from '../../componentes/Button';
import Typography from '../../componentes/Typography';
import axiosInstance from '../../configuraciones/axios';
import endpoints, { EXPEDIENTES } from '../../configuraciones/endpoints';
import { base64ToBlob, trim } from '../../utilidades/functions';
import SubirImagen from '../../componentes/SubirImagen';
import Modal from '../../componentes/Modal';

const Documentos = ({ id }) => {
  const classes = styles();
  const [archivos, setArchivos] = useState([]);
  const [mostrarModal, setMostrarModal] = useState(false);
  const [documento, setDocumento] = useState('');
  const [infoDocumento, setInfoDocumento] = useState('');
  const [tipo, setTipo] = useState('');
  const metodosForm = useForm();

  const {
    handleSubmit
  } = metodosForm;

  const guardarArchivo = useCallback(() => {
    const formData = new FormData();

    const nuevoDocumento = {
      ventaId: id,
      nombre: infoDocumento.nombre,
      tipo,
    }
    if (documento) {
      formData.append('documento', base64ToBlob(documento, infoDocumento.tipo));
      formData.append('datos', JSON.stringify(nuevoDocumento));

      axiosInstance.post(EXPEDIENTES, formData, {
        headers: {
          'enctype': 'multipart/form-data',
        }
      }).then(() => consultarArchivos()).catch(() => {});
      alCancelar();
    }

  }, [documento, tipo, infoDocumento]);

  const seleccionarDocumento = (tipo) => {
    setMostrarModal(true);
    setTipo(tipo);
  }

  const alCancelar = () => {
    setMostrarModal(false);
    setDocumento(undefined);
    setInfoDocumento(undefined);
    setTipo('');
  }

  const consultarArchivos = async () => {
    setArchivos(await axiosInstance.get(endpoints.obtenerArchivosExpediente(id)));
  }

  // Función para descargar el archivo
  const descargarArchivo = (ruta, nombre) => {
    fetch(ruta)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', nombre);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch(error => console.error('Error al descargar el archivo:', error));
  };

  const eliminarArchivo = (id) => {
    axiosInstance.delete(endpoints.eliminarArchivoExpediente(id)).then(() => consultarArchivos()).catch(() => { });
  };

  const cabeceros = [
    { label: 'Fecha', transform: ({ fechaCreacion }) => moment(fechaCreacion).format('DD-MM-YYYY') },
    { label: 'Hora', transform: ({ fechaCreacion }) => moment(fechaCreacion).format('HH:mm') },
    { label: 'Concepto', transform: ({ esContrato, esFiniquito }) => esContrato ? 'Contrato' : esFiniquito ? 'Finiquito' : 'Otros' },
    { label: 'Usuario', transform: ({ usuarioCreacion }) => usuarioCreacion.nombre },
    { label: 'Nombre archivo', transform: ({ nombre }) => nombre },
    {
      label: 'Opciones', transform: (_, index) => (
        <>
          <IconButton
            size="small"
            onClick={() => descargarArchivo(_.ruta, _.nombre)}
          >
            <FaFileArrowDown />
          </IconButton>
          <IconButton
            size="small"
            onClick={() => eliminarArchivo(_.id)}
          >
            <BsTrash />
          </IconButton>
        </>
      )
    },
  ];

  /** Effect que obtiene las cuentas cuando la función cambie. */
  useEffect(async () => consultarArchivos(), []);

  return (
    <>
    <FormProvider {...metodosForm}>
      <form noValidate onSubmit={handleSubmit(guardarArchivo)} id="frmDocumentos">
      <Grid container spacing={2} justifyContent={"flex-end"} style={{ marginTop: 20 }}>
        <Grid item xs={12}>
          <Typography
            component="h2"
          >
            Documentos:
          </Typography>
        </Grid>
        <Grid item lg={2} md={4} sm={6} xs={12}>
          <Boton
            fullWidth
            icono={<FaFileSignature />}
            label="Contrato"
            classesCustom={{ boton: classes.btnLote }}
            onClick={() => seleccionarDocumento('Contrato')}
            disabled={archivos.some((a) => a.esContrato === true)}
          />
        </Grid>
        <Grid item lg={2} md={4} sm={6} xs={12}>
          <Boton
            fullWidth
            icono={<FaMoneyCheckAlt />}
            label="Finiquito"
            classesCustom={{ boton: classes.btnLote }}
            onClick={() => seleccionarDocumento('Finiquito')}
            disabled={archivos.some((a) => a.esFiniquito === true)}
          />
        </Grid>
        <Grid item lg={2} md={4} sm={6} xs={12}>
          <Boton
            fullWidth
            icono={<FaFileArrowUp />}
            label="Otros"
            onClick={() => seleccionarDocumento('Otros')}
            classesCustom={{ boton: classes.btnLote }}
          />
        </Grid>
      </Grid>

      <Grid container style={{ marginTop: 15 }}>
        <Grid item xs={12}>
          <Table
            headers={cabeceros}
            rows={archivos}
            showActions={false}
          />
        </Grid>
      </Grid>
      <Modal
        open={mostrarModal}
        showActions
        actions={
          <>
            <Button onClick={() => alCancelar()} variant="outlined" >
              Cancelar
            </Button>
            <Button
              onClick={() => guardarArchivo()}
              variant="contained"
              type="submit"
            >
              Aceptar
            </Button>
          </>
        }
      >

        <SubirImagen tambienDocumentos imagenFirma={documento} setImagenFirma={setDocumento} setTipoArchivo={setInfoDocumento} label="Subir documento" tamanioMB={10}></SubirImagen>
      </Modal>

      </form>
    </FormProvider>
    </>
  )
}

export default Documentos;
