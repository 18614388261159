import React from 'react';
import propTypes from 'prop-types';
import { Popover, Typography, Grid } from '@material-ui/core';

import Boton from '../Button';
import styles from './styles';

const Confirmacion = ({
  anchor,
  onClose,
  customClickClose,
  onAccept,
  mensaje,
  labelPrimary,
  labelSecondary,
  customClass,
  iconPrimary,
  iconSecondary,
}) => {
  const classes = styles();
  return (
    <Popover
      anchorEl={anchor}
      open={Boolean(anchor)}
      keepMounted={false}
      onClose={onClose}
      classes={{ paper: classes.paper }}
      anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
      transformOrigin={{ horizontal: 'left', vertical: 'top' }}
      PaperProps={{
        style: {
          transform: 'translateX(-150px) translateY(-35px)',
        },
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography className={classes.text}>{mensaje}</Typography>
        </Grid>
        <Grid item xs={12} className={customClass || classes.btnContainer}>
          <Boton
            label={labelPrimary || 'Si'}
            onClick={onAccept}
            icono={iconPrimary}
          />
          <Boton
            label={labelSecondary || 'No'}
            onClick={customClickClose || onClose}
            border
            icono={iconSecondary}
          />
        </Grid>
      </Grid>
      <div className={classes.arrow}>
        <span></span>
      </div>
    </Popover>
  );
};

Confirmacion.propTypes = {
  anchor: propTypes.any,
  onClose: propTypes.func.isRequired,
  customClickClose: propTypes.func,
  mensaje: propTypes.string,
  onAccept: propTypes.func.isRequired,
  iconPrimary: propTypes.node,
  iconSecondary: propTypes.node,
  customClass: propTypes.string,
  labelPrimary: propTypes.string,
  labelSecondary: propTypes.string,
};

Confirmacion.defaultProps = {
  anchor: null,
  mensaje: '¿Está seguro de eliminar el elemento?',
  customClickClose: null,
};

export default Confirmacion;
