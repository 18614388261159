import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles((theme) => ({
  pasoIcono: {
    backgroundColor: paleta.tablas.botonAdd,
    color: paleta.tablas.colorTextoAdd,
    borderRadius: 50,
    margin: theme.spacing(0.5),
  },
  pasoLabel: {
    '&.MuiStepLabel-active': {
      color: paleta.planos.pasoLabelActivo,
      fontWeight: 'bold',
    },
    '&.MuiStepLabel-completed': {
      color: paleta.planos.pasoLabelCompletado,
    },
  },
  conectorPaso: {
    margin: theme.spacing(1.4),
    '& span': {
      borderColor: 'gray',
    },
  },
  btn: {
    color: 'white',
    border: `1px solid ${paleta.boton.border}`,
    '& .MuiTypography-body1': {
      fontSize: 14,
      fontWeight: 'bold',
      marginTop: 3,color: 'white',
    },
    '&:hover': {
      backgroundColor: `${paleta.boton.secondary}BF !important`,
    },
  },
  regresar: {
    backgroundColor: paleta.boton.primary,
  },
  continuar: {
    backgroundColor: paleta.boton.secondary,
  },
}));

export default styles;
