import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles((theme) => ({
  root: {
    height: 'auto',
    maxHeight: '75vh',
    overflow: 'auto',
    minHeight: '400px',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    [theme.breakpoints.only('xs')]: {
      overflow: 'auto',
      height: '55vh',
    },
    border: `1px solid rgba(36, 36, 36, 0.1)`,
    position: 'relative',
  },
  noPaginator: {
    [theme.breakpoints.only('xs')]: {
      height: '70vh',
    }
  },
  root2: {
    border: '1px solid #E6EBF5',
    height: '50vh'
  },
  table: {
    borderCollapse: 'separate',
    width: '100%',
    borderSpacing: '0px',
    overflow: 'auto',
    maxHeight: '75vh',
    '& th, td': {
      borderBottom: `1px solid ${paleta.tabla.bordertd}`,
      padding: theme.spacing(0, 1),
      '&:last-child': {
        borderRight: 'none',
      }
    },
    '& th': {
      padding: theme.spacing(1),
      position: 'sticky',
      backgroundColor: paleta.sidebar.active,
      top: 0,
      zIndex: 10,
      userSelect: 'none',
      '& span': {
        fontSize: 12,
        textTransform: 'uppercase',
      },
    },
    '& td': {
      padding: theme.spacing(0.5, 2),
    },
  },
  tablev2: {
    '& th, td': {
      borderRight: 'none',
    },
  },
  label: {
    fontWeight: 600,
    fontSize: 16,
  },
  btnAdd: {
    backgroundColor: paleta.tablas.botonAdd,
    color: paleta.tablas.colorTextoAdd,
    textTransform: 'none',
    boxShadow: 'none',
  },
  btnAction: {
    margin: theme.spacing(0.5),
  },
  btnActionReactive: {
    margin: theme.spacing(0.5),
    padding: '1px',
  },
  actions: {
    minWidth: 100,
    textAlign: 'center',
  },
  rowHover: {
    backgroundColor: 'initial',
    '&:hover': {
      backgroundColor: paleta.planos.botonAdd,
    },
  },
  rowError: {
    backgroundColor: `${paleta.tabla.rowError}AA`,
    '&:hover': {
      backgroundColor: `${paleta.tabla.rowError}77`,
    },
  }
}));

export default styles;
