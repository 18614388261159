import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles(() => ({
  root: {
    marginBottom: 16,
    '& > .MuiGrid-item': {
      paddingBlock: 4,
    },
    '& .MuiGrid-item': {
      paddingInline: 8,
    },
  },
  header: {
    width: 150,
  },
  chip: {
    width: 80,
    '&.Disponible': { backgroundColor: '#D3E5E3' },
    '&.Suspendido': { backgroundColor: '#BEC6CD' },
    '&.Apartado': { backgroundColor: '#F6D086' },
    '&.Vendido': { backgroundColor: '#409A8E', color: 'white' },
    '&.Eliminado': { backgroundColor: paleta.activo.primary, color: 'white' },
  },
  ejemplo: {
    padding: 40,
    borderRadius: '12px',
    backgroundColor: '#F6F9FC',
    cursor: 'pointer',
    border: `1px solid ${paleta.bar.primary}`,
  },
}));

export default styles;