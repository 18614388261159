/* eslint no-restricted-syntax: 0 */
/* eslint guard-for-in: 0 */
/* eslint no-param-reassign: 0 */
import moment from 'moment';
import { toast } from 'react-toastify';
import Regex, { regexMessages } from './regex';

import 'moment/locale/es';
import axios from '../configuraciones/axios';
import { VALIDAR_SELECCION_REPORTE } from '../constantes/mensajes';

moment.locale('es');
const formatter = new Intl.NumberFormat('en-MX', {
  style: 'currency',
  currency: 'USD',
});

export const removeByVal = (obj, val) => {
  for (const key in obj) {
    if ((obj[key] && (obj[key] === val || obj[key].toString() === val.toString()))) delete obj[key];
  }
  return obj;
};

export const findPropertysEmpty = (obj, showMsg = false) => {
  const errors = {};
  const regex = {};
  const elements = obj?.querySelectorAll('input, textarea, select') || [];
  let totalErrors = 0;
  let totalRegex = 0;
  Array.prototype.slice.call(elements).forEach((element) => {
    const regexElement = element.getAttribute('regex');
    const regexOnSubmit = element.getAttribute('regexonsubmit');
    const index = element.getAttribute('index');
    const equals = element.getAttribute('equals');
    if (regex && regexOnSubmit && element.value.trim() !== '') {
      const validation = Regex[regexElement];
      const regexValid = validation(element.value, equals);
      if (!regexValid) {
        if (index) {
          regex[`${element.name}${index}`] = regexMessages[regexElement] || true;
        } else {
          regex[element.name] = regexMessages[regexElement] || true;
        }
        totalRegex++;
      }
    }
    if (!element.required) return;
    const type = typeof (element.value);
    const errorName = element.getAttribute('errorname');
    if (type === 'string') {
      if (element.value.trim() === '' || element.value.trim() === '-1') {
        totalErrors++;
        errors[errorName || element.name] = true;
      }
    } else if (type === 'number') {
      if (element.value === -1) {
        totalErrors++;
        errors[errorName || element.name] = true;
      }
    }
  });

  for (const key in errors) {
    if (key.includes('.')) {
      const arr = key.split('.');
      if (!errors[arr[0]]) errors[arr[0]] = {};
      errors[arr[0]][arr[1]] = errors[key];
      delete errors[key];
    }
  }

  if (showMsg && (totalErrors > 0 || totalRegex > 0)) {
    toast.error(`${totalErrors + totalRegex} campos requeridos no han sido completados.`);

  };

  return {
    errors, totalErrors, regex, totalRegex,
  };
};

export const objToFormData = (obj) => {
  const formData = new FormData();
  Object.keys(obj).forEach((key) => {
    let valor = '';
    if (Array.isArray(obj[key]) || typeof obj[key] === 'object') valor = JSON.stringify(obj[key]);
    else valor = obj[key];
    formData.append(key, valor);
  });
  return formData;
};

export const getDifferences = (original, update) => {
  const differences = {};
  Object.keys(update)
    .forEach((key) => {
      if (original[key] !== update[key]) {
        differences[key] = original[key];
      }
    });
  return differences;
}

export const fileToBase64 = (file) => new Promise((resolve) => {
  const reader = new FileReader();
  reader.onload = () => resolve(reader.result);
  reader.readAsDataURL(file);
})

export const getMimeType = (name) => {
  const split = name?.split('.');
  return split[split.length - 1]
};

export const trim = (obj) => {
  const objTrim = {};

  // Eliminamos espacios en blanco de la propiedades
  for (const [key, value] of Object.entries(obj)) {
    objTrim[key] = typeof value === 'string' && key !== 'password' ? value.trim() : value
  };

  return {
    ...objTrim
  };
}

export const getISOWeekDates = (isoWeekNum = 1, format = '') => {
  let d = moment()
    .isoWeek(1)
    .startOf('isoWeek')
    .add(isoWeekNum - 1, 'weeks');
  for (var dates = [], i = 0; i < 7; i++) {
    const p = d.clone().format(format);
    dates.push(p);
    d.add(1, 'day');
  }
  return dates;
};
export const getTheDaysOfTheWeek = (isoWeekNum = 1, format = '') => {
  let d = moment()
    .isoWeek(1)
    .startOf('isoWeek')
    .add(isoWeekNum - 1, 'weeks');
  for (var dates = [], i = 0; i < 7; i++) {
    const nombre = d.clone().format(format);
    const dia = d.clone().format('YYYY-MM-DD')
    dates.push({ id: i, nombre, dia: dia });
    d.add(1, 'day');
  }
  return dates;
};

export const getWeeks = () => {
  const semanas = [];
  for (let i = 1; i <= moment().weeksInYear(); i++) {
    semanas.push({
      id: i,
      fechaObjetivo: moment().subtract(i, 'weeks').format('W-YYYY'),
      nombre: `${i} - ${moment().isoWeek(i).startOf("isoweek").format('DD-MMM-YYYY')}
       /  ${moment().isoWeek(i).endOf("isoweek").format('DD-MMM-YYYY')}`,
    });
  }

  return semanas;
};

export const getWeeksFormatted = () => {
  const semanas = [];
  for (let i = 0; i < moment().weeksInYear(); i++) {
    semanas.push({
      id: i,
      semanaID: moment().subtract(i, 'weeks').isoWeek(),
      nombre: `${moment().subtract(i, 'weeks').isoWeek()} (${moment()
        .subtract(i, 'weeks')
        .startOf('isoWeek')
        .format('DD-MMM-YYYY')} / ${moment()
          .subtract(i, 'weeks')
          .endOf('isoWeek')
          .format('DD-MMM-YYYY')})`,
    });
  }

  return semanas;
};

export const sumarArr = (arr, key) => {
  const valores = arr.map(e => e[key]);
  return valores.reduce((a, b) => Number(a) + Number(b), 0);
}

export const parseFloatProperties = (obj) => {
  const arr = Object.entries(obj);
  const parseValues = arr.map(e => {
    const value = parseFloat(e[1]);
    if (!Number.isNaN(value)) {
      return [e[0], parseFloat(e[1])];
    }
    return e;
  })
  return Object.fromEntries(parseValues);
}

export const ValidarRangos = (rangos, id) => {
  const listaRangos = Object.entries(rangos)
    .filter(rango => rango[0].includes('rango'))
    .map(rango => Number(rango[1]));

  // Validación de tipo de datos y valores nulos
  if (listaRangos.some(rango => rango === null || isNaN(Number(rango)))) {
    return id;
  }

  // Conversión de rangos a números para facilitar comparaciones
  const [rojoMin, rojoMax, amarilloMin, amarilloMax, verdeMin, verdeMax] = listaRangos;

  // Validación de rangos positivos
  if (rojoMin < 0 || rojoMax < 0 || amarilloMin < 0 || amarilloMax < 0 || verdeMin < 0 || verdeMax < 0) {
    return id;
  }

  // Validación de rangos internos
  if (rojoMin >= rojoMax || amarilloMin >= amarilloMax || verdeMin >= verdeMax) {
    return id;
  }

  // Validación de no solapamiento
  const rangosOrdenados = [
    { min: rojoMin, max: rojoMax },
    { min: amarilloMin, max: amarilloMax },
    { min: verdeMin, max: verdeMax }
  ].sort((a, b) => a.min - b.min);

  for (let i = 0; i < rangosOrdenados.length - 1; i++) {
    if (rangosOrdenados[i].max >= rangosOrdenados[i + 1].min) {
      return id;
    }
  }

  // Si todas las validaciones son exitosas
  return;
}

export const capitalizarPalabras = (string) => {
  return string.replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());
};

export const TiposDocumentos = ({ response, tipoArchivo }) => {
  let outputFilename;
  if (tipoArchivo === 'excel') {
    outputFilename = `excel_${moment(moment.now()).format(
      'DD-MM-YYYY:HH:mm'
    )}.xlsx`;
  }

  if (tipoArchivo === 'pdf') {
    outputFilename = `reporte_${moment(moment.now()).format(
      'DD-MM-YYYY:HH:mm'
    )}.pdf`;
  }
  const url = URL.createObjectURL(new Blob([response]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', outputFilename);
  document.body.appendChild(link);
  link.click();
};


export const exportarDocumento = async ({ params, endpoint, tipoArchivo, method }) => {
  try {
    let response;
    if (method === 'POST') {
      response = await axios({
        url: endpoint,
        method,
        responseType: 'arraybuffer',
        data: {
          ...params,
          exportar: true,
          tipoExportacion: tipoArchivo
        }
      });
    } else {
      response = await axios.get(endpoint, {
        responseType: 'arraybuffer',
        params: {
          ...params,
          exportar: true,
          tipoExportacion: tipoArchivo,
        },
        headers: { 'Content-Type': 'blob' },
      });
    }
    if (response.byteLength > 0) {
      TiposDocumentos({ response, tipoArchivo });
    } else {
      toast.error('Sin registros para exportar');
    }
  } catch (error) {
    return error;
  }
};

export const customCabecero = (selects) => {
  const cabecero = {
    sitio: { width: 4, show: false },
    invernadero: { width: 4, show: false },
    nave: { width: 4, show: false },
    fenologia: { width: 4, show: false },
    tabla: { width: 4, show: false },
    planta: { width: 4, show: false },
    parametro: { width: 4, show: false },
    invernaderoNave: { width: 4, show: false },
    enfermedad: { width: 4, show: false },
    trampa: { width: 4, show: false },
    plaga: { width: 4, show: false },
    surco: { width: 4, show: false },
    saneo: { width: 4, show: false },
    seccion: { width: 4, show: false },
    nivel: { width: 4, show: false },
    nivelInfeccion: { width: 4, show: false },
    semana: { width: 4, show: false },
    dia: { width: 4, show: false },
  };

  return {
    ...cabecero,
    ...selects,
  };
};

export const CamposValidados = (formData) => {
  let esInvalido = true;
  Object.keys(formData).forEach((key) => {
    if (formData[key].length === 0 || formData[key] === '') esInvalido = false;
  });
  return !esInvalido && toast.error(VALIDAR_SELECCION_REPORTE);
};

export const obtenerCentroide = (pts) => {
  if (!pts[0]?.x || !pts[0]?.y) return null;
  var first = pts[0], last = pts[pts.length - 1];
  if (first.x !== last.x || first.y !== last.y) pts.push(first);
  var twicearea = 0,
    x = 0, y = 0,
    nPts = pts.length,
    p1, p2, f;
  for (var i = 0, j = nPts - 1; i < nPts; j = i++) {
    p1 = pts[i]; p2 = pts[j];
    f = (p1.y - first.y) * (p2.x - first.x) - (p2.y - first.y) * (p1.x - first.x);
    twicearea += f;
    x += (p1.x + p2.x - 2 * first.x) * f;
    y += (p1.y + p2.y - 2 * first.y) * f;
  }
  f = twicearea * 3;
  return { x: x / f + first.x, y: y / f + first.y };
};

export const obtenerRangoFechas = (desde, hasta, format = 'W-YYYY', orderAsc = false, hastaFechaActual = true) => {
  const fecha = moment(desde).startOf('isoWeek');
  const fechas = [];
  if (hastaFechaActual) {
    while (fecha.isSameOrBefore(hasta) && fecha.isSameOrBefore(moment())) {
      fechas.push(fecha.format(format));
      fecha.add(1, 'isoWeek');
    }
  } else {
    while (fecha.isSameOrBefore(hasta)) {
      fechas.push(fecha.format(format));
      fecha.add(1, 'isoWeek');
    }
  }
  fechas.sort((a, b) => {
    const fechaA = moment(a, format);
    const fechaB = moment(b, format);
    if (fechaA.isBefore(fechaB)) return orderAsc ? -1 : 1;
    if (fechaA.isAfter(fechaB)) return orderAsc ? 1 : -1;
    return 0;
  });
  return fechas;
};

export const obtenerDiasSemana = (semana = moment().isoWeek(), format = 'W-YYYY', orderAsc = false) => {
  const fecha = moment(semana, 'W (YYYY)').startOf('isoWeek');
  const hasta = moment(fecha).endOf('isoWeek');
  const fechas = [];
  while (fecha.isSameOrBefore(hasta)) {
    fechas.push(fecha.format(format));
    fecha.add(1, 'day');
  }
  fechas.sort((a, b) => {
    const fechaA = moment(a, format);
    const fechaB = moment(b, format);
    if (fechaA.isBefore(fechaB)) return orderAsc ? -1 : 1;
    if (fechaA.isAfter(fechaB)) return orderAsc ? 1 : -1;
    return 0;
  });
  return fechas;
};

export const max = (data) => {
  if (data && data.length > 0) {
    return Math.max(...data);
  }
  return 0;
};

export const min = (data) => {
  if (data && data.length > 0) {
    return Math.min(...data);
  }
  return 0;
};

export const moda = (array, reglas) => {
  const count = array.reduce((acc, curr) => {
    acc[curr] = acc[curr] ? acc[curr] + 1 : 1;
    return acc;
  }, {});

  const maxValue = Math.max(...Object.values(count));
  const modaValor = Object.keys(count).filter(key => count[key] === maxValue);

  if (modaValor.length === 1) {
    return modaValor[0];
  }
  else if (reglas) {
    const copiaModaValor = [...modaValor];
    const modaValorOrdenada = copiaModaValor.sort((a, b) => {
      if (reglas[a] > reglas[b]) return -1;
      if (reglas[a] < reglas[b]) return 1;
      return 0;
    });
    return modaValorOrdenada[0] || 'No definido';
  }
  else {
    return 'No definido';
  }
};

export const generarRecta = (coord1, coord2, n) => {
  const { lat: x1, lng: y1 } = coord1;
  const { lat: x2, lng: y2 } = coord2;
  const cantidad = n - 1;

  const incrementoX = (x2 - x1) / cantidad;
  const incrementoY = (y2 - y1) / cantidad;

  const coordenadas = [];
  for (let i = 0; i <= cantidad; i++) {
    const xI = x1 + i * incrementoX;
    const yI = y1 + i * incrementoY;
    coordenadas.push([xI, yI]);
  }

  coordenadas.push([x2, y2]);

  return coordenadas;
};


/**
 * Función que obtiene los días de una semana
 * @param {number} semana Semana a obtener los días
 * @param {number} anio Año a obtener los días
 * @param {string} [formato] Formato de los días, por defecto YYYY-MM-DD
 * @returns {Array} Días de la semana
 */
export function obtenerDiasDeSemana(semana, anio, formato = 'YYYY-MM-DD') {
  const dias = [];
  const inicioSemana = moment().year(anio).isoWeek(semana).startOf('isoWeek');

  for (let i = 0; i < 7; i++) {
    dias.push(moment(inicioSemana).add(i, 'days').format(formato));
  }

  return dias;
}

export function formatoMoneda(valor) {
  return formatter.format(valor);
}

/**
 * Calcula la diferencia en días entre una fecha determinada y la fecha
 * actual, proporcionando un mensaje basado en el resultado.
 */
export function leyendaFechaCobranza(fecha) {

  const fechaObjetivo = moment(fecha, 'YYYY-MM-DD').startOf('day');
  const fechaActual = moment(new Date(), 'YYYY-MM-DD').startOf('day');
  const diferenciaDias = fechaObjetivo.diff(fechaActual, 'days');
  
  if (diferenciaDias > 0) {
    return `${diferenciaDias} días restantes`;
  } else if (diferenciaDias < 0) {
    return `${Math.abs(diferenciaDias)} días vencido`;
  } else {
    return 'Vence ahora';
  }

}

/**
 * Convierte una URL de datos en un objeto Archivo con el nombre de archivo
 * y el tipo MIME especificados.
 */
export const base64ToBlob = (base64, tipo = 'image/png') => {
  // Decodificar la cadena base64
  const byteCharacters = atob(base64.split(',')[1]);
  const byteNumbers = new Array(byteCharacters.length);
  
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  
  const byteArray = new Uint8Array(byteNumbers);
  
  return new Blob([byteArray], { type: tipo });
}

/**
 * Convierte la URL de una imagen en una cadena codificada en base64 
 */
export const urlToBase64 = (url) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function() {
      const canvas = document.createElement('canvas');
      canvas.width = this.width;
      canvas.height = this.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(this, 0, 0);
      const base64 = canvas.toDataURL('image/png');
      resolve(base64);
    };
    img.onerror = function(error) {
      reject(error);
    };
    img.src = url;
  });
}

/**
 * Valida si un objeto es vacío
 */
export const esObjetoVacio = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

/**
 * Valida si un array no tiene objetos
 */
export const esArrayVacio = (obj) => {
  return Array.isArray(obj) && obj.length === 0;
};