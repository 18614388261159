import React from 'react';
import propTypes from 'prop-types';
import clsx from 'clsx';
import Typography from '../Typography';
import styles from './styles';

const Estatus = ({ activo, habilitado }) => {
  const classes = styles();

  return <Typography className={clsx(classes.root, { disabled: !habilitado || !activo })}>
    {!activo ? 'Eliminado' : habilitado ? 'Habilitado' : 'Inhabilitado'}
  </Typography>
};

Estatus.propTypes = {
  activo: propTypes.bool.isRequired,
};

Estatus.defaultProps = {
  activo: true,
};

export default React.memo(Estatus);