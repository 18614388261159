import React from 'react';
import Estatus from '../../componentes/Estatus';
import Listados from '../../contenedores/Listados';
import { EMPRESAS } from '../../configuraciones/endpoints';

const cabeceros = [
  { label: 'Nombre', key: 'nombre' },
  { label: 'Razón social', key: 'razonSocial' },
  { label: 'RFC', key: 'rfc' },
  { label: 'Domicilio', key: 'domicilio' },
  { label: 'Teléfono', key: 'telefono' },
  { label: 'Correo', key: 'correo' },
  { label: 'Proyectos', key: 'numProyectos' },
  {
    label: 'Estatus', transform: ({ habilitado, activo }) => (
      <Estatus habilitado={habilitado} activo={activo} />
    ),
  },
];

const Empresas = ({ history, location }) => (
  <Listados
    titulo="Empresas"
    placeHolder="Búsqueda por nombre"
    history={history}
    location={location}
    showActions
    cabeceros={cabeceros}
    apiKey={EMPRESAS}
  />
);

export default Empresas;
