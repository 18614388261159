import { makeStyles } from '@material-ui/core';

const styles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 9999999999,
    pointerEvents: 'none',
  }
}));

export default styles;
