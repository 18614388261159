/* eslint prefer-promise-reject-errors: 0 */
import axios from 'axios';
import { toast } from 'react-toastify';
import store from '../ducks';
import { startAjax, endAjax } from '../ducks/ajax';
import { STORAGE } from '../ducks/user';
import general from './general';
import { ERROR, SUCCESS } from './mensajes';

const axiosInstance = axios.create({
  baseURL: general.URL_API,
  headers: {
    'TimeZone': Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
});

axiosInstance.interceptors.request.use((config) => {
  const { dispatch } = store;
  dispatch(startAjax());
  return config;
}, (error) => {
  const { dispatch } = store;
  dispatch(endAjax());
  return Promise.reject(error);
})

axiosInstance.interceptors.response.use(({ data, status }) => {
  const { dispatch } = store;
  dispatch(endAjax());
  if (status === 201 || status === 204)
    toast.success(SUCCESS);
  return data;
},
  ({ response }) => {
    const { dispatch } = store;
    dispatch(endAjax());
    let isError = false;
    let isMessage = false;

    if (response) {
      const { status, data } = response;

      if ((typeof data === 'string' || data instanceof String) && data.trim() !== '') {
        if (!data.includes('html')) isMessage = true;
      }
      if (status !== 404 && status !== 403) isError = true;
    } else {
      isError = true;
      isMessage = false;
    }
    const tipoMensaje = response.data.isError ? 'error' : 'warning';
    toast[tipoMensaje](response.data.isError ? ERROR : response.data.errorMessage, { toastId: `AXIOS_ERROR_${response.status || 500}` });

    return Promise.reject({ ...response, isError, isMessage });
  });

export const setTokenHeader = (token) => {
  axiosInstance.defaults.headers.common.Authorization = token;
};

const user = JSON.parse(localStorage.getItem(STORAGE) || sessionStorage.getItem(STORAGE) || '{}');

setTokenHeader(user.token || '');

export default axiosInstance;
