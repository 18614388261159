import React, { forwardRef, useCallback, useImperativeHandle } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button, Grid, Typography } from '@material-ui/core';
import { FaMagnifyingGlass } from 'react-icons/fa6';
import { MdFilterAltOff } from 'react-icons/md';
import clsx from 'clsx';

import styles from './styles';

const AccionesCabecero = forwardRef((_, ref) => {
  const classes = styles();
  const { reset } = useFormContext();

  const limpiarFiltros = useCallback(() => {
    reset();
  },[reset]);
  
  useImperativeHandle(ref, () => {
    return {
      limpiarFiltro() {
        limpiarFiltros();
      },
    };
  }, [limpiarFiltros]);

  return (
    <Grid container justifyContent="flex-end" spacing={2}>
      <Grid item xs>
        <Button
          type="submit"
          startIcon={<FaMagnifyingGlass size={14} />}
          className={clsx(classes.btn, classes.buscar)}
          fullWidth
        >
          <Typography>Buscar</Typography>
        </Button>
      </Grid>
      <Grid item xs>
        <Button
          type="submit"
          startIcon={<MdFilterAltOff size={14} />}
          className={clsx(classes.btn, classes.limpiar)}
          onClick={limpiarFiltros}
          fullWidth
        >
          <Typography>Limpiar</Typography>
        </Button>
      </Grid>
    </Grid>
  )
});

export default AccionesCabecero;
