import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import propTypes from 'prop-types';
import { Tooltip, IconButton, Select, MenuItem, Chip, Box } from '@material-ui/core';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { GiInfo } from 'react-icons/gi';
import clsx from 'clsx';
import Typography from '../Typography';
import styles from './styles';

const CHIP_EXTRA_ID = 'extra';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const OFFSET = 105;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CustomSelect = ({
  label,
  value,
  name,
  onChange,
  isHandleChange,
  required,
  error,
  tooltip,
  className,
  classNameInput,
  labelProp,
  options,
  valueProp,
  multiple,
  customLabel,
  style,
  disabled,
  onClick,
  IconComponent,
  displayTextStyles,
  placeHolder,
  placeHolderColor,
  onDelete,
  onClickMenuItem,
  fullWidth,
  helperText,
}) => {
  const classes = styles();
  const selectRef = useRef();
  const chipContainerRef = useRef();

  const [elementosExtras, setElementosExtras] = useState(0);

  const customOnClickItem = (value) => {
    if (onClickMenuItem)
      onClickMenuItem(value);
  };

  const handleChips = useCallback(() => {
    if (value.length === 0) return;

    const anchuraTotal = selectRef.current.clientWidth - OFFSET;
    const arr = chipContainerRef?.current?.children
      ? [...chipContainerRef.current.children]
      : [];
    let acumulador = 0;
    let ancho = 0;

    for (const element of arr) {
      if (element.id === CHIP_EXTRA_ID) continue;
      ancho += element.clientWidth;

      if (ancho >= anchuraTotal) {
        acumulador++;
      }
    }

    setElementosExtras(acumulador);
  }, [value]);

  useEffect(() => {
    if (!selectRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      handleChips();
    });
    resizeObserver.observe(selectRef.current);
    return () => resizeObserver.disconnect();
  }, [handleChips]);

  const formMethods = useFormContext();
  const customRegister = useMemo(() => (formMethods && formMethods.register(name)) || {}, [name, formMethods]);
  const customValue = formMethods.watch(name);

  return (
    <div
      className={clsx(classes.root, className, { [classes.error]: error })}
      style={style}
    >
      <Typography className={classes.label}>
        {label}
        {label !== '' && required && ' * '}
        {tooltip !== '' && (
          <Tooltip
            title={<Typography style={{ fontSize: 14 }}>{tooltip}</Typography>}
          >
            <IconButton size='small'>
              <GiInfo />
            </IconButton>
          </Tooltip>
        )}
      </Typography>
      <Select
        ref={selectRef}
        variant='outlined'
        disabled={disabled}
        className={clsx({
          [classes.input]: !classNameInput,
          [classes.fullWidth]: fullWidth,
        })}
        IconComponent={IconComponent}
        name={name}
        displayEmpty={placeHolder ? true : false}
        {...customRegister}
        // value={customValue}
        value={typeof customValue === 'undefined' ? '' : customValue}
        renderValue={(selected) => {
          let option = null;
          let renderValue = null;
          if (Array.isArray(selected)) {
            option = options.filter((option) =>
              selected.includes(option[valueProp])
            );
            renderValue =
              option?.map((option) => option[labelProp]).join(', ') || '';
          } else {
            // eslint-disable-next-line eqeqeq
            option = options.find((option) => option[valueProp] == (selected || customValue));
            renderValue = option ? option[labelProp] : '';
          }
          if (Array.isArray(selected) && onDelete) {
            renderValue = (
              <Box className={classes.chipContainer} ref={chipContainerRef}>
                {
                  selected.map((value, index) => (
                    <Chip
                      key={value}
                      label={options.find((opcion) => opcion[valueProp] === value)[labelProp]}
                      className={clsx(classes.chipItem, { [classes.ghostChip]: index >= (selected.length - elementosExtras) })}
                      deleteIcon={<AiOutlineCloseCircle className={classes.iconDelete} />}
                      onDelete={() => onDelete(value, name)}
                      onMouseDown={(e) => e.stopPropagation()}
                    />
                  ))
                }
                {
                  elementosExtras > 0 && <Chip id={CHIP_EXTRA_ID} className={classes.chipItem} label={`${elementosExtras} más`} />
                }
              </Box>
            )
          }
          return (
            <Typography
              style={{
                color: placeHolderColor,
                paddingRight: '20px',
                ...displayTextStyles,
              }}
            >
              {option ? renderValue : placeHolder}
            </Typography>
          );
        }}
        MenuProps={MenuProps}
        multiple={multiple}
        required={required}
        onClick={onClick}
      >
        {options.map((option, index) => (
          <MenuItem
            key={index}
            className={classes.li}
            value={valueProp ? option[valueProp] : option}
            onClick={() => customOnClickItem(option[valueProp])}
          >
            {customLabel ? customLabel(option) : option[labelProp]}
          </MenuItem>
        ))}
        {options.length === 0 && (
          <MenuItem disabled>Sin elementos para mostrar</MenuItem>
        )}
      </Select>
      {helperText && helperText !== '' && <Typography
        className={clsx(classes.helperText, { error })}
        component="p">
        {helperText}
      </Typography>}
    </div >
  );
};

CustomSelect.propTypes = {
  label: propTypes.string,
  value: propTypes.oneOfType([
    propTypes.string,
    propTypes.number,
    propTypes.bool,
    propTypes.array,
  ]),
  name: propTypes.string.isRequired,
  onChange: propTypes.func,
  isHandleChange: propTypes.bool,
  required: propTypes.bool,
  error: propTypes.oneOfType([propTypes.string, propTypes.bool, propTypes.object]),
  tooltip: propTypes.string,
  className: propTypes.string,
  labelProp: propTypes.string,
  options: propTypes.array.isRequired,
  valueProp: propTypes.string,
  multiple: propTypes.bool,
  onDelete: propTypes.func,
  customLabel: propTypes.func,
  IconComponent: propTypes.func,
  styleComponent: propTypes.string,
  style: propTypes.object,
  disabled: propTypes.bool,
  onClick: propTypes.func,
  placeHolder: propTypes.string,
  placeHolderColor: propTypes.string,
  displayTextStyles: propTypes.object,
  onClickMenuItem: propTypes.func,
  fullWidth: propTypes.bool,
  helperText: propTypes.string,
};

CustomSelect.defaultProps = {
  label: '',
  value: '',
  isHandleChange: false,
  required: false,
  error: false,
  tooltip: '',
  className: '',
  labelProp: 'label',
  valueProp: 'id',
  multiple: false,
  customLabel: null,
  onDelete: null,
  style: {},
  disabled: false,
  placeHolderColor: 'black',
  displayTextStyles: {},
  onClickMenuItem: null,
  fullWidth: true,
};

export default CustomSelect;
