/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import md5 from 'md5';

import Default from '../../contenedores/Default';
import AutoComplete from '../../componentes/AutoComplete';
import Select from '../../componentes/Select';
import SubirImagen from '../../componentes/SubirImagen';

import TextField from '../../componentes/TextField';
import Tabla from '../../componentes/Table';
import { phoneRegex, regexMessages, emailRegex } from '../../utilidades/regex';

import axios from '../../configuraciones/axios';
import {
  CAMPO_REQUERIDO, CONTRASENA_LONGITUD, CONTRASENAS_NO_COINCIDEN,
  ELEMENTO_NO_ENCONTRADO, SELECCIONA_EMPRESA
} from '../../constantes/mensajes';

import endpoints, {
  USUARIOS, PERFILES, EMPRESAS,
} from '../../configuraciones/endpoints';
import estatus from '../../constantes/estatus';
import { base64ToBlob, trim, urlToBase64 } from '../../utilidades/functions';

const schema = yup.object({
  nombre: yup.string().required(CAMPO_REQUERIDO),
  correo: yup.string()
    .matches(
      emailRegex,
      {
        message: regexMessages.EMAIL,
        excludeEmptyString: true,
      }
    ).required(CAMPO_REQUERIDO),
  contrasena: yup.string()
    .when('id', ([id], field) => {
      return (!id ? field.required(CAMPO_REQUERIDO).min(8, CONTRASENA_LONGITUD) : field)
    }),
  confirmarContrasena: yup.string()
    .when('id', ([id], field) => {
      return (!id ? field.oneOf([yup.ref('contrasena'), null], CONTRASENAS_NO_COINCIDEN).required(CAMPO_REQUERIDO).min(8, CONTRASENA_LONGITUD) : field)
    }),
  perfilId: yup.number().required(CAMPO_REQUERIDO),
  habilitado: yup.boolean().oneOf([true, false]).required(),
  telefono:
    yup.string()
      .matches(
        phoneRegex,
        {
          message: regexMessages.PHONE,
          excludeEmptyString: true,
        }
      ).required(CAMPO_REQUERIDO)
});

const Usuario = ({ history, match }) => {
  const { params: { id } } = match;
  const metodosForm = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      habilitado: true,
      id
    }
  });
  const [imagenFirma, setImagenFirma] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = metodosForm;

  const cabeceros = [
    { label: 'Empresa', key: 'nombre' },
  ];

  const [empresas, setEmpresas] = useState([]);
  const [perfiles, setPerfiles] = useState([]);

  /*Realiza el guardado/editado del usuario de sus campos*/
  const guardar = useCallback((usuario) => {
    const formData = new FormData();
    if (imagenFirma) {
      formData.append('firma', base64ToBlob(imagenFirma));
    }

    const metodo = id ? 'put' : 'post';

    const objTrim = trim(usuario);
    const nuevoObj = {
      ...objTrim,
      usuarioEmpresas: empresas.map(({ empresaId, isSelected }) => {
        const empresa = {
          empresaId,
          habilitado: isSelected,
          activo: isSelected,
        };

        if (id) empresa.usuarioId = id;
        return empresa;
      })
    };
    // Verificar si al menos una empresa está seleccionada
    const empresaSeleccionada = nuevoObj.usuarioEmpresas.find(empresa => empresa.habilitado);

    if (!empresaSeleccionada) {
      toast.warning(SELECCIONA_EMPRESA);
      return;
    }

    /* Si el método es 'put' y las contraseñas estan vacias, no se envian */
    if (metodo === 'put') {
      if (!objTrim.contrasena) {
        delete nuevoObj.contrasena;
      } else {
        nuevoObj.contrasena = md5(objTrim.contrasena);
      }
      if (!objTrim.confirmarContrasena) {
        delete nuevoObj.confirmarContrasena;
      } else {
        nuevoObj.confirmarContrasena = md5(objTrim.confirmarContrasena);
      }
    } else {
      // Si el método es 'post', se incluyen las contraseñas encriptadas
      nuevoObj.contrasena = md5(objTrim.contrasena);
      nuevoObj.confirmarContrasena = md5(objTrim.confirmarContrasena);
    }

    formData.append('usuario', JSON.stringify(nuevoObj));

    axios[metodo](endpoints.base.url(USUARIOS, id), formData, {
      headers: {
        'enctype': 'multipart/form-data',
      }
    }).then(regresar).catch(() => { });
  }, [id, empresas, imagenFirma]);

  /*Selecciona todos las opciones de la tabla */
  const seleccionarTodos = (flag) => {
    const nuevasEmpresas = [...empresas];
    setEmpresas(nuevasEmpresas.map(empresa => ({ ...empresa, isSelected: !flag })));
  };

  /*Selecciona empresa de tabla de formulario */
  const seleccionarEmpresa = (row) => {
    const index = empresas.findIndex(({ empresaId }) => empresaId === row.empresaId);
    if (index !== -1) {
      const nuevasEmpresas = [...empresas];
      nuevasEmpresas[index].isSelected = !nuevasEmpresas[index].isSelected;
      setEmpresas(nuevasEmpresas);
    }
  };

  /*Regresa a la pagina de catalogos donde se muestra el listado */
  const regresar = () => {
    const { location: { state: { pagina } } } = history;
    history.push({
      pathname: '/catalogos/usuarios',
      search: pagina && pagina > 1 ? `?pagina=${pagina}` : '',
    });
  };

  const cancelar = useCallback(regresar, []);

  /*Carga inicial para mostrar la información pre cargada en las inputs,select,tablas */
  const consultarDatosIniciales = () => new Promise((resolve, reject) => {
    const promesas = [
      axios.get(endpoints.base.opciones(EMPRESAS)),
      axios.get(endpoints.base.opciones(PERFILES))
    ];

    if (id) promesas.push(axios.get(endpoints.base.url(USUARIOS, id)));

    Promise.all(promesas).then(async (resultadosPromesas) => {
      const [empresasDb, perfilesDb, usuarioDb] = resultadosPromesas;
      const empresaMapeadas = [];
      setPerfiles(perfilesDb)

      const nuevoUsuarioDb = {
        ...usuarioDb,
      };

      for (const empresa of empresasDb) {
        const emp = {
          empresaId: empresa.id,
          nombre: empresa.nombre,
          isSelected: id ? Boolean(nuevoUsuarioDb?.usuarioEmpresas?.find(userEmpresa => userEmpresa.empresaId === empresa.id)) : false,
        };
        empresaMapeadas.push(emp);
      }
      setEmpresas(empresaMapeadas);

      if (nuevoUsuarioDb?.id) {
        reset({
          ...nuevoUsuarioDb,
          perfilId: nuevoUsuarioDb.perfilId.toString(),
        });
        if(nuevoUsuarioDb.urlFirma){
          setImagenFirma(await urlToBase64(nuevoUsuarioDb.urlFirma));
        }
      } else if (id) {
        toast.warning(ELEMENTO_NO_ENCONTRADO);
        regresar();
      }
      resolve();
    }).catch(reject);
  });

  useEffect(() => {
    consultarDatosIniciales();
  }, []);

  return <Default
    titulo={Boolean(id) ? 'Editar Usuario' : 'Nuevo Usuario'}
    placeHolder={''}
    mostrarCabeceroFormulario
    cancelar={cancelar}
    frmId="frmUsuario">
    <FormProvider {...metodosForm}>
      <form noValidate onSubmit={handleSubmit(guardar)} id="frmUsuario">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={4}>
                <TextField
                  label="Nombre"
                  name="nombre"
                  register={register}
                  inputProps={{ maxLength: 50 }}
                  error={errors.nombre}
                  helperText={errors.nombre?.message}
                  required
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <TextField
                  label="Correo"
                  name="correo"
                  inputProps={{ maxLength: 50 }}
                  error={errors.correo}
                  register={register}
                  helperText={errors.correo?.message}
                  required
                />
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={4}>
                <TextField
                  label="Contraseña"
                  type="password"
                  name="contrasena"
                  placeHolder="Ingrese mínimo 8 carácteres"
                  register={register}
                  inputProps={{ maxLength: 50 }}
                  error={errors.contrasena}
                  helperText={errors.contrasena?.message}
                  required={!Boolean(id)}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <TextField
                  label="Confirmar Contraseña"
                  type="password"
                  name="confirmarContrasena"
                  placeHolder="Ingrese mínimo 8 carácteres"

                  register={register}
                  inputProps={{ maxLength: 50 }}
                  error={errors.confirmarContrasena}
                  helperText={errors.confirmarContrasena?.message}
                  required={!Boolean(id)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={4}>
                <TextField
                  label="Teléfono"
                  name="telefono"
                  placeHolder="Teléfono"
                  register={register}
                  inputProps={{ maxLength: 10 }}
                  error={errors.telefono}
                  helperText={errors.telefono?.message}
                  required
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <AutoComplete
                  label="Perfiles"
                  required
                  options={perfiles}
                  name="perfilId"
                  error={errors.perfilId}
                  labelProp="nombre"
                  placeHolder="Selecciona un opción"
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Select
                  label="Habilitado"
                  required
                  options={estatus}
                  name="habilitado"
                  error={errors.habilitado}
                  register={register}
                  placeHolder="Selecciona un opción"
                />
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={4}>
                <Tabla
                  hidePaginator
                  showActions={false}
                  showCheckbox
                  headers={cabeceros}
                  rows={empresas}
                  onSelectAll={seleccionarTodos}
                  onSelect={seleccionarEmpresa}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <SubirImagen imagenFirma={imagenFirma} setImagenFirma={setImagenFirma} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  </Default>;
};

export default React.memo(Usuario);