export const ESTADO_VENTA = {
  VENDIDO: 'Vendido',
  APARTADO: 'Apartado',
};

export const tipoEstadosVenta = [
  { id: 9, nombre: 'Vendido' },
  { id: 10, nombre: 'Apartado' },
  { id: -1, nombre: 'Eliminado' },
];

export default ESTADO_VENTA;
