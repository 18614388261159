import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles(() => ({
  root: {
    marginTop: 5
  },
  label: {
    marginTop: 5,
    fontSize: 12,
    fontWeight: 'bold',
  },
  input: {
    minHeight: 45,
    marginTop: 10,
    backgroundColor: paleta.textField.backgroundColor,
    borderRadius: 8,
    outline: 'none',
    width: '95%',
    padding: '10px',
    border: `1px solid ${paleta.textField.border}`,
    '&:hover, &:focus': {
      backgroundColor: paleta.textField.backgroundColorHover,
    },
    '&.disabled': {
      backgroundColor: paleta.textField.backgroundColorDisabled,
    },
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      appearance: 'none',
      margin: 0,
    }
  },
  textarea: {
    resize: 'vertical',
  },
  simbolo: {
    paddingRight: 35,
  },
  error: {
    border: `1px solid ${paleta.textField.error}`,
  },
  helperText: {
    marginTop: 5,
    marginLeft: 8,
    '&.error': {
      color: paleta.textField.error,
    },
  },
  fullWidth: {
    width: '100%',
  },
  iconContainer: {
    display: 'flex',
    position: 'absolute',
    right: '20px',
    top: '20px'
  },
  containerInputs: {
    position: 'relative'
  }
}));

export default styles;
