import { makeStyles } from '@material-ui/core';

const styles = makeStyles(() => ({
  container: {
    position: 'absolute',
    zIndex: 55,
    bottom: '2%',
    left: '2%',
    padding: '1vw',
    borderRadius: '5px',
    transformOrigin: 'bottom left',
    width: 'auto',
    pointerEvents: 'none',
  },
  simbologiaColor: {
    padding: '5px',
    border: '1px solid',
  }
}));

export default styles;