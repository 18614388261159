import React from 'react';
import { Box, LinearProgress, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Typography from '../Typography';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 18,
    borderRadius: 14,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 14,
    backgroundColor: '#1CC88A',
  },
}))(LinearProgress);

const BarraProgreso = ({ total, avance, esPorcentual, customDescripcion }) => {
  const valor = ((parseInt(avance, 10) / parseInt(total, 10) * 100) || 0).toFixed(2) || 0;

  return (
    <Box style={{ position: 'relative' }}>
      <BorderLinearProgress variant="determinate" value={+valor} />
      <Box style={{ position: 'absolute', top: 2, width: '100%' }} textAlign="center">
        <Typography>
          {esPorcentual ? `${valor}%` : customDescripcion !== '' ? `${customDescripcion}: ${avance} de ${total}` :  ` ${avance} / ${total}`}
        </Typography>
      </Box>
    </Box>
  )
}

BarraProgreso.propTypes = {
  /** Total de unidades para calcular el porcentaje de avance */
  total: PropTypes.string.isRequired,
  /** Unidades avanzadas */
  avance: PropTypes.string.isRequired,
  /** Tipo porcentual */
  esPorcentual: PropTypes.bool,
  /** Descripción personalizada */
  customDescripcion: PropTypes.string,

};

BarraProgreso.defaultProps = {
  esPorcentual: true,
  customDescripcion: '',
}

export default BarraProgreso;
