import React from 'react';
import Estatus from '../../componentes/Estatus';
import FiltrosClientes from './filtrosClientes';
import Listados from '../../contenedores/Listados';
import { CLIENTES } from '../../configuraciones/endpoints';

const cabeceros = [
  { label: 'Nombre', key: 'nombre' },
  { label: 'CURP', key: 'curp' },
  { label: 'Domicilio', key: 'domicilio' },
  { label: 'Teléfono', transform: ({ telefono }) => telefono?.trim() || '---' },
  { label: 'Celular', transform: ({ celular }) => celular?.trim() || '---' },
  { label: 'Correo', key: 'correo' },
  { label: 'Empresa', transform: ({ empresa }) => empresa?.nombre || '---' },
  {
    label: 'Estatus', transform: ({ habilitado, activo }) => (
      <Estatus activo={activo} habilitado={habilitado} />
    ),
  },
];

const ReporteClientes = ({ history, location }) => (
  <Listados
    titulo="Reporte Clientes"
    customCabecero={<FiltrosClientes />}
    history={history}
    location={location}
    cabeceros={cabeceros}
    apiKey={CLIENTES}
    hideDelete={({ activo }) => !activo}
    soloListado
    esReporte
  />
);

export default ReporteClientes;