import React, { useCallback, useEffect, useState } from 'react';
import { Button, Grid, IconButton } from '@material-ui/core';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import { FaDownLeftAndUpRightToCenter, FaUpRightAndDownLeftFromCenter } from 'react-icons/fa6';

import styles from './styles';
import Select from '../../componentes/Select';
import endpoints, { CATEGORIAS, TIPOS_LOTE } from '../../configuraciones/endpoints';
import axiosInstance from '../../configuraciones/axios';
import { ESTADOS_LOTES } from '../../constantes/tipoEstado';


const FiltroLotes = ({ proyectoId, asignarFiltros }) => {
  const classes = styles();
  const [estadosLote, setEstadosLote] = useState([]);
  const [manzanas, setManzanas] = useState([]);
  const [tiposLote, setTiposLote] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [expandir, setExpandir] = useState(false);
  const { setValue, getValues } = useFormContext();


  /**
   * Metodo para aplicar valores de los filtros
   */
  const aplicarFiltros = () => {
    const { manzana, tipoLote, categoria, disponibilidad } = getValues();
    asignarFiltros({ manzana, tipoLote, categoria, disponibilidad });
  };

  /**
   * Limpiar formulario de filtros
   */
  const limpiarFiltros = () => {
    setValue('manzana', '');
    setValue('tipoLote', '');
    setValue('categoria', '');
    setValue('disponibilidad', '');

    asignarFiltros({ manzana: '', tipoLote: '', categoria: '', disponibilidad: '' });
  };

  useEffect(() => new Promise((resolve, reject) => {
    const promesas = [
      axiosInstance.get(endpoints.manzanasPorProyecto(proyectoId)),
      axiosInstance.get(endpoints.base.opciones(TIPOS_LOTE)),
      axiosInstance.get(endpoints.base.opciones(CATEGORIAS)),
      axiosInstance.get(endpoints.estados(ESTADOS_LOTES)),
    ];

    Promise.all(promesas).then((resultadosPromesas) => {
      const [manzanasBd, tiposLoteBd, categoriasBd, estadosLoteBd] = resultadosPromesas;
      setManzanas(manzanasBd);
      setTiposLote(tiposLoteBd);
      setCategorias(categoriasBd);
      setEstadosLote(estadosLoteBd);
      resolve();
    }).catch(reject);
  }), []);

  return (
    <Grid container spacing={1} className={classes.container}>
      <Grid item xs={12} className={classes.header} >
        <h3 fullWidth>Filtros de mapa</h3>
        <IconButton size="small" onClick={() => { setExpandir(!expandir) }} className={classes.fullscreenButton}>
          {expandir ? <FaDownLeftAndUpRightToCenter /> : <FaUpRightAndDownLeftFromCenter />}
        </IconButton>
      </Grid>
      {
        expandir && <>
          <Grid item lg={12} md={6} xs={6}>
            <Select
              label="Disponibilidad:"
              name="disponibilidad"
              placeHolder="Seleccionar"
              labelProp={'nombre'}
              options={estadosLote} />
          </Grid>
          <Grid item lg={12} md={6} xs={6}>
            <Select
              label="Manzana:"
              name="manzana"
              placeHolder="Seleccionar"
              labelProp={'nombre'}
              options={manzanas} />
          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            <Select
              label="Tipo lote:"
              name="tipoLote"
              labelProp={'nombre'}
              placeHolder="Seleccionar"
              options={tiposLote} />
          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            <Select
              label="Categoría:"
              name="categoria"
              labelProp={'nombre'}
              placeHolder="Seleccionar"
              options={categorias} />
          </Grid>
          <Grid item xs={6}>
            <Button fullWidth size="small" variant='outlined' className={clsx(classes.btn, classes.filtrar)} onClick={() => aplicarFiltros()}>
              Filtrar
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button fullWidth size="small" variant='outlined' className={clsx(classes.btn, classes.filtrar)} onClick={() => limpiarFiltros()}>
              Limpiar
            </Button>
          </Grid>
        </>
      }

    </Grid>
  );
};

export default FiltroLotes;
