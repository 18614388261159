import React from 'react'
import { Chip } from '@material-ui/core';
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';
import clsx from 'clsx';

import Listados from '../../contenedores/Listados';
import { VENTAS } from '../../configuraciones/endpoints';
import paleta from '../../configuraciones/paleta';
import { formatoMoneda } from '../../utilidades/functions';
import FiltrosVenta from './filtros';
import styles from './styles';

const CheckBox = ({ checked }) => (
  <>
    {
      checked ? <MdCheckBox size={20} color={paleta.bar.primary} /> :
        <MdCheckBoxOutlineBlank size={20} color={paleta.bar.primary} />
    }
  </>
)

const Ventas = ({ history, location }) => {
  const classes = styles();

  const cabeceros = [
    { label: '#', key: 'id', className: classes.header },
    { label: 'Emp.', transform: ({ lote }) => lote?.manzana?.proyecto?.empresa?.nombre },
    { label: 'Proyecto', transform: ({ lote }) => lote?.manzana?.proyecto?.nombre },
    { label: 'Manzana', transform: ({ lote }) => lote?.manzana?.nombre },
    { label: 'Lote', transform: ({ lote }) => lote?.numero },
    { label: 'Área', transform: ({ lote }) => lote?.area },
    { label: 'Fecha', key: 'fecha', width: 100 },
    { label: 'Cliente', transform: ({ cliente }) => cliente?.nombre },
    { label: 'Vendedor', transform: ({ vendedor }) => vendedor?.nombre },
    { label: 'F.Canc.', transform: ({ fechaCanc }) => fechaCanc || 'N/A' },
    { label: 'Anti.', transform: ({ anticipoMinimo }) => formatoMoneda(anticipoMinimo) },
    { label: 'A.Pag.', transform: ({ anticipo }) => formatoMoneda(anticipo) },
    { label: 'Crédito', transform: ({ credito }) => <CheckBox checked={credito} /> },
    { label: 'Total', transform: ({ total }) => formatoMoneda(total) },
    { label: 'Pagado', transform: ({ pagado }) => pagado ? formatoMoneda(pagado) : '---' },
    {
      label: 'Cobrado', transform: ({ total, pagado }) => {
        return (<CheckBox checked={total === pagado} />)
      }
    },
    {
      label: 'Estatus', align: 'center', transform: ({ estado, activo }) => (
        <Chip
          label={activo ? estado.nombre : 'Eliminado'}
          size="small"
          className={clsx(classes.chip, activo ? estado.nombre : 'Eliminado')}
        />
      ),
    },
  ];

  return <Listados
    titulo="Reporte Ventas"
    customCabecero={<FiltrosVenta />}
    history={history}
    location={location}
    cabeceros={cabeceros || []}
    apiKey={VENTAS}
    soloListado
    esReporte
  />;
}

export default Ventas;
