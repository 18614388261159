import {
  createStore, applyMiddleware, compose,
  combineReducers,
} from 'redux';
import thunk from 'redux-thunk';
import user from './user';
import ajax from './ajax';
import appbar from './appbar';
import listados from './listados';

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
  user,
  ajax,
  appbar,
  listados,
});

const store = createStore(
  reducers,
  storeEnhancers(applyMiddleware(thunk)),
);

export default store;
