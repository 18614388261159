/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify/dist';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { Grid } from '@material-ui/core';

import styles from './styles';

import Default from '../../contenedores/Default';
import TextField from '../../componentes/TextField';
import CustomSelect from '../../componentes/Select';

import endpoints, { EMPRESAS } from '../../configuraciones/endpoints';
import axios from '../../configuraciones/axios';

import { trim } from '../../utilidades/functions';
import { emailRegex, regexMessages, rfcRegex } from '../../utilidades/regex';

import { CAMPO_REQUERIDO, FORMATO_CORREO_REQUERIDO, ELEMENTO_NO_ENCONTRADO} from '../../constantes/mensajes';
import estatus from '../../constantes/estatus';

const schema = yup.object({
  nombre: yup.string().required(CAMPO_REQUERIDO),
  razonSocial: yup.string().required(CAMPO_REQUERIDO),
  rfc:
    yup.string()
      .required(CAMPO_REQUERIDO)
      .matches(
        rfcRegex,
        { message: regexMessages.RFC }
      ),
  domicilio: yup.string().required(CAMPO_REQUERIDO),
  telefono:
    yup.number()
    .max(9999999999, regexMessages.LONGITUD_TELEFONO)
    .transform((value) => (isNaN(value) ? undefined : value))
    .required(CAMPO_REQUERIDO),
  correo: 
    yup.string()
    .matches(
      emailRegex,
      {
        message: FORMATO_CORREO_REQUERIDO,
        excludeEmptyString: true,
      })
    .required(CAMPO_REQUERIDO),
  habilitado: yup.boolean(),
});

const Empresa = ({ history, match }) => {

  const { params: { id } } = match;
  const classes = styles();

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      habilitado: true,
    },
  });
  const {
    handleSubmit,
    reset,
    formState: {
      errors,
    },
  } = methods;

  /**
   * La función "regresar" navega a la página '/catalogos/empresas' con un parámetro de consulta
   * 'pagina' basado en el estado de la página anterior.
   */
  const regresar = () => {
    const { location: { state: { pagina } } } = history;
    history.push({
      pathname: '/catalogos/empresas',
      search: pagina && pagina > 1 ? `?pagina=${pagina}` : '',
    });
  };

  /**
   * La función `consultarDatosIniciales` recupera datos iniciales de una empresa de una base de datos
   * y establece valores específicos basados en los datos recuperados.
   */
  const consultarDatosIniciales = () => new Promise((resolve, reject) => {
    const promesas = [];
    if (id) promesas.push(axios.get(endpoints.base.url(EMPRESAS, id)));
    Promise.all(promesas).then((resultadosPromesas) => {
      const [empresaDb] = resultadosPromesas;
      if (empresaDb?.id) {
        reset({
          ...empresaDb,
        });
      } else if (id) {
        toast.warning(ELEMENTO_NO_ENCONTRADO);
        regresar();
      }
      resolve();
    }).catch(reject);
  });

  useEffect(async () => {
    consultarDatosIniciales();
  }, [id]);

  const cancelar = useCallback(regresar, []);

  /* El código realiza una solicitud PUT usando Axios para actualizar el objeto 
  `empresa` en un punto final específico. Si `id` no está definido, realiza una
  solicitud POST para crear un nuevo objeto `empresa` en el mismo punto final.
  */
  const guardar = useCallback((empresa) => {
    const metodo = id ? 'put' : 'post';

    axios[metodo](endpoints.base.url(EMPRESAS, id), trim(empresa)).then(regresar).catch(() => { });
  });

  return (
    <Default
      titulo={Boolean(id) ? 'Editar empresa' : 'Nueva empresa'}
      mostrarCabeceroFormulario
      cancelar={cancelar}
      frmId="formEmpresa"
    >
      <FormProvider {...methods}>
        <form id="formEmpresa" onSubmit={handleSubmit(guardar)}>
          <Grid container className={classes.generales}>
            {/* Datos generales de la empresa */}
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    label="Nombre: *"
                    name="nombre"
                    placeHolder="Nombre"
                    inputProps={{ maxLength: 100 }}
                    error={errors.nombre}
                    helperText={errors.nombre?.message}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    label="Razón social: *"
                    name="razonSocial"
                    placeHolder="Razón social"
                    inputProps={{ maxLength: 250 }}
                    error={errors.razonSocial}
                    helperText={errors.razonSocial?.message}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    label="RFC: *"
                    name="rfc"
                    placeHolder="R.F.C."
                    inputProps={{ maxLength: 13 }}
                    error={errors.rfc}
                    helperText={errors.rfc?.message}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={9}>
                  <TextField
                    label="Domicilio: *"
                    name="domicilio"
                    placeHolder="Domicilio"
                    inputProps={{ maxLength: 250 }}
                    error={errors.domicilio}
                    helperText={errors.domicilio?.message}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <TextField
                    label="Teléfono: *"
                    name="telefono"
                    placeHolder="Teléfono"
                    inputProps={{ min: 0 }}
                    error={errors.telefono}
                    helperText={errors.telefono?.message}
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <TextField
                    label="Correo: *"
                    name="correo"
                    placeHolder="Correo"
                    inputProps={{ maxLength: 50 }}
                    error={errors.correo}
                    helperText={errors.correo?.message}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <CustomSelect
                    label="Estado:"
                    name="habilitado"
                    placeHolder="Habilitado"
                    options={estatus}
                    error={errors.habilitado}
                    helperText={errors.habilitado?.message}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Default>
  );
};

export default React.memo(Empresa);