import React from 'react';
import Default from '../../contenedores/Default';
import styles from './styles';

const Dashboard = () => {
  const classes = styles();

  return (
    <Default>
      <div className={classes.root}>
        <img
          alt="logo"
          src="/assets/images/logoSINfondo.svg"
          className={classes.img}
        />
      </div>
    </Default>
  );
}

export default Dashboard;
