import React from 'react';
import { useDropzone } from 'react-dropzone';
import { LuImagePlus } from 'react-icons/lu';
import { IoMdClose } from 'react-icons/io';
import { FaFileCircleCheck, FaFileCirclePlus } from 'react-icons/fa6';
import { toast } from 'react-toastify';
import propTypes from 'prop-types';

import { Typography, IconButton, Box } from '@material-ui/core';

import estilos from './estilos';
import { TAMANIO_EXCEDIDO, TIPO_ARCHIVO_NO_ACEPTADO } from '../../constantes/mensajes';

const SubirImagen = ({ imagenFirma, setImagenFirma, setTipoArchivo, label, tambienDocumentos, tamanioMB = 2 }) => {
  const clases = estilos();

  const eliminarImagen = () => {
    setImagenFirma(null);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (archivosAceptados, fileRejections) => {
      if (archivosAceptados && archivosAceptados.length > 0) {
        const archivo = archivosAceptados[0];

        if (
          (archivo.type !== "image/jpeg" &&
            archivo.type !== "image/png") &&
          (!tambienDocumentos ||
            (archivo.type !== "application/pdf" &&
              archivo.type !== "application/msword" &&
              archivo.type !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document"))
        ) {
          const extension = archivo.name.split('.').pop().toLowerCase();
          toast.warn(TIPO_ARCHIVO_NO_ACEPTADO(extension));
          return;
        }

        const lector = new FileReader();
        lector.onload = () => {
          setImagenFirma(lector.result);
          setTipoArchivo({tipo: archivo.type, nombre: archivo.path});
        };
        lector.readAsDataURL(archivo);
      }

      if (fileRejections && fileRejections.length > 0) {
        fileRejections.forEach((rejectedFile) => {
          rejectedFile.errors.forEach((err) => {
            if (err.code === "file-too-large") {
              toast.warn(TAMANIO_EXCEDIDO(tamanioMB));
            }
          });
        });
      }
    },
    accept: 'image/jpg, image/png',
    multiple: false,
    maxSize: 14 * 1024 * 1024, // Tamaño en MB
  });


  return (
    <div className={clases.contenedorCargadorImagenes}>
      <Typography className={clases.label} >{label}</Typography>
      {imagenFirma ? (

        tambienDocumentos ?
          <Box className={clases.previsualizacionImagen}>
            <FaFileCircleCheck className={clases.iconoSubir} />
          </Box>
          :
          <Box className={clases.previsualizacionImagen}>
            <IconButton onClick={eliminarImagen} className={clases.botonCerrar}>
              <IoMdClose />
            </IconButton>
            <img src={imagenFirma} alt="Seleccionada" className={clases.imagenSeleccionada} />
          </Box>

      ) : (
        <Box {...getRootProps()} className={clases.dropzone}>
          <input {...getInputProps()} />
          {tambienDocumentos ? <FaFileCirclePlus className={clases.iconoSubir} /> : <LuImagePlus className={clases.iconoSubir} />}
          <Typography variant="body1" className={clases.textoSubir}> {tambienDocumentos ? "Subir documento" : "Subir imagen"}</Typography>
          <Typography variant="body2" className={clases.subtextoSubir}> {tambienDocumentos ? `Formato PDF, DOCX, JPG, PNG, tamaño máximo de ${tamanioMB} MB` : `Formato JPG, JPEG o PNG, tamaño máximo de ${tamanioMB} MB`}</Typography>
        </Box>
      )}
    </div>
  );
};

SubirImagen.propTypes = {
  imagenFirma: propTypes.string || propTypes.array,
  setImagenFirma: propTypes.func,
  label: propTypes.string,
  tambienDocumentos: propTypes.bool,
  setTipoArchivo: propTypes.func,
}

SubirImagen.defaultProps = {
  imagenFirma: null,
  setImagenFirma: null,
  setTipoArchivo: () => {},
  label: 'Firma del usuario:',
  tambienDocumentos: false
}

export default SubirImagen;
