import React from 'react';
import Estatus from '../../componentes/Estatus';
import Listados from '../../contenedores/Listados';
import FiltrosComponent from '../../componentes/FiltrosComponent';

import { USUARIOS } from '../../configuraciones/endpoints';

const cabeceros = [
  { label: 'Nombre', key: 'nombre' },
  { label: 'Correo', key: 'correo' },
  { label: 'Perfil', transform: ({ perfil }) => perfil.nombre || '' },
  { label: 'Teléfono', key: 'telefono' },
  { label: 'Empresas', transform: ({ numeroEmpresas }) => numeroEmpresas },
  {
    label: 'Estatus', transform: ({ habilitado, activo }) => (
      <Estatus habilitado={habilitado} activo={activo} />
    ),
  },
];

const Usuarios = ({ history, location }) => {
  return <Listados
    titulo="Usuarios"
    customCabecero={<FiltrosComponent />}
    history={history}
    location={location}
    showActions
    cabeceros={cabeceros || []}
    apiKey={USUARIOS}
  />;
};

export default Usuarios;