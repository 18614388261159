/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import { Divider, Grid } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import axios from '../../configuraciones/axios';
import endpoints, { EMPRESAS, PROYECTOS } from '../../configuraciones/endpoints';

import styles from './styles';
import Default from '../../contenedores/Default';
import validations from '../../utilidades/regex';
import { trim } from '../../utilidades/functions';

import Typography from '../../componentes/Typography';
import TextField from '../../componentes/TextField';
import CustomSelect from '../../componentes/Select';
import AutoComplete from '../../componentes/AutoComplete';

import { CAMPO_REQUERIDO, ELEMENTO_NO_ENCONTRADO } from '../../constantes/mensajes';
import estatus from '../../constantes/estatus';
import { tipoPago } from '../../constantes/tipoPago';

const schema = yup.object({
    nombre: yup.string().required(CAMPO_REQUERIDO),
    clave: yup.string().required(CAMPO_REQUERIDO),
    fecha: yup.string().required(CAMPO_REQUERIDO),
    descripcion: yup.string().required(CAMPO_REQUERIDO),
    parcela: yup.string().required(CAMPO_REQUERIDO),
    ubicacion: yup.string().required(CAMPO_REQUERIDO),
    empresaId: yup.number().required(CAMPO_REQUERIDO),
    color: yup.string(),
    anticipoFijo: yup.boolean(),
    cantidadPorcentajeAnticipo:
        yup.number()
            .max(100, validations.PORCENTAJE_MAXIMO(100))
            .transform((value) => (isNaN(value) ? undefined : value))
            .when('anticipoFijo', ([anticipoFijo], field) => {
                return (!anticipoFijo ? field.required(CAMPO_REQUERIDO) : field)
            }),
    cantidadAnticipoFijo:
        yup.number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .when('anticipoFijo', ([anticipoFijo], field) => {
                return (anticipoFijo ? field.required(CAMPO_REQUERIDO) : field)
            }),
    apartadoMinimo:
        yup.number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .required(CAMPO_REQUERIDO),
    comisionFija: yup.boolean(),
    cantidadPorcentajeComision:
        yup.number()
            .max(100, validations.PORCENTAJE_MAXIMO(100))
            .transform((value) => (isNaN(value) ? undefined : value))
            .when('comisionFija', ([comisionFija], field) => {
                return (!comisionFija ? field.required(CAMPO_REQUERIDO) : field)
            }),
    cantidadComisionFija:
        yup.number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .when('comisionFija', ([comisionFija], field) => {
                return (comisionFija ? field.required(CAMPO_REQUERIDO) : field)
            }),
    apartadoComision:
        yup.number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .required(CAMPO_REQUERIDO),
    mesesSinIntereses:
        yup.number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .required(CAMPO_REQUERIDO),
    mesesConIntereses:
        yup.number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .required(CAMPO_REQUERIDO),
    porcentajeInteresAnual:
        yup.number()
            .max(100, validations.PORCENTAJE_MAXIMO(100))
            .transform((value) => (isNaN(value) ? undefined : value))
            .required(CAMPO_REQUERIDO),
    porcentajeInteresMoratorio:
        yup.number()
            .max(100, validations.PORCENTAJE_MAXIMO(100))
            .transform((value) => (isNaN(value) ? undefined : value))
            .required(CAMPO_REQUERIDO),
    diasAnticipo:
        yup.number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .required(CAMPO_REQUERIDO),
    porcentajeCancelacion:
        yup.number()
            .max(100, validations.PORCENTAJE_MAXIMO(100))
            .transform((value) => (isNaN(value) ? undefined : value))
            .required(CAMPO_REQUERIDO),
    habilitado: yup.boolean().oneOf([true, false]).required(CAMPO_REQUERIDO),
    representante: yup.string().required(CAMPO_REQUERIDO),

});

const Proyecto = ({ history, match }) => {
    const [empresas, setEmpresas] = useState([]);
    const { params: { id } } = match;
    const classes = styles();

    const methods = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            habilitado: true,
            color: '#000000',
            anticipoFijo: false,
            comisionFija: false,
        }
    });

    const {
        watch,
        handleSubmit,
        formState: { errors },
        reset,
    } = methods;

    const anticipoFijo = watch('anticipoFijo');
    const comisionFija = watch('comisionFija');

    /* Restablecer valores según las condiciones de `anticipoFijo`. */
    useEffect(() => {
        const prop = anticipoFijo ? 'cantidadPorcentajeAnticipo' : 'cantidadAnticipoFijo';
        reset((actual) => ({ ...actual, [prop]: '' }));
    }, [anticipoFijo]);
    /* Restablecer valores según las condiciones de `comisionFija`. */
    useEffect(() => {
        const prop = comisionFija ? 'cantidadPorcentajeComision' : 'cantidadComisionFija';
        reset((actual) => ({ ...actual, [prop]: '' }));
    }, [comisionFija]);

    /*Regresa a la pagina de proyectos donde se muestra el listado */
    const regresar = () => {
        const { location: { state: { pagina } } } = history;
        history.push({
            pathname: '/catalogos/proyectos',
            search: pagina && pagina > 1 ? `?pagina=${pagina}` : '',
        });
    };

    /**
      * Realiza una petición POST o PUT para afectar Proyecto
      * en la base de datos.
      */
    const guardar = useCallback((proyecto) => {
        const metodo = id ? 'put' : 'post';
        axios[metodo](endpoints.crearActualizar(PROYECTOS, id), trim(proyecto)).then(regresar).catch(() => { });
    }, []);

    const cancelar = useCallback(regresar, []);

    /**
   * Consulta los datos iniciales del formulario de proyecto.
   * @returns Promesa con los objetos consultados.
   */
    const consultarDatosIniciales = () => new Promise((resolve, reject) => {
        const promesas = [
            axios.get(endpoints.base.opciones(EMPRESAS)),
        ];
        if (id) promesas.push(axios.get(endpoints.base.url(PROYECTOS, id)));
        Promise.all(promesas).then((resultadosPromesas) => {
            const [empresasBd, proyectosBd] = resultadosPromesas;
            setEmpresas(empresasBd);

            if (proyectosBd?.id) {
                reset({
                    ...proyectosBd,
                    cantidadPorcentajeAnticipo: !proyectosBd.anticipoFijo ? proyectosBd.cantidadAnticipo : undefined,
                    cantidadAnticipoFijo: proyectosBd.anticipoFijo ? proyectosBd.cantidadAnticipo : undefined,
                    cantidadPorcentajeComision: !proyectosBd.comisionFija ? proyectosBd.cantidadComision : undefined,
                    cantidadComisionFija: proyectosBd.comisionFija ? proyectosBd.cantidadComision : undefined,
                });
            } else if (id) {
                toast.warning(ELEMENTO_NO_ENCONTRADO);
                regresar();
            }

            resolve();
        }).catch(reject);
    });

    useEffect(() => {
        consultarDatosIniciales();
    }, [id]);

    return <Default
        titulo={Boolean(id) ? 'Editar Proyecto' : 'Nuevo Proyecto'}
        placeHolder={''}
        mostrarCabeceroFormulario
        cancelar={cancelar}
        frmId="frmProyecto">
        <FormProvider {...methods}>
            <form noValidate onSubmit={handleSubmit(guardar)} id="frmProyecto">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} lg={4}>
                                <TextField
                                    label="Nombre:"
                                    name="nombre"
                                    inputProps={{ maxLength: 50 }}
                                    error={errors.nombre}
                                    helperText={errors.nombre?.message}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <TextField
                                    label="Clave:"
                                    name="clave"
                                    inputProps={{ maxLength: 10 }}
                                    error={errors.clave}
                                    helperText={errors.clave?.message}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <TextField
                                    label="Fecha:"
                                    name="fecha"
                                    inputProps={{ maxLength: 50 }}
                                    error={errors.fecha}
                                    helperText={errors.fecha?.message}
                                    required
                                    type="date"
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={4}>
                            <Grid item xs={12} lg={4}>
                                <TextField
                                    label="Descripción:"
                                    name="descripcion"
                                    type="textarea"
                                    rows={8}
                                    inputProps={{ maxLength: 250 }}
                                    error={errors.descripcion}
                                    helperText={errors.descripcion?.message}
                                    required
                                />
                            </Grid>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Nº de Parcela:"
                                            name="parcela"
                                            inputProps={{ maxLength: 30 }}
                                            error={errors.parcela}
                                            helperText={errors.parcela?.message}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <AutoComplete
                                            name="empresaId"
                                            label="Empresa:"
                                            options={empresas}
                                            placeHolder="Selecciona un opción"
                                            error={errors.empresaId}
                                            required
                                        />
                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Ubicación:"
                                            name="ubicacion"
                                            inputProps={{ maxLength: 100 }}
                                            error={errors.ubicacion}
                                            helperText={errors.ubicacion?.message}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={8} lg={9}>
                                        <TextField
                                            label="Representante:"
                                            name="representante"
                                            placeHolder="Representante"
                                            inputProps={{ maxLength: 50 }}
                                            error={errors.representante}
                                            required
                                            helperText={errors.representante?.message}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4} lg={3}>
                                        <TextField
                                            label="Color:"
                                            name="color"
                                            placeHolder=""
                                            inputProps={{ maxLength: 10 }}
                                            error={errors.color}
                                            helperText={errors.color?.message}
                                            required
                                            type="color"
                                        />
                                    </Grid>
                                    
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* Datos de la administración de la empresa */}
                        <Grid item xs={12}>
                            <Grid container spacing={1} className={classes.administracion}>
                                <Grid item xs={12}>
                                    <Typography bold variant="subtitle2">Administración:</Typography>
                                    <Divider className={classes.marginbottom} />
                                </Grid>
                                <Grid item xs={12} md={3} lg={3}>
                                    <CustomSelect
                                        label="Tipo anticipo: *"
                                        name="anticipoFijo"
                                        options={tipoPago}
                                        placeHolder="Porcentaje"
                                        error={errors.anticipoFijo}
                                        helperText={errors.anticipoFijo?.message}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3} lg={3}>
                                    <TextField
                                        label="Porcentaje anticipo: *"
                                        name="cantidadPorcentajeAnticipo"
                                        placeHolder="% Anticipo"
                                        inputProps={{ min: 0 }}
                                        error={errors.cantidadPorcentajeAnticipo}
                                        helperText={errors.cantidadPorcentajeAnticipo?.message}
                                        disabled={anticipoFijo === true}
                                        type="number"
                                    />
                                </Grid>
                                <Grid item xs={12} md={3} lg={3}>
                                    <TextField
                                        label="Anticipo fijo: *"
                                        name="cantidadAnticipoFijo"
                                        placeHolder="$ Anticipo"
                                        inputProps={{ min: 0 }}
                                        error={errors.cantidadAnticipoFijo}
                                        helperText={errors.cantidadAnticipoFijo?.message}
                                        disabled={anticipoFijo === false}
                                        type="number"
                                    />
                                </Grid>
                                <Grid item xs={12} md={3} lg={3}>
                                    <TextField
                                        label="Apartado mínimo: *"
                                        name="apartadoMinimo"
                                        placeHolder="$ Apartado mínimo"
                                        inputProps={{ min: 0 }}
                                        error={errors.apartadoMinimo}
                                        helperText={errors.apartadoMinimo?.message}
                                        type="number"
                                    />
                                </Grid>
                                <Grid item xs={12} md={3} lg={3}>
                                    <CustomSelect
                                        label="Tipo comisión: *"
                                        name="comisionFija"
                                        options={tipoPago}
                                        placeHolder="Porcentaje"
                                        error={errors.comisionFija}
                                        helperText={errors.comisionFija?.message}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3} lg={3}>
                                    <TextField
                                        label="Porcentaje comisión: *"
                                        name="cantidadPorcentajeComision"
                                        placeHolder="% Comisión"
                                        inputProps={{ min: 0 }}
                                        error={errors.cantidadPorcentajeComision}
                                        helperText={errors.cantidadPorcentajeComision?.message}
                                        disabled={comisionFija === true}
                                        type="number"
                                    />
                                </Grid>
                                <Grid item xs={12} md={3} lg={3}>
                                    <TextField
                                        label="Comisión fija: *"
                                        name="cantidadComisionFija"
                                        placeHolder="$ Comisión"
                                        inputProps={{ min: 0 }}
                                        error={errors.cantidadComisionFija}
                                        helperText={errors.cantidadComisionFija?.message}
                                        disabled={comisionFija === false}
                                        type="number"
                                    />
                                </Grid>
                                <Grid item xs={12} md={3} lg={3}>
                                    <TextField
                                        label="Apartado comisión: *"
                                        name="apartadoComision"
                                        placeHolder="$ Apartado comisión"
                                        inputProps={{ min: 0 }}
                                        error={errors.apartadoComision}
                                        helperText={errors.apartadoComision?.message}
                                        type="number"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={2} lg={2}>
                                    <TextField
                                        label="Meses sin intereses: *"
                                        name="mesesSinIntereses"
                                        placeHolder="# Meses"
                                        inputProps={{ min: 0 }}
                                        error={errors.mesesSinIntereses}
                                        helperText={errors.mesesSinIntereses?.message}
                                        type="number"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={2} lg={2}>
                                    <TextField
                                        label="Meses con intereses: *"
                                        name="mesesConIntereses"
                                        placeHolder="# Meses"
                                        inputProps={{ min: 0 }}
                                        error={errors.mesesConIntereses}
                                        helperText={errors.mesesConIntereses?.message}
                                        type="number"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={2} lg={2}>
                                    <TextField
                                        label="Porcentaje interes anual: *"
                                        name="porcentajeInteresAnual"
                                        placeHolder="% Anual"
                                        inputProps={{ maxLength: 50 }}
                                        error={errors.porcentajeInteresAnual}
                                        helperText={errors.porcentajeInteresAnual?.message}
                                        type="number"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={2} lg={2}>
                                    <TextField
                                        label="Porcen. interés moratorio: *"
                                        name="porcentajeInteresMoratorio"
                                        inputProps={{ min: 0 }}
                                        error={errors.porcentajeInteresMoratorio}
                                        helperText={errors.porcentajeInteresMoratorio?.message}
                                        type="number"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={2} lg={2}>
                                    <TextField
                                        label="Días anticipo: *"
                                        name="diasAnticipo"
                                        placeHolder="# Días"
                                        inputProps={{ min: 0 }}
                                        error={errors.diasAnticipo}
                                        helperText={errors.diasAnticipo?.message}
                                        type="number"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={2} lg={2}>
                                    <TextField
                                        label="Porcentaje cancelación: *"
                                        name="porcentajeCancelacion"
                                        placeHolder="% Cancelación"
                                        inputProps={{ min: 0 }}
                                        error={errors.porcentajeCancelacion}
                                        helperText={errors.porcentajeCancelacion?.message}
                                        type="number"
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} lg={4}>
                                    <CustomSelect
                                        label="Estado:"
                                        name="habilitado"
                                        placeHolder="Habilitado"
                                        options={estatus}
                                        error={errors.habilitado}
                                        helperText={errors.habilitado?.message}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>

        </FormProvider>
    </Default>;
};

export default React.memo(Proyecto);