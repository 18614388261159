import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Grid } from '@material-ui/core';

import axios from '../../configuraciones/axios';
import endpoints, { EMPRESAS } from '../../configuraciones/endpoints';

import AccionesCabecero from '../../componentes/AccionesCabecero';

import AutoComplete from '../../componentes/AutoComplete';
import TextField from '../../componentes/TextField';

const FiltrosClientes = () => {
  const [empresas, setEmpresas] = useState([]);

  const consultarEmpresas = async () => {
    const bdEmpresas = await axios.get(endpoints.base.opciones(EMPRESAS));
    setEmpresas(bdEmpresas);
  };

  useEffect(() => consultarEmpresas(), []);

  return (
    <Grid
      container
      justifyContent="flex-end"
      alignItems="flex-end"
      spacing={1}
      style={{ marginBottom: 20 }}
    >
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <AutoComplete
          name="empresaId"
          label="Empresa:"
          options={empresas}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <TextField
          fullWidth
          name="busqueda"
          placeHolder="Búsqueda por nombre"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <AccionesCabecero />
      </Grid>
    </Grid>
  );
};

export default FiltrosClientes;
