import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles(() => ({
  formControl: {
    '& .MuiInputBase-root': {
      backgroundColor: '#F5F8FA',
      padding: '0px 10px',
      border: '1px solid #E1E7ED',
      minHeight: 45,
      marginTop: 10,
      borderRadius: 8,
      '& .MuiInputBase-input': {
        height: '100%',
        '&::placeholder': {
          opacity: 1,
        },
      },
      '&::before, &::after': {
        display: 'none',
      },
      '& .MuiAutocomplete-clearIndicator': {
        marginTop: 2,
      }
    },
  },
  label: {
    marginTop: 5,
    fontSize: 12,
    fontWeight: 'bold',
  },
  error: {
    '& .MuiAutocomplete-inputRoot': {
      border: `1px solid ${paleta.textField.error}`,
    },
    '& .MuiFormHelperText-root': {
      // position: 'absolute',
      fontSize: '.75rem',
      // bottom: -24,
    },
  },
  helperText: {
    marginTop: 5,
    marginLeft: 8,
    '&.error': {
      color: paleta.textField.error,
    },
  },
  fullWidth: {
    width: '100%',
  },
}));

export default styles;
