import { Grid } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form';
import Tabla from '../../componentes/Table'
import FiltrosLotes from '../Lotes/FiltrosLotes';
import endpoints, { LOTES } from '../../configuraciones/endpoints';
import general from '../../configuraciones/general';
import axios from '../../configuraciones/axios';
import { FaCartPlus } from 'react-icons/fa';
import Typography from '../../componentes/Typography';
import Modal from '../../componentes/Modal';
import Button from '../../componentes/Button';
import styles from './styles';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import EstadosLoteEnum from '../../constantes/estadosLoteEnum';

const cabeceros = [
  { label: 'No.', key: 'numero' },
  { label: 'Clave', key: 'clave' },
  { label: 'Empresa', transform: ({ manzana }) => manzana.proyecto.empresa.nombre },
  { label: 'Proyecto', transform: ({ manzana }) => manzana.proyecto.nombre },
  { label: 'Manzana', transform: ({ manzana }) => manzana.nombre },
  { label: 'Tipo', transform: ({ tipoLote }) => tipoLote.nombre },
  { label: 'Categoría', transform: ({ categorium }) => categorium.nombre },
  { label: 'Frente', key: 'frente' },
  { label: 'Fondo', key: 'fondo' },
  { label: 'L. IZQ', key: 'lateralIzquierdo' },
  { label: 'L. DER', key: 'lateralDerecho' },
  { label: 'Área', key: 'area' },
  { label: 'Cons.', key: 'construccion' },
  { label: `$ m²`, key: 'precioM2' },
  { label: 'Total', key: 'precioTotal' },
];

const BusquedaLotes = ({ abierto, handleClose, seleccionarLote }) => {
  const classes = styles();
  const [lotes, setLotes] = useState();
  const paginaRef = useRef(1);

  const metodos = useForm();
  const {
    handleSubmit,
  } = metodos;
  const history = useHistory();
  const buscar = useCallback((filtros) => {

    axios
      .get(endpoints.base.url(LOTES), {
        params: {
          ...filtros,
          estadoId: EstadosLoteEnum.Disponible,
          registrosPorPagina: general.ELEMENTOS_POR_PAGINA,
          pagina: paginaRef.current,
          esVenta: true,
        },
      })
      .then((e) => {
        if (e.rows.length === 0 && paginaRef.current > 1) {
          history.location.push({ search: `?pagina=${paginaRef.current - 1}` });
        }
        setLotes(e);
      });
  }, [history.location]);

  useEffect(() => {
    const query = queryString.parse(history.location.search);
    paginaRef.current = query.pagina;
    buscar();
  }, [history.location, buscar]);

  return (
    <Modal
      open={abierto}
      handleClose={handleClose}
      maxWidth="lg"
      showTitle={false}
    >
      <Grid container>
        <FormProvider {...metodos}>
          <form id="frmBusqueda" onSubmit={handleSubmit(buscar)}>
            <Grid container alignItems="center">
              <Grid item xs={10}>
                <Typography variant="h6">Buscar Lote</Typography>
              </Grid>
              <Grid item xs>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Button
                      variant="outlined"
                      label="Cancelar"
                      onClick={handleClose}
                      classesCustom={{
                        boton: classes.btnCancelar
                      }}
                    />
                  </Grid>
                </Grid>

              </Grid>
            </Grid>
            <FiltrosLotes mostrarEstados={false} />
            <Tabla
              headers={cabeceros}
              rows={lotes?.rows || []}
              count={lotes?.count || 0}
              moreActions={[
                {
                  onClick: seleccionarLote,
                  icon: <FaCartPlus />,
                }
              ]}
              customClass={classes.tblLotes}
            />
          </form>
        </FormProvider>
      </Grid>
    </Modal>
  )
}

export default BusquedaLotes