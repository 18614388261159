export const CAMPO_REQUERIDO = 'Este campo es requerido.';
export const CAMPO_NUMERO_POSITIVO = 'El número capturado debe de ser mayor a 0.';
export const FORMATO_CORREO_REQUERIDO = 'El correo ingresado no cumple con el formato correcto.';
export const FECHA_NACIMIENTO_INCORRECTA = 'La fecha de nacimiento no puede ser mayor a la fecha actual.';
export const MIN_CAMPO = (min) => `El campo debe tener un valor mínimo de ${min}.`;
export const MAX_CAMPO = (max) => `El campo debe tener un valor máximo de ${max}.`;
export const MAX_LONGITUD = (longitud) => `El campo no debe exceder los ${longitud} caracteres.`;
export const ELEMENTO_NO_ENCONTRADO = 'El elemento no ha sido encontrado.';
export const CONTRASENA_LONGITUD = 'La contraseña no cumple con la longitud mínima';
export const VALIDAR_SELECCION_REPORTE = 'Debe seleccionar al menos un campo en cada sección';
export const CONTRASENA_OLVIDADA_ETIQUETA = 'Ingrese su correo electrónico y le enviaremos instrucciones para restablecer su contraseña.'
export const CORREO_ENVIADO_REINICIO_CONTRA = (correo) => (<>Te hemos enviado un correo de confirmación a la dirección <strong>{correo}</strong>, para recuperar la contraseña.</>);
export const CORREO_INVALIDO = 'Dirección de correo inválida';
export const CORREO_NO_REGISTRADO = 'Dirección de correo no registrada';
export const CONTRASENAS_NO_COINCIDEN = 'Las contraseñas deben coincidir';
export const LOGIN_ERROR = 'Usuario y/o contraseña erroneos, valida de nuevo';
export const LOTE_REQUERIDO = 'Es necesario seleccionar un lote para continuar.';
export const ANTICIPO_MINIMO = 'El anticipo capturado es menor al anticipo mínimo.';
export const ANTICIPO_MAXIMO = 'El anticipo capturado es mayor del total de la venta.';
export const TOTAL_INCORRECTO = 'El total de la venta es menor a cero, favor de verificar.';
export const PERFIL_SIN_PERMISOS = 'Es necesario seleccionar al menos un módulo.';
export const SIN_PERMISOS_EDICION = 'No cuenta con permisos de edición.';
export const SELECCIONA_EMPRESA = 'Debe seleccionar al menos una empresa.';
export const MINIMO_MAYOR_ENGANCHE = 'El anticipo mínimo no debe ser mayor al enganche.';
export const ERROR_PDF_COTIZACION = 'Ocurrió un error al generar el pdf de cotización';
export const ALERTA_COTIZACION_CONTADO = 'La cotización se genera solo en ventas a crédito';
export const ERROR_IMPORTAR_ARCHIVOS = 'Ha ocurrido un error al importar el archivo.';
export const TAMANIO_EXCEDIDO = (tamanioMaximo) => `El tamaño del archivo debe ser menor a ${tamanioMaximo} MB`;
export const TIPO_ARCHIVO_NO_ACEPTADO = (tipo) => (<>El tipo de archivo <strong>{tipo}</strong> no es aceptado.</>);;
export const TIPO_NO_PERMITIDO = 'Formato de archivo no válido.';
export const IMPORTE_MENOR_A_CERO = 'El monto importe debe ser mayor a cero.';
export const ABONO_MENSUALIDAD_INCORRECTO = 'El monto de un abono no puede ser mayor al total de la mensualidad.';
export const PAGO_MENSUALIDAD_INCORRECTO = 'El monto del pago no debe de ser menor o mayor al total de la mensualidad';
export const ERROR_REPORTE = 'Error al generar el reporte';
export const SALDO_CUENTA_EXCEDIDO = 'El importe a devolver supera el saldo disponible en la cuenta.';
export const ERROR_PETICION_GENERAL = 'Ha ocurrido un error en la petición: ';
export const DEVOLUCION_MAYOR_IMPORTE = 'El importe a devolver es mayor que el total de la venta.';
export const EDITAR_VENTA_ABONADA = 'No es posible editar el importe de la venta, ya que cuenta con abonos o mensualidades cubiertas. Favor de verificar.';
export const SELECCIONAR_EMPRESA_PROYECTO = 'Favor de seleccionar empresa y proyecto.';
export const ALERTA_SUBIR_PLANO = 'Favor de subir la imagen del plano.';
export const ALERTA_CONFIGURAR_MANZANA = 'Favor de configurar al menos una manzana.';
export const ALERTA_CONFIGURAR_LOTES = 'Favor de configurar al menos un lote.';
export const LOTE_VENTA_NO_ENCONTRADO = 'Lote no encontrado.'
export const ALERTA_PROYECTO_SIN_MANZANAS = 'El proyecto no cuenta con manzanas asignadas.';
export const ALERTA_PROYECTO_SIN_LOTES = 'El proyecto no cuenta con lotes asignados.';
export const MENSAJE_ELIMINAR_ELEMENTO = '¿Está seguro de eliminar el elemento?';
export const MENSAJE_RESTABLECER_ELEMENTO = '¿Está seguro de restablecer el elemento?';
export const MENSAJE_CAPTURAR_NUMERO_LOTES_IRREGULATES = 'Favor de capturar con cuántos lotes irregulares cuenta la manzana';
export const MENSAJE__LOTES_MAYOR_AL_TOTAL = 'El número de lotes capturado sobrepasa el número de lotes de la manzana, favor de validar';