import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  img: {
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
    marginTop: theme.spacing(-8),
  },
}));

export default styles;
