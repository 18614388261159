import React from 'react';
import Estatus from '../../componentes/Estatus';
import FiltrosComponent from '../../componentes/FiltrosComponent';
import Listados from '../../contenedores/Listados';
import { CUENTAS } from '../../configuraciones/endpoints';
import { formatoMoneda } from '../../utilidades/functions';
import Color from '../../componentes/Color';

const cabeceros = [
  { label: 'Nombre', key: 'nombre' },
  { label: 'Empresa', transform: ({ proyecto }) => proyecto?.empresa?.nombre },
  { label: 'Proyecto', transform: ({ proyecto }) => proyecto?.nombre },
  { label: 'Banco', key: 'banco' },
  { label: 'Tarjeta', key: 'tarjeta' },
  { label: 'CLABE', key: 'clabe' },
  { label: 'Color', transform: ({ color }) => <Color color={color} /> },
  { label: 'Saldo', transform: ({ saldo }) => formatoMoneda(saldo) },
  {
    label: 'Estatus', transform: ({ habilitado, activo }) => (
      <Estatus habilitado={habilitado} activo={activo} />
    ),
  },
];

const Cuentas = ({ history, location }) => (
  <Listados
    titulo="Cuentas"
    customCabecero={<FiltrosComponent mostrarProyectos />}
    history={history}
    location={location}
    showActions
    cabeceros={cabeceros || []}
    apiKey={CUENTAS}
  />);

export default Cuentas;