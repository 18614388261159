import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import propTypes from 'prop-types';

import axios from '../../configuraciones/axios';
import TextField from '../../componentes/TextField';
import styles from './styles';
import endpoints, { EMPRESAS, TIPOS_LOTE } from '../../configuraciones/endpoints';

import AutoComplete from '../../componentes/AutoComplete';
import AccionesCabecero from '../../componentes/AccionesCabecero';
import { ESTADOS_LOTES } from '../../constantes/tipoEstado';
import config from '../../configuraciones/general';
import { tipoEstadosLotes } from '../../constantes/estadosLotes';

const FiltrosLotes = ({ mostrarEstados }) => {
  const classes = styles();
  const [empresas, setEmpresas] = useState([]);
  const [estados, setEstados] = useState([]);
  const [proyectos, setProyectos] = useState([]);
  const [manzanas, setManzanas] = useState([]);
  const [tipos, setTipos] = useState([]);

  const { watch, setValue } = useFormContext();

  const { superAdmin } = useSelector(
    ({ user: { user: { superAdmin } } }) => ({ superAdmin })
  );
  const empresaId = watch('empresaId');
  const proyectoId = watch('proyectoId');
  const busqueda = watch('busqueda');

  /**
   * La función "consultarDatosIniciales" obtiene datos iniciales del filtro.
   */
  const consultarDatosIniciales = () => new Promise((resolve, reject) => {
    const promesas = [
      axios.get(endpoints.base.opciones(EMPRESAS)),
      axios.get(endpoints.estados(ESTADOS_LOTES)),
      axios.get(endpoints.base.opciones(TIPOS_LOTE)),
    ];

    Promise.all(promesas).then((res) => {
      const [bdEmpresas, bdEstados, bdTipoLotes] = res;

      setEmpresas(bdEmpresas);
      setEstados(bdEstados);
      setEstados(superAdmin ? tipoEstadosLotes : bdEstados);
      setTipos(bdTipoLotes)

      resolve();
    }).catch(reject);
  });

  /* Se encarga de recuperar proyectos basados en la empresa
  seleccionada (`empresaId`) mediante una operación asincrónica. */
  const consultarProyectos = useCallback(async () => {
    const bdProyectos = await axios.get(endpoints.proyectosPorEmpresa(empresaId));
    setProyectos(bdProyectos);
  }, [empresaId]);

  /* Se encarga de buscar datos de forma asincrónica para recuperar la lista de
  manzanas (bloques o secciones) en función del proyecto seleccionado (`proyectoId`). */
  const consultarManzanas = useCallback(async () => {
    const bdProyectos = await axios.get(endpoints.manzanasPorProyecto(proyectoId));
    setManzanas(bdProyectos);
  }, [proyectoId]);

  /* La función `limpiarProyecto` establece el valor del elemento con id 'manzanaId' en indefinido. */
  const limpiarProyecto = useCallback(() => {
    setValue('manzanaId', undefined);
  },[setValue]);

  /*  La función `limpiarEmpresa` borra los valores de `proyectoId` y `manzanaId`. */
  const limpiarEmpresa = useCallback(() => {
    setValue('proyectoId', undefined);
    limpiarProyecto();
  },[limpiarProyecto, setValue]);

  useEffect(() => {
    consultarDatosIniciales();
  }, []);

  /* Control del filtro al seleccionar empresa */
  useEffect(() => {
    if (!!empresaId) {
      limpiarEmpresa();
      consultarProyectos();
    } else {
      setProyectos([]);
    }
  }, [consultarProyectos, empresaId, limpiarEmpresa]);

  /* Control del filtro al seleccionar proyecto */
  useEffect(() => {
    if (!!proyectoId) {
      limpiarProyecto();
      consultarManzanas();
    } else {
      setManzanas([]);
    }
  }, [consultarManzanas, limpiarProyecto, proyectoId]);

  /* Valida la longitud del campo de búsqueda para no dejar escribir más de 9 caracteres. */
  useEffect(() => {
    if (busqueda?.length >= config.INT_MAXLENGTH)
      setValue('busqueda', busqueda.substring(0, config.INT_MAXLENGTH));
  }, [setValue, busqueda]);

  return (
    <Grid container justifyContent="flex-end" alignItems="flex-end" className={classes.root}>
      <Grid item xs={12} sm={6} md={3} lg={3}>
        <AutoComplete
          name="empresaId"
          label="Empresa:"
          options={empresas}
          onClear={limpiarEmpresa}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={3}>
        <AutoComplete
          label="Proyecto:"
          name="proyectoId"
          options={proyectos}
          onClear={limpiarProyecto}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={3}>
        <AutoComplete
          label="Manzana:"
          name="manzanaId"
          options={manzanas}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={3}>
        <AutoComplete
          label="Tipo:"
          name="tipoLoteId"
          options={tipos}
        />
      </Grid>
      {
        mostrarEstados && (
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <AutoComplete
              label="Estado:"
              name="estadoId"
              options={estados}
            />
          </Grid>
        )
      }
      <Grid item xs={12} sm={6} md={3} lg={3}>
        <TextField
          type="number"
          name="busqueda"
          placeHolder="Búsqueda por número"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        <AccionesCabecero />
      </Grid>
    </Grid>
  )
}

FiltrosLotes.propTypes = {
  mostrarEstados: propTypes.bool,
};

FiltrosLotes.defaultProps = {
  mostrarEstados: true,
};

export default FiltrosLotes;