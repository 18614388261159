import React from 'react';
import { Grid, Hidden } from '@material-ui/core';
import { Link } from 'react-router-dom';

import Footer from '../../componentes/Footer';
import Button from '../../componentes/Button';
import styles from './styles';
import { CORREO_ENVIADO_REINICIO_CONTRA } from '../../constantes/mensajes';

const CorreoConfirmacion = () => {
  const classes = styles();

  const correoUsuario = localStorage.getItem("correoUsuario");

  return (
    <Grid container className={classes.main}>
      <Grid item xs={12}>
        <Grid container className={classes.body}>
          <Hidden smDown>
            <Grid item md={7} className={classes.contenedorTitulo}>
              <Grid container alignItems='baseline'>
                <img src='/assets/images/logoSINfondo.svg' alt='icono' width='100' height='100' />
              </Grid>
              <Grid container alignItems='baseline'>
                <h1>Condor&nbsp;</h1>
                <h2>ADMON</h2>
              </Grid>
            </Grid>
          </Hidden>
          <Grid item xs={12} md={4}>
            <Grid container spacing={1} className={classes.card} id="card">
              <Grid item xs={12}>
                <h2>Olvidé mi contraseña</h2>
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent='center' style={{ marginBottom: '40px' }}>
                  <img src='/assets/images/logoSINfondo.svg' alt='icono' className={classes.logoDelCampo} />
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.descripcion}>
                <span>{CORREO_ENVIADO_REINICIO_CONTRA(correoUsuario)}</span>
              </Grid>

              <Grid item xs={12} className={classes.pieCardLabel} style={{marginBottom:'40px'}}>
                <Link to='/login' className={classes.enlace}>
                  <Button
                    name="aceptar"
                    label="Aceptar"
                    classesCustom={{ boton: classes.botonInicio }}
                    isSubmit
                  />
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Footer />
      </Grid>
    </Grid >
  );
};

export default CorreoConfirmacion;
