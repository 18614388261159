import React from 'react';
import Estatus from '../../componentes/Estatus';
import Listados from '../../contenedores/Listados';
import { PROYECTOS } from '../../configuraciones/endpoints';
import BarraProgreso from '../../componentes/BarraProgreso';
import Color from '../../componentes/Color';
import FiltrosComponent from '../../componentes/FiltrosComponent';

const cabeceros = [
  { label: 'Nombre', key: 'nombre' },
  { label: 'Clave', transform: ({ clave }) => clave?.trim() },
  { label: 'Descripción', key: 'descripcion' },
  { label: 'Empresa', transform: ({ empresa }) => empresa?.nombre },
  { label: 'Representante', key: 'representante' },
  { label: 'Fecha', key: 'fecha' },
  { label: 'Manzanas', key: 'cantidadManzanas' },
  { label: 'Lotes', key: 'cantidadLotes' },
  { label: 'Disponibles', key: 'cantidadLotesDisponibles' },
  { label: 'Vendidos', key: 'cantidadLotesVendidos' },
  {
    label: 'Color',
    transform: ({ color }) => <Color color={color} />,
  },
  {
    label: 'Avance',
    align: 'center',
    width: 100,
    transform: ({ cantidadLotes, cantidadLotesVendidos }) => <BarraProgreso total={cantidadLotes} avance={cantidadLotesVendidos} />,
  },
  {
    label: 'Estatus', transform: ({ habilitado, activo }) => (
      <Estatus habilitado={habilitado} activo={activo} />
    ),
  },
];

const Proyectos = ({ history, location }) => {
  return <Listados
    titulo="Proyectos"
    customCabecero={<FiltrosComponent />}
    history={history}
    location={location}
    showActions
    cabeceros={cabeceros}
    apiKey={PROYECTOS}
  />;
};

export default Proyectos;