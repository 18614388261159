import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles((theme) => ({
  btnLote: {
    backgroundColor: paleta.boton.secondary,
    borderColor: paleta.boton.border,
    '&.Mui-disabled': {
      backgroundColor: paleta.boton.secondaryDisabled,
    },
    '&:hover': {
      backgroundColor: `${paleta.boton.secondary}D9 !important`,
      borderColor: paleta.boton.border,
    },
    
  },
  btnCancelar: {
    backgroundColor: paleta.boton.white,
    '& .MuiTypography-root': {
      color: `${paleta.boton.primary} !important`,
    },
    '&:hover': {
      backgroundColor: paleta.boton.white,
    }
  },
  tblLotes: {
    maxHeight: '50vh',
    minHeight: '50vh',
  },
  btnApartar: {
    backgroundColor: '#D5900B',
    borderColor: paleta.boton.border,
  },
  acciones: {
    marginTop: '-8vh',
    marginBottom: 15,
    [theme.breakpoints.down('xs')]: {
      marginTop: '-3vh',
      marginBottom: '1vh',
    },
  },
  control: {
    position: 'relative',
    '& .error': {
      position: 'absolute',
      top: '4.5rem',
    },
    '& .MuiFormControl-root': {
      '& .MuiFormHelperText-root': {
        position: 'absolute',
        top: '3.5rem',
      },
    }
  },
  frmAnticipo: {
    '&:has(.error)': {
      marginBottom: 25,
    },
  },
  expedienteForm: {
    margin: 30
  },
  barraProgreso: {
    marginTop: 35,
  },
}));

export default styles;
