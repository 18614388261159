import React, { useState } from 'react';
import { Checkbox, FormControlLabel, Grid, Hidden, IconButton } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link } from 'react-router-dom';
import { IoEyeSharp, IoEyeOffSharp } from 'react-icons/io5';

import md5 from 'md5';

import TextField from '../../componentes/TextField';
import Button from '../../componentes/Button';
import Footer from '../../componentes/Footer';
import styles from './styles';
import { setUser, STORAGE } from '../../ducks/user';
import endpoints from '../../configuraciones/endpoints';
import axios, { setTokenHeader } from '../../configuraciones/axios';
import { CAMPO_REQUERIDO } from '../../constantes/mensajes';
import { setMenuActivo, setSubmenuActivo } from '../../ducks/appbar';

const schema = yup.object({
  correo: yup.string().required(CAMPO_REQUERIDO),
  password: yup.string().required(CAMPO_REQUERIDO),
});

const Login = () => {
  const dispatch = useDispatch();
  const classes = styles();
  const [showPassword, setShowPassword] = useState(false);
  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = methods;

  const iniciarSesion = (usuario) => {
    let usuarioLogin = {
      correo: usuario.correo,
      contrasena: md5(usuario.password),
    };

    axios.post(endpoints.iniciarSesion(), usuarioLogin)
      .then((sesion) => {
        if (usuario.mantenerSesion)
          localStorage.setItem(STORAGE, JSON.stringify(sesion));
        else
          sessionStorage.setItem(STORAGE, JSON.stringify(sesion));

        setTokenHeader(sesion.token);
        dispatch(setUser(sesion));
        dispatch(setMenuActivo({}));
        dispatch(setSubmenuActivo({}));

      })
      .catch((error) => {
      });
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Grid container className={classes.main}>
      <Grid item xs={12}>
        <Grid container className={classes.body}>
          <Hidden smDown>
            <Grid item md={7} className={classes.contenedorTitulo}>
              <Grid container alignItems='baseline'>
                <img src='/assets/images/logoSINfondo.svg' alt='icono' width='100' height='100' />
              </Grid>
              <Grid container alignItems='baseline'>
                <h1>Condor&nbsp;</h1>
                <h2>ADMON</h2>
              </Grid>
            </Grid>
          </Hidden>
          <Grid item xs={12} md={4}>
            <FormProvider {...methods}>
              <form noValidate onSubmit={handleSubmit(iniciarSesion)}>
                <Grid container spacing={1} className={classes.card} id="card">
                  <Grid item xs={12}>
                    <h2>INICIO DE SESIÓN</h2>
                    <p>Ingrese sus credenciales para identificarse</p>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container justifyContent='center'>
                      <img src='/assets/images/logoSINfondo.svg' alt='icono' className={classes.logoDelCampo} />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name='correo'
                      placeHolder="Correo electrónico"
                      required
                      error={errors.correo}
                      helperText={errors.correo?.message}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name='password'
                      placeHolder="Contraseña"
                      type={showPassword ? 'text' : 'password'}
                      required
                      error={errors.password}
                      helperText={errors.password?.message}
                      simbolo={
                        <IconButton size="small" onClick={toggleShowPassword}>
                          {showPassword ? <IoEyeOffSharp className={classes.icon} /> : <IoEyeSharp className={classes.icon} />}
                        </IconButton>
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel control={
                      <Checkbox
                        {...register('mantenerSesion')}
                      />
                    }
                      label="Recordar mis credenciales" />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      name="iniciar"
                      label="Iniciar"
                      classesCustom={{ boton: classes.botonInicio }}
                      isSubmit
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.pieCardLabel}>
                    <Link to='/contrasena-olvidada' className={classes.enlace} >
                      <h2>¿Olvidaste tu contraseña?</h2>
                    </Link>
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Footer />
      </Grid>
    </Grid >
  );
};

export default Login;
