import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const useStyles = makeStyles(() => ({
  '@keyframes spin': {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
  },
  spinnerLoading: {
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    border: `12px solid ${paleta.spinner.border}`,
    borderLeftColor: paleta.spinner.main,
    animation: '$spin 1s linear infinite',
    marginBottom: '20px',
  },
  spinnerDiseño: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width:'100%',
    marginTop:'50px',
    marginBottom:'100px',
    fontWeight:'bold',
    letterSpacing:'0.6px'
  },
}));

const SpinnerCirculo = ({ label }) => {
  const classes = useStyles();

  return (
    <Box className={classes.spinnerDiseño}>
      <Box className={classes.spinnerLoading} />
      <p>{label}</p>
    </Box>
  );
};

export default SpinnerCirculo;
