import queryString from 'query-string';

export const PERMISOS = '/permisos';
export const PERFILES = '/perfiles';
export const USUARIOS = '/usuarios';
export const SESIONES = '/sesiones';
export const MODULOS = '/modulos';
export const MANZANAS = '/manzanas';
export const MANZANA_VERTICES = '/manzana-vertices';
export const PROYECTOS = '/proyectos';
export const EMPRESAS = '/empresas';
export const CLIENTES = '/clientes';
export const ESTADOS = '/estados';
export const CUENTAS = '/cuentas';
export const LOTES = '/lotes';
export const LOTE_VERTICES = '/lote-vertices';
export const TIPOS_LOTE = '/tipos-lote';
export const CATEGORIAS = '/categorias';
export const VENDEDORES = '/vendedores';
export const VENTAS = '/ventas';
export const EXPEDIENTES = '/expedientes';
export const REINICIAR_CONTRASENA = '/reiniciar-contrasena';
export const CONTRASENA_OLVIDADA = '/contrasena-olvidada';
export const COTIZACION = 'cotizaciones';
export const FLUJOS = '/flujos';
export const TIPO_CUENTA_PROYECTO = '/tipo-cuenta-proyecto';
export const COBRANZAS = '/cobranzas';
export const EXPORTAR_DOC = '/exportar'
export const TIPOS_MOVIMIENTO = '/tipos-movimiento';
export const IMPORTAR = '/importar';
export const CONFIGURACION_PROYECTO = '/configuracion-proyecto';


const endpoints = {
  base: {
    url: (uri, id = '') => `${uri}/${id}`,
    opciones: (uri) => `${uri}/opciones`,
    busqueda: (uri, params) => `${uri}/busqueda${queryString.stringify(params)}`,
    coordenadas: (uri, id) => `${uri}/${id}/coordenadas`,
    exportar: (uri) => `${uri}${EXPORTAR_DOC}`,
  },

  iniciarSesion: () => `${SESIONES}/iniciar`,
  reiniciarContrasena: () => `${USUARIOS}${REINICIAR_CONTRASENA}`,
  envioCorreoContrasenaOlvidada: () => `${USUARIOS}${CONTRASENA_OLVIDADA}`,
  permisosAsignados: (perfilId = '') => `${PERMISOS}/perfil/${perfilId}`,
  crearActualizar: (uri, id) => `${uri}/${id || ''}`,
  crearActualizarPerfil: (perfilId) => `${PERFILES}/${perfilId ? perfilId : ''}`,
  modulosDisponibles: () => `${MODULOS}/padre`,
  proyectosPorEmpresa: (empresaId) => `${PROYECTOS}/empresa/${empresaId}`,
  manzanasPorProyecto: (proyectoId) => `${MANZANAS}/proyecto/${proyectoId}`,
  manzanasPlanos: (proyectoId) => `${MANZANAS}/proyecto/${proyectoId}/plano`,
  estados: (tipoEstado) => `${ESTADOS}/tipoEstado/${tipoEstado}`,
  clientesPorEmpresa: (empresaId) => `${CLIENTES}/empresa/${empresaId}`,
  clientesPorProyecto: (proyectoId) => `${CLIENTES}/proyecto/${proyectoId}`,
  lotesPorCliente: (clienteId) => `${LOTES}/cliente/${clienteId}`,
  ventasPorCliente: (clienteId) => `${VENTAS}/cliente/${clienteId}`,
  cuentasPorTipoCuenta: (tipoCuentaId) => `${CUENTAS}/tipoCuenta/${tipoCuentaId}`,
  obtenerFlujosCobranza: (cobranzaId) => `${FLUJOS}/cobranza/${cobranzaId}`,
  obtenerComprobanteFlujo: (flujoId) => `${FLUJOS}/comprobante/${flujoId}`,
  obtenerContratoVenta: (ventaId) => `${VENTAS}/contrato/${ventaId}`,
  proyectoPlano: (planoId) => `${PROYECTOS}/${planoId}/plano`,
  configuracionProyecto: (proyectoId) => `${CONFIGURACION_PROYECTO}/proyecto/${proyectoId}`,
  estadoMapaProyecto: (proyectoId, estadoMapa) => `${PROYECTOS}/${proyectoId}/estadoMapa/${estadoMapa}`,
  guardarConfiguracionMapa: (proyectoId) => `${PROYECTOS}/${proyectoId}/configuracion-mapa`,
  eliminarConfiguracionMapa: (proyectoId) => `${PROYECTOS}/${proyectoId}/configuracion-mapa`,
  obtenerDatosVentaExpediente: (ventaId) => `${VENTAS}/${ventaId}/expediente`,
  obtenerArchivosExpediente: (ventaId) => `${EXPEDIENTES}/${ventaId}`,
  eliminarArchivoExpediente: (archivoId) => `${EXPEDIENTES}/${archivoId}`,
};

export default endpoints;
