import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

export const useStyles = makeStyles(() => ({
  manzana: {
    borderBottom: `1px solid ${paleta.boton.border}`,

    '&:last-child': {
      borderBottom: 'none',
    },
    '&.active': {
      backgroundColor: paleta.planos.manzanaActivoBackground,
      '& span': {
        fontWeight: 600,
      },
    },
  },
  dobleHilera: {
    fontSize: 10,
  },
  btn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 10,
    minWidth: '70%',
    height: 'auto',
    '& span': {
      marginLeft: 5,
      color: paleta.planos.botonesHover
    }
  },
  iconoConfigurar:{ 
    marginRight: '10px',
  },
  btnConfigurar: {
    backgroundColor: paleta.planos.botonesHover,
    color: paleta.boton.white,
    minWidth: '70%',
    '&:hover': {
      backgroundColor: paleta.planos.botonesHover,
    }
  }
}));