import * as yup from 'yup';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Checkbox, Grid } from '@material-ui/core';
import { toast } from 'react-toastify';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';

import Default from '../../contenedores/Default';
import Select from '../../componentes/Select';
import Tabla from '../../componentes/Table';
import TextField from '../../componentes/TextField';
import axios from '../../configuraciones/axios';
import endpoints, { PERFILES } from '../../configuraciones/endpoints';
import estatus from '../../constantes/estatus';
import { ELEMENTO_NO_ENCONTRADO, CAMPO_REQUERIDO, PERFIL_SIN_PERMISOS } from '../../constantes/mensajes';

import { trim } from '../../utilidades/functions';

const schema = yup.object({
  nombre: yup.string().required(CAMPO_REQUERIDO),
  habilitado: yup.boolean().oneOf([true, false]).required(CAMPO_REQUERIDO),
  descripcion: yup.string().required(CAMPO_REQUERIDO),
  vendedor: yup.boolean().oneOf([true, false]).required(CAMPO_REQUERIDO),
  lotesDisponibles: yup.boolean().oneOf([true, false]).required(CAMPO_REQUERIDO),
});

const Perfil = ({ history, match }) => {
  const { params: { id } } = match;
  const organizacionId = useSelector(({ user: { user: { perfil: { organizacionId } } } }) => (organizacionId));

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      habilitado: true,
      vendedor: false,
      lotesDisponibles: false,
    },
  });

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = methods;

  const [modulos, setModulos] = useState([]);

  const regresar = useCallback(() => {
    const { location: { state: { pagina } } } = history;
    history.push({
      pathname: '/catalogos/perfiles',
      search: pagina && pagina > 1 ? `?pagina=${pagina}` : '',
    });
  }, [history]);

  const guardar = useCallback((perfil) => {
    const metodo = id ? 'put' : 'post';

    const objTrim = trim(perfil);
    const perfilGuardar = {
      ...objTrim,
      organizacionId,
      perfilModulos: modulos.map(({ moduloId, isSelected, permisoEdicion }) => {
        const permiso = {
          moduloId,
          permisoEdicion,
          habilitado: isSelected,
          activo: isSelected,
        };
        if (id) permiso.perfilId = id;
        return permiso;
      }),
    };

    if (perfilGuardar.perfilModulos.filter(({ habilitado }) => habilitado).length === 0) {
      return toast.warning(PERFIL_SIN_PERMISOS);
    }

    axios[metodo](endpoints.crearActualizarPerfil(id), perfilGuardar).then(regresar).catch(() => { });
  }, [id, organizacionId, modulos, regresar]);

  const seleccionarTodos = (flag) => {
    const nuevosModulos = [...modulos];
    setModulos(nuevosModulos.map(modulo => ({ ...modulo, isSelected: !flag, permisoEdicion: !flag })));
  };

  const seleccionarModulo = (row) => {
    const index = modulos.findIndex(({ moduloId }) => moduloId === row.moduloId);
    if (index !== -1) {
      const nuevosModulos = [...modulos];
      const seleccionado = !nuevosModulos[index].isSelected;
      nuevosModulos[index].isSelected = seleccionado;
      nuevosModulos[index].permisoEdicion = seleccionado;
      setModulos(nuevosModulos);
    }
  };

  /**
   * Método que selecciona el checkbox del permiso de edición.
   * @param row Registros al cual pertenece el checkbox.
   */
  const asingarPermisoEdicion = useCallback((row) => {
    const index = modulos.findIndex(({ moduloId }) => moduloId === row.moduloId);
    if (index !== -1) {
      const nuevosModulos = [...modulos];
      const seleccionado = !nuevosModulos[index].permisoEdicion;
      nuevosModulos[index].permisoEdicion = seleccionado;
      if (!nuevosModulos[index].isSelected) nuevosModulos[index].isSelected = seleccionado;
      setModulos(nuevosModulos);
    }
  }, [modulos]);

  const consultarDatosIniciales = useCallback(() => new Promise((resolve, reject) => {
    const promesas = [
      axios.get(endpoints.modulosDisponibles()),
    ];
    if (id) promesas.push(axios.get(endpoints.base.url(PERFILES, id)));

    Promise.all(promesas).then((resultadosPromesas) => {
      const [modulosDb, perfilDb] = resultadosPromesas;
      const modulosMapeados = [];
      for (const moduloPadre of modulosDb) {
        for (const modulo of moduloPadre.modulosHijos) {
          const perfilModulo = perfilDb?.perfilModulos?.find((permiso) => (
            permiso.moduloId === modulo.id
          ));
          const mod = {
            moduloId: modulo.id,
            nombre: modulo.nombre,
            nombrePadre: moduloPadre.nombre,
            isSelected: id ? !!perfilModulo : false,
            permisoEdicion: id ? !!perfilModulo?.permisoEdicion : false,
          };
          modulosMapeados.push(mod);
        }
      }

      setModulos(modulosMapeados);
      if (perfilDb?.id)
        reset({ ...perfilDb })
      else if (id) {
        toast.warning(ELEMENTO_NO_ENCONTRADO);
        regresar();
      }
      resolve();
    }).catch(reject);
  }), [id, regresar, reset]);

  const cabeceros = useMemo(() => [
    { label: 'Sección', key: 'nombrePadre' },
    { label: 'Módulo', key: 'nombre' },
    {
      label: 'Permiso Edición', transform: (row) => (
        <Checkbox
          checked={row.permisoEdicion || false}
          value={row.permisoEdicion || false}
          onClick={() => asingarPermisoEdicion(row)}
        />
      )
    },
  ], [asingarPermisoEdicion]);

  useEffect(() => {
    consultarDatosIniciales();
  }, [consultarDatosIniciales]);

  return <Default
    titulo={Boolean(id) ? 'Editar Perfil' : 'Nuevo Perfil'}
    placeHolder={''}
    mostrarCabeceroFormulario
    cancelar={regresar}
    frmId="frmPerfil"
  >
    <FormProvider {...methods}>
      <form noValidate onSubmit={handleSubmit(guardar)} id="frmPerfil">
        <Grid container spacing={2} id="frmPerfil">
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <TextField
                  label="Nombre del perfil"
                  name="nombre"
                  inputProps={{ maxLength: 100 }}
                  required
                  error={errors.nombre}
                  helperText={errors.nombre?.message}
                  register={register}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Descripción"
                  type="textarea"
                  rows={8}
                  name="descripcion"
                  inputProps={{ maxLength: 250 }}
                  required
                  error={errors.descripcion}
                  helperText={errors.descripcion?.message}
                  register={register}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Select
                  label="Puede vender:"
                  required
                  options={estatus}
                  name="vendedor"
                  placeHolder={'Selecciona una opción'}
                  error={errors.vendedor}
                  helperText={errors.vendedor?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  label="Solo ver lotes disponibles"
                  required
                  options={estatus}
                  name="lotesDisponibles"
                  placeHolder={'Selecciona una opción'}
                  error={errors.lotesDisponibles}
                  helperText={errors.lotesDisponibles?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  label="Estatus"
                  required
                  options={estatus}
                  name="habilitado"
                  placeHolder={'Selecciona una opción'}
                  error={errors.habilitado}
                  helperText={errors.habilitado?.message}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Tabla
              hidePaginator
              showActions={false}
              showCheckbox
              headers={cabeceros}
              rows={modulos}
              onSelectAll={seleccionarTodos}
              onSelect={seleccionarModulo}
            />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  </Default>;
};

export default React.memo(Perfil);