
import React from 'react';
import { Grid } from '@material-ui/core';
import SubirImagen from '../../componentes/SubirImagen';

const Proyecto = ({ plano, setPlano }) => {
  return (
    <Grid container justifyContent='center'>
      <Grid item xs={8}>
        <SubirImagen imagenFirma={plano} setImagenFirma={setPlano} tamanioMB={10} label="Subir imagen del plano*" />
      </Grid>
    </Grid>
  );
}

export default Proyecto;