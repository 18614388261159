import React from 'react';
import {
  Box,
  Collapse,
  List, ListItem, ListItemText,
  makeStyles,
} from '@material-ui/core';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { setMenuActivo, setSubmenuActivo } from '../../ducks/appbar';
import paleta from '../../configuraciones/paleta';

const useStyles = makeStyles(() => ({
  subMenuActivo: {
    backgroundColor: paleta.bar.subItemActivo,
    borderLeft: `5px solid ${paleta.bar.adorno}`,
    paddingLeft: '25px !important',
  },
}));

const MenuItem = ({ menu }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { menuActivo, submenuActivo } =
    useSelector(({ appbar: { menuActivo, submenuActivo } }) => ({ menuActivo, submenuActivo }));

  const hasSubitems = menu.submenu?.length > 0;

  const handleMenu = () => {
    if (menuActivo.index !== menu.index) {
      dispatch(setMenuActivo({ ...menu }));
      if (!menu.submenu?.find((s) => s.id === submenuActivo.id))
        dispatch(setSubmenuActivo({}));
    }
    else
      dispatch(setMenuActivo({}));
  };

  return (
    <>
      <ListItem button component={hasSubitems ? null : Link} to={menu.link || '/'} onClick={handleMenu}
      >
        {menu.icono ?
          <Box padding={1}>
            {menu.icono}
          </Box>
          :
          <></>
        }
        <ListItemText>
          {menu.label}
        </ListItemText>
        {hasSubitems && (menu.index === menuActivo.index ? <IoIosArrowUp /> : <IoIosArrowDown />)}
      </ListItem >
      <Collapse in={menu.index === menuActivo.index} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {menu.submenu?.map((sub, index) => (
            <ListItem key={index} button style={{ paddingLeft: 30 }} component={Link} to={sub.link}
              onClick={() => dispatch(setSubmenuActivo(sub))}
              className={clsx({ [classes.subMenuActivo]: submenuActivo?.id === sub.id })}
            >
              {sub.icono ?
                <Box padding={0.5}>
                  {sub.icono}
                </Box>
                :
                <></>
              }
              <ListItemText primary={sub.label} />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
}

export default MenuItem;
