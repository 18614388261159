import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import propTypes from 'prop-types';

import axios from '../../configuraciones/axios';
import TextField from '../../componentes/TextField';
import styles from './styles';
import endpoints, { EMPRESAS } from '../../configuraciones/endpoints';

import AutoComplete from '../../componentes/AutoComplete';
import AccionesCabecero from '../../componentes/AccionesCabecero';
import { ESTADOS_COBRANZAS } from '../../constantes/tipoEstado';
import config from '../../configuraciones/general';
import tipoCobranza from '../../constantes/tipoCobranza';
import CustomSelect from '../../componentes/Select';
import { EstadosCobranzas } from '../../constantes/estadosCobranzas';

const FiltroCobranzas = forwardRef(({ mostrarEstados}, ref) => {
  const classes = styles();
  const [empresas, setEmpresas] = useState([]);
  const [proyectos, setProyectos] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [lotesCliente, setLotesCliente] = useState([]);
  const [estados, setEstados] = useState([]);
  const { watch, setValue } = useFormContext();

  const empresaId = watch('empresaId');
  const proyectoId = watch('proyectoId');
  const clienteId = watch('clienteId');
  const busqueda = watch('busqueda');

  const { superAdmin } = useSelector(
    ({ user: { user: { superAdmin } } }) => ({ superAdmin })
  );

  const refenciaFiltro = useRef();

  useImperativeHandle(ref, () => {
    return {
      limpiarFiltro() {
        refenciaFiltro.current.limpiarFiltro();
      },
    };
  }, []);

  /**
   * La función "consultarDatosIniciales" obtiene datos iniciales del filtro.
   */
  const consultarDatosIniciales = () => new Promise((resolve, reject) => {
    const promesas = [
      axios.get(endpoints.base.opciones(EMPRESAS)),
      axios.get(endpoints.estados(ESTADOS_COBRANZAS)),
    ];

    Promise.all(promesas).then((res) => {
      const [bdEmpresas, bdEstados] = res;

      setEmpresas(bdEmpresas);
      setEstados(superAdmin ? EstadosCobranzas : bdEstados);

      resolve();
    }).catch(reject);
  });

  /* Se encarga de recuperar proyectos basados en la empresa
  seleccionada (`empresaId`) mediante una operación asincrónica. */
  const consultarProyectos = useCallback(async () => {
    const bdProyectos = await axios.get(endpoints.proyectosPorEmpresa(empresaId));
    setProyectos(bdProyectos);
  }, [empresaId]);
  
  /* Se encarga de buscar los clientes asociados a un proyecto específico en
  función del valor `proyectoId`. */
  const consultarClientes = useCallback(async () => {
    const dbClientes = await axios.get(endpoints.clientesPorProyecto(proyectoId));
    setClientes(dbClientes);
  }, [proyectoId]);
  
  /* Se encarga de recuperar los lotes asociados a un cliente específico. */
  const consultarLotesCliente = useCallback(async () => {
    const dbLotesCliente = await axios.get(endpoints.lotesPorCliente(clienteId));
    setLotesCliente(dbLotesCliente);
  }, [clienteId]);

  /*  La función `limpiarLotesClientes` borra los valores de `loteId`. */
  const limpiarLotesClientes = useCallback(() => {
    setValue('loteId', undefined);
    setLotesCliente([]);
  },[setValue]);

  /*  La función `limpiarProyecto` borra los valores de `clienteId` y `loteId`. */
  const limpiarProyecto = useCallback(() => {
    setValue('clienteId', undefined);
    setClientes([]);
    limpiarLotesClientes();
  },[limpiarLotesClientes, setValue]);

  /*  La función `limpiarEmpresa` borra los valores de `proyectoId`, `clienteId` y `loteId`. */
  const limpiarEmpresa = useCallback(() => {
    setValue('proyectoId', undefined);
    setProyectos([]);
    limpiarProyecto();
  },[limpiarProyecto, setValue]);

  useEffect(() => {
    consultarDatosIniciales();
  }, []);

  /* Control del filtro al seleccionar empresa */
  useEffect(() => {
    if (!!empresaId) {
      consultarProyectos();
    }
  }, [consultarProyectos, empresaId]);

  /* Control del filtro al seleccionar proyecto */
  useEffect(() => {
    if (!!proyectoId) {
      limpiarProyecto();
      consultarClientes();
    } else {
      setClientes([]);
    }
  }, [consultarClientes, proyectoId, limpiarProyecto]);

  /* Control del filtro al seleccionar cliente */
  useEffect(() => {
    if (!!clienteId) {
      limpiarLotesClientes();
      consultarLotesCliente();
    } else {
      setLotesCliente([]);
    }
  }, [consultarLotesCliente, clienteId, limpiarLotesClientes]);

  /* Valida la longitud del campo de búsqueda para no dejar escribir más de 9 caracteres. */
  useEffect(() => {
    if (busqueda?.length >= config.INT_MAXLENGTH)
      setValue('busqueda', busqueda.substring(0, config.INT_MAXLENGTH));
  }, [setValue, busqueda]);

  return (
    <Grid container justifyContent="flex-end" alignItems="flex-end" className={classes.root}>
      
      <Grid item xs={12} sm={6} md={3} lg={3}>
        <AutoComplete
          name="empresaId"
          label="Empresa:"
          options={empresas}
          onClear={limpiarEmpresa}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={3}>
        <AutoComplete
          label="Proyecto:"
          name="proyectoId"
          options={proyectos}
          onClear={limpiarProyecto}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={3}>
        <AutoComplete
          label="Cliente:"
          name="clienteId"
          options={clientes}
          onClear={limpiarLotesClientes}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={3}>
        <AutoComplete
          label="Lotes cliente:"
          name="loteId"
          propEtiqueta="nombre"
          options={lotesCliente}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={3}>
        <CustomSelect
          label="Tipo:"
          name="tipoCobranza"
          options={tipoCobranza}
          placeHolder="Selecciona una opción"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={3}>
        <AutoComplete
          label="Estado:"
          name="estadoId"
          options={estados}
        />
      </Grid>
      
      <Grid item xs={12} sm={6} md={3} lg={3}>
        <TextField
          type="number"
          name="busqueda"
          placeHolder="Búsqueda por pago"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        <AccionesCabecero ref={refenciaFiltro}/>
      </Grid>
    </Grid>
  )
});

FiltroCobranzas.propTypes = {
  mostrarEstados: propTypes.bool,
};

FiltroCobranzas.defaultProps = {
  mostrarEstados: true,
};

export default FiltroCobranzas;