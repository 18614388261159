import * as yup from 'yup';
import React, { useEffect, useState, useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { toast } from 'react-toastify';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Default from '../../contenedores/Default';
import TextField from '../../componentes/TextField';
import axios from '../../configuraciones/axios';
import endpoints, { LOTES, EMPRESAS, TIPOS_LOTE, CATEGORIAS } from '../../configuraciones/endpoints';
import { trim } from '../../utilidades/functions';
import { CAMPO_REQUERIDO, MIN_CAMPO, ELEMENTO_NO_ENCONTRADO, MAX_CAMPO } from '../../constantes/mensajes';
import AutoComplete from '../../componentes/AutoComplete';
import { ESTADOS_LOTES } from '../../constantes/tipoEstado';

const schema = yup.object({
  numero: yup.number()
    .min(1, MIN_CAMPO(1))
    .transform((value) => (isNaN(value) ? undefined : value))
    .required(CAMPO_REQUERIDO),
  descripcion: yup.string().required(CAMPO_REQUERIDO),
  empresaId: yup.number().required(CAMPO_REQUERIDO),
  proyectoId: yup.number().required(CAMPO_REQUERIDO),
  manzanaId: yup.number().required(CAMPO_REQUERIDO),
  tipoLoteId: yup.number().required(CAMPO_REQUERIDO),
  categoriaId: yup.number().required(CAMPO_REQUERIDO),
  frente: yup.number()
    .min(0, MIN_CAMPO(0))
    .transform((value) => (isNaN(value) ? undefined : value))
    .required(CAMPO_REQUERIDO),
  fondo: yup.number()
    .min(0, MIN_CAMPO(0))
    .transform((value) => (isNaN(value) ? undefined : value))
    .required(CAMPO_REQUERIDO),
  lateralIzquierdo: yup.number()
    .min(0, MIN_CAMPO(0))
    .transform((value) => (isNaN(value) ? undefined : value))
    .required(CAMPO_REQUERIDO),
  lateralDerecho: yup.number()
    .min(0, MIN_CAMPO(0))
    .transform((value) => (isNaN(value) ? undefined : value))
    .required(CAMPO_REQUERIDO),
  area: yup.number()
    .min(1, MIN_CAMPO(1))
    .transform((value) => (isNaN(value) ? undefined : value))
    .required(CAMPO_REQUERIDO),
  construccion: yup.number()
    .min(0, MIN_CAMPO(0))
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable(),
  calleNorte: yup.string().required(CAMPO_REQUERIDO),
  calleSur: yup.string().required(CAMPO_REQUERIDO),
  calleEste: yup.string().required(CAMPO_REQUERIDO),
  calleOeste: yup.string().required(CAMPO_REQUERIDO),
  precioM2: yup.number()
    .min(1, MIN_CAMPO(1))
    .max(9999999.99, MAX_CAMPO(9999999.99))
    .transform((value) => (isNaN(value) ? undefined : value))
    .required(CAMPO_REQUERIDO),
  precioTotal: yup.number()
    .min(1, MIN_CAMPO(1))
    .transform((value) => (isNaN(value) ? undefined : value))
    .required(CAMPO_REQUERIDO),
  estadoId: yup.number().required(CAMPO_REQUERIDO),
});

const Lote = ({ history, match }) => {
  const [empresas, setEmpresas] = useState([]);
  const [proyectos, setProyectos] = useState([]);
  const [manzanas, setManzanas] = useState([]);
  const [tiposLote, setTiposLote] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [estadosLote, setEstadosLote] = useState([]);
  const { params: { id } } = match;

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = methods;

  const empresaId = watch('empresaId');
  const proyectoId = watch('proyectoId');
  const area = watch('area');
  const precioM2 = watch('precioM2');

  const regresar = useCallback(() => {
    const { location: { state: { pagina } } } = history;
    history.push({
      pathname: '/catalogos/lotes',
      search: pagina && pagina > 1 ? `?pagina=${pagina}` : '',
    });
  }, [history]);

  const guardar = useCallback((lote) => {
    const metodo = id ? 'put' : 'post';
    const loteGuardar = trim(lote);
    axios[metodo](endpoints.crearActualizar(LOTES, id), loteGuardar).then(regresar).catch(() => { });
  }, [id, regresar]);

  const consultarDatosIniciales = useCallback(() => new Promise((resolve, reject) => {
    const promesas = [
      axios.get(endpoints.base.opciones(EMPRESAS)),
      axios.get(endpoints.base.opciones(TIPOS_LOTE)),
      axios.get(endpoints.base.opciones(CATEGORIAS)),
      axios.get(endpoints.estados(ESTADOS_LOTES)),
    ];
    if (id) promesas.push(axios.get(endpoints.base.url(LOTES, id)));

    Promise.all(promesas).then((resultadosPromesas) => {
      const [empresasBd, tiposLoteBd, categoriasBd, estadosLoteBd, loteBd] = resultadosPromesas;
      setEmpresas(empresasBd);
      setTiposLote(tiposLoteBd);
      setCategorias(categoriasBd);
      setEstadosLote(estadosLoteBd);

      if (loteBd?.id) {
        reset({
          ...loteBd,
          manzanaId: loteBd.manzanaId.toString(),
          proyectoId: loteBd.manzana.proyectoId.toString(),
          empresaId: loteBd.manzana.proyecto.empresaId,
        })
      } else if (id) {
        toast.warning(ELEMENTO_NO_ENCONTRADO);
        regresar();
      }
      resolve();
    }).catch(reject);
  }), [id, regresar, reset]);

  /**
   * Consulta los proyectos con base a la empresa seleccionada.
   */
  const consultarProyectos = useCallback(async () => {
    const proyectosBd = await axios.get(endpoints.proyectosPorEmpresa(empresaId));
    setProyectos(proyectosBd);
  }, [empresaId]);

  /**
   * Consulta las manzanas con base al proyecto seleccionado.
   */
  const consultarManzanas = useCallback(async () => {
    const manzanasBd = await axios.get(endpoints.manzanasPorProyecto(proyectoId));
    setManzanas(manzanasBd);
  }, [proyectoId]);

  useEffect(() => {
    consultarDatosIniciales();
  }, [consultarDatosIniciales]);

  useEffect(() => {
    if (!!empresaId) consultarProyectos();
  }, [empresaId, consultarProyectos]);

  useEffect(() => {
    if (!!proyectoId) consultarManzanas();
  }, [proyectoId, consultarManzanas]);

  useEffect(() => {
    if (!!area && !!precioM2) {
      reset((actual) => ({
        ...actual,
        precioTotal: area * precioM2,
      }));
    }
  }, [area, precioM2, reset]);

  /* La función `limpiarProyecto` establece el valor del elemento con id 'manzanaId' en indefinido. */
  const limpiarProyecto = useCallback(() => {
    setValue('manzanaId', undefined);
    setManzanas([]);
  },[setValue]);

  /*  La función `limpiarEmpresa` borra los valores de `proyectoId` y `manzanaId`. */
  const limpiarEmpresa = useCallback(() => {
    setValue('proyectoId', undefined);
    setProyectos([])
    limpiarProyecto();
  },[limpiarProyecto, setValue]);


  return <Default
    titulo={Boolean(id) ? 'Editar Lote' : 'Nuevo Lote'}
    placeHolder={''}
    mostrarCabeceroFormulario
    cancelar={regresar}
    frmId="frmLote"
  >
    <FormProvider {...methods}>
      <form noValidate onSubmit={handleSubmit(guardar)} id="frmLote">
        <Grid container spacing={2} id="frmLote">
          <Grid item xs={12} md={3}>
            <TextField
              type="number"
              label="Número:"
              name="numero"
              placeHolder="Número"
              inputProps={{ min: 1 }}
              error={errors.numero}
              helperText={errors.numero?.message}
              required
            />
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              label="Descripción:"
              name="descripcion"
              placeHolder="Descripción"
              inputProps={{ maxLength: 250 }}
              error={errors.descripcion}
              helperText={errors.descripcion?.message}
              required
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <AutoComplete
              label="Empresa: *"
              name="empresaId"
              options={empresas}
              error={errors.empresaId}
              onClear={limpiarEmpresa}
              helperText={errors.empresaId?.message}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <AutoComplete
              label="Proyecto: *"
              name="proyectoId"
              options={proyectos}
              error={errors.proyectoId}
              onClear={limpiarProyecto}
              helperText={errors.proyectoId?.message}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <AutoComplete
              label="Manzana: *"
              name="manzanaId"
              options={manzanas}
              error={errors.manzanaId}
              helperText={errors.manzanaId?.message}
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <AutoComplete
              label="Tipo: *"
              name="tipoLoteId"
              options={tiposLote}
              error={errors.tipoLoteId}
              helperText={errors.tipoLoteId?.message}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <AutoComplete
              label="Categoria: *"
              name="categoriaId"
              options={categorias}
              error={errors.categoriaId}
              helperText={errors.categoriaId?.message}
            />
          </Grid>
          <Grid item md={3} xs={12} />
          <Grid item md={2} xs={12}>
            <TextField
              type="number"
              label="Frente:"
              name="frente"
              placeHolder="Frente m²"
              inputProps={{ min: 1 }}
              error={errors.frente}
              helperText={errors.frente?.message}
              required
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <TextField
              type="number"
              label="Fondo:"
              name="fondo"
              placeHolder="Fondo m²"
              inputProps={{ min: 1 }}
              error={errors.fondo}
              helperText={errors.fondo?.message}
              required
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              type="number"
              label="Lateral Izquierdo:"
              name="lateralIzquierdo"
              placeHolder="Lateral Izquierdo"
              inputProps={{ min: 1 }}
              error={errors.lateralIzquierdo}
              helperText={errors.lateralIzquierdo?.message}
              required
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              type="number"
              label="Lateral Derecho:"
              name="lateralDerecho"
              placeHolder="Lateral Derecho"
              inputProps={{ min: 1 }}
              error={errors.lateralDerecho}
              helperText={errors.lateralDerecho?.message}
              required
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              type="number"
              label="Área:"
              name="area"
              placeHolder="Área m²"
              inputProps={{ min: 1 }}
              error={errors.area}
              helperText={errors.area?.message}
              required
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              type="number"
              label="Construcción:"
              name="construccion"
              placeHolder="Construcción m²"
              inputProps={{ min: 1 }}
              error={errors.construccion}
              helperText={errors.construccion?.message}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label="C.Norte:"
              name="calleNorte"
              placeHolder="Norte"
              inputProps={{ maxLength: 50 }}
              error={errors.calleNorte}
              helperText={errors.calleNorte?.message}
              required
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label="C.Sur:"
              name="calleSur"
              placeHolder="Sur"
              inputProps={{ maxLength: 50 }}
              error={errors.calleSur}
              helperText={errors.calleSur?.message}
              required
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label="C.Este:"
              name="calleEste"
              placeHolder="Este"
              inputProps={{ maxLength: 50 }}
              error={errors.calleEste}
              helperText={errors.calleEste?.message}
              required
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label="C.Oeste:"
              name="calleOeste"
              placeHolder="Oeste"
              inputProps={{ maxLength: 50 }}
              error={errors.calleOeste}
              helperText={errors.calleOeste?.message}
              required
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              type="number"
              label="Precio m²:"
              name="precioM2"
              placeHolder="Precio m² $"
              inputProps={{ min: 1 }}
              error={errors.precioM2}
              helperText={errors.precioM2?.message}
              required
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              type="number"
              label="Total:"
              name="precioTotal"
              placeHolder="$0.00"
              inputProps={{ min: 1 }}
              error={errors.precioTotal}
              helperText={errors.precioTotal?.message}
              disabled
              required
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <AutoComplete
              label="Estatus: *"
              name="estadoId"
              options={estadosLote}
              error={errors.estadoId}
              helperText={errors.estadoId?.message}
            />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  </Default>;
};

export default React.memo(Lote);