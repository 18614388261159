import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles(() => ({
  root: {
    marginBottom: 16,
    '& > .MuiGrid-item': {
      paddingBlock: 4,
    },
    '& .MuiGrid-item': {
      paddingInline: 8,
    },
  },
  header: {
    width: 150,
  },
  chip: {
    width: 80,
    color: 'white',
    '&.Apartado': { backgroundColor: paleta.apartado.main },
    '&.Vendido': { backgroundColor: paleta.vendido.main },
    '&.Eliminado': { backgroundColor: paleta.activo.primary, },
  },
}));

export default styles;
