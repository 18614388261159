import React from 'react'

import { MANZANAS } from '../../configuraciones/endpoints';
import Listados from '../../contenedores/Listados';
import FiltrosComponent from '../../componentes/FiltrosComponent';
import Estatus from '../../componentes/Estatus';
import BarraProgreso from '../../componentes/BarraProgreso';

const cabeceros = [
  { label: 'Nombre', key: 'nombre' },
  { label: 'Clave', key: 'clave' },
  { label: 'Fecha', key: 'fecha' },
  { label: 'Empresa', transform: ({ proyecto }) => proyecto?.empresa?.nombre },
  { label: 'Proyecto', transform: ({ proyecto }) => proyecto?.nombre },
  { label: 'Lotes', key: 'numLotes' },
  { label: 'Vendidos', key: 'lotesVendidos' },
  { label: 'Disponibles', key: 'lotesDisponibles' },
  {
    label: 'Avance', align: 'center', width: 100,
    transform: ({ numLotes, lotesVendidos }) => <BarraProgreso total={numLotes} avance={lotesVendidos} />
  },
  {
    label: 'Estatus', transform: ({ habilitado, activo }) => (
      <Estatus habilitado={habilitado} activo={activo} />
    ),
  },
];

const Manzanas = ({ history, location }) => (
  <Listados
    titulo="Manzanas"
    customCabecero={<FiltrosComponent mostrarProyectos />}
    history={history}
    location={location}
    showActions
    cabeceros={cabeceros || []}
    apiKey={MANZANAS}
  />
);

export default Manzanas;
