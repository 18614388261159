import React from 'react';
import moment from 'moment';

import styles from './styles';
import FiltrosFlujos from './filtroFlujos';
import Listados from '../../contenedores/Listados';
import { FLUJOS } from '../../configuraciones/endpoints';
import { formatoMoneda } from '../../utilidades/functions';


const ReporteFlujos = ({ history, location }) => {

  const cabeceros = [
    { label: 'Folio', key: 'folio' },
    { label: 'Flujo', transform: ({ ingreso }) => ingreso ? 'Ingreso' : 'Egreso' },
    { label: 'Fecha', width: 100, transform: ({ fecha }) => moment(fecha).format('DD-MM-YYYY') },
    { label: 'Concepto', key: 'concepto' },
    { label: 'Empresa', transform: ({ venta }) => venta?.lote?.manzana?.proyecto?.empresa?.nombre },
    { label: 'Proyecto', transform: ({ venta }) => venta?.lote?.manzana?.proyecto?.nombre },
    { label: 'Manzana', transform: ({ venta }) => venta?.lote?.manzana?.nombre },
    { label: 'Lote', transform: ({ venta }) => venta?.lote?.numero },
    { label: 'Cliente', transform: ({ venta }) => venta?.cliente?.nombre },
    { label: 'Movimiento', transform: ({ tipoMovimiento }) => tipoMovimiento?.nombre },
    { label: 'Cuenta', transform: ({ cuenta }) => cuenta?.nombre },
    { label: 'Referencia', key: 'referencia' },
    { label: 'Forma de pago', transform: ({ cuenta }) => cuenta?.tipoCuenta?.nombre },
    { label: 'Total', transform: ({ importe }) => formatoMoneda(importe) },
    { label: 'Saldo anterior', transform: ({ saldoAnterior }) => formatoMoneda(saldoAnterior) },
    { label: 'Saldo posterior', transform: ({ saldoPosterior }) => formatoMoneda(saldoPosterior) },
  ];

  return (
    <Listados
      titulo="Reporte Flujos"
      customCabecero={<FiltrosFlujos />}
      history={history}
      location={location}
      cabeceros={cabeceros}
      apiKey={FLUJOS}
      soloListado
      esReporte
    />
  );
}

export default ReporteFlujos;
