import React, { useCallback, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';

import axios from '../../configuraciones/axios';
import config from '../../configuraciones/general';
import Select from '../../componentes/Select';
import TextField from '../../componentes/TextField';
import styles from './styles';
import endpoints, {
  EMPRESAS, USUARIOS
} from '../../configuraciones/endpoints';
import AutoComplete from '../../componentes/AutoComplete';
import AccionesCabecero from '../../componentes/AccionesCabecero';
import { ESTADOS_VENTA } from '../../constantes/tipoEstado';
import { tipoEstadosVenta } from '../../constantes/estadosVenta';

const ReporteVentas = () => {
  const classes = styles();
  const [empresas, setEmpresas] = useState([]);
  const [estados, setEstados] = useState([]);
  const [vendedores, setVendedores] = useState([]);
  const [proyectos, setProyectos] = useState([]);
  const [clientes, setClientes] = useState([]);
  const { watch, setValue } = useFormContext();
  const empresaId = watch('empresaId');
  const folio = watch('folio');
  const lote = watch('lote');

  const { superAdmin } = useSelector(
    ({ user: { user: { superAdmin } } }) => ({ superAdmin })
  );

  const consultarDatosIniciales = useCallback(() => new Promise((resolve, reject) => {
    const promesas = [
      axios.get(endpoints.base.opciones(EMPRESAS)),
      axios.get(endpoints.base.opciones(USUARIOS)),
      axios.get(endpoints.estados(ESTADOS_VENTA)),
    ];
    Promise.all(promesas).then((resultados) => {
      const [empresasBd, usuariosBd, estadosBd] = resultados;
      setEmpresas(empresasBd);
      setVendedores(usuariosBd);
      setEstados(superAdmin ? tipoEstadosVenta : estadosBd);
      resolve();
    }).catch(reject);
  }), []);

  const consultarDatosPorEmpresa = useCallback(() => new Promise((resolve, reject) => {
    const promesas = [
      axios.get(endpoints.proyectosPorEmpresa(empresaId)),
      axios.get(endpoints.clientesPorEmpresa(empresaId)),
    ];
    Promise.all(promesas).then((resultados) => {
      const [proyectosBd, clientesBd] = resultados;
      setProyectos(proyectosBd);
      setClientes(clientesBd);
      resolve();
    }).catch(reject)
  }), [empresaId]);

  useEffect(() => consultarDatosIniciales(), [consultarDatosIniciales]);
  useEffect(() => {
    if (!!empresaId) {
      consultarDatosPorEmpresa();
    }
  }, [empresaId, consultarDatosPorEmpresa]);

  /**
   * Valida la longitud del folio y del lote para no dejar escribir más de 18
   * caracteres.
   */
  useEffect(() => {
    if (folio?.length >= config.BIGINT_MAXLENGTH) {
      setValue('folio', folio.substring(0, config.BIGINT_MAXLENGTH));
    }
    if (lote?.length >= config.INT_MAXLENGTH) {
      setValue('lote', lote.substring(0, config.INT_MAXLENGTH));
    }
  }, [setValue, folio, lote]);

  /*  La función `limpiarProyecto` borra los valores de `clienteId`. */
  const limpiarProyecto = useCallback(() => {
    setValue('clienteId', undefined);
  },[setValue]);

    /*  La función `limpiarEmpresa` borra los valores de `proyectoId` y `clienteId`. */
    const limpiarEmpresa = useCallback(() => {
      setValue('proyectoId', undefined);
      setProyectos([]);
      limpiarProyecto();
    },[limpiarProyecto, setValue]);

  return (
    <Grid container justifyContent="flex-end" alignItems="flex-end" className={classes.root}>
      <Grid item xs={12} sm={6} md={3} lg={2}>
        <TextField
          fullWidth
          type="date"
          name="fechaInicial"
          label="Fecha Inicial:"
          inputProps={{ maxLength: 50 }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={2}>
        <TextField
          fullWidth
          type="date"
          name="fechaFinal"
          label="Fecha Final:"
          inputProps={{ maxLength: 50 }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={2}>
        <AutoComplete
          name="empresaId"
          label="Empresa:"
          propValor="id"
          propEtiqueta="nombre"
          options={empresas}
          onClear={limpiarEmpresa}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={2}>
        <AutoComplete
          label="Proyecto:"
          name="proyectoId"
          propValor="id"
          propEtiqueta="nombre"
          options={proyectos}
          onClear={limpiarProyecto}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={2}>
        <AutoComplete
          name="clienteId"
          label="Cliente:"
          propValor="id"
          propEtiqueta="nombre"
          options={clientes}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={2}>
        <AutoComplete
          label="Vendedor:"
          name="vendedorId"
          propValor="id"
          propEtiqueta="nombre"
          options={vendedores}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={2}>
        <Select
          fullWidth
          label="Estado:"
          name="estadoId"
          labelProp="nombre"
          placeHolder="Selecciona una opción"
          options={estados}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={2}>
        <TextField
          name="folio"
          type="number"
          label="Folio:"
          placeHolder="Folio"
          inputProps={{ maxLength: 50 }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={3}>
        <TextField
          name="lote"
          type="number"
          fullWidth
          label="Lote:"
          placeHolder="Lote"
          inputProps={{ maxLength: 50 }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        <AccionesCabecero />
      </Grid>
    </Grid>
  )
}

export default ReporteVentas;
