import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles((theme) => ({
  appBar: {
    [theme.breakpoints.up("md")]: {
      padding: 20,
    },
    [theme.breakpoints.down("sm")]: {
      padding: 10,
    },
    position: 'fixed',
    top: 'auto',
    bottom: 0,
    backgroundColor: paleta.bar.primary,
    color: 'white',
  },
  footer: {
    fontFamily: 'Designer',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    '& h1': {
      margin: 0,
      fontWeight: '500',
      fontSize: 30,
    },
    '& h2': {
      margin: 0,
      fontWeight: '500',
      fontSize: 24,
    },
  },
}));

export default styles;
