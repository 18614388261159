const TiposMovimientoEnum = {
  Apartados: 1,
  Cancelaciones: 2,
  Comisiones: 3,
  Enganches: 4,
  Ingresos: 5,
  Mensualidad: 6,
}

export default TiposMovimientoEnum;
