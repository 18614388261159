import React, { useEffect, useState, useRef, useMemo } from 'react';
import { PanZoomWithCover } from '@sasza/react-panzoom';
import pointInPolygon from 'point-in-polygon';
import { toast } from 'react-toastify';
import { Button, Grid } from '@material-ui/core';
import propTypes from 'prop-types';
import { MdEdit } from 'react-icons/md';
import clsx from 'clsx';

import { ColoresPlanos, ColoresSolidosPlanos } from '../../constantes/coloresPlanos';
import useStyles from './styles';

import FiltroLotes from '../FiltroLotes';
import PunteroPlano from './PunteroPlano';
import Typography from '../Typography';
import Simbologia from '../Simbologia';

const Plano = ({
  img, grabarPuntos, onChange, poligonos, generarLotes, numLotes,
  onChangeLotes, poligonoValidarPunto, mostrarTooltip, alCancelar, opcion,
  manzanaSeleccionada, habilitarEdicion, capturandoIrregular, enAsignacion,
  asignarOrdenLotes, loteId, loteIndice, poligonoIndice, valorLoteIndice,
  mostrarFiltro, filtros, proyectoId, editandoIrregulares, editarLoteIrregularSeccionado,
  onChangeIrregular, mostrarDetalleLote, grupoLotes, perteneceHilera, seleccionandoGrupoLotes,
  cancelarSeleccionGrupoLotes, seleccionarGrupoLoteEditar, asignacionLotes, mostrarSimbologia,
  simbologia
}) => {
  const [grabarVertices, setGrabarVertices] = useState(false);
  const [vertices, setVertices] = useState([]);
  const [valor, setValor] = useState(null);
  const [textoAsignacion, setTextoAsignacion] = useState('');
  const [propiedad, setPropiedad] = useState('');
  const [asignandoOrden, setAsignandoOrden] = useState(false);
  const [manzanaValida, setManzanaValida] = useState(false);
  const timer = useRef(null);
  const agregoPunto = useRef(false);
  const enLotes = opcion === 'LOTES';
  const styles = useStyles();

  const elementosPoligonos = useMemo(() => poligonos.map((poly) => {
    const coordenadas = Array.isArray(poly) ? [poly] : poly?.coordenadas;
    return coordenadas?.map((poligono) => (
      <polygon
        key={poligono.indice}
        points={(poligono.coordenadas || poligono)?.map(({ x, y }) => `${x},${y}`).join(',')}
        fill={poly.fill || poligono.fill || ColoresPlanos.Default}
        stroke={poly.stroke || poligono.stroke || ColoresSolidosPlanos.Default}
        strokeWidth={poly.strokeWidth || 2}
        style={{ pointerEvents: 'all' }}
        onClick={() => handlePolygonClick(poligono.id, poligono.indiceLote, poligono.indicePoligono, poligono.grupoLotes, poligono.manzanaId)}
      >
        {mostrarTooltip && (
          <title>{poligono.tooltip || `Lote ${poligono.lote}`}</title>
        )}
      </polygon>
    ));
  }), [poligonos, manzanaSeleccionada, mostrarTooltip, editandoIrregulares, editarLoteIrregularSeccionado, asignarOrdenLotes, asignandoOrden, manzanaValida, seleccionandoGrupoLotes]);

  /**
   * Metodo al hacer clic en un poligono
   */
  const handlePolygonClick = (id, indiceLote, indicePoligono, grupoLotes, manzana) => {
    if (asignandoOrden && manzanaValida) setValor(indiceLote);
    if (seleccionandoGrupoLotes && grupoLotes !== undefined && (manzana === manzanaSeleccionada.id)) seleccionarGrupoLoteEditar(grupoLotes);

    loteId(id);
    if (editandoIrregulares && (manzana === manzanaSeleccionada.id)) {
      poligonoIndice(indicePoligono);
      loteIndice(indiceLote);
    }
    if (mostrarFiltro) mostrarDetalleLote();
  };

  /**
   * Metodo obtener intermedios de la manzana 
   */
  const obtenerIntermedios = (p1, p2, intermedios, esDivision = false) => {
    const { x: x1, y: y1 } = p1;
    const { x: x2, y: y2 } = p2;
    const dx = (x2 - x1) / intermedios;
    const dy = (y2 - y1) / intermedios;
    const puntos = [];

    for (let i = 1; i < intermedios; i++) {
      const x = parseInt(x1) + i * dx;
      const y = parseInt(y1) + i * dy;
      puntos.push({ x, y });
    }

    if (intermedios >= 1 && !esDivision)
      return [p1, ...puntos, p2];

    return puntos;
  };

  /**
   * Metodo obtener la división (puntos) de la manzana 
   */
  const obtenerDivisionManzana = (numLotes) => {
    const [puntoA] = obtenerIntermedios(vertices[0], vertices[3], 2, true);
    const [puntoB] = obtenerIntermedios(vertices[1], vertices[2], 2, true);
    const puntos = obtenerIntermedios(puntoA, puntoB, numLotes);

    return puntos;
  };

  /**
   * Metodo que se encarga para realizar la división de los lotes regulares a partir de sus vertices
   * y número de lotes por hileras
   */
  const obtenerLotesManzana = () => {
    if (!generarLotes || vertices.length !== 4) return;

    const puntos1 = obtenerIntermedios(vertices[0], vertices[1], numLotes);
    const puntosDivivision = obtenerDivisionManzana(numLotes);
    const puntos2 = obtenerIntermedios(vertices[3], vertices[2], numLotes);
    const lotes = [];

    for (let i = 0; i < numLotes; i++) {
      if (perteneceHilera === 0) {
        lotes.push({
          hilera: 1,
          regular: true,
          coordenadas: [
            { ...puntos1[i], orden: 1 },
            { ...puntos1[i + 1], orden: 2 },
            { ...puntosDivivision[i + 1], orden: 3 },
            { ...puntosDivivision[i], orden: 4 },
          ],
          fill: ColoresPlanos.Hilera1,
          stroke: ColoresSolidosPlanos.Hilera1,
          grupoLotes
        });
        lotes.push({
          hilera: 2,
          regular: true,
          coordenadas: [
            { ...puntosDivivision[i], orden: 1 },
            { ...puntosDivivision[i + 1], orden: 2 },
            { ...puntos2[i + 1], orden: 3 },
            { ...puntos2[i], orden: 4 },
          ],
          fill: ColoresPlanos.Hilera2,
          stroke: ColoresSolidosPlanos.Hilera2,
          grupoLotes

        });
      } else {
        lotes.push({
          hilera: perteneceHilera,
          regular: true,
          coordenadas: [
            { ...puntos1[i], orden: 1 },
            { ...puntos1[i + 1], orden: 2 },
            { ...puntos2[i + 1], orden: 3 },
            { ...puntos2[i], orden: 4 },
          ],
          fill: ColoresPlanos['Hilera' + perteneceHilera],
          stroke: ColoresSolidosPlanos['Hilera' + perteneceHilera],
          grupoLotes
        });
      }
    }
    onChangeLotes(lotes);
    habilitarEdicion(false);
  };

  const handleClick = (e) => {
    const { x, y } = e;

    if (poligonoValidarPunto && !pointInPolygon([x, y], poligonoValidarPunto.map(({ x, y }) => [x, y]))) {
      agregoPunto.current = false;
      setManzanaValida(false);
      return toast.warning('La selección del cursor no pertenece a la manzana seleccionada');
    } else {
      setManzanaValida(true);
    }

    if (!grabarVertices || (grabarVertices && generarLotes && vertices.length > 10)) return;

    agregoPunto.current = true;
    setVertices((c) => [...c, { x, y, orden: c.length + 1 }]);
  };

  const onContainerPositionChange = () => {
    if (!grabarVertices || vertices.length === 0) return;

    if (timer.current) clearTimeout(timer.current);

    if (agregoPunto.current) {
      timer.current = setTimeout(() => {
        if (!poligonoValidarPunto ||
          pointInPolygon([vertices[vertices.length - 1].x, vertices[vertices.length - 1].y], poligonoValidarPunto.map(({ x, y }) => [x, y]))) {
          setVertices((c) => c.slice(0, -1));
        }
      }, 200);
    }
  };

  const grabar = () => {
    editarLoteIrregularSeccionado ? onChangeIrregular(valorLoteIndice, [...vertices]) : onChange([...vertices]);
    setVertices([]);
    habilitarEdicion(false);
  };

  const cancelar = () => {
    if (enAsignacion) {
      alCancelar();
    } else {
      setVertices([]);
      alCancelar();
      habilitarEdicion(false);
    }
  };

  useEffect(() => setGrabarVertices(grabarPuntos), [grabarPuntos]);
  useEffect(() => { if (!manzanaValida) setValor(null); }, [manzanaValida]);

  useEffect(() => {
    if (!capturandoIrregular && !editarLoteIrregularSeccionado) {
      obtenerLotesManzana();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vertices]);

  const asignacionOrdenLotes = () => {
    asignarOrdenLotes(propiedad, valor);
    setAsignandoOrden(false);
    setValor(null)
  }

  const loteInicial = () => {
    setAsignandoOrden(true);
    setTextoAsignacion('inicial');
    setPropiedad('loteInicial');
  };

  const loteFinal = () => {
    setAsignandoOrden(true);
    setTextoAsignacion('final');
    setPropiedad('loteFinal');
  };

  useEffect(() => setVertices([]), [manzanaSeleccionada]);

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>

      <PanZoomWithCover
        cover={img}
        onContainerClick={handleClick}
        zoomInitial={0.5}
        onContainerPositionChange={onContainerPositionChange}
      >
        <svg
          width="10000"
          height="10000"
          style={{ background: 'initial', display: 'block', }}
        >
          {elementosPoligonos}
          {grabarVertices && vertices.map(({ x, y }) => (
            <PunteroPlano x={x} y={y} key={`${x}-${y}`} />
          ))}
        </svg>
      </PanZoomWithCover>
      {
        grabarPuntos && manzanaSeleccionada &&
        <div className={clsx(styles.accionesBase, styles.acciones)}>
          <Grid container spacing={4} style={{ padding: 10 }}>
            <Grid item xs={6}>
              <Button fullWidth size="small" className={styles.btnCancelar} onClick={cancelar}>
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth size="small" className={styles.btnGuardar} onClick={grabar} disabled={vertices.length < 3}>
                Guardar
              </Button>
            </Grid>
          </Grid>
        </div>
      }

      {
        enLotes && seleccionandoGrupoLotes &&
        <div className={clsx(styles.accionesBase, styles.acciones)}>
          <Grid container spacing={4} style={{ padding: 10 }}>
            <Grid item xs={6}>
              <Typography className={clsx(styles.texto)}>Selecciona el grupo de lotes regulares a editar</Typography>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth size="small" variant='outlined' className={styles.btnEditar} onClick={() => cancelarSeleccionGrupoLotes()}>
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </div>
      }

      {
        enLotes && editandoIrregulares &&
        <div className={clsx(styles.accionesBase, styles.acciones)}>
          <Grid container spacing={4} style={{ padding: 10 }}>
            <Grid item xs={6}>
              <Typography className={clsx(styles.texto)}>Selecciona el lote irregular a editar</Typography>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth size="small" variant='outlined' className={styles.btnEditar} onClick={cancelar}>
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </div>
      }

      {
        !asignandoOrden && enAsignacion && asignacionLotes && manzanaSeleccionada.id && manzanaSeleccionada.hileras !== null &&
        <div className={clsx(styles.accionesBase)}>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Button fullWidth size="small" variant='outlined' className={styles.btnEditar} onClick={() => loteInicial()}>
                <MdEdit size={20} className={clsx(styles.iconoEditar)} /> Asignar lote inicial
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth size="small" variant='outlined' className={styles.btnEditar} onClick={() => loteFinal()}>
                <MdEdit size={20} className={clsx(styles.iconoEditar)} /> Asignar lote final
              </Button>
            </Grid>
          </Grid>
        </div>
      }

      {
        asignandoOrden &&
        <div className={clsx(styles.accionesBase, styles.acciones)}>

          <Grid container spacing={4} style={{ padding: 10, color: 'white' }}>
            <Grid item xs={6}>
              Seleccionar el lote {textoAsignacion} de la manzana
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                size="small"
                className={styles.btnGuardar}
                onClick={
                  () => { asignacionOrdenLotes(); }
                }
                disabled={valor === null}
              >
                Aceptar
              </Button>
            </Grid>
          </Grid>
        </div>
      }
      {
        mostrarFiltro && <FiltroLotes proyectoId={proyectoId} asignarFiltros={filtros} className={clsx(styles.accionesBase, styles.acciones)} />
      }
      {
        mostrarSimbologia && <Simbologia simbologia={simbologia} />
      }
    </div >
  );
};

Plano.propTypes = {
  img: propTypes.string,
  grabarPuntos: propTypes.bool,
  onChange: propTypes.func,
  poligonos: propTypes.array,
  opcion: propTypes.oneOf(['MANZANAS', 'LOTES']),
  configurarLotes: propTypes.bool,
  manzanaSeleccionada: propTypes.object,
  asignarInicial: propTypes.func,
  asignarFinal: propTypes.func,
  loteId: propTypes.func,
  mostrarDetalleLote: propTypes.func,
  cancelarEdicionGrupoLotes: propTypes.func,
  seleccionarGrupoLoteEditar: propTypes.func,
};

Plano.defaultProps = {
  img: '',
  grabarPuntos: false,
  onChange: () => { },
  poligonos: [],
  opcion: 'MANZANAS',
  configurarLotes: false,
  manzanaSeleccionada: {},
  asignarInicial: () => { },
  asignarFinal: () => { },
  loteId: () => { },
  mostrarDetalleLote: () => { },
  cancelarSeleccionGrupoLotes: () => { },
  seleccionarGrupoLoteEditar: () => { },
};

export default Plano;
