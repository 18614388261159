import React, { useRef, useState } from 'react';
import { Chip } from '@material-ui/core';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import styles from './styles';

import FiltrosLotes from './FiltrosLotes';
import Listados from '../../contenedores/Listados';
import { LOTES } from '../../configuraciones/endpoints';
import EstadosLoteEnum from '../../constantes/estadosLoteEnum';
import ModalImportar from './modalImportar';


const Lotes = ({ history, location }) => {
  const perfil = useSelector(({ user }) => user?.user?.perfil);
  const clases = styles();
  const [importarAbierto, setImportarAbierto] = useState(false);
  const listadoRef = useRef();

  /** Método que abre el modal para el importado de los lotes. */
  const abrirModal = () => {
    setImportarAbierto(true);
  }

  /** Método que abre el modal para el importado de los lotes. */
  const cerrarModal = () => {
    setImportarAbierto(false);
    listadoRef.current.consultarListado();
  }


  const cabeceros = [
    { label: 'No.', key: 'numero' },
    { label: 'Clave', key: 'clave' },
    { label: 'Empresa', transform: ({ manzana }) => manzana.proyecto.empresa.nombre },
    { label: 'Proyecto', transform: ({ manzana }) => manzana.proyecto.nombre },
    { label: 'Tipo', transform: ({ tipoLote }) => tipoLote.nombre },
    { label: 'Manzana', transform: ({ manzana }) => manzana.nombre },
    { label: 'Categoría', transform: ({ categorium }) => categorium.nombre },
    { label: 'Frente', key: 'frente' },
    { label: 'Fondo', key: 'fondo' },
    { label: 'L. IZQ.', key: 'lateralIzquierdo' },
    { label: 'L. DER.', key: 'lateralDerecho' },
    { label: 'Área', key: 'area' },
    { label: 'Cons.', key: 'construccion' },
    { label: `$ m²`, key: 'precioM2' },
    { label: 'Total', key: 'precioTotal' },
    {
      label: 'Estatus', align: 'center', transform: ({ estado, activo }) => (
        <Chip
          label={activo ? estado.nombre : 'Eliminado'}
          size="small"
          className={clsx(clases.chip, activo ? estado.nombre : 'Eliminado')}
        />
      ),
    },
  ];

  return (
    <>
      <Listados
        ref={listadoRef}
        titulo="Lotes"
        parametros={perfil?.lotesDisponibles && { estadoId: EstadosLoteEnum.Disponible }}
        customCabecero={<FiltrosLotes mostrarEstados={!perfil?.lotesDisponibles} />}
        history={history}
        location={location}
        showActions
        cabeceros={cabeceros}
        apiKey={LOTES}
        importar={abrirModal}
      />
      <ModalImportar
        abierto={importarAbierto}
        cerrarModal={cerrarModal}
        alEnviar={() => { }}
      />
    </>
  );
}

export default Lotes;
