import React from 'react';

const PunteroPlano = ({ x, y, radius = 7, color = 'blue' }) => {
  const size = radius * 2;

  return (
    <svg x={x - radius} y={y - radius} width={size} height={size} viewBox={`0 0 ${size} ${size}`} overflow="visible">
      <circle cx={radius} cy={radius} r={radius} stroke={color} strokeWidth="1.5" fill='transparent'/>
      <line x1={radius} y1={0} x2={radius} y2={size} stroke={color} strokeWidth="1.5" />
      <line x1={0} y1={radius} x2={size} y2={radius} stroke={color} strokeWidth="1.5" />
    </svg>
  );
};

export default PunteroPlano;
