/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import { Grid, IconButton } from '@material-ui/core';
import { toast } from 'react-toastify';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { BsFillSignpost2Fill } from 'react-icons/bs';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { LuCalendarClock } from 'react-icons/lu';
import { useSelector } from 'react-redux';
import moment from 'moment';
import queryString from 'query-string'

import Default from '../../contenedores/Default';
import Boton from '../../componentes/Button';
import TextField from '../../componentes/TextField';
import AutoComplete from '../../componentes/AutoComplete';
import Typography from '../../componentes/Typography';
import CustomSelect from '../../componentes/Select';
import axios from '../../configuraciones/axios';

import endpoints, {
  USUARIOS, VENTAS, COTIZACION, PROYECTOS, VENDEDORES, LOTES,
} from '../../configuraciones/endpoints';
import paleta from '../../configuraciones/paleta';

import { ConfiguracionXMLHttp, peticionXMLHttp } from '../../utilidades/peticionXMLHttp';

import {
  ALERTA_COTIZACION_CONTADO, CAMPO_NUMERO_POSITIVO, CAMPO_REQUERIDO,
  EDITAR_VENTA_ABONADA,
  ELEMENTO_NO_ENCONTRADO, LOTE_VENTA_NO_ENCONTRADO, MAX_LONGITUD, MINIMO_MAYOR_ENGANCHE, TOTAL_INCORRECTO,
} from '../../constantes/mensajes';
import tiposVenta from '../../constantes/tiposVenta';
import opcionesAplica from '../../constantes/aplica';
import tiposCredito from '../../constantes/tiposCredito';
import EstadosLoteEnum from '../../constantes/estadosLoteEnum';
import EstadosVentaEnum from '../../constantes/estadosVentaEnum';

import styles from './styles';
import BusquedaLotes from './busquedaLotes';
import VentaAnticipos from './anticipos';
import NuevoCliente from './cliente';
import { ANTICIPO_MAXIMO, ANTICIPO_MINIMO, LOTE_REQUERIDO } from '../../constantes/mensajes';

const schema = yup.object({
  loteId: yup.string().required(),
  credito: yup.bool().required(),
  clienteId: yup.string().required(CAMPO_REQUERIDO),
  vendedorId: yup.string().required(CAMPO_REQUERIDO),
  fecha: yup.string().required(CAMPO_REQUERIDO),
  descuento: yup.bool().required(CAMPO_REQUERIDO).typeError(CAMPO_REQUERIDO),
  cantidadDescuento: yup.number()
    .when('descuento', ([descuento], field) => {
      return (descuento ? field.required(CAMPO_REQUERIDO) : field)
    }),
  anticipoMinimo: yup.string().required(CAMPO_REQUERIDO),
  total: yup.string().required(CAMPO_REQUERIDO),
  enganche: yup.string().required(CAMPO_REQUERIDO),
  financiado: yup.string().required(CAMPO_REQUERIDO),
  tipoCredito: yup.string().nullable()
    .when('credito', ([credito], field) => {
      return (credito ? field.required(CAMPO_REQUERIDO) : field)
    }),
  mesesFinanciamiento: yup.string()
    .when('credito', ([credito], field) => {
      return (credito ? field.required(CAMPO_REQUERIDO) : field)
    })
    .notOneOf(['0'], CAMPO_NUMERO_POSITIVO)
    .max(3, MAX_LONGITUD(3)),
  tasaInteres: yup.string()
    .when(['tipoCredito',], ([tipoCredito], field) => {
      return (tipoCredito === 'MCI' ? field.required(CAMPO_REQUERIDO) : field)
    }),
  fechaInicioCredito: yup.string().required(CAMPO_REQUERIDO),
  comision: yup.bool().required(CAMPO_REQUERIDO),
  cantidadComision: yup.string().required(CAMPO_REQUERIDO),
  cantidadComisionApartado: yup.string().required(CAMPO_REQUERIDO),
});

const Venta = ({ history, match }) => {
  const { params: { id } } = match;
  const query = queryString.parse(history.location.search);
  const loteId = query && query.loteId;

  const hoy = moment().format('yyyy-MM-DD');
  const classes = styles();

  const [venta, setVenta] = useState();
  const [clientes, setClientes] = useState([]);
  const [vendedores, setVendedores] = useState([]);
  const [lote, setLote] = useState();
  const [proyecto, setProyecto] = useState();
  const [ayudaLoteAbierto, setAyudaLoteAbierto] = useState(false);
  const [nuevoClienteAbierto, setNuevoClienteAbierto] = useState(false);
  const [anticipos, setAnticipos] = useState([]);
  const token = useSelector(({ user }) => user?.token);

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      anticipo: 0,
      credito: true,
      habilitado: true,
      descuento: false,
      cantidadDescuento: 0,
      comision: false,
      fecha: hoy,
      fechaInicioCredito: hoy,
      ventaAnticipos: [],
    }
  });

  const {
    trigger,
    getValues,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = methods;

  const descuento = watch('descuento');
  const tipoCredito = watch('tipoCredito');
  const comision = watch('comision');
  const credito = watch('credito');

  /**
   * Método que regresa al listado de ventas.
   */
  const regresar = () => {
    history.push({
      pathname: '/ventas/ventas',
    });
  };

  /**
   * Método que valida el anticipo sea mayor del anticipo mínimo y menor que el total
   * de la venta.
   * @param {*} param Objeto con información de la venta (anticipoMinimo, anticipo y total) 
   * @returns Verdadero o falso en caso de cumplir o no las validaciones.
   */
  const validaAnticipo = ({ enganche, anticipoMinimo, anticipo, total, cantidadDescuento, financiado }) => {
    const minimo = parseFloat(anticipoMinimo, 10);
    const pagado = parseFloat(anticipo, 10);
    const descuento = parseFloat(cantidadDescuento, 10);
    const aPagar = parseFloat(total, 10);
    const impEnganche = parseFloat(enganche, 10);
    const financiadoVenta = parseFloat(financiado, 10);

    let valido = true;

    switch (true) {
      case (minimo > impEnganche):
        toast.warning(MINIMO_MAYOR_ENGANCHE);
        valido = false;
        break;

      case (typeof anticipo === 'undefined' || (minimo - descuento) > pagado):
        toast.warning(ANTICIPO_MINIMO);
        valido = false;
        break;

      case (pagado > aPagar):
        toast.warning(ANTICIPO_MAXIMO);
        valido = false;
        break; 
        
      case (financiadoVenta < 0):
        toast.warning(TOTAL_INCORRECTO);
        valido = false;
        break;
      
      default:
        break;
    }
    return valido;
  };

  /**
   * Método que devuelve el estado de la venta de acuerdo si fue
   * completamente pagada o no.
   * @param {*} ventaInfo Objeto con información de la venta (credito, anticipo y total)
   * @returns 
   */
  const asignaEstadoVenta = (ventaInfo) => {
    const anticipo = parseFloat(ventaInfo.anticipo);
    const total = parseFloat(ventaInfo.total - ventaInfo.cantidadDescuento);
    
    if (!ventaInfo.credito && anticipo === total) {
      return EstadosVentaEnum.Vendido;
    }
    return EstadosVentaEnum.Apartado;
  };

  /**
   * Método que guarda o edita una venta.
   */
  const guardar = useCallback((venta) => {
    const metodo = id ? 'put' : 'post';
  
    const ventaObj = {
      ...venta,
      pagado: venta.anticipo,
    };

    const configuracion = new ConfiguracionXMLHttp({
      tipoPeticion: metodo,
      body: ventaObj,
      token: token,
      urlEndpoint: endpoints.base.url(VENTAS, id),
      mensajeNoAceptable: EDITAR_VENTA_ABONADA,
      formatoArchivo: 'word',
      nombreArchivo: `Contrato de venta a ${credito ? 'credito' : 'contado'}`,
    });
  
    peticionXMLHttp(configuracion).then(regresar).catch(() => { });
  }, [credito]);
  
  /**
   * Evento que se ejecuta al enviar el formulario de venta.
   * @param {*} venta Objeto con información de la venta.
   */
  const alEnviar = (ventaObj) => {
    if (validaAnticipo(ventaObj)) {
      const estadoIdVenta = asignaEstadoVenta(ventaObj);
      if (ventaObj.credito && parseFloat(ventaObj.financiado) === 0) ventaObj.credito = false;
      
      const ventaGuardar = {
        ...ventaObj,
        estadoId: estadoIdVenta,
        lote: {
          ...lote,
          estadoId: estadoIdVenta === EstadosVentaEnum.Vendido ?
            EstadosLoteEnum.Vendido : EstadosLoteEnum.Apartado,
        },
      };
      guardar(ventaGuardar);
    }
  };

  /**
   * Método que uuna empresa y clientes de acuerdo
   * al lote seleccionado los datos
   * @param {*} loteInfo Información del lote seleccionado.
   * @returns Promesa de petición con los datos del lote.
   */
  const obtenerDatosLote = (loteInfo) => new Promise((resolve, reject) => {
    const { proyectoId, proyecto: { empresaId } } = loteInfo.manzana;
    const promesas = [
      axios.get(endpoints.base.url(PROYECTOS, proyectoId)),
      axios.get(endpoints.clientesPorEmpresa(empresaId)),
    ];
    return Promise.all(promesas).then(resolve).catch(reject)
  });

  /**
   * Método que consulta los datos inciales de la pantalla
   * (vendedores y la venta por id).
   * @returns Promesa de resultado.
   */
  const consultarDatosIniciales = () => new Promise((resolve, reject) => {
    const promesas = [
      axios.get(endpoints.base.url(USUARIOS, VENDEDORES)),
    ];

    if (id) promesas.push(axios.get(endpoints.base.url(VENTAS, id)));

    Promise.all(promesas).then(async (resultadosPromesas) => {
      const [vendedoresBd, ventaBd] = resultadosPromesas;
      setVendedores(vendedoresBd);
      if (ventaBd?.id) {
        setVenta(ventaBd);
        const [proyectoBd, clientesBd] = await obtenerDatosLote(ventaBd.lote);
        setClientes(clientesBd);
        setProyecto(proyectoBd);
        setLote(ventaBd.lote);
        reset({
          ...ventaBd,
        });
        setAnticipos(ventaBd.ventaAnticipos);
      } else if (id) {
        toast.warning(ELEMENTO_NO_ENCONTRADO);
        regresar();
      }
      resolve();
    }).catch(reject);
  });

  /**
   * Evento que se ejecuta al seleccionar un lote en la ayuda de lotes.
   * @param {*} loteSeleccionado Información del lote seleccionado.
   */
  const seleccionarLote = async (loteSeleccionado) => {
    const [proyectoBd, clientesBd] = await obtenerDatosLote(loteSeleccionado);
    setProyecto(proyectoBd);
    setClientes(clientesBd);
    const { anticipoFijo, cantidadAnticipo } = proyectoBd;
    reset((curr) => ({
      ...curr,
      loteId: loteSeleccionado.id,
      enganche: anticipoFijo ?
        cantidadAnticipo : (loteSeleccionado.precioTotal * cantidadAnticipo / 100),
      anticipoMinimo: proyectoBd?.apartadoMinimo,
      total: loteSeleccionado.precioTotal,
      financiado: loteSeleccionado.precioTotal,
      ...(comision && {
        cantidadComision: proyectoBd.cantidadComision,
        cantidadComisionApartado: proyectoBd?.apartadoComision,
      }),
    }));
    setLote(loteSeleccionado);
    cerrarAyudaLote();
  };

  /**
   * Método que cierra la ventana emergente de lotes.
   */
  const cerrarAyudaLote = () => {
    if (loteId) {
      history.replace({ search: `loteId=${loteId}` });
    }
    setAyudaLoteAbierto(false);
  };

  /**
   * Método que abre la ventana emergente de un nuevo cliente.
   */
  const agregarCliente = () => {
    if (lote) setNuevoClienteAbierto(true);
    else toast.warning(LOTE_REQUERIDO);
  };

  /**
   * Método que cierra la ventana nuevo cliente.
   */
  const cerrarNuevoCliente = async (nuevo) => {
    if (nuevo) {
      const { proyecto: { empresaId } } = lote?.manzana;
      const clientesBd = await axios.get(endpoints.clientesPorEmpresa(empresaId));
      setClientes(clientesBd);
    }
    setNuevoClienteAbierto(false);
  };

  /**
   * Evento que se ejecuta al cambiar los anticipos capturados.
   * @param {*} nuevosAnticipos Lista de anticipos capturados.
   */
  const alCambiarAnticipos = (nuevosAnticipos) => {
    const { cantidadDescuento, total } = getValues();
    const anticipo = nuevosAnticipos.reduce(
      (total, { importe }) => total + parseFloat(importe, 10), 0)
      .toFixed(2) || 0;
    let ventaAnticipos = [];
    venta?.ventaAnticipos.forEach((ventaAnticipo) => {
      const anticipoAgregar = nuevosAnticipos.find(({ id }) => id === ventaAnticipo.id);
      if (anticipoAgregar) ventaAnticipos.push(ventaAnticipo);
      else ventaAnticipos.push({ ...ventaAnticipo, activo: false });
    });
    ventaAnticipos = ventaAnticipos.concat(nuevosAnticipos.filter(({ id }) => !id));
    const financiado = parseFloat(total) - (parseFloat(cantidadDescuento) || 0) - parseFloat(anticipo);
    reset((curr) => ({ ...curr, ventaAnticipos, anticipo, financiado }))
  }

  /**
   * Genera una cotización enviando una solicitud POST con datos
   * específicos y abre la URL de respuesta en una nueva ventana si los datos son válidos; de lo
   * contrario, muestra un mensaje de advertencia.
   */
  const generarCotizacion = () => {
    trigger().then((valido) => {
      if (valido) {
        const body = getValues();
        if (validaAnticipo(body)) {
          if (!credito || (parseFloat(body.anticipo) >= parseFloat(body.total))) {
            toast.warning(ALERTA_COTIZACION_CONTADO);
          } else {
            body.nombreCliente = clientes.find(({ id }) => id === body.clienteId).nombre;
            body.nombreVendedor = vendedores.find(({ id }) => id === body.vendedorId).nombre;

            const configuracion = new ConfiguracionXMLHttp({
              tipoPeticion: 'POST',
              body: body,
              token: token,
              urlEndpoint: endpoints.base.url(VENTAS, COTIZACION),
              formatoArchivo: 'pdf',
              visualizar: true,
            });

            peticionXMLHttp(configuracion);
          }
        }
      }
    });
  }

  /**
   * La función `alPerderFocoTotal` calcula el monto financiado en función de los valores de entrada y
   * actualiza el estado en consecuencia.
   * @param e - Evento que dispara la función.
   */
  const alPerderFocoTotal = (e) => {
    const valorLimpio = e.target.value.replace(/\D/g, '');
    const impTotal = (Number(valorLimpio) / 100);
    const { cantidadDescuento, anticipo } = getValues();
    const financiado = parseFloat(impTotal, 10) - parseFloat(cantidadDescuento, 10) - parseFloat(anticipo, 10);
    reset((curr) => ({ ...curr, financiado }));
  }

  /**
   * La función `alPerderFocoDescuento` calcula el monto financiado luego de aplicar un descuento en
   * base a los datos ingresados por el usuario.
   * @param e - Evento que dispara la función.
   */
  const alPerderFocoDescuento = (e) => {
    const valorLimpio = e.target.value.replace(/\D/g, '');
    const impDescuento = (Number(valorLimpio) / 100);
    const { total, anticipo } = getValues();
    const financiado = parseFloat(total, 10) - parseFloat(impDescuento, 10) - parseFloat(anticipo, 10);
    reset((curr) => ({ ...curr, financiado }));
  }

  /**
   * Función que obtiene la información del lote en base al id proporcionado en la ruta.
   */
  const obtenerInformacionLote = useCallback(async () => {
    if (loteId) {
      const lote = await axios.get(endpoints.base.url(LOTES, loteId))
      if (lote) {
        seleccionarLote(lote);
      } else {
        toast.warning(LOTE_VENTA_NO_ENCONTRADO);
        regresar();
      }
    }
  }, [loteId]);

  /**
   * Evento que establece el valor de la cantidad de descuento a 0
   * cuando no aplica descuento.
   */
  useEffect(() => {
    const { financiado, cantidadDescuento } = getValues();

    if (!descuento && cantidadDescuento > 0) {
      reset((curr) => ({
        ...curr,
        financiado: parseFloat(financiado) + parseFloat(cantidadDescuento),
        cantidadDescuento: 0,
      }));
    }

  }, [descuento]);

  /**
   * Evento que recalcula la cantidad de comisión de acuerdo a lo configurado
   * por empresa.
   */
  useEffect(() => {
    let cantidadComision = 0;
    let cantidadComisionApartado = 0;
    if (comision) {
      if (id) {
        cantidadComision = venta?.cantidadComision;
        cantidadComisionApartado = venta?.cantidadComisionApartado;
      } else {
        cantidadComision = id ? venta?.cantidadComision : proyecto?.comisionFija ?
          proyecto?.cantidadComision : (
            (proyecto?.cantidadComision * (lote?.precioTotal || venta?.total)) / 100
          ) || 0;
        cantidadComisionApartado = proyecto?.apartadoComision;
      }
    }
    reset((curr) => ({
      ...curr,
      cantidadComision,
      cantidadComisionApartado,
    }));
  }, [comision]);

  /**
   * Evento que recalcula la cantidad meses a financiar de cacuerdo al
   * tipo de crédito seleccionado.
   */
  useEffect(() => {
    if (!!tipoCredito) {
      let mesesFinanciamiento;
      let tasaInteres = 0;
      switch (tipoCredito) {
        case 'MCI':
          tasaInteres = (venta && venta.tipoCredito === tipoCredito) ? venta.tasaInteres : proyecto?.tasaInteres;
          mesesFinanciamiento = (venta && venta.tipoCredito === tipoCredito) ? venta.mesesFinanciamiento.toString() : proyecto?.mesesConIntereses.toString();
          break;
        case 'MSI':
          mesesFinanciamiento = (venta && venta.tipoCredito === tipoCredito) ? venta.mesesFinanciamiento.toString() : proyecto?.mesesSinIntereses.toString();
          break;
        default:
          break;
      }

      reset((curr) => ({ ...curr, mesesFinanciamiento, tasaInteres }));
    }
  }, [tipoCredito]);

  /**
   * Evento que restablece el valor del tipo de crédito cuando la venta
   * no es a crédito.
   */
  useEffect(() => {
    if (!credito) {
      const { total } = getValues();
      reset((curr) => ({ ...curr, tipoCredito: null, enganche: total, anticipoMinimo: total }));
    } else if (proyecto) {
      const { anticipoFijo, cantidadAnticipo } = proyecto;
      reset((curr) => (
        {
          ...curr,
          enganche: anticipoFijo ?
            cantidadAnticipo : (lote.precioTotal * cantidadAnticipo / 100),
          anticipoMinimo: proyecto?.apartadoMinimo,
        }));
    }
  }, [credito]);

  /** Effect para ejecutar la función obtenerInformacionLote cada que cambie. */
  useEffect(() => obtenerInformacionLote(), [obtenerInformacionLote])

  /**
   * Evento tipo constructor al entrar a la pantalla de venta.
   */
  useEffect(() => {
    if (loteId) {
      history.replace({ search: `loteId=${loteId}` });
    }
    consultarDatosIniciales();
  }, [id]);

  return <Default
    titulo={Boolean(id) ? 'Editar Venta' : 'Nueva Venta'}
    placeHolder={''}
    frmId="frmVenta"
    cancelar={regresar}
  >
    <FormProvider {...methods}>
      <form noValidate onSubmit={handleSubmit(alEnviar)} id="frmVenta">
        <Grid container spacing={2} alignItems="flex-end">
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <Boton
              fullWidth
              icono={<BsFillSignpost2Fill />}
              label="Buscar Lote"
              onClick={() => setAyudaLoteAbierto(true)}
              classesCustom={{ boton: classes.btnLote }}
              disabled={!!id}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <Grid container alignItems="flex-end">
              <Grid item xs={10}>
                <AutoComplete
                  name="clienteId"
                  label="Cliente:"
                  options={clientes}
                  className={classes.control}
                  error={errors.clienteId}
                  helperText={errors.clienteId?.message}
                  required
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  style={{ marginLeft: 3, padding: 7 }}
                  onClick={agregarCliente}
                >
                  <AiOutlineUserAdd size={30} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <AutoComplete
              name="vendedorId"
              label="Vendedor:"
              options={vendedores}
              className={classes.control}
              error={errors.vendedorId}
              helperText={errors.vendedorId?.message}
              required
              disabled={!!id}
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <TextField
              name="fecha"
              type="date"
              label="Fecha:"
              className={classes.control}
              error={errors.fecha}
              helperText={errors.fecha?.message}
              required
              disabled={!!id}
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <CustomSelect
              name="credito"
              label="Tipo Venta:"
              options={tiposVenta}
              className={classes.control}
              error={errors.credito}
              helperText={errors.credito?.message}
              required
              disabled={!!id}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="flex-end" style={{ marginTop: 20 }}>
          <Grid item xs={12}>
            <Typography
              component="h2"
            >
              Lote: {lote?.clave}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="flex-end" style={{ marginBottom: 15 }}>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <TextField
              name="enganche"
              type="coin"
              label="Enganche:"
              placeHolder="$0.00"
              className={classes.control}
              error={errors.enganche}
              helperText={errors.enganche?.message}
              disabled={!credito || !!id}
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <TextField
              name="anticipoMinimo"
              type="coin"
              label="Mínimo:"
              placeHolder="$0.00"
              className={classes.control}
              error={errors.anticipoMinimo}
              helperText={errors.anticipoMinimo?.message}
              disabled={!credito || !!id}
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <TextField
              name="anticipo"
              type="coin"
              label="Anticipo Recibido:"
              placeHolder="$0.00"
              disabled
              className={classes.control}
              error={errors.anticipo}
              helperText={errors.anticipo?.message}
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <TextField
              name="total"
              onBlur={alPerderFocoTotal}
              type="coin"
              label="Importe Total:"
              placeHolder="$0.00"
              className={classes.control}
              error={errors.total}
              helperText={errors.total?.message}
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <TextField
              name="financiado"
              type="coin"
              label="Monto a Financiar"
              placeHolder="$0.00"
              disabled
              className={classes.control}
              error={errors.financiado}
              helperText={errors.financiado?.message}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="flex-end" style={{ marginBottom: 15 }}>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <CustomSelect
              name="tipoCredito"
              options={tiposCredito}
              label="Tipo crédito:"
              disabled={!credito || !!id}
              placeHolder="Selecciona una opción"
              className={classes.control}
              error={errors.tipoCredito}
              helperText={errors.tipoCredito?.message}
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <TextField
              name="mesesFinanciamiento"
              type="number"
              label="Meses:"
              placeHolder="Meses:"
              disabled={!credito || !!id}
              className={classes.control}
              error={errors.mesesFinanciamiento}
              helperText={errors.mesesFinanciamiento?.message}
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <TextField
              name="tasaInteres"
              type="number"
              label="Tasa de interés:"
              placeHolder="Tasa de interés:"
              disabled={tipoCredito !== 'MCI' || !!id}
              className={classes.control}
              error={errors.tasaInteres}
              helperText={errors.tasaInteres?.message}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Grid container alignItems="flex-end">
              <Grid item xs={6}>
                <IconButton style={{ marginLeft: 3, marginBottom: 2, padding: 7 }} onClick={generarCotizacion}>
                  <LuCalendarClock size={30} color={paleta.bar.icono} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="flex-end" style={{ marginTop: 10 }}>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <CustomSelect
              name="descuento"
              options={opcionesAplica}
              label="Descuento:"
              placeHolder="Selecciona una opción"
              className={classes.control}
              error={errors.descuento}
              helperText={errors.descuento?.message}
              disabled={!!id || !lote}
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <TextField
              name="cantidadDescuento"
              type="coin"
              onBlur={alPerderFocoDescuento}
              label="Importe Descuento:"
              placeHolder="$0.00"
              disabled={!descuento || !!id}
              className={classes.control}
              error={errors.cantidadDescuento}
              helperText={errors.cantidadDescuento?.message}
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <CustomSelect
              name="comision"
              options={opcionesAplica}
              label="Comisión:"
              placeHolder="Selecciona una opción"
              className={classes.control}
              error={errors.comision}
              helperText={errors.comision?.message}
              disabled={!!id}
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <TextField
              name="cantidadComision"
              type="coin"
              label="Importe Comisión:"
              placeHolder="$0.00"
              disabled={!comision || !!id}
              className={classes.control}
              error={errors.cantidadComision}
              helperText={errors.cantidadComision?.message}
            />
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <TextField
              name="cantidadComisionApartado"
              type="coin"
              label="Importe Comisión Apartado:"
              placeHolder="$0.00"
              disabled={!comision || !!id}
              className={classes.control}
              error={errors.cantidadComisionApartado}
              helperText={errors.cantidadComisionApartado?.message}
            />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
    <VentaAnticipos
      datos={anticipos}
      onChange={alCambiarAnticipos}
      deshabilitado={!!id}
      proyectoId={proyecto?.id}
    />
    <BusquedaLotes
      abierto={ayudaLoteAbierto}
      handleClose={cerrarAyudaLote}
      seleccionarLote={seleccionarLote}
    />
    <NuevoCliente
      abierto={nuevoClienteAbierto}
      cerrar={cerrarNuevoCliente}
      empresaId={lote?.manzana?.proyecto?.empresaId}
    />
  </Default >;
};

export default React.memo(Venta);