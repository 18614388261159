import { AiOutlineHome } from "react-icons/ai";
import { GrCatalog } from "react-icons/gr";
import { HiOutlineBuildingOffice } from "react-icons/hi2";
import { FaUsers, FaUserTie, FaRoad, FaRegMap, FaMoneyBillTransfer, FaMagnifyingGlassDollar } from "react-icons/fa6";
import { FaFunnelDollar, FaMapSigns, FaFileInvoiceDollar, FaMapMarked } from "react-icons/fa";
import { TbReport, TbReportMoney, TbReportAnalytics } from "react-icons/tb";
import { LuClipboardList } from "react-icons/lu";
import { BiArchive } from "react-icons/bi";
import { BsFillKeyFill } from "react-icons/bs";
import { MdOutlinePointOfSale } from "react-icons/md";

/* eslint-disable import/no-anonymous-default-export */
export const MENU_OPCIONES = [
  {
    label: "Inicio",
    path: "/dashboard",
    index: 0,
    icono: <AiOutlineHome size={20} />
  },
  {
    label: "Catálogos",
    submenu: [
      { label: 'Empresas', sublabel: 'Catálogo', link: '/catalogos/empresas', id: 5, icono: <HiOutlineBuildingOffice /> },
      { label: 'Usuarios', sublabel: 'Catálogo', link: '/catalogos/usuarios', id: 3, icono: <FaUsers /> },
      { label: 'Perfiles', sublabel: 'Catálogo', link: '/catalogos/perfiles', id: 4, icono: <BsFillKeyFill /> },
      { label: 'Clientes', sublabel: 'Catálogo', link: '/catalogos/clientes', id: 6, icono: <FaUserTie /> },
      { label: 'Proyectos', sublabel: 'Catálogo', link: '/catalogos/proyectos', id: 7, icono: <FaRegMap /> },
      { label: 'Manzanas', sublabel: 'Catálogo', link: '/catalogos/manzanas', id: 8, icono: <FaRoad /> },
      { label: 'Lotes', sublabel: 'Catálogo', link: '/catalogos/lotes', id: 9, icono: <FaMapSigns /> },
      { label: 'Mapas', sublabel: 'Catálogo', link: '/catalogos/mapas', id: 19, icono: <FaMapMarked /> },
      { label: 'Cuentas', sublabel: 'Catálogo', link: '/catalogos/cuentas', id: 11, icono: <FaFunnelDollar /> },
    ],
    path: "/dashboard",
    index: 1,
    icono: <GrCatalog />
  },
  {
    id: 10,
    label: "Ventas",
    path: "/dashboard",
    submenu: [
      { label: 'Ventas', sublabel: 'Ventas', link: '/ventas/ventas', id: 12, icono: <TbReportMoney /> },
      { label: 'Flujos', sublabel: 'Flujos', link: '/ventas/flujos', id: 13, icono: <FaMoneyBillTransfer /> },
      { label: 'Cobranzas', sublabel: 'Ventas', link: '/ventas/cobranzas', id: 14, icono: <FaMagnifyingGlassDollar /> },
      { label: 'Expedientes', sublabel: 'Ventas', link: '/ventas/expedientes', id: 20, icono: <BiArchive /> },
    ],
    index: 2,
    icono: <MdOutlinePointOfSale />,
  },
  {
    id: 15,
    label: "Reportes",
    path: "/dashboard",
    submenu: [
      { label: 'Ventas', sublabel: 'Ventas', link: '/reportes/ventas', id: 16, icono: <FaFileInvoiceDollar /> },
      { label: 'Flujos', sublabel: 'Flujos', link: '/reportes/flujos', id: 17, icono: <TbReportAnalytics /> },
      { label: 'Clientes', sublabel: 'Clientes', link: '/reportes/clientes', id: 18, icono: <LuClipboardList /> },
    ],
    index: 3,
    icono: <TbReport />,
  },
];

const SET_INDEX_ACTIVO = 'SET_INDEX_ACTIVO';
const SET_SIDEBAR_ISOPEN = 'SET_SIDEBAR_ISOPEN';
const SET_PERMISOS = 'SET_PERMISOS';
const QUITAR_PERMISOS = 'QUITAR_PERMISOS';
const OCULTAR_OPCIONES = 'OCULTAR_OPCIONES';
const SET_MENU_ACTIVO = 'SET_MENU_ACTIVO';
const SET_SUBMENU_ACTIVO = 'SET_SUBMENU_ACTIVO';

const tabInitIndex = Number(sessionStorage.getItem('tabIndex')) || 0;

const initialState = {
  indexActivo: tabInitIndex,
  sidebarIsOpen: true,
  permisos: [],
  permisosConsultados: false,
  ocultarOpciones: false,
  menuActivo: {},
  submenuActivo: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_INDEX_ACTIVO:
      return { ...state, indexActivo: payload };
    case SET_SIDEBAR_ISOPEN:
      return { ...state, sidebarIsOpen: !state.sidebarIsOpen };
    case SET_PERMISOS:
      return { ...state, permisos: payload, permisosConsultados: true };
    case QUITAR_PERMISOS:
      return { ...state, permisos: [], permisosConsultados: false };
    case OCULTAR_OPCIONES:
      return { ...state, ocultarOpciones: payload };
    case SET_MENU_ACTIVO:
      return { ...state, menuActivo: payload };
    case SET_SUBMENU_ACTIVO:
      return { ...state, submenuActivo: payload };
    default:
      return state;
  }
};

export const setOpcionActiva = (payload) => ({
  type: SET_INDEX_ACTIVO,
  payload,
});

export const setSidebarIsOpen = () => ({ type: SET_SIDEBAR_ISOPEN });

export const setPermisos = (payload) => ({ type: SET_PERMISOS, payload });

export const quitarPermisos = () => ({ type: QUITAR_PERMISOS });

export const ocultarOpciones = (payload) => ({ type: OCULTAR_OPCIONES, payload });

export const setMenuActivo = (payload) => ({ type: SET_MENU_ACTIVO, payload });

export const setSubmenuActivo = (payload) => ({ type: SET_SUBMENU_ACTIVO, payload });