import { makeStyles } from '@material-ui/core';
import paleta from '../../configuraciones/paleta';

const styles = makeStyles(() => ({
  root: {
    marginBottom: 16,
    '& > .MuiGrid-item': {
      paddingBlock: 4,
    },
    '& .MuiGrid-item': {
      paddingInline: 8,
    },
  },
  header: {
    width: 150,
  },
  chip: {
    width: 80,
    '&.Cobrado': { backgroundColor: '#409A8E' },
    '&.Vencido': { backgroundColor: '#DD524C' },
    '&.No': { backgroundColor: '#D6E4E3' },
    '&.Eliminado': { backgroundColor: paleta.activo.primary, color: 'white' },
  },
  btnCancelar: {
    backgroundColor: paleta.boton.white,
    '& .MuiTypography-root': {
      color: `${paleta.boton.primary} !important`,
    },
    '&:hover': {
      backgroundColor: paleta.boton.white,
    }
  },
  tablaFlujos: {
    marginTop: '40px',
    maxHeight: '50vh',
    minHeight: '50vh',
  },
}));

export default styles;