import React from 'react';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Spinner from './componentes/Spinner';
import Routes from './configuraciones/rutas';
import TemaDefault from './temas/default';
import store from './ducks';

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={TemaDefault}>
        <CssBaseline />
        <ToastContainer />
        <Routes />
        <Spinner />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
