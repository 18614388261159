import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Grid } from '@material-ui/core';

import AccionesCabecero from '../../componentes/AccionesCabecero';
import AutoComplete from '../../componentes/AutoComplete';
import TextField from '../../componentes/TextField';
import Select from '../../componentes/Select';

import axios from '../../configuraciones/axios';
import config from '../../configuraciones/general';
import endpoints, {
  CUENTAS,
  EMPRESAS, TIPOS_MOVIMIENTO, TIPO_CUENTA_PROYECTO,
} from '../../configuraciones/endpoints';

import tipoFlujos from '../../constantes/tipoFlujos';
import tipoFormaPago from '../../constantes/tipoFormaPago';

import styles from './styles';

const FiltroFlujos = () => {

  const classes = styles();
  const [empresas, setEmpresas] = useState([]);
  const [proyectos, setProyectos] = useState([]);
  const [cuentas, setCuentas] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [tipos, setTipos] = useState([]);

  const { watch, setValue } = useFormContext();

  const empresaId = watch('empresaId');
  const proyectoId = watch('proyectoId');
  const tipoCuentaId = watch('tipoCuentaId');
  const busqueda = watch('busqueda');
  const ingreso = watch('ingreso');

  /**
   * Método que obtiene los datos iniciales de la pantalla: Empresas
   * @returns Promesa con datos.
   */
  const consultarDatosIniciales = () => new Promise((resolve, reject) => {
    const promesas = [
      axios.get(endpoints.base.opciones(EMPRESAS)),
      axios.get(endpoints.base.opciones(TIPOS_MOVIMIENTO)),
    ];

    Promise.all(promesas).then((res) => {
      const [bdEmpresas, bdTipos] = res;
      setEmpresas(bdEmpresas);
      setTipos(bdTipos);

      resolve();
    }).catch(reject);
  });

  /**
   * Obtiene los proyectos con base a la empresa seleccionada.
   */
  const consultarProyectos = useCallback(async () => {
    const bdProyectos = await axios.get(endpoints.proyectosPorEmpresa(empresaId));
    setProyectos(bdProyectos);
  }, [empresaId]);

  /**
   * Obtiene las cuentas con base al proyecto seleccionado.
   */
   const consultarCuentas = useCallback(async () => {
    if (tipoCuentaId || proyectoId) {
      const bdCuentas = await axios.get(
        endpoints.base.url(CUENTAS, TIPO_CUENTA_PROYECTO),
        {
          params: {
            tipoCuentaId,
            proyectoId,
          }
        },
      );
      setCuentas(bdCuentas);
    }
  }, [tipoCuentaId, proyectoId]);

  /**
   * Método que consulta los clientes relacionados a las ventas del proyecto seleccionado.
   */
  const consultarClientes = useCallback(async () => {
    if (proyectoId) {
      const bdClientes = await axios.get(endpoints.clientesPorProyecto(proyectoId));
      setClientes(bdClientes);
    }
  }, [proyectoId]);

  /**
   * useEffect utilizado para consultar los proyectos cuando la empresa seleccionada cambie.
   */
  useEffect(() => {
    if (empresaId) consultarProyectos();
  }, [consultarProyectos, empresaId]);

  /**
   * useEffect utilizado para consultar las cuentas cuando la función cambie.
   */
  useEffect(() => consultarCuentas(), [consultarCuentas]);

  /**
   * useEffect utilizado para consultar los clientes cuando la función cambie.
   */
  useEffect(() => consultarClientes(), [consultarClientes]);

  /**
   * useEffect utilizado para reasignar el valor del campo 'ingreso' cuando sea
   * un valor de tipo 'string'.
   */
  useEffect(() => {
    if (typeof ingreso === 'string') setValue('ingreso', undefined);
  }, [ingreso, setValue]);

  useEffect(() => {
    consultarDatosIniciales();
  }, []);

  /**
   * Valida la longitud del campo de búsqueda para no dejar escribir
   * más de 9 caracteres.
   */
  useEffect(() => {
    if (busqueda?.length >= config.INT_MAXLENGTH)
      setValue('busqueda', busqueda.substring(0, config.INT_MAXLENGTH));
  }, [setValue, busqueda]);

  /*  La función `limpiarProyecto` borra los valores de `clienteId` y `tipoCuentaId`. */
  const limpiarProyecto = useCallback(() => {
    setValue('clienteId', undefined);
    setClientes([]);
    setValue('cuentaId', undefined);
    setCuentas([]);
  },[setValue]);

  /*  La función `limpiarEmpresa` borra los valores de `proyectoId`, `clienteId` y `tipoCuentaId`. */
  const limpiarEmpresa = useCallback(() => {
    setValue('proyectoId', undefined);
    setProyectos([]);
    limpiarProyecto();
  },[limpiarProyecto, setValue]);

  return (
    <Grid container justifyContent="flex-end" alignItems="flex-end" className={classes.root}>
      <Grid item xs={12} sm={6} md={3} lg={3}>
        <TextField
          name="fechaInicial"
          type="date"
          label="Fecha inicial:"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={3}>
        <TextField
          type="date"
          name="fechaFinal"
          label="Fecha final:"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={3}>
        <Select
          label="Tipo de flujo:"
          name="ingreso"
          placeHolder="Selecciona una opción"
          options={tipoFlujos}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={3}>
        <AutoComplete
          label="Forma de pago:"
          name="tipoCuentaId"
          options={tipoFormaPago}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={3}>
        <AutoComplete
          label="Empresa:"
          name="empresaId"
          options={empresas}
          onClear={limpiarEmpresa}

        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={3}>
        <AutoComplete
          label="Proyecto:"
          name="proyectoId"
          options={proyectos}
          onClear={limpiarProyecto}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={3}>
        <AutoComplete
          label="Cuenta:"
          name="cuentaId"
          options={cuentas}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={3}>
        <AutoComplete
          label="Cliente:"
          name="clienteId"
          options={clientes}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={3}>
        <AutoComplete
          label="Tipo de movimiento:"
          name="tipoMovimientoId"
          options={tipos}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={3}>
        <TextField
          type="number"
          name="busqueda"
          placeHolder="Búsqueda por folio"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        <AccionesCabecero />
      </Grid>
    </Grid>
  )
}

export default FiltroFlujos;
