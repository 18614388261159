import { toast } from 'react-toastify';
import store from '../ducks';
import { endAjax, startAjax } from '../ducks/ajax';

import configuraciones from '../configuraciones/general';
import { ERROR_PETICION_GENERAL } from '../constantes/mensajes';

export class ConfiguracionXMLHttp {
  constructor({
    tipoPeticion = 'GET',
    body = {},
    token = '',
    urlEndpoint = '',
    nombreArchivo = 'archivo',
    formatoArchivo = 'pdf',
    visualizar = false,
    mensajeNoAceptable = '',
  } = {}) {
    this.tipoPeticion = tipoPeticion;
    this.body = body;
    this.token = token;
    this.urlEndpoint = urlEndpoint;
    this.nombreArchivo = nombreArchivo;
    this.formatoArchivo = formatoArchivo;
    this.visualizar = visualizar;
    this.mensajeNoAceptable = mensajeNoAceptable;
  }
}

/**
 * La función `peticionXMLHttp` envía una XMLHttpRequest basada en la configuración proporcionada y
 * maneja la respuesta en consecuencia.
 */
export const peticionXMLHttp = (configuracion) => {
  return new Promise((resolve, reject) => {
    const { dispatch } = store;

    const formatos = {
      'EXCEL': 'xlsx',
      'WORD': 'docx',
      'PDF': 'pdf'
    };
    
    configuracion.formatoArchivo = formatos[configuracion.formatoArchivo.toUpperCase()];    

    dispatch(startAjax());
    const xhr = new XMLHttpRequest();
    xhr.open(configuracion.tipoPeticion, `${configuraciones.URL_API}${configuracion.urlEndpoint}`, true);
    
    // Configurar responseType según el formato del archivo esperado
    xhr.responseType = configuracion.formatoArchivo === 'pdf' || configuracion.formatoArchivo === 'docx' ? 'blob' : 'arraybuffer';
    
    xhr.setRequestHeader('Authorization', `${configuracion.token}`);
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xhr.setRequestHeader("TimeZone", Intl.DateTimeFormat().resolvedOptions().timeZone);

    xhr.onload = function () {
      if (this.status === 200) {
        const type = `application/${configuracion.formatoArchivo}`;
        const blob = new Blob([this.response], { type });

        if (configuracion.visualizar) {
          const url = URL.createObjectURL(blob);
          window.open(url, '_blank');
        } else {
          const link = document.createElement('a');
          link.setAttribute('download', `${configuracion.nombreArchivo}.${configuracion.formatoArchivo}`);
          link.href = window.URL.createObjectURL(blob);
          link.target = '_blank';
          link.click();
        }
        resolve(this.response);  // Resuelve la promesa con la respuesta
      } else if (this.status === 406 && configuracion.mensajeNoAceptable) {
        toast.warning(configuracion.mensajeNoAceptable);
        reject(new Error(configuracion.mensajeNoAceptable)); // Rechaza la promesa con el mensaje de error
      } else {
        const errorMessage = ERROR_PETICION_GENERAL + this.statusText;
        toast.error(errorMessage);
        reject(new Error(errorMessage));  // Rechaza la promesa con el mensaje de error
      }
      dispatch(endAjax());
    };
    
    xhr.onerror = function () {
      const errorMessage = ERROR_PETICION_GENERAL + this.statusText;
      toast.error(errorMessage);
      reject(new Error(errorMessage));  // Rechaza la promesa con el mensaje de error
      dispatch(endAjax());
    };

    xhr.send(configuracion.tipoPeticion.toUpperCase() !== 'GET' ? JSON.stringify(configuracion.body) : null);
  });
};
